import { Fragment } from 'react';
import classNames from 'classnames/bind';
import { ErrorMessage } from 'formik';
import _ from 'lodash';

import {
  IErrorInput,
  ElementTextClassnames,
  ElementBgClassnames
} from '../../interfaces';

interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    IErrorInput {
  rows?: number;
  withFormikError?: boolean;
}

const TextArea = ({
  withFormikError = false,
  error,
  rows = 4,
  ...restProps
}: ITextAreaProps): JSX.Element => (
  <Fragment>
    <textarea
      className={classNames(
        'block w-full rounded-md border border-transparent focus:border-gray-300 focus:ring-0 focus:text-white',
        ElementBgClassnames.primary,
        {
          'border-red-300 border-2': error,
          [ElementTextClassnames.danger]: error
        }
      )}
      rows={rows}
      {...restProps}
    />
    {withFormikError && (
      <ErrorMessage
        name={restProps.name || ''}
        render={(msg) => (
          <div
            className={classNames(ElementTextClassnames.danger, 'block mt-1')}
          >
            {_.upperFirst(_.toLower(_.startCase(msg)))}
          </div>
        )}
      />
    )}
  </Fragment>
);

export default TextArea;
