import { Formik } from 'formik';
import { Fragment, useEffect, useMemo, useState } from 'react';
import apiAgent from '../../api/apiAgent';
import {
  ContainerFull,
  MainPanel,
  PageSubDescription,
  PageTitle,
  Pagination,
  ReactTable,
  SearchForm,
  StyledBtnRouterLink,
  WithLoadingDiv
} from '../../components';
import { ElementTextClassnames, ElementVariant } from '../../interfaces';
import { toastHandler } from '../../utils';
import * as Yup from 'yup';

const Alias = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [aliases, setAliases] = useState([]);
  const [searchText, setSearchText] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState<any>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const { totalPages, totalItems, currentPageCount, itemsPerPage } =
    paginationInfo;

  const fetchAliases = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      //fetch from backend
      const result = await (
        await apiAgent.Alias.getAliases({
          searchText: searchText !== '' ? searchText : undefined,
          page,
          limit
        })
      ).data;
      setAliases(result.data.aliases);
      const pageInfo = result.meta;
      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAliases({
      page: 1,
      limit: 12
    });
  }, [paginationInfo.itemsPerPage, searchText]);

  const formikSchema = {
    initialValues: {
      searchText: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setPaginationInfo({
        ...paginationInfo,
        currentPageCount: 1
      });
    },
    validateOnChange: true
  };
  const columns = useMemo(
    () => [
      {
        Header: 'IFA',
        accessor: 'ifa', // accessor is the "key" in the data
        thClassName: ElementTextClassnames.sysGenId
      },
      {
        Header: 'Alias',
        accessor: 'alias', // accessor is the "key" in the data
        thClassName: ElementTextClassnames.sysGenId
      }
    ],
    [JSON.stringify(aliases)]
  );

  return (
    <ContainerFull>
      <MainPanel>
        <div className="flex justify-between mb-4">
          <div>
            <PageTitle>Aliases</PageTitle>
            <PageSubDescription>Manage aliases for IFAs</PageSubDescription>
          </div>
          <div className="flex ">
            <div className="flex justify-end items-start">
              <Formik {...formikSchema}>
                {(formikProps) => (
                  <SearchForm
                    {...formikProps}
                    placeholder="e.g. Alias, IFA"
                    currentSearch={searchText}
                  />
                )}
              </Formik>
            </div>
            <div className="text-right ml-2">
              <StyledBtnRouterLink
                to={'/alias/new'}
                variant={ElementVariant.info}
                className=" mb-5"
              >
                Edit Aliases
              </StyledBtnRouterLink>
            </div>
            {/* <div className="flex justify-end items-start">
              <Formik {...formikSchema}>
                {(formikProps) => (
                  <SearchForm
                    {...formikProps}
                    placeholder="e.g. alias, ifa"
                    currentSearch={searchText}
                  />
                )}
              </Formik>
            </div> */}
          </div>
        </div>
        <WithLoadingDiv isLoading={isLoading}>
          {aliases ? (
            <Fragment>
              <div className="-mx-5 mb-5 overflow-y-auto">
                <ReactTable columns={columns} data={aliases} />
              </div>
            </Fragment>
          ) : (
            <div className="text-3xl text-center my-10">No results found</div>
          )}
        </WithLoadingDiv>
        {aliases && (
          <Pagination
            totalPages={totalPages}
            totalItems={totalItems}
            currentPageCount={currentPageCount}
            itemsPerPage={itemsPerPage}
            fetchData={fetchAliases}
          />
        )}
      </MainPanel>
    </ContainerFull>
  );
};

export default Alias;
