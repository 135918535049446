import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import apiAgent from '../../api/apiAgent';
import {
  ContainerFull,
  Modal,
  PageSubDescription,
  PageTitle,
  StyledButton,
  Tooltip,
  WithLoadingDiv
} from '../../components';
import FileUpload from '../../components/form/FileUpload';
import Input from '../../components/form/Input';
import {
  ElementBgClassnames,
  ElementTextClassnames,
  ElementVariant
} from '../../interfaces';
import { toastHandler } from '../../utils';

interface Alias {
  alias: string;
  ifa: string;
}
type aliasKeys = keyof Alias;

const aliasesSchema = Yup.array().of(
  Yup.object({
    ifa: Yup.string().required().trim().min(1),
    alias: Yup.string().required().trim().min(1)
  })
);

const getDuplicateAliases = (
  aliases: { ifa: string; alias: string }[]
): { duplicateAliases: string[]; duplicateIfas: string[] } => {
  let ifaValuesSoFar = Object.create(null);
  let aliasValuesSoFar = Object.create(null);
  const duplicateAliases: string[] = [];
  const duplicateIfas: string[] = [];
  for (let i = 0; i < aliases.length; ++i) {
    let ifaValue = aliases[i].ifa;
    let aliasValue = aliases[i].alias;
    if (ifaValue in ifaValuesSoFar) {
      duplicateIfas.push(ifaValue);
    }
    if (aliasValue in aliasValuesSoFar) {
      duplicateAliases.push(aliasValue);
    }
    ifaValuesSoFar[ifaValue] = true;
    aliasValuesSoFar[aliasValue] = true;
  }
  return {
    duplicateIfas,
    duplicateAliases
  };
};

const AliasEdit = (): JSX.Element => {
  const [aliases, setAliases] = useState<Alias[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
  const [showBackModal, setShowBackModal] = useState<boolean>(false);
  const [showClearModal, setShowClearModal] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteAlias, setDeleteAlias] = useState<number>(-1);
  const [duplicateAliases, setDuplicateAliases] = useState<string[]>([]);
  const [duplicateIfas, setDuplicateIfas] = useState<string[]>([]);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);

  const fetchAliases = async () => {
    setIsLoading(true);
    const result: any = await apiAgent.Alias.getAllAliases();
    const fetchedAliases: any = result.data.data.aliases;
    setAliases(fetchedAliases);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAliases();
  }, []);

  const handleFileUpload = async (uploadFiles: any) => {
    Papa.parse(uploadFiles[0], {
      header: true,
      skipEmptyLines: true,
      complete: (rows) => {
        const csvRows: Array<any> = rows.data;
        try {
          //check if missing any data
          if (!csvRows[0].alias || !csvRows[0].ifa) {
            console.log(csvRows[0]);
            throw new Error('file should have alias and ifa columns');
          }

          let parsedAliases = [...aliases];
          for (let csvIndex = 0; csvIndex < csvRows.length; csvIndex++) {
            const csvRow = csvRows[csvIndex];
            if (csvRow.alias === '') {
              throw new Error('alias cannot be empty');
            }
            if (csvRow.ifa === '') {
              throw new Error('ifa cannot be empty');
            }
            parsedAliases.push({ ifa: csvRow.ifa, alias: csvRow.alias });
          }
          setAliases(parsedAliases);
          const { duplicateAliases, duplicateIfas } =
            getDuplicateAliases(parsedAliases);
          setDuplicateAliases(duplicateAliases);
          setDuplicateIfas(duplicateIfas);
        } catch (e: any) {
          toastHandler({
            messages: e.message,
            toastOptions: { type: 'error' }
          });
        }
      }
    });
  };

  const handleAddAlias = () => {
    let resultAliases = [...aliases];
    resultAliases.push({
      alias: '',
      ifa: ''
    });
    setAliases(resultAliases);
    setShouldScroll(true);
  };

  const handleAliasChange = (
    aliasIndex: number,
    value: any,
    field: aliasKeys
  ) => {
    let newArr: any = [...aliases];
    newArr[aliasIndex][field] = value;
    setAliases(newArr);
    const { duplicateAliases, duplicateIfas } = getDuplicateAliases(newArr);
    setDuplicateAliases(duplicateAliases);
    setDuplicateIfas(duplicateIfas);
  };

  const handleDeleteAlias = (index: number) => {
    setAliases([
      ...aliases.slice(0, index),
      ...aliases.slice(index + 1, aliases.length)
    ]);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const { duplicateAliases, duplicateIfas } = getDuplicateAliases(aliases);
      if (duplicateAliases.length > 0 || duplicateIfas.length > 0) {
        throw new Error('Duplicate IFAs and/or Aliases detected.');
      }
      await aliasesSchema.validate(aliases);
    } catch (e: any) {
      console.log(e);
      setIsSubmitting(false);
      setError(e.message);
      toastHandler({
        messages: e.message,
        toastOptions: { type: 'error' }
      });
      return;
    }

    try {
      //submit alias
      const result = await apiAgent.Alias.updateAliases(aliases);
      setError('Alias updated!');
      toastHandler({
        messages: 'Aliases updated!',
        toastOptions: { type: 'success' }
      });
      fetchAliases();
      setIsSubmitting(false);
    } catch (err: any) {
      console.log(err);
      setIsSubmitting(false);
      setError(err.response.data.message);
      toastHandler({
        messages: err.response.data.message,
        toastOptions: { type: 'error' }
      });
    }
  };
  const handleClear = () => {
    setAliases([]);
  };

  const scrollToNewAlias = () => {
    const scrollDiv = document.getElementById('scrollDiv');
    if (scrollDiv) {
      scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
      window.scrollTo(0, scrollDiv.offsetTop);
      scrollDiv.scrollTop = scrollDiv.scrollHeight;
    }
    setShouldScroll(false);
  };

  useEffect(() => {
    if (shouldScroll) {
      scrollToNewAlias();
    }
  }, [shouldScroll]);

  useEffect(() => {}, [aliases]);
  // TODO: on submit, (confirm modal), remove the error message
  return (
    <ContainerFull>
      <div className="px-12 ">
        <StyledButton className="mb-4" onClick={() => setShowBackModal(true)}>
          Back
        </StyledButton>
        <PageTitle>Editing Alias</PageTitle>
        <PageSubDescription>
          {`Aliases submitted will replace the existing iteration`}
        </PageSubDescription>
        <React.Fragment>
          <div className="flex">
            <PageTitle> Add from CSV Files</PageTitle>
            <Tooltip className="">
              {
                <div>
                  <div className="mb-2">Alias file columns: aliasId, ifa</div>
                </div>
              }
            </Tooltip>
          </div>
          <PageSubDescription>
            {`Uploaded files add to current form`}
          </PageSubDescription>
          <FileUpload withFilename={false} handleSetFiles={handleFileUpload}>
            Upload Alias files
          </FileUpload>
        </React.Fragment>
        <div className="flex">
          <div className="grow">
            <PageTitle>Alias</PageTitle>
          </div>
          <div>
            <StyledButton
              className="mr-4"
              onClick={handleAddAlias}
              variant={ElementVariant.info}
            >
              Add
            </StyledButton>
          </div>
          <div>
            <StyledButton
              className="grow-0"
              variant={ElementVariant.primaryAltTwo}
              onClick={() => setShowResetModal(true)}
            >
              Reset
            </StyledButton>
          </div>
          <div>
            <StyledButton
              className="ml-4"
              variant={ElementVariant.danger}
              onClick={() => setShowClearModal(true)}
            >
              Clear
            </StyledButton>
          </div>
        </div>

        <WithLoadingDiv isLoading={isLoading}>
          <div
            className={`relative w-full flex flex-col items-center py-8 rounded-lg  ${ElementBgClassnames.primaryAlt} overflow-y-scroll`}
            style={{ height: '80vh' }}
            id="scrollDiv"
          >
            {aliases.map((alias: Alias, aliasIndex: number) => {
              return (
                <div
                  id={`alias${aliasIndex}`}
                  className={`grid grid-cols-12 items-center border-lg shadow-lg border-gray-800 rounded-md mb-4 w-11/12 py-4 px-2 gap-3 ${ElementBgClassnames.primary}`}
                >
                  <div>IFA</div>
                  <Input
                    className={`col-span-4 py-1.5 ${ElementBgClassnames.primaryAltTwo}`}
                    value={alias.ifa}
                    type="text"
                    onChange={(e: any) =>
                      handleAliasChange(aliasIndex, e.target.value, 'ifa')
                    }
                  />
                  <div className="text-right">Alias</div>
                  <Input
                    className={`col-span-4   py-1.5 ${ElementBgClassnames.primaryAltTwo}`}
                    value={alias.alias}
                    type="text"
                    onChange={(e: any) => {
                      e.preventDefault();
                      handleAliasChange(aliasIndex, e.target.value, 'alias');
                    }}
                  />

                  <div className="col-start-12 flex justify-end">
                    <StyledButton
                      className=""
                      variant={ElementVariant.danger}
                      onClick={() => {
                        // call to backend to delete
                        // also expand with modal
                        setDeleteAlias(aliasIndex);
                        setShowDeleteModal(true);
                      }}
                    >
                      <i className="fa fa-trash-alt"></i>
                    </StyledButton>
                  </div>
                  <div
                    className={`col-start-2 col-span-4 ${ElementTextClassnames.danger}`}
                  >
                    {duplicateIfas.includes(alias.ifa)
                      ? 'IFA needs to be unique'
                      : ''}
                  </div>
                  <div
                    className={`col-start-7 col-span-4 ${ElementTextClassnames.danger}`}
                  >
                    {duplicateAliases.includes(alias.alias)
                      ? 'Alias needs to be unique'
                      : ''}
                  </div>
                </div>
              );
            })}
          </div>
        </WithLoadingDiv>
        <div className="w-full mt-4 mb-64 flex flex-col justify-center items-center">
          <div
            className={`${
              error === 'Alias updated!'
                ? ElementTextClassnames.success
                : ElementTextClassnames.danger
            }`}
          >
            {error}
          </div>
          <WithLoadingDiv isLoading={isSubmitting}>
            <div className="flex justify-center">
              <StyledButton
                className="my-2 mx-2"
                variant={ElementVariant.danger}
                onClick={() => {
                  setShowBackModal(true);
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                className="my-2  mx-2"
                variant={ElementVariant.success}
                onClick={() => {
                  setError('');
                  setShowSubmitModal(true);
                }}
              >
                Submit
              </StyledButton>
            </div>
          </WithLoadingDiv>
        </div>
      </div>
      <SubmitModal
        showModal={showSubmitModal}
        setShowExternal={setShowSubmitModal}
        handleSubmit={handleSubmit}
      />
      <BackModal showModal={showBackModal} setShowExternal={setShowBackModal} />
      <ClearModal
        showModal={showClearModal}
        setShowExternal={setShowClearModal}
        handleClear={handleClear}
      />
      <ResetModal
        showModal={showResetModal}
        setShowExternal={setShowResetModal}
        handleReset={fetchAliases}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowExternal={setShowDeleteModal}
        handleDelete={handleDeleteAlias}
        alias={aliases[deleteAlias]}
        aliasIndex={deleteAlias}
      />
    </ContainerFull>
  );
};

const SubmitModal = ({
  showModal,
  setShowExternal,
  handleSubmit
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
  handleSubmit: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm Submission?</Modal.Header>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal && setShowExternal(false);
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.success}
            onClick={() => {
              setShowExternal && setShowExternal(false);
              handleSubmit();
            }}
          >
            Submit
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const BackModal = ({
  showModal,
  setShowExternal
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
}) => {
  const navigate = useNavigate();
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm leave page?</Modal.Header>
        <Modal.Body>You'll lose your current draft</Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => navigate('/alias')}
          >
            Leave
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const ClearModal = ({
  showModal,
  setShowExternal,
  handleClear
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleClear: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm clear aliases?</Modal.Header>
        <Modal.Body>
          You can reset this before submitting by clicking the RESET button
        </Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal(false);
              handleClear();
            }}
          >
            Clear
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const ResetModal = ({
  showModal,
  setShowExternal,
  handleReset
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleReset: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm reset aliases?</Modal.Header>
        <Modal.Body>You'll lose the current draft</Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.info}
            onClick={() => {
              setShowExternal(false);
              handleReset();
            }}
          >
            Reset
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const DeleteModal = ({
  showModal,
  setShowExternal,
  handleDelete,
  alias,
  aliasIndex
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleDelete: Function;
  alias: Alias;
  aliasIndex: number;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        {alias && (
          <Modal.Header>{`Confirm delete alias(${alias.alias}) for IFA (${alias.ifa})?`}</Modal.Header>
        )}
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal(false);
              handleDelete(aliasIndex);
            }}
          >
            Delete
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

export default AliasEdit;
