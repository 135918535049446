import { ReactText } from 'react';
import { fromUnixTime, format } from 'date-fns';

const DATE_FORMAT = 'do MMM, yyyy';
const TIME_FORMAT = 'hh:mm aa';

const strftime = (
  dataTime: Date | string,
  dateFormat: string = `${DATE_FORMAT} - ${TIME_FORMAT}`
): string => {
  if (!dataTime) return '';

  const date = typeof dataTime === 'string' ? new Date(dataTime) : dataTime;

  return format(date, dateFormat);
};

interface IDateFormatter {
  timestamp: ReactText;
  dateFormat?: string;
  timeFormat?: string;
  withTime?: boolean;
}

const dateFormatter = ({
  timestamp,
  withTime,
  dateFormat = DATE_FORMAT,
  timeFormat = TIME_FORMAT
}: IDateFormatter): string => {
  if (!timestamp) return '';

  const unixTime: number =
    typeof timestamp === 'string' ? Date.parse(timestamp) / 1000 : timestamp;

  return strftime(
    fromUnixTime(unixTime),
    (withTime ? `${dateFormat} ${timeFormat}` : dateFormat) || 'do MMM, yyyy'
  );
};

const formatDateForDetails = (timestamp: ReactText): string => {
  return dateFormatter({
    timestamp,
    withTime: true,
    timeFormat: 'hh:mm:ss aa'
  });
};

const formatDateForListings = (timestamp: string): string => {
  return dateFormatter({
    timestamp: timestamp,
    withTime: true,
    dateFormat: 'd MMM yyyy',
    timeFormat: 'HH:mm:ss'
  });
};

const nl2br = (text: string): string => {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
};

export {
  dateFormatter,
  strftime,
  formatDateForDetails,
  formatDateForListings,
  nl2br
};
