import _ from 'lodash';
import React from 'react';
import { Row, useExpanded, useTable } from 'react-table';
import { ElementBgClassnames } from '../interfaces/index';
interface CustomRow<D extends object> extends Row<D> {
  isExpanded?: boolean;
  getToggleRowExpandedProps: () => any;
  toggleRowExpanded: () => void;
  toggleAllRowsExpanded: () => void;
}
const AccordionTable = ({
  columns,
  data,
  selectedFilter,
  setSelectedFilter,
  initialState,
  className,
  rowClassModifier,
  renderRowSubComponent
}: {
  columns: any;
  data: any;
  selectedFilter: number;
  setSelectedFilter: (id: number) => void;
  initialState?: any;
  className?: string;
  rowClassModifier?: (obj: any) => string;
  renderRowSubComponent: any;
}): JSX.Element => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state
  } = useTable({ columns, data, initialState }, useExpanded);
  return (
    <>
      <table
        {...getTableProps()}
        className={`${className || ''} w-full text-white`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    className: `py-2 text-xs bg-background-alt uppercase ${_.get(
                      column,
                      'thClassName'
                    )}`
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <div className="text-center text-2xl mt-10">
                  No results found
                </div>
              </td>
            </tr>
          )}
          {rows.map((row: any, index) => {
            prepareRow(row);
            const classMod = rowClassModifier
              ? rowClassModifier(row.original)
              : '';
            const currRowDetails = row.original;
            const filter = row.values.id;
            return (
              <React.Fragment key={index}>
                <tr
                  // {...row.getToggleRowExpandedProps()}
                  {...row.getRowProps()}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedFilter(filter);
                  }}
                  className={`${
                    selectedFilter === filter
                      ? `${ElementBgClassnames.success}`
                      : index % 2 === 0
                      ? 'bg-background-row-alt ' + classMod
                      : 'bg-background-alt ' + classMod
                  } hover:outline hover:outline-1 hover:outline-offset-0 h-[48px]`}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: `text-sm py-2 px-4 ${_.get(
                            cell.column,
                            'tdClassName',
                            'text-center'
                          )}`
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row.index, currRowDetails)}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default AccordionTable;
