import React from 'react';
import { ElementVariant, ElementTextClassnames } from '../interfaces';

interface ISpanProps extends React.HTMLAttributes<HTMLSpanElement> {
  readonly variant?: ElementVariant;
  readonly className?: string;
}

const Badge = (props: ISpanProps): JSX.Element => {
  const { variant, children, className, ...rest } = props;
  return (
    <span
      className={`inline-block rounded-lg bg-faded py-0.5 px-1.5 ${
        className ?? ''
      } ${ElementTextClassnames[variant || 'secondary']}`}
      {...rest}
    >
      {children}
    </span>
  );
};

export default Badge;
