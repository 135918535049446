import { Routes, Route } from 'react-router-dom';

import PrivateRoute from './private-route';
import SysLogin from '../domains/sys-login';
import CreateFilters from '../domains/campaigns/CreateFilters';
import MyProfileDashboard from '../domains/my-user/profile-dashboard';
import TargetedDevicesDashboard from '../domains/targeted-devices-dashboard';
import MainDashboard from '../domains/main-dashboard';

import Error404 from '../components/error-pages/404';
import { SignOut } from '../components';

/* User Management */
import UserIndex from '../domains/admin/users';
import UserCreate from '../domains/admin/users/UserCreate';
import UserEdit from '../domains/admin/users/UserEdit';

import SiteSettings from '../domains/admin/site-settings';
import CheckOtpSetup from '../domains/auth/CheckOtpSetup';
import VerifyOtp from '../domains/auth/VerifyOtp';
import FiltersLanding from '../domains/campaigns/FiltersLanding';
import FiltersList from '../domains/campaigns/FiltersList';
import ClusterTags from '../domains/cluster-tags/ClusterTags';
import CreateClusterTags from '../domains/cluster-tags/UpdateClusterTags';
import AlertRulesCreate from '../domains/alert-rules/detection/AlertRulesCreate';
import Alerts from '../domains/alerts';
import Alias from '../domains/alias';
import AliasCreate from '../domains/alias/AliasEdit';
import AlertRulesEdit from '../domains/alert-rules/detection/AlertRulesEdit';
import AlertRulesMain from '../domains/alert-rules';
import ThresholdAlertRulesCreate from '../domains/alert-rules/threshold/ThresholdAlertRulesCreate';
import ThresholdAlertRulesEdit from '../domains/alert-rules/threshold/ThresholdAlertRulesEdit';
import AuditTrail from '../domains/admin/audit-trail';

/*
  URL path definition should be kebab-case, e.g. /who-am-i
  When displaying a page to show resources, it should be pural e.g. assets, alerts, announcements.
  However in the case where pural form sounds illogical, e.g. respositories, change it to repository instead. 
*/
const CustomSwitch = (): JSX.Element => {
  return (
    <Routes>
      {/* Admin modules */}
      <Route
        path="/admin/site-settings"
        element={
          <PrivateRoute>
            <SiteSettings />
          </PrivateRoute>
        }
      />
      {/* Filter modules */}

      <Route
        path="/campaigns/list"
        element={
          <PrivateRoute>
            <FiltersList />
          </PrivateRoute>
        }
      />
      <Route
        path="/campaigns/create/:filterId"
        element={
          <PrivateRoute>
            <CreateFilters />
          </PrivateRoute>
        }
      />
      <Route
        path="/campaigns/create"
        element={
          <PrivateRoute>
            <CreateFilters />
          </PrivateRoute>
        }
      />

      {/* Main Dashboard */}
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <MainDashboard />
          </PrivateRoute>
        }
      />

      {/* Campaign Filters module */}
      <Route
        path="/campaigns"
        element={
          <PrivateRoute>
            <FiltersLanding />
          </PrivateRoute>
        }
      />

      {/* ClusterTags modules */}
      <Route
        path="/cluster-tags/create"
        element={
          <PrivateRoute>
            <CreateClusterTags />
          </PrivateRoute>
        }
      />

      <Route
        path="/cluster-tags"
        element={
          <PrivateRoute>
            <ClusterTags />
          </PrivateRoute>
        }
      />
      {/* Alert rules modules */}
      <Route
        path="/alert-rules/detection/:id/edit"
        element={
          <PrivateRoute>
            <AlertRulesEdit />
          </PrivateRoute>
        }
      />

      <Route
        path="/alert-rules/detection/new"
        element={
          <PrivateRoute>
            <AlertRulesCreate />
          </PrivateRoute>
        }
      />

      <Route
        path="/alert-rules/threshold/:id/edit"
        element={
          <PrivateRoute>
            <ThresholdAlertRulesEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/alert-rules/threshold/new"
        element={
          <PrivateRoute>
            <ThresholdAlertRulesCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/alert-rules"
        element={
          <PrivateRoute>
            <AlertRulesMain />
          </PrivateRoute>
        }
      />
      {/* Alert modules */}
      <Route
        path="/alerts"
        element={
          <PrivateRoute>
            <Alerts />
          </PrivateRoute>
        }
      />

      {/* Aliases modules */}
      <Route
        path="/alias/new"
        element={
          <PrivateRoute>
            <AliasCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/alias"
        element={
          <PrivateRoute>
            <Alias />
          </PrivateRoute>
        }
      />

      {/* Targeted Devices Dashboard */}
      <Route
        path="/targeted-devices-dashboard"
        element={
          <PrivateRoute>
            <TargetedDevicesDashboard />
          </PrivateRoute>
        }
      />

      {/* User Module */}
      <Route
        path="/admin/users/new"
        element={
          <PrivateRoute>
            <UserCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/users/:id/edit"
        element={
          <PrivateRoute>
            <UserEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/users"
        element={
          <PrivateRoute>
            <UserIndex />
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/audit-trail"
        element={
          <PrivateRoute>
            <AuditTrail />
          </PrivateRoute>
        }
      />

      <Route
        path="/my/profile-dashboard"
        element={
          <PrivateRoute>
            <MyProfileDashboard />
          </PrivateRoute>
        }
      />

      {/* Authentication modules */}
      <Route path="/auth/check-otp-setup" element={<CheckOtpSetup />} />

      <Route path="/auth/verify-otp" element={<VerifyOtp />} />
      <Route path="/" element={<SysLogin />} />
      <Route path="/sys-logout" element={<SignOut />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default CustomSwitch;
