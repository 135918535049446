const Paragraph = ({
  children,
  className
}: {
  children: React.ReactNode | string;
  className?: string;
}): JSX.Element => (
  <p className={`${className ?? ''} leading-7 text-gray-300 mb-1`}>
    {children}
  </p>
);

export default Paragraph;
