import { Fragment, useEffect, useState } from 'react';
import {
  ContainerFull,
  MainPanel,
  PageSubDescription,
  PageTitle,
  Pagination,
  ReactTable,
  WithLoadingDiv
} from '../../../components';
import apiAgent from '../../../api/apiAgent';
import { addSortableHeadersToColumns } from '../../../components/SortableHeaders';
import {
  ElementBgClassnamesWithHover,
  ElementTextClassnames,
  ISortState
} from '../../../interfaces';
import { dateFormatter, toastHandler } from '../../../utils';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { useAtom } from 'jotai';
import { fetchUserProfileAtom } from '../../../atoms';
import Error403 from '../../../components/error-pages/403';

const transformDataForTable = (list: any[]): any[] => {
  const data = list.map((obj: any) => {
    return {
      ...obj,
      object:
        JSON.parse(obj.object).query || JSON.stringify(obj.object) || 'N.A',
      createdAt: dateFormatter({
        timestamp: obj.createdAt,
        withTime: true
      })
    };
  });

  return data || [];
};

const AuditTrail = (): JSX.Element => {
  const [currentUser] = useAtom(fetchUserProfileAtom);

  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: moment().subtract(13, 'days').toDate(),
    endDate: moment().toDate()
  });
  const [isLoading, setIsLoading] = useState(true);
  const [auditTrail, setAuditTrail] = useState<any[]>([]);

  const onDateChange = (dates: any) => {
    const [start, end] = dates;
    setDateRange({ startDate: start, endDate: end });
  };

  const [paginationInfo, setPaginationInfo] = useState<any>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const { totalPages, totalItems, currentPageCount, itemsPerPage } =
    paginationInfo;

  const fetchAuditTrail = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await apiAgent.AuditTrail.getAll({
        page,
        limit,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        ...sortState
      });

      const pageInfo = result.data.meta;
      // console.log(
      //   `result is ${JSON.stringify(result.data.data.auditTrail, null, 2)}`
      // );
      setAuditTrail(transformDataForTable(result.data.data.auditTrail));

      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const auditTrailColumns = [
    {
      Header: 'ID',
      accessor: 'id',
      sortable: true,
      thClassName: ElementTextClassnames.sysGenId
    },
    { Header: 'Type', accessor: 'itemType', sortable: true },

    { Header: 'User', accessor: 'whodunnit', sortable: true },
    { Header: 'Event', accessor: 'event', sortable: true },
    { Header: 'Details', accessor: 'object', sortable: false },
    {
      Header: 'Time of Event',
      accessor: 'createdAt',
      sortable: true,
      thClassName: ElementTextClassnames.sysGenDate
    }
  ];

  const [sortState, setSortState] = useState<ISortState>({
    sortBy: 'id',
    desc: true
  });

  const sortColumn = (c: string) => {
    const order = sortState.sortBy === c ? !sortState.desc : sortState.desc;
    setSortState({ sortBy: c, desc: order });
  };

  const columns = addSortableHeadersToColumns(
    auditTrailColumns,
    sortState,
    sortColumn
  );

  useEffect(() => {
    if (currentUser.isAdmin) {
      fetchAuditTrail({
        page: 1,
        limit: paginationInfo.itemsPerPage
      });
    }
  }, [paginationInfo.itemsPerPage, dateRange, sortState]);

  if (!currentUser.isAdmin) {
    return <Error403 />;
  }

  return (
    <ContainerFull>
      <MainPanel className="mb-4 h-full">
        <div className="flex justify-between mb-4">
          <div className="text-white text-lg">
            <PageTitle>Audit Trail</PageTitle>
            <PageSubDescription>
              A log of events for logins and creation/updating/generation of all
              items.
            </PageSubDescription>
          </div>
          <div className="flex flex-wrap place-content-end space-x-3 justify-end">
            <DatePicker
              className={`rounded focus:text-white  border-0 w-full mt-2 ${ElementBgClassnamesWithHover.primary}`}
              selected={dateRange.startDate}
              onChange={onDateChange}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              selectsRange
              dateFormat="dd-MM-yyyy"
            />
          </div>
        </div>
        <WithLoadingDiv isLoading={isLoading}>
          {auditTrail ? (
            <Fragment>
              <div className="-mx-5 mb-5 overflow-y-auto">
                <ReactTable columns={columns} data={auditTrail} />
              </div>
            </Fragment>
          ) : (
            <div className="text-3xl text-center my-10">No results found</div>
          )}
        </WithLoadingDiv>
        {auditTrail && (
          <Pagination
            totalPages={totalPages}
            totalItems={totalItems}
            currentPageCount={currentPageCount}
            itemsPerPage={itemsPerPage}
            fetchData={fetchAuditTrail}
          />
        )}
      </MainPanel>
    </ContainerFull>
  );
};

export default AuditTrail;
