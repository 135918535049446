import React, {
  Fragment,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  BadgeBoolean,
  confirmAlert,
  Pagination,
  ReactTable,
  StyledButton,
  WithLoadingDiv
} from '../../../../components';
import {
  ElementBgClassnames,
  ElementTextClassnames,
  IPaginationState
} from '../../../../interfaces';
import { toastHandler } from '../../../../utils';
import classNames from 'classnames';
import apiAgent from '../../../../api/apiAgent';
import moment from 'moment';
import { FLASH_MESSAGES } from '../../../../cnstants';

interface IAlertTable {
  isLoading: boolean;
  alerts: any[] | null;
  aliases: any[] | null;
  clusterTags: any[] | null;
  paginationInfo: IPaginationState;
  updateAlertAcknowledged: (rowIndex: number) => void;
  fetchAlerts: ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }) => Promise<void>;
}

const AlertTable = ({
  isLoading,
  alerts,
  aliases,
  clusterTags,
  paginationInfo,
  updateAlertAcknowledged,
  fetchAlerts
}: IAlertTable) => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [alerts, setAlerts] = useState<any[] | null>(null);
  // const [aliases, setAliases] = useState<any[] | null>(null);
  // const [clusterTags, setClusterTags] = useState<any[] | null>(null);

  // const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
  //   totalItems: 0,
  //   currentPageCount: 0,
  //   itemsPerPage: 12,
  //   totalPages: 0
  // });

  // const fetchAlerts = async ({
  //   page,
  //   limit
  // }: {
  //   page: number;
  //   limit: number;
  // }): Promise<void> => {
  //   try {
  //     setIsLoading(true);
  //     let params: any = {};
  //     if (searchText) params.searchText = searchText;
  //     if (searchType) params.alertType = searchType;
  //     if (searchAcknowledged !== null)
  //       params.acknowledged = searchAcknowledged === 'yes';
  //     const result = await apiAgent.Alerts.getAlerts({
  //       ...params,
  //       page,
  //       limit
  //     });
  //     const aliases = await apiAgent.Alias.getAllAliases();
  //     const clusterTags = await apiAgent.ClusterTags.getAllClusterTags();
  //     setAlerts(result.data.data.alerts);
  //     setAliases(aliases.data.data.aliases);
  //     setClusterTags(clusterTags.data.data.clusterTags);
  //     const pageInfo = result.data.meta;
  //     console.log(pageInfo);
  //     setPaginationInfo({
  //       totalItems: pageInfo.totalCount,
  //       currentPageCount: pageInfo.currentPageCount,
  //       itemsPerPage: limit,
  //       totalPages: pageInfo.totalPages
  //     });
  //   } catch (error: any) {
  //     toastHandler({
  //       messages: error?.message,
  //       toastOptions: { type: 'error' },
  //       toastId: 'dashboard'
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchAlerts({
  //     page: 1,
  //     limit: paginationInfo.itemsPerPage
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paginationInfo.itemsPerPage, searchText, searchAcknowledged, searchType]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'alertName',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        )
      },
      {
        Header: 'Type',
        accessor: 'alertType',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        )
      },
      // {
      //   Header: 'count',
      //   accessor: 'count',
      //   thClassName: classNames(
      //     ElementTextClassnames.sysGenDate,
      //     'whitespace-nowrap px-2'
      //   )
      // },
      {
        Header: 'Cluster Tag',
        accessor: 'clusterTag',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        Cell: ({ row }: any) => {
          let filteredClusterTags = clusterTags?.filter((clusterTag: any) =>
            clusterTag.devices.some(
              (device: any) => device.ifa === row.values.ifa
            )
          );
          return (
            <div className="flex flex-wrap">
              {filteredClusterTags?.map((clusterTag) => (
                <div
                  className={`${ElementBgClassnames.primaryAltTwo}  rounded p-1 m-1 `}
                >
                  {clusterTag.clusterTagId}
                </div>
              ))}
            </div>
          );
        }
      },
      {
        Header: 'Alias',
        accessor: 'alias',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        Cell: ({ row }: any) => {
          let aliasObj = aliases?.find((alias: any) => {
            return alias.ifa === row.values.ifa;
          });
          return <div>{aliasObj?.alias}</div>;
        }
      },
      {
        Header: 'IFA',
        accessor: 'ifa',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        )
      },
      {
        Header: 'Location',
        accessor: 'alertLoc',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        )
      },

      {
        Header: 'Time',
        accessor: 'alertTime',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        Cell: ({ row }: any) => {
          const time = moment(row.values.alertTime);
          return <div>{time.format('DD/MM/YYYY hh:mm A')}</div>;
        }
      },
      {
        Header: 'Acknowledged?',
        accessor: 'acknowledged',
        Cell: ({ row }: any) => {
          return <BadgeBoolean value={row.values.acknowledged} />;
        }
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <div className="flex justify-center">
            <Fragment>
              {!row.values.acknowledged && (
                <AcknowledgeButtonWithState
                  alertId={row.original.id}
                  afterFunc={() => updateAlertAcknowledged(row.index)}
                />
              )}
            </Fragment>
          </div>
        )
      }
    ],
    [JSON.stringify(alerts)]
  );

  return (
    <GridChildTable className="col-span-6" title="Alerts">
      <WithLoadingDiv isLoading={isLoading}>
        <div className="-mx-5 mb-5 overflow-y-auto overflow-x-auto">
          <ReactTable
            columns={columns}
            data={alerts || []}
            className="w-full"
          />
        </div>
      </WithLoadingDiv>
      <div>
        {alerts && (
          <Pagination
            totalPages={paginationInfo.totalPages}
            totalItems={paginationInfo.totalItems}
            currentPageCount={paginationInfo.currentPageCount}
            itemsPerPage={paginationInfo.itemsPerPage}
            fetchData={fetchAlerts}
          />
        )}
      </div>
    </GridChildTable>
  );
};

const GridChildTable = ({
  children,
  className,
  title
}: {
  children: React.ReactNode;
  className?: string;
  title: string;
}): JSX.Element => (
  <div
    className={classNames(
      'rounded-md bg-background-alt py-4 px-5',
      className ?? ''
    )}
  >
    {/* <div className="text-center text-lg mb-2">{title}</div> */}
    <div>{children}</div>
  </div>
);

const AcknowledgeButtonWithState = (props: {
  alertId: number;
  afterFunc: Function;
}): JSX.Element => {
  const { alertId, afterFunc } = props;
  const [isLoading, setIsLoading] = useState(false);

  const acknowledgeAlert =
    (id: number): Function =>
    async () => {
      try {
        setIsLoading(true);
        const result = await apiAgent.Alerts.acknowledgeAlert(alertId);

        if (result.data.status === 'success') {
          toastHandler({
            messages: `Alert ${result?.data?.data?.alertName} acknowledged.`,
            toastOptions: { type: 'info' }
          });

          afterFunc();
        } else {
          throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
        }
      } catch (error: any) {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      } finally {
        setIsLoading(false);
      }
    };

  const handleOnClick =
    (id: number): MouseEventHandler<HTMLButtonElement> =>
    (event: any) => {
      event.preventDefault();

      confirmAlert({
        title: 'Confirm acknowledge alert',
        message: confirmationMessage(id),
        buttons: [
          {
            onClick: acknowledgeAlert(id)
          }
        ]
      });
    };

  return (
    <StyledButton loading={isLoading} onClick={handleOnClick(alertId)}>
      acknowledge
    </StyledButton>
  );
};

const confirmationMessage = (alertRuleId: number): JSX.Element => {
  return (
    <div className="mb-1" key={alertRuleId}>
      Are you sure you want to acknowledge this Alert? This process cannot be
      undone.
    </div>
  );
};

export default AlertTable;
