import { useTable } from 'react-table';
import _ from 'lodash';

const Table = ({
  columns,
  data,
  initialState,
  className,
  rowClassModifier
}: {
  columns: any;
  data: any;
  initialState?: any;
  className?: string;
  rowClassModifier?: (obj: any) => string;
}): JSX.Element => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      // @ts-ignore
      { columns, data, initialState }
    );

  return (
    <table
      {...getTableProps()}
      className={`${className || ''} w-full text-white`}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  className: `py-2 text-xs bg-background-alt uppercase ${_.get(
                    column,
                    'thClassName'
                  )}`
                })}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.length === 0 && (
          <tr>
            <td colSpan={columns.length}>
              <div className="text-center text-2xl mt-10">No results found</div>
            </td>
          </tr>
        )}
        {rows.map((row, index) => {
          prepareRow(row);
          const classMod = rowClassModifier
            ? rowClassModifier(row.original)
            : '';
          return (
            <tr
              {...row.getRowProps()}
              className={`${
                index % 2 === 0
                  ? 'bg-background-row-alt ' + classMod
                  : 'bg-background-alt ' + classMod
              }`}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps({
                      className: `text-sm py-2 px-4 ${_.get(
                        cell.column,
                        'tdClassName',
                        'text-center'
                      )}`
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
