import { AxiosError } from 'axios';

import { toastHandler } from '../utils';
import { FLASH_MESSAGES, HTTP_ERROR_CODES } from '../cnstants';
import { clearJwtCookie, clearLoginCookie } from '../auth';

// Only include critical errors here,
// DO NOT PUT IN UNPROCESSABLE_ENTITY, handle UNPROCESSABLE_ENTITY at your component level
// UNPROCESSABLE_ENTITY is for validation error
const errorHandler = (error: AxiosError) => {
  const { status } = error.response! || {};

  if (status === HTTP_ERROR_CODES.GATEWAY_TIMEOUT) {
    toastHandler({
      messages: FLASH_MESSAGES.GATEWAY_TIMEOUT,
      toastOptions: {
        type: 'error',
        toastId: HTTP_ERROR_CODES.GATEWAY_TIMEOUT
      }
    });
  } else if (status === HTTP_ERROR_CODES.UNAUTHORIZED) {
    clearJwtCookie();
    clearLoginCookie();

    toastHandler({
      messages: FLASH_MESSAGES.SESSION_EXPIRED,
      toastOptions: {
        type: 'error',
        toastId: HTTP_ERROR_CODES.UNAUTHORIZED
      }
    });
  } else if (status === HTTP_ERROR_CODES.INTERNAL_SERVER_ERROR) {
    toastHandler({
      messages: FLASH_MESSAGES.MSG_SERVER_DOWN,
      toastOptions: {
        type: 'error',
        toastId: HTTP_ERROR_CODES.INTERNAL_SERVER_ERROR
      }
    });
  }
};

export { errorHandler };
