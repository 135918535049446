import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import apiAgent from '../../../api/apiAgent';
import { Form, StyledButton } from '../../../components';
import {
  ElementBgClassnamesWithHover,
  ElementTextClassnames,
  IPaginationState
} from '../../../interfaces';
import { toastHandler } from '../../../utils';
import ThresholdAlertTable from './components/ThresholdAlertTable';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface IThresholdAlerts {
  alertRuleName: string | null;
}

const ThresholdAlerts = ({ alertRuleName }: IThresholdAlerts) => {
  const [searchText, setSearchText] = useState('');
  const [searchAcknowledged, setSearchAcknowledged] = useState<any>(null);
  const [searchType, setSeachType] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [thresholdAlerts, setThresholdAlerts] = useState<any[] | null>(null);
  const [thisAlertRuleName, setThisAlertRuleName] = useState<string | null>(
    alertRuleName
  );

  const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const navigate = useNavigate();

  const fetchThresholdAlerts = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      let params: any = {};
      if (searchText) {
        params.searchText = searchText;
      }
      if (searchType) {
        params.isIncreased = searchType === 'INCREASED';
      }
      if (searchAcknowledged !== null) {
        params.acknowledged = searchAcknowledged === 'yes';
      }
      if (thisAlertRuleName) {
        params.thresholdAlertRuleName = thisAlertRuleName;
      }
      const result = await apiAgent.ThresholdAlerts.getThresholdAlerts({
        ...params,
        page,
        limit
      });
      setThresholdAlerts(result.data.data.thresholdAlerts);
      const pageInfo = result.data.meta;
      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchThresholdAlerts({
      page: 1,
      limit: paginationInfo.itemsPerPage
    });
  }, [
    paginationInfo.itemsPerPage,
    searchText,
    searchAcknowledged,
    searchType,
    thisAlertRuleName
  ]);

  const updateAlertAcknowledged = (rowIndex: number) => {
    let temporaryarray = thresholdAlerts!.slice();
    temporaryarray[rowIndex]['acknowledged'] = true;
    setThresholdAlerts(temporaryarray);

    // setThresholdAlerts([
    //   ...thresholdAlerts!.slice(0, rowIndex),
    //   Object.assign({}, thresholdAlerts![rowIndex], { acknowledged: true }),
    //   ...thresholdAlerts!.slice(rowIndex + 1)
    // ]);
  };

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {
      searchText: '',
      searchType: null,
      searchAcknowledged: null
    },
    validationSchema: Yup.object({
      searchText: Yup.string().nullable(),
      searchType: Yup.string().nullable(),
      searchAcknowledged: Yup.string().nullable()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setSeachType(values.searchType);
      setSearchAcknowledged(values.searchAcknowledged);
    },
    validateOnChange: true
  };

  return (
    <React.Fragment>
      {thisAlertRuleName && (
        <div className="mb-2 text-text-primary flex items-center">
          <h1>Showing only alerts from Threshold Alert Rule: </h1>
          <h1
            className={`${ElementTextClassnames.warning} mx-1`}
          >{` ${alertRuleName}`}</h1>
          <StyledButton
            className="mx-2"
            onClick={() => {
              setThisAlertRuleName(null);
              navigate('/alerts?tab=threshold');
            }}
          >
            Show All
          </StyledButton>
        </div>
      )}
      <Formik {...formikSchema}>
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            submitting={formikProps.isSubmitting}
            submitLabel="Login"
            noSubmitButton={true}
            className="flex gap-x-4"
          >
            <div className="flex-grow">
              <Form.Input
                type="text"
                name="searchText"
                placeholder="e.g. Name, Location"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="off"
                value={formikProps.values.searchText}
              />
            </div>
            <div className="flex items-center">
              <h1>Increased/ Decreased</h1>
            </div>
            <div className="w-1/6">
              <Form.Select
                name="searchType"
                value={formikProps.values.searchType}
                options={[
                  { label: 'INCREASED', value: 'INCREASED' },
                  { label: 'DECREASED', value: 'DECREASED' }
                  // { label: 'count-loi', value: 'count-loi' }
                ]}
                onBlur={formikProps.setFieldTouched}
                onSelect={formikProps.setFieldValue}
                withFormikError={true}
                isClearable={true}
                placeholder={'Select...'}
              />
            </div>
            <div className="flex items-center">
              <h1>Acknowledged</h1>
            </div>
            <div className="w-1/6 text-white">
              <Form.Select
                name="searchAcknowledged"
                value={formikProps.values.searchAcknowledged}
                options={[
                  { label: 'yes', value: 'yes' },
                  { label: 'no', value: 'no' }
                ]}
                onBlur={formikProps.setFieldTouched}
                onSelect={formikProps.setFieldValue}
                withFormikError={true}
                isClearable={true}
                placeholder={'Select...'}
              />
            </div>
            <StyledButton
              type="submit"
              className={classNames(
                ElementBgClassnamesWithHover.success,
                'border-none'
              )}
              loading={formikProps.isSubmitting}
              hideChildren={true}
            >
              Search
            </StyledButton>
          </Form>
        )}
      </Formik>
      <ThresholdAlertTable
        isLoading={isLoading}
        thresholdAlerts={thresholdAlerts}
        paginationInfo={paginationInfo}
        updateAlertAcknowledged={updateAlertAcknowledged}
        fetchThresholdAlerts={fetchThresholdAlerts}
      />
    </React.Fragment>
  );
};

export default ThresholdAlerts;
