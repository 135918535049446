import { LOADING_STATES } from '../cnstants';

const loadingStateHandler = (
  state: LOADING_STATES
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  currentLoadingState: LOADING_STATES;
} => ({
  isLoading: state === LOADING_STATES.LOADING,
  isSuccess: state === LOADING_STATES.SUCCESS,
  isError: state === LOADING_STATES.ERROR,
  currentLoadingState: state
});

export default loadingStateHandler;
