import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiAgent from '../../../api/apiAgent';
import { FLASH_MESSAGES, HTTP_ERROR_CODES } from '../../../cnstants';
import {
  Container,
  MainPanel,
  PageTitle,
  PageSubDescription,
  FlashMessage
} from '../../../components';
import { useRouteId } from '../../../hooks';
import {
  IFlashContainer,
  ElementVariant,
  ElementBgClassnames
} from '../../../interfaces';
import { toastHandler, formValidation } from '../../../utils';
import AlertRuleForm from './components/AlertRuleForm';
import * as Yup from 'yup';

const AlertRulesEdit = (): JSX.Element => {
  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });
  const [alertRuleId] = useRouteId();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [alertRule, setAlertRule] = useState<any>({
    name: '',
    locations: [],
    devices: [],
    status: true,
    clusterTagId: ''
  });
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const editAlertRule = async (params: {
    name: string;
    locations: string[];
    devices: string[];
    status: boolean;
  }): Promise<boolean> => {
    try {
      const result: any = await apiAgent.AlertRules.editAlertRule(
        parseInt(alertRuleId),
        params
      );

      if (result.data.status === 'success') {
        toastHandler({
          messages: 'Alert rule edited successfully.',
          toastOptions: { type: 'success' }
        });
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }

      return true;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === HTTP_ERROR_CODES.BAD_REQUEST) {
        let data = error?.response?.data?.data;
        let message: any = '';
        if (data.duplcateNames && data?.duplicateNames?.length > 0) {
          message = 'alert name in use';
          setError(data);
        } else {
          message = error?.response?.data?.message;
        }

        setFlashContainer({
          messages: message,
          variant: ElementVariant.danger
        });
        toastHandler({
          messages: message,
          toastOptions: { type: 'error' }
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }

      return false;
    }
  };

  const getAlertRule = async (id: number) => {
    try {
      setIsLoading(true);
      const result: any = await apiAgent.AlertRules.getById(id);
      setAlertRule(result.data.data.alertRule);
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.UNPROCESSABLE_ENTITY) {
        setFlashContainer({
          messages: formValidation.getMsgArr(error?.response?.data?.data),
          variant: ElementVariant.danger
        });
      }
      if (error?.response?.status === HTTP_ERROR_CODES.NOT_FOUND) {
        setFlashContainer({
          messages: error?.response?.data?.message,
          variant: ElementVariant.danger
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAlertRule(parseInt(alertRuleId));
  }, [alertRuleId]);

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {
      name: alertRule?.name ?? '',
      ifas: alertRule
        ? alertRule?.devices.map((device: any) => device.ifa).join('\n')
        : '',
      deviceValues: [],
      locations: alertRule
        ? alertRule?.locations
            .map((location: any) => location.locationId)
            .join('\n')
        : '',
      locationValues: [],
      isActive: alertRule?.status === 1 ? true : false,
      withClusterTag:
        alertRule.clusterTagId !== '' && alertRule.clusterTagId !== null,
      clusterTag: alertRule.clusterTagId ?? '',
      withClusterTagDeviceValues:
        alertRule.clusterTagId !== '' && alertRule.clusterTagId !== null
          ? alertRule?.devices.map((device: any) => device.ifa)
          : []
    },
    validationSchema: Yup.object().shape(
      {
        name: Yup.string()
          .matches(
            /^[a-zA-Z0-9-_]+$/,
            'Name can only use alphanumeric characters without spaces'
          )
          .required('Alert rule name required')
          .trim(),
        withClusterTag: Yup.boolean().required(),
        // clusterTag: Yup.string()
        //   .trim()
        //   .when('withClusterTag', {
        //     is: true,
        //     then: Yup.string().required('Select one cluster tag.'),
        //     otherwise: Yup.string().notRequired()
        //   }),
        // withClusterTagDeviceValues: Yup.array()
        //   .of(Yup.string())
        //   .when('withClusterTag', {
        //     is: false,
        //     then: Yup.array().of(Yup.string()).notRequired(),
        //     otherwise: Yup.array()
        //       .of(Yup.string())
        //       .min(1, 'Select at least one IFA.')
        //       .required('Select at least one IFA.')
        //   }),
        // deviceValues: Yup.array()
        //   .of(
        //     Yup.object({
        //       label: Yup.string().required(),
        //       value: Yup.object().shape({
        //         value: Yup.string(),
        //         groupLabel: Yup.string()
        //       })
        //     })
        //   )
        //   .when(['ifas', 'withClusterTag'], {
        //     //when withClusterTag = true OR ifas is not an empty string THEN this property is not required
        //     is: (ifas: string, withClusterTag: boolean) =>
        //       withClusterTag || (ifas && ifas !== ''),
        //     then: Yup.array()
        //       .of(
        //         Yup.object({
        //           label: Yup.string().required(),
        //           value: Yup.object()
        //             .shape({ value: Yup.string(), groupLabel: Yup.string() })
        //             .required()
        //         })
        //       )
        //       .notRequired(),
        //     otherwise: Yup.array()
        //       .of(
        //         Yup.object({
        //           label: Yup.string().required(),
        //           value: Yup.object()
        //             .shape({ value: Yup.string(), groupLabel: Yup.string() })
        //             .required()
        //         })
        //       )
        //       .required('At least one IFA required.')
        //   }),
        // ifas: Yup.string()
        //   .trim()
        //   .matches(
        //     /^[a-zA-Z0-9-_\n]+$/,
        //     'Device ifas should only contain alphanumeric or dashes or underscore characters without spaces'
        //   )
        //   .when(['deviceValues', 'withClusterTag'], {
        //     //when withClusterTag = true OR deviceValue is not empty
        //     is: (devicesValue: any, withClusterTag: boolean) =>
        //       withClusterTag || (devicesValue && devicesValue.length) > 0,
        //     then: Yup.string().notRequired(),
        //     otherwise: Yup.string().required('At least one IFA required.')
        //   }),
        // locationValues: Yup.array()
        //   .of(
        //     Yup.object({
        //       label: Yup.string().required(),
        //       value: Yup.string().required()
        //     })
        //   )
        //   .when('locations', {
        //     is: (locations: string) => locations && locations.length > 0,
        //     then: Yup.array()
        //       .of(
        //         Yup.object({
        //           label: Yup.string().required(),
        //           value: Yup.string().required()
        //         })
        //       )
        //       .notRequired(),
        //     otherwise: Yup.array()
        //       .of(
        //         Yup.object({
        //           label: Yup.string().required(),
        //           value: Yup.string().required()
        //         })
        //       )
        //       .required('At least one location required.')
        //   })
        //   .required('At least one location required.')
        //   .min(0),
        clusterTag: Yup.string().trim(),
        withClusterTagDeviceValues: Yup.array().of(Yup.string()),
        deviceValues: Yup.array().of(
          Yup.object({
            label: Yup.string().required(),
            value: Yup.object().shape({
              value: Yup.string(),
              groupLabel: Yup.string()
            })
          })
        ),
        ifas: Yup.string()
          .trim()
          .matches(
            /^[a-zA-Z0-9-_\n]+$/,
            'Device ifas should only contain alphanumeric or dashes or underscore characters without spaces'
          ),
        locationValues: Yup.array()
          .of(
            Yup.object({
              label: Yup.string().required(),
              value: Yup.string().required()
            })
          )
          .when('locations', {
            is: (locations: string) => locations && locations.length > 0,
            then: Yup.array()
              .of(
                Yup.object({
                  label: Yup.string().required(),
                  value: Yup.string().required()
                })
              )
              .notRequired(),
            otherwise: Yup.array()
              .of(
                Yup.object({
                  label: Yup.string().required(),
                  value: Yup.string().required()
                })
              )
              .required('At least one location required.')
          })
          .required('At least one location required.')
          .min(0),
        locations: Yup.string()
          .trim()
          .matches(
            /^[a-zA-Z0-9-_\n]+$/,
            'Location ids should only contain alphanumeric or dashes or underscore characters without spaces'
          )
          .when('locationValues', {
            is: (locationValue: any) =>
              locationValue && locationValue.length > 0,
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('At least one location required.')
          }),
        isActive: Yup.boolean().required('Is active required')
      },
      [
        ['deviceValues', 'ifas'],
        ['locationValues', 'locations']
      ]
    ),
    onSubmit: async ({ name, ...values }: any, { resetForm }: any) => {
      let params: any = {
        name: name,
        devices: values.withClusterTag
          ? // if withClusterTag === true, then only use withClusterTagDeviceValues
            values.withClusterTagDeviceValues
          : // else use the deviceValues together with the textarea values
            values.deviceValues
              .map((option: any) => option.value.value)
              .concat(
                values.ifas.split('\n').filter((str: string) => str !== '')
              ),
        locations: values.locationValues
          .map((option: any) => option.value)
          .concat(
            values.locations.split('\n').filter((str: string) => str !== '')
          ),
        status: values.isActive,
        clusterTag: values.withClusterTag ? values.clusterTag : null
      };
      const flag: any = await editAlertRule(params);
      if (flag) {
        resetForm();
        navigate('/alert-rules');

        setFlashContainer({
          messages: '',
          variant: undefined
        });
      }
    },
    validateOnChange: true
  };

  return (
    <Container>
      <MainPanel className="mb-6">
        <PageTitle>Edit Alert Rule</PageTitle>
        <PageSubDescription>
          Alerts will be generated when the IFAs (or any IFA, when no IFAs are
          specified) enter/exit the locations.
        </PageSubDescription>
        {(flashContainer.messages || []).length > 0 && (
          <FlashMessage
            messages={flashContainer?.messages || ''}
            variant={flashContainer.variant}
          />
        )}
        {error && (
          <div className={`${ElementBgClassnames.danger} mt-2 rounded p-2`}>
            <div>
              {error.duplicateNames.length > 0 ? 'Alert name in use' : ''}
            </div>
            {error.conflictingValues.map((conflictingValue: any) => {
              return (
                <div>{`Conflicting with Alert Rule(${
                  conflictingValue.name
                }), IFAs: ${conflictingValue.devices.join(
                  ','
                )}, Location IDs: ${conflictingValue.locations.join(
                  ','
                )}`}</div>
              );
            })}
          </div>
        )}
        <Formik {...formikSchema}>
          {(formikProps) => (
            <div>
              <AlertRuleForm {...formikProps} />
            </div>
          )}
        </Formik>
      </MainPanel>
    </Container>
  );
};

export default AlertRulesEdit;
