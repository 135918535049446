import {  useRef, useState } from 'react';
import { ElementTextClassnames } from '../../interfaces';
import { StyledButton } from '../Button';

const FileNames = (fileList: any) => {
  const names = [];
  for (var fileIndex = 0; fileIndex < fileList.filelist.length; fileIndex++) {
    names.push(fileList.filelist[fileIndex].name);
  }
  return (
    <div>
      <h3
        className={`block mb-1 ${ElementTextClassnames.primary}`}
      >{`File: ${names.toString()}`}</h3>
    </div>
  );
};

const FileUpload = ({
  children,
  handleSetFiles,
  className,
  withFilename
}: {
  children?: JSX.Element | string;
  handleSetFiles: (newFiles: any) => void;
  className?: string;
  withFilename?: boolean;
}) => {
  const [files, setFiles] = useState<any>();
  // ref
  const inputRef = useRef<any>(null);

  const handleFiles = async (newFiles: any) => {
    setFiles(newFiles);
    const dataString = await newFiles[0].text();

    handleSetFiles(newFiles);
    //handleSetData(JSON.parse(dataString));
  };

  // triggers when file is selected with click
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <div>
      {!withFilename || !files ? null : <FileNames filelist={files} />}
      <form>
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleChange}
          style={{ display: 'none' }}
          onClick={(event) => {(event.target as HTMLInputElement).value=''}}
        />
        <StyledButton
          className={`mb-4 p-1 ${className}`}
          onClick={onButtonClick}
        >
          {children}
        </StyledButton>
      </form>
    </div>
  );
};

export default FileUpload;
