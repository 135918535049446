export const APP_VERSION = '1.2.0';

enum APP_URL {
  ADMIN_LOGIN_PAGE = '/',
  SIGN_OUT_PAGE = '/sys-logout',
  NOT_FOUND_PAGE = '/404'
}

enum FLASH_MESSAGES {
  MSG_SERVER_DOWN = 'There is a connection issue with the server. Please refresh again in awhile!',
  SESSION_EXPIRED = 'Your session has expired, please login again!',
  SIGN_OUT = 'You have sign out successfully. We hope to see you again!',
  NOT_AUTHORISED = 'You are not authorised to access the page or functionalities.',
  OTP_NOT_SETUP = 'Please setup your OTP before accessing other pages.',
  //GATEWAY_TIMEOUT = 'The backend api is not ready. Contact your administrator if the problem persist.'
  GATEWAY_TIMEOUT = 'API request timed out. Please try again.'
}

enum LOADING_STATES {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

enum STATUSES {
  INACTIVE = 0,
  ACTIVE = 1
}

enum HTTP_ERROR_CODES {
  BAD_REQUEST = 400, // Use this for force throwing of Errors
  UNAUTHORIZED = 401, // IMPORTANT: Use this for invalid access tokens, ApiAgent middleware will force a redirection to login page if accessToken/refreshToken expire!
  FORBIDDEN = 403, // Use this when request to resource is forbidden aka Module Access Rights
  NOT_FOUND = 404, // Use this if api endpoint is not found
  UNPROCESSABLE_ENTITY = 422, // Use this for validation errors
  INTERNAL_SERVER_ERROR = 500,
  GATEWAY_TIMEOUT = 504
}

enum STAT_BUTTON {
  NONE = 0,
  DOI = 1,
  LOI = 2
}

export {
  APP_URL,
  FLASH_MESSAGES,
  LOADING_STATES,
  HTTP_ERROR_CODES,
  STATUSES,
  STAT_BUTTON
};

export {
  AUTH_METHODS,
  CREATION_METHOD,
  CREATION_METHOD_LABEL,
  CREATION_METHOD_FORM_LABEL
} from './users';
