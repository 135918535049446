import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  FlashMessage,
  MainPanel,
  PageSubDescription,
  PageTitle
} from '../../../components';
import { IUserFormParams } from '../../../interfaces/api';
import apiAgent from '../../../api/apiAgent';
import { toastHandler, formValidation } from '../../../utils';
import {
  FLASH_MESSAGES,
  HTTP_ERROR_CODES,
  AUTH_METHODS
} from '../../../cnstants';
import { ElementVariant, IFlashContainer } from '../../../interfaces';
import { useState } from 'react';
import UserForm from './components/UserForm';

const UserCreate = (): JSX.Element => {
  const navigate = useNavigate();
  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });

  const createUser = async (formData: IUserFormParams): Promise<boolean> => {
    try {
      const result: any = await apiAgent.User.create({
        ...formData,
        password: formData.password,
        confirmPassword: formData.confirmPassword
      });

      if (result.data.status === 'success') {
        toastHandler({
          messages: 'User created successfully.',
          toastOptions: { type: 'success' }
        });
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }

      return true;
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.UNPROCESSABLE_ENTITY) {
        setFlashContainer({
          messages: formValidation.getMsgArr(error?.response?.data?.data),
          variant: ElementVariant.danger
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }

      return false;
    }
  };

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      authMethod: '',
      isAdmin: false,
      skipTwoFactor: false
    },
    validationSchema: Yup.object({
      email: Yup.string().email().min(5).required('Email is required'),
      // name: Yup.string().min(5).required("Name is required"),
      name: Yup.string()
        .required('Full Name is a required field')
        .trim()
        .min(3, 'Full Name must be at least 3 characters'),
      password: Yup.string()
        .min(12)
        .matches(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])/,
          'At least 1 upper case character 1 lower case character, 1 digit and 1 special character'
        ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
      authMethod: Yup.string()
        .required('Authentication Method is required')
        .oneOf(
          [...AUTH_METHODS.values()],
          `Authentication Method must be ${[...AUTH_METHODS.keys()].join(
            ' or '
          )}`
        )
    }),
    onSubmit: async ({ name, ...values }: any, { resetForm }: any) => {
      const flag = await createUser({
        ...values,
        name: name.trim()
      });
      if (flag) {
        resetForm();
        navigate('/admin/users');

        setFlashContainer({
          messages: '',
          variant: undefined
        });
      }
    },
    validateOnChange: true
  };

  return (
    <Container>
      <MainPanel className="mb-6">
        <PageTitle>Add User</PageTitle>
        <PageSubDescription>Add a new user.</PageSubDescription>

        {(flashContainer.messages || []).length > 0 && (
          <FlashMessage
            messages={flashContainer?.messages || ''}
            variant={flashContainer.variant}
          />
        )}

        <Formik {...formikSchema}>
          {(formikProps) => <UserForm {...formikProps} />}
        </Formik>
      </MainPanel>
    </Container>
  );
};

export default UserCreate;
