import { FormikProps } from 'formik';
import { Form } from '../../components';

interface IFormProps extends FormikProps<any> {
  isEdit?: boolean;
}

const UserPasswordForm = (props: IFormProps): JSX.Element => {
  const {
    isSubmitting,
    values,
    // touched,
    // errors,
    handleChange,
    handleBlur,
    handleSubmit
  } = props;

  return (
    <Form
      onSubmit={handleSubmit}
      submitting={isSubmitting}
      submitLabel="Submit"
      // backLabel="Profile"
      // backLink="/my/profile-dashboard"
    >
      <Form.Section>
        <Form.Multi size={[4, 4]}>
          <Form.Element label="Current Password">
            <Form.Input
              type="password"
              placeholder="Current Password"
              name="currentPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              withFormikError={true}
              value={values.currentPassword}
            />
          </Form.Element>
        </Form.Multi>
      </Form.Section>
      <Form.Section>
        <Form.Multi size={[4, 4]}>
          <Form.Element label="New Password">
            <Form.Input
              type="password"
              placeholder="New Password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              withFormikError={true}
              value={values.password}
            />
          </Form.Element>
        </Form.Multi>
      </Form.Section>
      <Form.Section>
        <Form.Multi size={[4, 4]}>
          <Form.Element label="Confirm Password">
            <Form.Input
              type="password"
              placeholder="Confirm password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              withFormikError={true}
              // value={values.password}
            />
          </Form.Element>
        </Form.Multi>
      </Form.Section>
    </Form>
  );
};

export default UserPasswordForm;
