import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import apiAgent from '../api/apiAgent';

interface defaultUserProfile {
  id: number;
  email: string;
  name: string;
  otpMicrosoftFlag: boolean;
  authMethod: string;
  skipTwoFactor: boolean;
  currentSignInAt: Date;
  lastSignInAt: Date;
  lockedAt: Date;
}

const userProfileAtom = atomWithReset({} as defaultUserProfile);

const fetchUserProfileAtom = atom(
  (get: any) => get(userProfileAtom),
  async (_get: any, set: any) => {
    const result = await apiAgent.User.getProfile();

    set(userProfileAtom, {
      ...result?.data?.data?.user
    });
  }
);

export { userProfileAtom, fetchUserProfileAtom };
