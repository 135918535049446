import { Formik } from 'formik';
import {
  BadgeBoolean,
  confirmAlert,
  ContainerFull,
  ListDeleteButton,
  ListEditButton,
  MainPanel,
  Modal,
  PageSubDescription,
  PageTitle,
  Pagination,
  ReactTable,
  SearchForm,
  StyledBtnRouterLink,
  StyledButton,
  Tooltip,
  WithLoadingDiv
} from '../../../components';
import * as Yup from 'yup';
import {
  ElementBgClassnames,
  ElementTextClassnames,
  ElementVariant
} from '../../../interfaces';
import {
  Fragment,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import { toastHandler } from '../../../utils';
import apiAgent from '../../../api/apiAgent';
import { FLASH_MESSAGES } from '../../../cnstants';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const AlertRules = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [alertRules, setAlertRules] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);

  const [paginationInfo, setPaginationInfo] = useState<any>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const { totalPages, totalItems, currentPageCount, itemsPerPage } =
    paginationInfo;

  const fetchAlertRules = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      //fetch from backend
      const result = await apiAgent.AlertRules.getAlertRules({
        searchText,
        page,
        limit
      });
      const { alertRules } = result.data.data;
      const pageInfo = result.data.meta;
      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
      setAlertRules(alertRules);
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAlertRules({
      page: 1,
      limit: paginationInfo.itemsPerPage
    });
  }, [paginationInfo.itemsPerPage, searchText]);

  const formikSchema = {
    initialValues: {
      searchText: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setPaginationInfo({
        ...paginationInfo,
        currentPageCount: 1
      });
    },
    validateOnChange: true
  };

  const deleteTableRow = (rowIndex: number) => () => {
    const dataCopy = [...alertRules!];
    dataCopy.splice(rowIndex, 1);
    setAlertRules(dataCopy);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
        thClassName: ElementTextClassnames.sysGenId
      },
      {
        Header: 'Cluster Tag',
        accessor: 'clusterTagId' // accessor is the "key" in the data
      },
      {
        Header: 'IFA Count',
        accessor: 'devices', // accessor is the "key" in the data
        Cell: ({ row }: any) => {
          return <div>{row.values.devices.length}</div>;
        }
      },
      {
        Header: 'Locations Count',
        accessor: 'locations', // accessor is the "key" in the data
        Cell: ({ row }: any) => {
          return <div>{row.values.locations.length}</div>;
        }
      },
      // {
      //   Header: 'Rule Type',
      //   accessor: 'alertRuleType',

      // },
      {
        Header: 'Active?',
        accessor: 'status',
        Cell: ({ row }: any) => {
          return <BadgeBoolean value={row.values.status} />;
        }
      },
      {
        Header: 'Latest Alert',
        Cell: ({ row }: any) => {
          const alertRule = row.original;
          const alert = row.original.alert;
          if (!alert) {
            return <div></div>;
          }
          const time = moment(alert.alertTime);
          const timeStr = time.format('DD/MM/YYYY hh:mm A');
          let clusterTagStr = '';
          if (alertRule.clusterTag) {
            clusterTagStr = ` from ${alertRule.clusterTag}`;
          }
          return (
            <h1 className=" text-wrap">{`${timeStr} - IDs${clusterTagStr} detected in ${alert.alertLoc}`}</h1>
            // <h1 className="text-wrap">{JSON.stringify(alertRule)}</h1>
          );
        }
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <div className="flex justify-center">
            <Fragment>
              <StyledBtnRouterLink
                to={`/alerts?tab=detection&alert-rule-name=${row.original.name}`}
                variant={ElementVariant.primaryAltTwo}
                className="mr-1"
              >
                View Alerts
              </StyledBtnRouterLink>
              <StyledButton
                className="mr-1"
                onClick={() => {
                  setModalIndex(row.id);
                  setShowModal(true);
                }}
              >
                Details
              </StyledButton>
              <EditButton alertId={row.original.id} />
              <DeleteButtonWithState
                alertRuleId={row.original.id}
                afterFunc={deleteTableRow(row.index)}
              />
            </Fragment>
          </div>
        )
      }
    ],
    [JSON.stringify(alertRules)]
  );

  return (
    <Fragment>
      <div className="flex justify-between mb-4 items-center">
        <div className="">
          <PageSubDescription>
            Manage rules for generating detection alerts
            <Tooltip>
              Detection alerts are generated when a device signal is detected
              that has both the IFA and location id specified in the alert rule.
            </Tooltip>
          </PageSubDescription>
        </div>
        <div className="flex">
          <div className="flex justify-end items-start">
            <Formik {...formikSchema}>
              {(formikProps) => (
                <SearchForm
                  {...formikProps}
                  placeholder="e.g. name"
                  currentSearch={searchText}
                />
              )}
            </Formik>
          </div>
          <div className="text-right ml-4">
            <StyledBtnRouterLink
              to={'/alert-rules/detection/new'}
              variant={ElementVariant.primaryAltTwo}
              className="text-sm mb-5"
            >
              Add New Alert Rule
            </StyledBtnRouterLink>
          </div>
        </div>
      </div>
      <WithLoadingDiv isLoading={isLoading}>
        {alertRules ? (
          <Fragment>
            <div className="-mx-5 mb-5 overflow-y-auto">
              <ReactTable columns={columns} data={alertRules} />
            </div>
          </Fragment>
        ) : (
          <div className="text-3xl text-center my-10">No results found</div>
        )}
      </WithLoadingDiv>
      {alertRules && (
        <Pagination
          totalPages={totalPages}
          totalItems={totalItems}
          currentPageCount={currentPageCount}
          itemsPerPage={itemsPerPage}
          fetchData={fetchAlertRules}
        />
      )}
      <AlertRuleModal
        showModal={showModal}
        setShowExternal={setShowModal}
        alertRule={alertRules[modalIndex]}
      />
    </Fragment>
  );
};

const AlertRuleModal = ({
  showModal,
  setShowExternal,
  alertRule
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
  alertRule?: {
    name: string;
    devices: any[];
    locations: any[];
  };
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Alert Rule Name: {alertRule?.name}</Modal.Header>
        <Modal.Body>
          <div>IFAs</div>
          <ul
            className={`rounded p-2 mb-3 overflow-y-scroll ${ElementBgClassnames.primaryAltTwo}`}
            style={{ height: '22vh' }}
          >
            {alertRule?.devices.map((device) => (
              <li key={device.id}> {device.ifa}</li>
            ))}
          </ul>
          <div>Locations</div>
          <ul
            className={`rounded p-2 mb-3 overflow-y-scroll ${ElementBgClassnames.primaryAltTwo}`}
            style={{ height: '22vh' }}
          >
            {alertRule?.locations.map((location) => (
              <li key={location.id}> {location.locationId}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <></>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const DeleteButtonWithState = (props: {
  alertRuleId: number;
  afterFunc: Function;
}): JSX.Element => {
  const { alertRuleId, afterFunc } = props;
  const [isLoading, setIsLoading] = useState(false);

  const deleteAlertRule =
    (id: number): Function =>
    async () => {
      try {
        setIsLoading(true);
        const result = await apiAgent.AlertRules.deleteAlertRule(alertRuleId);
        if (result.data.status === 'success') {
          toastHandler({
            messages: `Alert Rule ${result?.data?.data?.name} deleted successfully.`,
            toastOptions: { type: 'info' }
          });

          afterFunc();
        } else {
          throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
        }
      } catch (error: any) {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      } finally {
        setIsLoading(false);
      }
    };

  const handleOnDelete =
    (id: number): MouseEventHandler<HTMLButtonElement> =>
    (event: any) => {
      event.preventDefault();

      confirmAlert({
        title: 'Confirm remove alert rule',
        message: confirmationMessage(id),
        buttons: [
          {
            onClick: deleteAlertRule(id)
          }
        ]
      });
    };

  return (
    <ListDeleteButton
      itemLabel="Alert Rule"
      loading={isLoading}
      onClick={handleOnDelete(alertRuleId)}
    />
  );
};

const confirmationMessage = (alertRuleId: number): JSX.Element => {
  return (
    <div className="mb-1" key={alertRuleId}>
      Are you sure you want to delete this Alert Rule? This process cannot be
      undone.
    </div>
  );
};

const EditButton = (props: { alertId: number }): JSX.Element => {
  const navigate = useNavigate();
  const { alertId } = props;

  const handleOnEdit = (id: number) => (event: any) => {
    event.preventDefault();
    navigate(`/alert-rules/detection/${id}/edit`);
  };

  return (
    <ListEditButton itemLabel="Alert Rule" onClick={handleOnEdit(alertId)} />
  );
};

export default AlertRules;
