import { FormikProps } from 'formik';
import { useState } from 'react';

import {
  CREATION_METHOD,
  CREATION_METHOD_FORM_LABEL,
  AUTH_METHODS
} from '../../../../cnstants';

import { Badge, Form, Tooltip } from '../../../../components';
import { ElementTextClassnames, ElementVariant } from '../../../../interfaces';

interface IFormProps extends FormikProps<any> {
  isEdit?: boolean;
}

const UserForm = (props: IFormProps): JSX.Element => {
  const [authMethod, setAuthMethod] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const {
    isSubmitting,
    values,
    // touched,
    // errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isEdit
  } = props;

  const handleAuthMethod = (e: any): void => {
    setAuthMethod(e.target.value);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      submitting={isSubmitting}
      submitLabel="Submit"
      backLabel="Users"
      backLink="/admin/users"
    >
      <Form.Section>
        <Form.Multi>
          <Form.Element
            label={isEdit ? 'Email (Cannot be changed)' : 'Email'}
            required={!isEdit}
            hint={
              !isEdit
                ? 'Ensure that this email exists in Active Directory of the selected Authentication Method'
                : undefined
            }
          >
            <Form.Input
              type="text"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              withFormikError={true}
              value={values.email}
              disabled={isEdit}
            />
          </Form.Element>

          <Form.Element
            label="Full Name"
            required
            hint={!isEdit ? 'Enter the full name of the user.' : undefined}
          >
            <Form.Input
              type="text"
              placeholder="Name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              withFormikError={true}
              value={values.name}
            />
          </Form.Element>
        </Form.Multi>
      </Form.Section>
      <Form.Section>
        <Form.Multi size={3}>
          <Form.Element hint="Admin provides access to administrative modules.">
            <Form.Checkbox
              label="Is Admin?"
              isChecked={values.isAdmin}
              onCheck={handleChange}
              name="isAdmin"
            />
          </Form.Element>

          <Form.Element hint="Skip two factor only if necessary. Please skip for root.">
            <Form.Checkbox
              label="Skip Two Factor?"
              isChecked={values.skipTwoFactor}
              onCheck={handleChange}
              name="skipTwoFactor"
            />
          </Form.Element>
        </Form.Multi>
      </Form.Section>
      <Form.Section>
        <Form.Multi size={3}>
          <Form.Element label="Authentication Method">
            <Tooltip>
              <p>
                SAML (SSO) authentication requires the user's email to exist in
                the WOG AD
              </p>
              <p>
                LDAPs authentication requires the user's email to exist in the
                GCSOC Project AD
              </p>
              <p>
                Normal authentication should{' '}
                <span className="font-bold text-red-800">NOT</span> be used in
                regular circumstances.
              </p>
            </Tooltip>
            <Form.Dropdown
              disabled={false}
              options={AUTH_METHODS}
              value={values.authMethod}
              onSelect={handleChange}
              onChange={handleAuthMethod}
              name="authMethod"
              withFormikError={true}
            />
          </Form.Element>
        </Form.Multi>
      </Form.Section>
      {(values.authMethod === 'normal' || authMethod === 'normal') && (
        <>
          <Form.Section>
            <Form.Multi size={[4, 4]}>
              <Form.Element label="Password">
                <Form.Input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  withFormikError={true}
                  value={values.password}
                />
              </Form.Element>
            </Form.Multi>
          </Form.Section>
          <Form.Section>
            <Form.Multi size={[4, 4]}>
              <Form.Element label="Confirm Password">
                <Form.Input
                  type="password"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  withFormikError={true}
                  // value={values.password}
                />
              </Form.Element>
            </Form.Multi>
          </Form.Section>
        </>
      )}
      {(values.authMethod === 'ldaps' || authMethod === 'ldaps') && (
        <div className="-mt-5">
          <Form.Hint>
            Ensure full name matches the CN attribute value in AD
          </Form.Hint>
        </div>
      )}

      {isEdit && values.lockedAt && (
        <Form.Section>
          <Badge className="mb-3" variant={ElementVariant.danger}>
            Account locked at
            <br />
            {values.lockedAt}
          </Badge>
          <Form.Element>
            <Form.Checkbox
              label="Unlock user account"
              isChecked={values.unlockUser}
              onCheck={handleChange}
              name="unlockUser"
            />
          </Form.Element>
        </Form.Section>
      )}
      {isEdit && (
        <Form.Section>
          <Form.Element label="Reset 2FA Device?">
            <Tooltip>
              <p>Clear the current 2FA for this user.</p>
              <p>
                The user will need to redo the authenticator OTP setup to
                continue using the CTP.
              </p>
            </Tooltip>
            {values.otpMicrosoftFlag ? (
              <Form.Checkbox
                label="Yes, confirm reset."
                isChecked={values.remove2FA}
                onCheck={handleChange}
                name="remove2FA"
              />
            ) : (
              <div className={ElementTextClassnames.info}>
                2FA for this user has not been setup
              </div>
            )}
          </Form.Element>
        </Form.Section>
      )}
    </Form>
  );
};

export default UserForm;
