import { Formik } from 'formik';
import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import apiAgent from '../../../api/apiAgent';
import {
  Modal,
  PageSubDescription,
  PageSubTitle,
  Pagination,
  ReactTable,
  SearchForm,
  StyledButton,
  SubmitForm,
  WithLoadingDiv
} from '../../../components';
import { ElementTextClassnames, ElementVariant } from '../../../interfaces';
import { toastHandler } from '../../../utils';

interface IDeviceEventsCoordinatesFilters {
  showPersistentFiltersExitModal: boolean;
  setShowPersistentFiltersExitModal: (value: boolean) => void;
  handleOnCancel: () => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

const confirmationMessage = (coordinate: string): JSX.Element => {
  return (
    <div className="mb-1" key={coordinate}>
      Are you sure you want to delete this Coordinate? This process cannot be
      undone.
    </div>
  );
};

const SubmitOrDeleteModal = ({
  isSubmit,
  showModal,
  setShowExternal,
  handleSubmit,
  coordinate
}: {
  isSubmit: boolean;
  showModal?: boolean;
  setShowExternal?: Function | undefined;
  handleSubmit: Function;
  coordinate?: string;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>
          {isSubmit
            ? 'Confirm Submission?'
            : `Confirm Deletion of ${coordinate}?`}
        </Modal.Header>
        <Modal.Body>
          {!isSubmit && confirmationMessage(coordinate || '')}
        </Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal && setShowExternal(false);
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.success}
            onClick={() => {
              setShowExternal && setShowExternal(false);
              handleSubmit();
            }}
          >
            Submit
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const ExitModal = ({
  showModal,
  setShowExternal,
  handleSave,
  handleDiscard
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleSave: Function;
  handleDiscard: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Unsaved Changes</Modal.Header>
        <Modal.Body>
          You have unsaved changes. Would you like to save them before exiting?
        </Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal && setShowExternal(false);
              handleDiscard();
            }}
          >
            Discard
          </StyledButton>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.success}
            onClick={() => {
              setShowExternal && setShowExternal(false);
              handleSave();
            }}
          >
            Save
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const DeviceEventsCoordinatesFilter = ({
  showPersistentFiltersExitModal,
  setShowPersistentFiltersExitModal,
  handleOnCancel,
  isLoading,
  setIsLoading
}: IDeviceEventsCoordinatesFilters) => {
  const [submitText, setSubmitText] = useState('');
  const [searchText, setSearchText] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [currentCoordinate, setCurrentCoordinate] = useState<string>('');
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState<any>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });
  const [coordinateList, setCoordinateList] = useState<any[]>([]);
  const [modifiedCoordinates, setModifiedCoordinates] = useState(new Map());

  const handleCheckboxChange = (coordinateId: any) => {
    setModifiedCoordinates((prevModifiedCoordinates) => {
      const updated = new Map(prevModifiedCoordinates);
      const currentValue =
        updated.get(coordinateId) ??
        coordinateList.find((coord) => coord.id === coordinateId)?.toExclude;
      const newValue = currentValue === 1 ? 0 : 1;
      const initialValue = coordinateList.find(
        (coord) => coord.id === coordinateId
      )?.toExclude;
      if (newValue === initialValue) {
        updated.delete(coordinateId);
      } else {
        updated.set(coordinateId, newValue);
      }
      return updated;
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const result: any = await apiAgent.PersistentFilters.postCoordinateFilter(
        {
          value: submitText
        }
      );
      fetchCoordinates({ page: 1, limit: 12 });
      toastHandler({
        messages: 'Coordinate added!',
        toastOptions: { type: 'success' }
      });
    } catch (error: any) {
      toastHandler({
        messages: error.response.data.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnDelete = (coordinate: string) => () => {
    setCurrentCoordinate(coordinate || '');
    setShowDeleteModal(true);
  };

  const deleteCoordinate = (coordinate: string) => async () => {
    try {
      setIsLoading(true);
      await apiAgent.PersistentFilters.deleteCoordinateFilter(coordinate);
      toastHandler({
        messages: 'Coordinate deleted!',
        toastOptions: { type: 'success' }
      });
      fetchCoordinates({ page: 1, limit: 12 });
    } catch (err: any) {
      toastHandler({
        messages: err.message || 'An error occurred',
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCoordinates = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      const result: any = await apiAgent.PersistentFilters.getCoordinateFilters(
        {
          searchText: searchText !== '' ? searchText : undefined,
          page,
          limit
        }
      );
      setCoordinateList(result.data.data.filters);
      const pageInfo = result.data.meta;

      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveAllChanges = async () => {
    setIsSubmitting(true);
    const updatePromises = Array.from(modifiedCoordinates.entries()).map(
      ([coordinateId, toExclude]) =>
        apiAgent.PersistentFilters.patchCoordinateFilter(coordinateId, {
          toExclude: toExclude
        })
    );

    try {
      await Promise.all(updatePromises);
      toastHandler({
        messages: 'All changes saved successfully!',
        toastOptions: { type: 'success' }
      });
      setModifiedCoordinates(new Map());
      fetchCoordinates({ page: 1, limit: 12 });
    } catch (error: any) {
      toastHandler({
        messages: error.response.data.message || 'Failed to save changes',
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleExit = () => {
    if (modifiedCoordinates.size > 0) {
      setShowExitModal(true);
    } else {
      handleOnCancel();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Coordinates',
        accessor: 'filterValue',
        thClassName: ElementTextClassnames.sysGenId
      },
      {
        Header: 'Excluded',
        accessor: 'toExclude',
        Cell: ({ row }: { row: any }) => (
          <input
            type="checkbox"
            checked={
              modifiedCoordinates.get(row.original.id) ?? row.original.toExclude
            }
            onChange={() => handleCheckboxChange(row.original.id)}
          />
        ),
        thClassName: ElementTextClassnames.sysGenId
      },
      {
        Header: 'Action',
        id: 'delete',
        accessor: 'id',
        Cell: ({ row }: { row: any }) => {
          return (
            <StyledButton
              className="p-2 fa fa-trash-alt"
              variant={ElementVariant.danger}
              onClick={handleOnDelete(row.original.filterValue)}
            ></StyledButton>
          );
        },
        thClassName: ElementTextClassnames.sysGenId
      }
    ],
    [coordinateList, modifiedCoordinates]
  );

  const addFormikSchema = {
    initialValues: {
      searchText: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSubmitText(values.searchText.replace(/\s/g, ''));
      setShowSubmitModal(true);
    },
    validateOnChange: true
  };

  const searchFormikSchema = {
    initialValues: {
      searchText: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setPaginationInfo((prev: any) => ({
        ...prev,
        currentPageCount: 1
      }));
    },
    validateOnChange: true
  };

  useEffect(() => {
    fetchCoordinates({ page: 1, limit: 12 });
  }, [paginationInfo.itemsPerPage, searchText]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (modifiedCoordinates.size > 0) {
        const message =
          'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [modifiedCoordinates]);

  return (
    <Fragment>
      <div className="flex flex-col rounded-md bg-background-alt py-4 px-5 h-auto text-white">
        <PageSubTitle>Persistent Coordinates Filter Options</PageSubTitle>
        <div className=" mb-5">
          <div className="flex justify-between">
            <div>
              <h1 className="text-lg">Add Coordinate</h1>
              <PageSubDescription>{`Create a coordinate to filter`}</PageSubDescription>
            </div>
            <div className="flex justify-end items-start">
              <Formik {...addFormikSchema}>
                {(formikProps) => (
                  <SubmitForm
                    {...formikProps}
                    placeholder="latitude, longitude"
                    currentSearch={submitText}
                  />
                )}
              </Formik>
            </div>
          </div>
        </div>
        <hr />
        <div className="mt-5 mb-5">
          <div className="flex justify-between">
            <div>
              <h1 className="text-lg">Manage Coordinates</h1>
              <PageSubDescription>{`Current coordinates to filter`}</PageSubDescription>
            </div>
            <div className="flex-col justify-end items-start">
              <div className="flex justify-end">
                <StyledButton
                  className="my-2 ml-2"
                  variant={ElementVariant.success}
                  onClick={saveAllChanges}
                  disabled={modifiedCoordinates.size === 0}
                >
                  Save All Changes
                </StyledButton>
                <StyledButton
                  className="my-2 ml-2"
                  variant={ElementVariant.danger}
                  onClick={handleExit}
                >
                  Exit
                </StyledButton>
              </div>
              <Formik {...searchFormikSchema}>
                {(formikProps) => (
                  <SearchForm
                    {...formikProps}
                    placeholder="e.g. Coordinates"
                    currentSearch={searchText}
                  />
                )}
              </Formik>
            </div>
          </div>
          <WithLoadingDiv isLoading={isLoading}>
            {coordinateList ? (
              <Fragment>
                <div className="-mx-5 mb-5 overflow-y-auto">
                  <ReactTable columns={columns} data={coordinateList} />
                </div>
              </Fragment>
            ) : (
              <div className="text-3xl text-center my-10">No results found</div>
            )}
          </WithLoadingDiv>
          {coordinateList && (
            <Pagination
              totalPages={paginationInfo.totalPages}
              totalItems={paginationInfo.totalItems}
              currentPageCount={paginationInfo.currentPageCount}
              itemsPerPage={paginationInfo.itemsPerPage}
              fetchData={fetchCoordinates}
            />
          )}
        </div>
        {showDeleteModal && (
          <SubmitOrDeleteModal
            isSubmit={false}
            showModal={showDeleteModal}
            setShowExternal={setShowDeleteModal}
            handleSubmit={deleteCoordinate(currentCoordinate)}
            coordinate={currentCoordinate}
          />
        )}
        {showSubmitModal && (
          <SubmitOrDeleteModal
            isSubmit={true}
            showModal={showSubmitModal}
            setShowExternal={setShowSubmitModal}
            handleSubmit={handleSubmit}
          />
        )}
        {showExitModal && (
          <ExitModal
            showModal={showExitModal}
            setShowExternal={setShowExitModal}
            handleSave={saveAllChanges}
            handleDiscard={handleOnCancel}
          />
        )}
      </div>
    </Fragment>
  );
};

export default memo(DeviceEventsCoordinatesFilter);
