import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import apiAgent from '../../api/apiAgent';
import {
  AccordionTable,
  ContainerFull,
  Modal,
  PageSubDescription,
  PageTitle,
  Pagination,
  StyledButton,
  WithLoadingDiv
} from '../../components';
import FillAppHeightDiv from '../../components/layout/FillAppHeightDiv';
import {
  ElementBgClassnames,
  ElementBgClassnamesWithHover,
  ElementTextClassnames,
  ElementVariant,
  IPaginationState
} from '../../interfaces';
import { toastHandler } from '../../utils';
import { Campaign } from './filtersUtil';
interface CustomRow<D extends object> extends Row<D> {
  isExpanded?: boolean;
  getToggleRowExpandedProps: () => any;
  toggleRowExpanded: () => void;
  toggleAllRowsExpanded: () => void;
}
const FiltersList = () => {
  const [selectedFilter, setSelectedFilter] = useState<number>(-1);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [showModal, setShowModal] = useState(false);

  const [modalBody, setModalBody] = useState<{
    filterIndex: number;
    campaignIndex: number;
  }>();
  const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 1000,
    totalPages: 0
  });
  const navigate = useNavigate();

  const fetchFiltersList = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      setIsLoading(true);
      const result = await apiAgent.Filters.getAllFilters({
        page,
        limit
      });
      setPaginationInfo({
        totalItems: result.data.meta.totalCount,
        currentPageCount: result.data.meta.currentPageCount,
        itemsPerPage: limit,
        totalPages: result.data.meta.totalPages
      });
      const parsedFilters = parseFilters(result.data.data.filters);
      setFiltersList(parsedFilters);
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const parseFilters = (fetchedFiltersArray: any) => {
    let filtersArr: any = [];
    fetchedFiltersArray.forEach((filters: any) => {
      let obj = filters;
      filtersArr.push(obj);
    });
    return filtersArr;
  };
  useEffect(() => {
    fetchFiltersList({ page: 1, limit: 12 });
  }, []);

  // Table columns
  const columns = useMemo(
    () => [
      {
        accessor: 'primary',
        Cell: (props: any) => {
          const filterIndex = props.row.id;
          return (
            <div className="flex justify-end items-center w-2">
              {/* TODO: Figure out how to get green specifically only for first page and first item. */}
              <div
                className={`w-2 h-2 rounded-xl ${ElementBgClassnames.success} ${
                  filterIndex === '0' ? 'opacity-100' : 'opacity-0'
                }`}
              ></div>
            </div>
          );
        }
      },
      {
        Header: 'Set ID',
        accessor: 'id',
        Cell: (props: any) => {
          return <div>{props.value}</div>;
        }
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: (props: any) => {
          return <div>{moment(props.value).format('DD/MM/YYYY hh:mm A')}</div>;
        }
      },
      {
        Header: 'Vendor ver',
        accessor: 'vendorVersion'
      },
      {
        Header: 'Campaigns',
        accessor: 'campaigns',
        Cell: (props: any) => {
          return <div>{props.value.length}</div>;
        }
      },
      {
        id: 'expander',
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
          <span {...getToggleAllRowsExpandedProps()}>
            <StyledButton className="font-normal">
              {isAllRowsExpanded ? 'Collapse All' : 'Expand All'}
            </StyledButton>
          </span>
        ),
        Cell: ({ row }: { row: CustomRow<any> }) => (
          <>
            {}
            <span {...row.getToggleRowExpandedProps()}>
              <>
                {row.isExpanded ? (
                  <i className="fa-solid fa-chevron-up" />
                ) : (
                  <i className="fa-solid fa-chevron-down" />
                )}
              </>
            </span>
          </>
        )
      }
    ],
    []
  );

  // Sub component for each row
  const renderRowSubComponent = useCallback(
    (filterIndex: number, filters: any) => {
      const campaignArray = filters.campaigns;

      return (
        <div
          className={`flex overflow-x-scroll rounded p-2 gap-2 ${ElementBgClassnames.primaryAlt}`}
          style={{ width: '100%' }}
        >
          {campaignArray.map((campaign: any, campaignIndex: number) => (
            <div
              className={`rounded-md px-1 py-2 text-sm ${
                selectedFilter === filters.id
                  ? ElementBgClassnames.primaryAltTwo
                  : ElementBgClassnames.primary
              } `}
              key={`filter-${filterIndex}-campaign-${campaignIndex}`}
            >
              <div className="grid grid-cols-3">
                <div>{`Cam ID `}</div>
                <div className="col-span-2">{campaign.campaignId}</div>
                <div className="col-start-1">{`Active`}</div>
                <div className="col-span-2">
                  {campaign.active ? 'true' : 'false'}
                </div>
                <div className="col-start-1">{`Start date`}</div>
                <div className="col-span-2">
                  {!campaign.startDate
                    ? 'Long running'
                    : moment(campaign.startDate).format('DD/MM/YYYY hh:mm A')}
                </div>
                <div className="col-start-1">{`End date`}</div>
                <div className="col-span-2">
                  {!campaign.endDate
                    ? 'Long running'
                    : moment(campaign.endDate).format('DD/MM/YYYY hh:mm A')}
                </div>
                <div className="col-start-1">IFAs</div>
                <div>{campaign.devices.length}</div>
                <div className="col-start-1">Locations</div>
                <div>{campaign.locations.length}</div>
              </div>
              <StyledButton
                className="mt-2"
                variant={
                  selectedFilter === filters.id
                    ? ElementVariant.primary
                    : ElementVariant.primaryAlt
                }
                onClick={() => {
                  setModalBody({ filterIndex, campaignIndex });
                  setShowModal(true);
                }}
              >
                Details
              </StyledButton>{' '}
            </div>
          ))}
        </div>
      );
    },
    []
  );
  return (
    <ContainerFull>
      <FillAppHeightDiv>
        <div
          className="flex-1 h-full w-full flex flex-col py-4 px-5 "
          onClick={() => setSelectedFilter(-1)}
        >
          <PageTitle> Previous Campaign Sets </PageTitle>
          <div className="flex justify-between items-end mb-4">
            <PageSubDescription>
              Choose 1 set of filters to use as template
            </PageSubDescription>
            <div className={`mr-3 ${ElementTextClassnames.danger}`}>
              {error}
            </div>
            <div className="flex gap-2 mt-2 text-white">
              <StyledButton
                className={`${ElementBgClassnamesWithHover.success}`}
                onClick={() => {
                  if (selectedFilter < 0) {
                    setError('Please select a set of campaigns');
                    return;
                  }
                  navigate(`/campaigns/create/${selectedFilter}`);
                }}
              >
                Confirm
              </StyledButton>
            </div>
          </div>
          <div className="rounded-md py-4 px-5 bg-background-alt ">
            <WithLoadingDiv isLoading={isLoading}>
              <div
                className="-mx-5 mb-5 overflow-y-auto overflow-x-auto"
                style={{ maxHeight: '65vh' }}
              >
                <AccordionTable
                  columns={columns}
                  data={filtersList}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  renderRowSubComponent={renderRowSubComponent}
                />
              </div>
            </WithLoadingDiv>
            <React.Fragment>
              <Pagination
                totalPages={paginationInfo.totalPages}
                totalItems={paginationInfo.totalItems}
                currentPageCount={paginationInfo.currentPageCount}
                itemsPerPage={paginationInfo.itemsPerPage}
                fetchData={fetchFiltersList}
              />
            </React.Fragment>
          </div>
        </div>
        <span className="text-white">
          <SimpleModal
            showModal={showModal}
            setShowExternal={setShowModal}
            campaign={
              modalBody &&
              filtersList[modalBody!.filterIndex].campaigns[
                modalBody!.campaignIndex
              ]
            }
          />
        </span>
      </FillAppHeightDiv>
    </ContainerFull>
  );
};

const SimpleModal = ({
  showModal,
  setShowExternal,
  campaign
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
  campaign?: Campaign;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      {campaign ? (
        <Modal.Display>
          <Modal.Header>
            <div>Cam ID: {campaign.campaignId}</div>
          </Modal.Header>
          <Modal.Body>
            <div>IFAs</div>
            <ul
              className={`overflow-y-scroll rounded ${ElementBgClassnames.primary}`}
              style={{ height: '35vh' }}
            >
              {campaign.devices.map((device: any) => (
                <li>{device.ifa}</li>
              ))}
            </ul>
            <div className="mt-4">Locations</div>
            <ul
              className={`overflow-y-scroll rounded ${ElementBgClassnames.primary}`}
              style={{ height: '35vh' }}
            >
              {campaign.locations.map((location: any) => (
                <li>{location.locationId}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <span></span>
          </Modal.Footer>
        </Modal.Display>
      ) : (
        <Modal.Display>
          <Modal.Header>No Data</Modal.Header>
        </Modal.Display>
      )}
    </Modal>
  );
};

export default FiltersList;
