import React, { useState } from 'react';

import { ColorResult, SketchPicker } from 'react-color';
import { Cluster } from '../../../../interfaces/mapChart';
import GridChild from '../../../../components/GridChild';
import { StyledButton } from '../../../../components';

const DataGroupColorPicker = ({
  data,
  colorsMap,
  colorsArray,
  setColorsMap,
  toggleDisplayColorPicker,
  setMapIsLoading
}: {
  data: Map<string, Cluster>;
  colorsMap: Map<string, string>;
  colorsArray: string[];
  setColorsMap: (map: Map<string, string>) => void;
  toggleDisplayColorPicker: () => void;
  setMapIsLoading: (bool: boolean) => void;
}): JSX.Element => {
  const generateColorPickers = (data: Map<string, Cluster>): JSX.Element => {
    const colorPickersArray: any = [];

    if (data) {
      data.forEach((cluster: Cluster, index: string) => {
        colorPickersArray.push(
          <DataColorPicker
            key={`color_picker_${index}`}
            cluster={cluster}
            colorsMap={colorsMap}
            colorsArray={colorsArray}
            setColorsMap={setColorsMap}
            setMapIsLoading={setMapIsLoading}
          />
        );
      });
    }
    return colorPickersArray;
  };

  return (
    <div
      className="flex flex-row justify-end text-lg"
      style={{
        // position: 'absolute',
        // backgroundColor: 'white',
        // padding: '10px',
        // left: '50%',
        // top: '50%',
        // transform: 'translate(-50%, -50%)',
        // width: 'auto',
        // maxHeight: '350px',
        zIndex: 1003
        // overflowY: 'scroll'
        // pointerEvents: 'auto'
      }}
    >
      <GridChild
        // className="mb-4 bg-background-alt-2 text-text-primary"
        title=""
        key={`ClusterColorsList`}
      >
        <div className="flex flex-row justify-end text-xs">
          <div className="justify-end">
            {/* Close Icon */}
            <i
              className={'fas fa-times text-lg ml-3 text-white cursor-pointer'}
              onClick={(e) => {
                e.stopPropagation();
                toggleDisplayColorPicker();
              }}
            />
          </div>
        </div>
        <GridChild
          className="pt-0 max-h-64 overflow-y-auto text-text-primary"
          title=""
        >
          {generateColorPickers(data)}
        </GridChild>
      </GridChild>
    </div>
  );
};

const DataColorPicker = ({
  cluster,
  colorsMap,
  colorsArray,
  setColorsMap,
  setMapIsLoading
}: {
  cluster: Cluster;
  colorsMap: Map<string, string>;
  colorsArray: string[];
  setColorsMap: (map: Map<string, string>) => void;
  setMapIsLoading: (bool: boolean) => void;
}): JSX.Element => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState<string>(
    colorsMap.get(cluster.id) || colorsArray[cluster.groupCount]
  );
  const [colorChanged, setColorChanged] = useState<boolean>(false);

  const toggleColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const closeColorPicker = () => {
    setDisplayColorPicker(false);
  };

  const updateCurrentColor = (color: ColorResult) => {
    setCurrentColor(color.hex);
    setColorChanged(true);
  };

  const updateColorsMap = async () => {
    if (colorChanged) {
      setMapIsLoading(true);
      await setColorsMap(new Map(colorsMap.set(cluster.id, currentColor)));
      setMapIsLoading(false);
    }
    closeColorPicker();
  };

  return (
    <React.Fragment key={`color_row_${cluster.id}`}>
      <div
        style={{ padding: '7px 0px', cursor: 'pointer' }}
        onClick={toggleColorPicker}
      >
        <i
          className="fa-solid fa-circle fa-2xl"
          style={{
            color: colorsMap.get(cluster.id) || colorsArray[cluster.groupCount],
            verticalAlign: 'middle',
            alignSelf: 'center',
            paddingTop: 2
          }}
        />{' '}
        {cluster.id}
      </div>
      {displayColorPicker && (
        <div
          // key={`color_picker_${cluster.id}`}
          style={{
            position: 'absolute',
            width: '260px',
            left: '70%',
            bottom: '5%',
            transform: 'translate(-60%, 5%)',
            zIndex: 1303
          }}
          // onClick={closeColorPicker}
        >
          <GridChild
            className="mb-4 bg-background-alt-2 text-text-primary"
            title={cluster.id}
            key={`ColorPickerPanel`}
          >
            {/* <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px'
              }}
            > */}
            <SketchPicker
              color={currentColor}
              onChange={updateCurrentColor}
              // onChangeComplete={closeColorPicker}
            />
            <StyledButton
              className="mt-3"
              style={{ backgroundColor: '#182330' }}
              onClick={(e) => {
                e.stopPropagation();
                updateColorsMap();
              }}
            >
              {colorChanged ? 'Update Color' : 'Close'}
            </StyledButton>
            {/* </div> */}
          </GridChild>
        </div>
      )}
    </React.Fragment>
  );
};

export default DataGroupColorPicker;
