const UpArrowHeadIcon = () => {
  return (
    <svg
      x="0px"
      y="0px"
      width="10px"
      height="10px"
      viewBox="0 0 451.847 451.847"
      className="fill-gray-200"
      transform="rotate(90)"
      //style="enable-background:new 0 0 451.847 451.847;"
      //xml:space="preserve"
    >
      <path
        d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
   c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
   c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"
      />
    </svg>
  );
};

export default UpArrowHeadIcon;
