import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const Paginate = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div className="paginate_section">{children}</div>
);

interface PaginationProps {
  totalPages: number;
  // children: React.ReactNode;
  totalItems: number;
  currentPageCount: number;
  itemsPerPage: number;
  fetchData: (params: { page: number; limit: number }) => void;
}

const getItemsRange = (
  itemsPerPage: number,
  currentPage: number,
  currentPageCount: number
) => {
  let start = 0;
  let end = 0;

  start = itemsPerPage * currentPage + 1;
  end = start + currentPageCount - 1;

  return [start, end];
};

const Pagination = ({
  totalPages,
  totalItems,
  currentPageCount,
  itemsPerPage,
  fetchData
}: PaginationProps): JSX.Element => {
  const [page, setPage] = useState(0);
  const itemRange = getItemsRange(itemsPerPage, page, currentPageCount);

  const onPageChange = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
    fetchData({ page: selectedPage + 1, limit: itemsPerPage });
  };
  //add to change page when new data comes is applied from outside effect
  useEffect(() => {
    setPage(0);
  }, [totalPages]);

  return (
    <div className="flex flex-wrap items-center">
      {totalItems > 0 && (
        <div className="flex-grow mb-3">
          Showing items {itemRange[0]} - {itemRange[1]} of {totalItems}
        </div>
      )}
      {totalPages > 1 && (
        <Paginate>
          <ReactPaginate
            previousLabel={<i className="fa fa-chevron-left" />}
            nextLabel={<i className="fa fa-chevron-right" />}
            breakLabel="..."
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={onPageChange}
            activeClassName="current"
            forcePage={page}
          />
        </Paginate>
      )}
    </div>
  );
};

export default Pagination;
