import moment from 'moment';
import * as Yup from 'yup';

import {
  PageTitle,
  ContainerFull,
  PageSubDescription,
  Form,
  StyledButton
} from '../../components';

import { ElementBgClassnamesWithHover } from '../../interfaces';
import Stats from './components/Stats';
import DeviceEventsTable from './components/DeviceEventsTable';
import ActiveTargetedIFAsClusterTagsListTable from './components/ActiveTargetedIFAsClusterTagsListTable';
import InactiveTargetedIfasListTable from './components/InactiveTargetedIFAsTable';
import ActiveTargetedIfasListTable from './components/ActiveTargetedIFAsTable';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import apiAgent from '../../api/apiAgent';
import { toastHandler } from '../../utils';
import ActiveTargetTimeSeriesCountLineChart from './components/ActiveTargetTimeSeriesCountLineChart';

const transformClusterTagsListForOptions = (
  list: any[]
): { value: string | number; label: string }[] => {
  return list.map((obj: any) => ({
    label: obj.cluster_tag_id,
    value: obj.cluster_tag_id
  }));
};

const TargetedDevicesDashboard = (): JSX.Element => {
  const [searchText, setSearchText] = useState('');
  const [searchClusterTags, setSearchClusterTags] = useState<string[]>([]);

  const [clusterTagsOptions, setClusterTagsOptions] = useState<
    | {
        value: string | number;
        label: string;
      }[]
    | null
  >([]);

  const fetchList = async (): Promise<void> => {
    try {
      const { data } = await apiAgent.Stats.getClusterTagsList();

      setClusterTagsOptions(
        transformClusterTagsListForOptions(data?.data?.clusterTagsList || [])
      );
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastId: error?.code,
        toastOptions: { type: 'error' }
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  const formikSchema = {
    initialValues: {
      searchText: '',
      clusterTags: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setSearchClusterTags(values.clusterTags);
    },
    validateOnChange: true
  };

  return (
    <ContainerFull>
      <PageTitle>
        Targeted Devices Dashboard {`${moment().format('DD-MMM-YYYY')}`}
      </PageTitle>
      <PageSubDescription>
        Main dashboard showing targeted devices matrix and useful information.
      </PageSubDescription>
      <div className="mb-6">
        <div className="grid grid-cols-8 gap-4 mb-4">
          <Stats />
        </div>

        <div className="rounded-md bg-background-alt py-4 px-5 mb-4">
          <Formik {...formikSchema}>
            {(formikProps) => (
              <Form
                onSubmit={formikProps.handleSubmit}
                submitting={formikProps.isSubmitting}
                submitLabel="Login"
                noSubmitButton={true}
                className="flex gap-x-4"
              >
                <div className="flex-grow">
                  <Form.Select
                    name="clusterTags"
                    value={formikProps.values.clusterTags}
                    onBlur={formikProps.setFieldTouched}
                    onSelect={formikProps.setFieldValue}
                    options={clusterTagsOptions || []}
                    isMulti
                    placeholder={'Select cluster tags'}
                  />
                </div>
                <div>
                  <Form.Input
                    type="text"
                    placeholder="e.g. IFAs"
                    name="searchText"
                    className="w-96"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    autoComplete="off"
                    value={formikProps.values.searchText}
                  />
                </div>
                <StyledButton
                  type="submit"
                  className={classNames(
                    ElementBgClassnamesWithHover.success,
                    'border-none'
                  )}
                  loading={formikProps.isSubmitting}
                  hideChildren={true}
                >
                  Search
                </StyledButton>
              </Form>
            )}
          </Formik>
        </div>

        <div className="grid grid-cols-12 gap-4 mb-4">
          <ActiveTargetedIFAsClusterTagsListTable
            searchClusterTags={searchClusterTags}
            searchText={searchText}
          />

          <ActiveTargetTimeSeriesCountLineChart
            searchClusterTags={searchClusterTags}
            searchText={searchText}
          />
        </div>

        <div className="mb-4">
          <DeviceEventsTable
            searchClusterTags={searchClusterTags}
            searchText={searchText}
          />
        </div>

        <div className="grid grid-cols-12 gap-4">
          <ActiveTargetedIfasListTable />

          <InactiveTargetedIfasListTable />
        </div>
      </div>
    </ContainerFull>
  );
};

export default TargetedDevicesDashboard;
