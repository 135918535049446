import { ErrorMessage, FormikProps, useField } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import apiAgent from '../../../../api/apiAgent';
import { Form, Tooltip } from '../../../../components';
import { toastHandler } from '../../../../utils';
import { Location } from '../../../campaigns/filtersUtil';
import Select from 'react-select';
import classNames from 'classnames';
import {
  ElementBgClassnames,
  ElementTextClassnames
} from '../../../../interfaces';

interface IFormProps extends FormikProps<any> {
  isEdit?: boolean;
}

const ThresholdAlertRuleForm = (props: IFormProps): JSX.Element => {
  const {
    isSubmitting,
    values,
    // touched,
    // errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isEdit
  } = props;

  // const [field, meta, helpers] = useField('location');

  const [locationOptions, setLocationOptions] = useState<any>([]);

  const fetchLocations = async () => {
    try {
      let result = await apiAgent.DeviceEvent.getLocationsList();
      let newLocationOptions: any[] = [];
      result.data.data.locations.forEach((location: Location) => {
        newLocationOptions.push({
          label: location.locationId,
          value: location.locationId
        });
      });
      setLocationOptions(newLocationOptions);
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      submitting={isSubmitting}
      submitLabel="Submit"
      backLabel="Threshold Alert Rules"
      backLink="/alert-rules?tab=threshold"
    >
      <Form.Section>
        <Form.Element
          label="Name"
          hint="Name of rule is required and must be unique."
        >
          <Form.Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            withFormikError={true}
            value={values.name}
          />
        </Form.Element>
      </Form.Section>

      <Form.Section>
        <Form.Element label="Location ID" hint="Select one.">
          <Form.Select
            name="location"
            options={locationOptions}
            onSelect={setFieldValue}
            onBlur={handleBlur}
            withFormikError={true}
            value={values.location}
          />
          {/* <Select
            options={locationOptions}
            onChange={(value: any) => helpers.setValue(value.value)}
            className={'select-special-container'}
            classNamePrefix="tw-select"
          /> */}
        </Form.Element>
      </Form.Section>
      <Form.Section>
        <Form.Element
          label="Baseline"
          hint="Number of detections expected in a 1 hour window"
        >
          <Form.Input
            type="number"
            name="baseline"
            min={0}
            step={1}
            onChange={handleChange}
            onBlur={handleBlur}
            withFormikError={true}
            value={values.baseline}
          />
        </Form.Element>
      </Form.Section>
      <Form.Section>
        <Form.Element
          label="Threshold (%)"
          hint="Generate alert when the difference between the actual number of detections and the baseline exceeds this threshold."
        >
          <Form.Input
            type="number"
            name="threshold"
            min={0}
            max={100}
            step={0.01}
            onChange={handleChange}
            onBlur={handleBlur}
            withFormikError={true}
            value={values.threshold}
          />
        </Form.Element>
      </Form.Section>
      <Form.Section>
        <Form.Element>
          <Form.Checkbox
            label="Is Active?"
            isChecked={values.isActive}
            onCheck={handleChange}
            name="isActive"
          />
        </Form.Element>
      </Form.Section>
    </Form>
  );
};

export default ThresholdAlertRuleForm;
