import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiAgent from '../../api/apiAgent';
import {
  ContainerFull,
  Modal,
  PageSubDescription,
  PageTitle,
  StyledButton,
  Tooltip,
  WithLoadingDiv
} from '../../components';
import FileUpload from '../../components/form/FileUpload';
import Input from '../../components/form/Input';
import TextArea from '../../components/form/TextArea';
import DownArrowHeadIcon from '../../components/icons/DownArrowheadIcon';
import UpArrowHeadIcon from '../../components/icons/UpArrowheadIcon';
import {
  ElementBgClassnames,
  ElementTextClassnames,
  ElementVariant
} from '../../interfaces';
import { toastHandler } from '../../utils';

interface ClusterTag {
  clusterTagId: string;
  // campaigns: string[];
  devices: string[];
  // locations: string[];
  isEditing?: boolean;
}
type clusterTagKeys = keyof ClusterTag;

// const validateClusterTags = (
//   clusterTags: ClusterTag[],
// filterCampaigns: string[],
//   filterDevices: string[]
// filterLocations: string[]
// ): void => {
//   // validate the cluster tags
//   let tempArr: any[] = [];
//   for (
//     let clusterTagsIndex = 0;
//     clusterTagsIndex < clusterTags.length;
//     clusterTagsIndex++
//   ) {
//     const clusterTag = clusterTags[clusterTagsIndex];
//     //check duplicates
//     if (tempArr.indexOf(clusterTag.clusterTagId) < 0) {
//       tempArr.push(clusterTag.clusterTagId);
//     } else {
//       throw new Error(`Duplicate clusterTagId: ${clusterTag.clusterTagId}`);
//     }
//     // clusterTag.campaigns.forEach((campaign) => {
//     //   if (!filterCampaigns.includes(campaign)) {
//     //     throw new Error(`Campaign: ${campaign} not found in current filters.`);
//     //   }
//     // });
//     clusterTag.devices.forEach((device) => {
//       if (!filterDevices.includes(device)) {
//         throw new Error(`IFA: ${device} not found in current filters.`);
//       }
//     });
//     // clusterTag.locations.forEach((location) => {
//     //   if (!filterLocations.includes(location)) {
//     //     throw new Error(`Location: ${location} not found in current filters.`);
//     //   }
//     // });
//   }
// };

const validateClusterTags = (
  clusterTags: ClusterTag[],
  filterDevices: string[]
): void => {
  let tempArr: any[] = [];
  for (
    let clusterTagsIndex = 0;
    clusterTagsIndex < clusterTags.length;
    clusterTagsIndex++
  ) {
    const clusterTag = clusterTags[clusterTagsIndex];
    //check duplicates
    if (tempArr.indexOf(clusterTag.clusterTagId) < 0) {
      tempArr.push(clusterTag.clusterTagId);
    } else {
      throw new Error(`Duplicate clusterTagId: ${clusterTag.clusterTagId}`);
    }
    clusterTag.devices.forEach((device) => {
      if (!filterDevices.includes(device)) {
        throw new Error(`IFA: ${device} not found in current filters.`);
      }
    });
  }
};

const EditingClusterTagDiv = ({
  clusterTag,
  clusterTagIndex,
  handleClusterTagChange,
  setDeleteClusterTagIndex
}: {
  clusterTag: ClusterTag;
  clusterTagIndex: number;
  setDeleteClusterTagIndex: (index: number) => void;
  handleClusterTagChange: (
    clusterTagIndex: number,
    value: any,
    field: clusterTagKeys
  ) => void;
}): JSX.Element => {
  return (
    <div
      className={`border-lg shadow-lg border-gray-800 rounded-md mb-12 w-11/12 ${ElementBgClassnames.primary}`}
    >
      <div className="py-4 px-2 w-full">
        <div className="flex grid grid-cols-12 gap-x-3 gap-y-6 mb-4 items-center">
          <div className="col-span-2">Cluster Tag</div>
          <Input
            className={`col-span-2 py-1.5 ${ElementBgClassnames.primaryAltTwo}`}
            value={clusterTag.clusterTagId}
            type="text"
            onChange={(e: any) =>
              handleClusterTagChange(
                clusterTagIndex,
                e.target.value,
                'clusterTagId'
              )
            }
          />
          <div className="flex col-start-11 h-full col-span-2 justify-end items-start">
            <StyledButton
              className=""
              variant={ElementVariant.primaryAltTwo}
              onClick={() => {
                handleClusterTagChange(clusterTagIndex, false, 'isEditing');
              }}
            >
              <UpArrowHeadIcon />
            </StyledButton>
          </div>
          {/* <div className="col-start-1">Campaign IDs </div>
          <TextArea
            className={`col-start-3 col-span-9 block w-full rounded-md border border-transparent focus:border-gray-300 focus:ring-0 focus:text-white ${ElementBgClassnames.primaryAltTwo}`}
            value={clusterTag.campaigns.join('\r\n')}
            onChange={(e: any) => {
              handleClusterTagChange(
                clusterTagIndex,
                e.target.value === '' ? [] : e.target.value.split('\n'),
                'campaigns'
              );
            }}
          /> */}

          <div className="col-start-1">IFAs </div>
          <TextArea
            className={`col-start-3 col-span-9 block w-full rounded-md border border-transparent focus:border-gray-300 focus:ring-0 focus:text-white ${ElementBgClassnames.primaryAltTwo}`}
            value={clusterTag.devices.join('\r\n')}
            onChange={(e: any) => {
              handleClusterTagChange(
                clusterTagIndex,
                e.target.value === '' ? [] : e.target.value.split('\n'),
                'devices'
              );
            }}
          />
          {/* <div className="col-start-1">Location IDs </div>
          <TextArea
            className={`col-start-3 col-span-9 block w-full rounded-md border border-transparent focus:border-gray-300 focus:ring-0 focus:text-white ${ElementBgClassnames.primaryAltTwo}`}
            value={clusterTag.locations.join('\r\n')}
            onChange={(e: any) => {
              handleClusterTagChange(
                clusterTagIndex,
                e.target.value === '' ? [] : e.target.value.split('\n'),
                'locations'
              );
            }}
          /> */}
        </div>
        <div className="flex items-center w-full justify-center">
          <StyledButton
            className="p-2"
            variant={ElementVariant.danger}
            onClick={() => setDeleteClusterTagIndex(clusterTagIndex)}
          >
            Delete
          </StyledButton>
        </div>
      </div>
    </div>
  );
};

const ClusterTagDiv = ({
  clusterTag,
  clusterTagIndex,
  setDeleteClusterTagIndex,
  handleClusterTagChange
}: {
  clusterTag: ClusterTag;
  clusterTagIndex: number;
  setDeleteClusterTagIndex: (index: number) => void;
  handleClusterTagChange: (
    clusterTagIndex: number,
    value: any,
    field: clusterTagKeys
  ) => void;
}): JSX.Element => {
  return (
    <div
      className={`grid grid-cols-12 items-center border-lg shadow-lg border-gray-800 rounded-md mb-4 w-11/12 py-4 px-2 gap-3 ${ElementBgClassnames.primary}`}
    >
      <div>Cluster Tag</div>

      <div className={`px-1 py-1.5 rounded ${ElementBgClassnames.primaryAlt}`}>
        {clusterTag.clusterTagId}
      </div>
      {/* <div className="text-right">Campaign IDs</div>
      <div
        className={`px-1 py-2 col-span-2 rounded truncate ${ElementBgClassnames.primaryAlt}`}
      >
        {clusterTag.campaigns.join()}
      </div> */}
      <div className="text-right">IFAs</div>
      <div
        className={`px-1 py-2 col-span-8 rounded truncate ${ElementBgClassnames.primaryAlt}`}
      >
        {clusterTag.devices.join()}
      </div>
      {/* <div className="text-right">Location IDs</div>
      <div
        className={`px-1 py-2 col-span-2 rounded truncate ${ElementBgClassnames.primaryAlt}`}
      >
        {clusterTag.locations.join()}
      </div> */}
      <div className="flex justify-end">
        <StyledButton
          className=""
          variant={ElementVariant.danger}
          onClick={() => {
            // call to backend to delete
            // also expand with modal
            setDeleteClusterTagIndex(clusterTagIndex);
          }}
        >
          <i className="fa fa-trash-alt"></i>
        </StyledButton>
        <StyledButton
          className="ml-2"
          variant={ElementVariant.primaryAltTwo}
          onClick={() => {
            handleClusterTagChange(clusterTagIndex, true, 'isEditing');
          }}
        >
          <DownArrowHeadIcon />
        </StyledButton>
      </div>
    </div>
  );
};

const UpdateClusterTags = (): JSX.Element => {
  const [clusterTags, setClusterTags] = useState<ClusterTag[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [filterCampaigns, setFilterCampaigns] = useState<string[]>([]);
  const [filterDevices, setFilterDevices] = useState<string[]>([]);
  // const [filterLocations, setFilterLocations] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
  const [showBackModal, setShowBackModal] = useState<boolean>(false);
  const [showClearModal, setShowClearModal] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteClusterTag, setDeleteClusterTag] = useState<number>(-1);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);

  const fetchFilters = async () => {
    setIsLoading(true);
    const result: any = await apiAgent.Filters.getCurrentFilter();
    const filter = result.data.data.filter;

    // let campaigns: string[] = [];
    let devices: string[] = [];
    // let locations: string[] = [];

    filter.campaigns.forEach((campaign: any) => {
      // campaigns.push(campaign.campaignId);
      campaign.devices.forEach((device: any) => {
        devices.push(device.ifa);
      });
      // campaign.locations.forEach((location: any) => {
      //   locations.push(location.locationId);
      // });
    });

    // console.log(`current filter devices: ${devices}`);

    // setFilterCampaigns(campaigns);
    setFilterDevices(devices);
    // setFilterLocations(locations);
    setIsLoading(false);
  };

  const fetchClusterTags = async () => {
    setIsLoading(true);
    const result: any = await apiAgent.ClusterTags.getAllClusterTags();
    const fetchedClusterTags: any = result.data.data.clusterTags;
    let parsedClusterTags: ClusterTag[] = fetchedClusterTags.map(
      (clusterTag: any) => {
        return {
          clusterTagId: clusterTag.clusterTagId,
          // campaigns: clusterTag.campaigns.map((campaign: any) => campaign.campaignId),
          devices: clusterTag.devices.map((device: any) => device.ifa)
          // locations: clusterTag.locations.map((location: any) => location.locationId)
        };
      }
    );
    setClusterTags(parsedClusterTags);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFilters();
    fetchClusterTags();
  }, []);

  // const handleFileUpload = async (uploadFiles: any) => {
  //   Papa.parse(uploadFiles[0], {
  //     header: true,
  //     skipEmptyLines: true,
  //     complete: (rows) => {
  //       const csvRows: Array<any> = rows.data;
  //       try {
  //         //check if missing any data
  //         if (
  //           !csvRows[0].clusterTagId ||
  //           !csvRows[0].value ||
  //           !csvRows[0].type
  //         ) {
  //           throw new Error(
  //             'Location file should have clusterTagId, value, and type columns'
  //           );
  //         }

  //         let parsedClusterTags = [...clusterTags];
  //         for (let csvIndex = 0; csvIndex < csvRows.length; csvIndex++) {
  //           const csvRow = csvRows[csvIndex];
  //           if (csvRow.clusterTagId === '') {
  //             throw new Error('clusterTagId cannot be empty');
  //           }
  //           let clusterTagIndex = parsedClusterTags.findIndex(
  //             (clusterTag) => clusterTag.clusterTagId === csvRow.clusterTagId
  //           );
  //           //if cluster tag not found, add empty cluster tag with the clusterTagId
  //           if (clusterTagIndex < 0) {
  //             parsedClusterTags.push({
  //               clusterTagId: csvRow.clusterTagId,
  //               // campaigns: [],
  //               devices: []
  //               // locations: []
  //             });
  //             clusterTagIndex = parsedClusterTags.length - 1;
  //           }
  //           switch (csvRow.type) {
  //             // case 'campaign':
  //             //   parsedClusterTags[clusterTagIndex].campaigns.push(csvRow.value);
  //             //   break;
  //             case 'ifa':
  //               parsedClusterTags[clusterTagIndex].devices.push(csvRow.value);
  //               break;
  //             // case 'location':
  //             //   parsedClusterTags[clusterTagIndex].locations.push(csvRow.value);
  //             //   break;
  //             default:
  //               throw new Error(
  //                 "type column has invalid entry. Should be 'campaign', 'ifa', or 'location'"
  //               );
  //           }
  //         }
  //         validateClusterTags(
  //           parsedClusterTags,
  //           // filterCampaigns,
  //           filterDevices
  //           // filterLocations
  //         );
  //         setClusterTags(parsedClusterTags);
  //       } catch (e: any) {
  //         console.log(e);
  //         toastHandler({
  //           messages: e.message,
  //           toastOptions: { type: 'error' }
  //         });
  //       }
  //     }
  //   });
  // };

  const handleFileUpload = async (uploadFiles: any) => {
    Papa.parse(uploadFiles[0], {
      header: true,
      skipEmptyLines: true,
      complete: (rows) => {
        const csvRows: Array<any> = rows.data;
        try {
          //check if missing any data
          if (!csvRows[0].clusterTagId || !csvRows[0].ifa) {
            console.log(csvRows[0]);
            throw new Error('File should have clusterTagId and ifa columns');
          }

          let parsedClusterTags = [...clusterTags];
          for (let csvIndex = 0; csvIndex < csvRows.length; csvIndex++) {
            const csvRow = csvRows[csvIndex];
            if (csvRow.clusterTagId === '') {
              throw new Error('clusterTagId cannot be empty');
            }
            let clusterTagIndex = parsedClusterTags.findIndex(
              (clusterTag) => clusterTag.clusterTagId === csvRow.clusterTagId
            );
            //if cluster tag not found, add empty cluster tag with the clusterTagId
            if (clusterTagIndex < 0) {
              parsedClusterTags.push({
                clusterTagId: csvRow.clusterTagId,
                // campaigns: [],
                devices: []
                // locations: []
              });
              clusterTagIndex = parsedClusterTags.length - 1;
            }
            parsedClusterTags[clusterTagIndex].devices.push(csvRow.ifa);
          }
          validateClusterTags(parsedClusterTags, filterDevices);
          setClusterTags(parsedClusterTags);
        } catch (e: any) {
          console.log(e);
          toastHandler({
            messages: e.message,
            toastOptions: { type: 'error' }
          });
        }
      }
    });
  };

  const handleAddClusterTag = () => {
    let resultClusterTags = clusterTags.map((clusterTag: ClusterTag) => {
      let clusterTagCollapse = clusterTag;
      clusterTagCollapse.isEditing = false;
      return clusterTagCollapse;
    });
    resultClusterTags.push({
      clusterTagId: '',
      // campaigns: [],
      devices: [],
      // locations: [],
      isEditing: true
    });
    setClusterTags(resultClusterTags);
    setShouldScroll(true);
  };

  const handleClusterTagChange = (
    clusterTagIndex: number,
    value: any,
    field: clusterTagKeys
  ) => {
    let newArr: any = [...clusterTags];
    newArr[clusterTagIndex][field] = value;
    setClusterTags(newArr);
  };

  const handleDeleteClusterTag = (index: number) => {
    setClusterTags([
      ...clusterTags.slice(0, index),
      ...clusterTags.slice(index + 1, clusterTags.length)
    ]);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      validateClusterTags(
        clusterTags,
        // filterCampaigns,
        filterDevices
        // filterLocations
      );
    } catch (e: any) {
      console.log(e);
      setIsSubmitting(false);
      setError(e.message);
      toastHandler({
        messages: e.message,
        toastOptions: { type: 'error' }
      });
      return;
    }

    try {
      //submit clusterTag
      const result = await apiAgent.ClusterTags.updateClusterTags(
        clusterTags.map((clusterTag: ClusterTag) => {
          return {
            clusterTagId: clusterTag.clusterTagId,
            // campaigns: clusterTag.campaigns,
            devices: clusterTag.devices
            // locations: clusterTag.locations
          };
        })
      );
      setError('Cluster Tags updated!');
      toastHandler({
        messages: 'Cluster Tags updated!',
        toastOptions: { type: 'success' }
      });
      fetchClusterTags();
      setIsSubmitting(false);
    } catch (err: any) {
      console.log(err);
      setIsSubmitting(false);
      setError(err.response.data.message);
      toastHandler({
        messages: err.response.data.message,
        toastOptions: { type: 'error' }
      });
    }
  };
  const handleClear = () => {
    setClusterTags([]);
  };

  const scrollToNewClusterTag = () => {
    const scrollDiv = document.getElementById('scrollDiv');
    if (scrollDiv) {
      scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
      window.scrollTo(0, scrollDiv.offsetTop);
      scrollDiv.scrollTop = scrollDiv.scrollHeight;
    }
    setShouldScroll(false);
  };

  useEffect(() => {
    if (shouldScroll) {
      scrollToNewClusterTag();
    }
  }, [clusterTags]);
  return (
    <ContainerFull>
      <div className="px-12 ">
        <StyledButton className="mb-4" onClick={() => setShowBackModal(true)}>
          Back
        </StyledButton>
        <PageTitle>Editing Cluster Tags</PageTitle>
        <PageSubDescription>
          {`Cluster tags submitted will replace the existing iteration`}
        </PageSubDescription>
        <React.Fragment>
          <div className="flex">
            <PageTitle> Add from CSV Files</PageTitle>
            <Tooltip className="">
              {/* old csv tooltip
              <div>
                <div className="mb-2">
                  Cluster Tag file columns: clusterTagId, value, type
                </div>
                <div className="mb-2">
                  type options: choose one of 'campaign'/'ifa'/'location'{' '}
                </div>
                <div className="mb-2">
                  value: the identifiers of data types specified in campaigns
                </div>
                <div className="mb-1">
                  e.g: Adding campaign(cam-1) and location(loc-1) to clusterTag
                  tag(clusterTag-1)
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <div>clusterTagId</div>
                  <div>value</div>
                  <div>type</div>
                  <div>clusterTag-1</div>
                  <div>cam-1</div>
                  <div>campaign</div>
                  <div>clusterTag-1</div>
                  <div>loc-1</div>
                  <div>location</div>
                </div>
              </div> */}
              {
                <div>
                  <div className="mb-2">
                    Cluster Tag file columns: clusterTagId, ifa
                  </div>
                </div>
              }
            </Tooltip>
          </div>
          <PageSubDescription>
            {`Uploaded files add to current form`}
          </PageSubDescription>
          <FileUpload withFilename={false} handleSetFiles={handleFileUpload}>
            Upload Cluster Tag files
          </FileUpload>
        </React.Fragment>
        <div className="flex">
          <div className="grow">
            <PageTitle>Cluster Tags</PageTitle>
          </div>
          <div>
            <StyledButton
              className="mr-4"
              onClick={handleAddClusterTag}
              variant={ElementVariant.info}
            >
              Add
            </StyledButton>
          </div>
          <div>
            <StyledButton
              className="grow-0"
              variant={ElementVariant.primaryAltTwo}
              onClick={() => setShowResetModal(true)}
            >
              Reset
            </StyledButton>
          </div>
          <div>
            <StyledButton
              className="ml-4"
              variant={ElementVariant.danger}
              onClick={() => setShowClearModal(true)}
            >
              Clear
            </StyledButton>
          </div>
        </div>
        <WithLoadingDiv isLoading={isLoading}>
          <div
            className={`relative w-full flex flex-col items-center py-8 rounded-lg  ${ElementBgClassnames.primaryAlt} overflow-y-scroll`}
            style={{ height: '80vh' }}
            id="scrollDiv"
          >
            {clusterTags.map(
              (clusterTag: ClusterTag, clusterTagIndex: number) => {
                return clusterTag.isEditing ? (
                  <EditingClusterTagDiv
                    clusterTag={clusterTag}
                    clusterTagIndex={clusterTagIndex}
                    handleClusterTagChange={handleClusterTagChange}
                    setDeleteClusterTagIndex={(index: number) => {
                      setShowDeleteModal(true);
                      setDeleteClusterTag(index);
                    }}
                  />
                ) : (
                  <ClusterTagDiv
                    clusterTag={clusterTag}
                    clusterTagIndex={clusterTagIndex}
                    setDeleteClusterTagIndex={(index: number) => {
                      setShowDeleteModal(true);
                      setDeleteClusterTag(index);
                    }}
                    handleClusterTagChange={handleClusterTagChange}
                  />
                );
              }
            )}
          </div>
        </WithLoadingDiv>

        <div className="w-full mt-4 mb-64 flex flex-col justify-center items-center">
          <div
            className={`${
              error === 'Cluster Tags updated!'
                ? ElementTextClassnames.success
                : ElementTextClassnames.danger
            }`}
          >
            {error}
          </div>
          <WithLoadingDiv isLoading={isSubmitting}>
            <div className="flex justify-center">
              <StyledButton
                className="my-2 mx-2"
                variant={ElementVariant.danger}
                onClick={() => {
                  setShowBackModal(true);
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                className="my-2  mx-2"
                variant={ElementVariant.success}
                onClick={() => {
                  setError('');
                  setShowSubmitModal(true);
                }}
              >
                Submit
              </StyledButton>
            </div>
          </WithLoadingDiv>
        </div>
      </div>
      <SubmitModal
        showModal={showSubmitModal}
        setShowExternal={setShowSubmitModal}
        handleSubmit={handleSubmit}
      />
      <BackModal showModal={showBackModal} setShowExternal={setShowBackModal} />
      <ClearModal
        showModal={showClearModal}
        setShowExternal={setShowClearModal}
        handleClear={handleClear}
      />
      <ResetModal
        showModal={showResetModal}
        setShowExternal={setShowResetModal}
        handleReset={fetchClusterTags}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowExternal={setShowDeleteModal}
        handleDelete={handleDeleteClusterTag}
        clusterTag={clusterTags[deleteClusterTag]}
        clusterTagIndex={deleteClusterTag}
      />
    </ContainerFull>
  );
};

const SubmitModal = ({
  showModal,
  setShowExternal,
  handleSubmit
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
  handleSubmit: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm Submission?</Modal.Header>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal && setShowExternal(false);
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.success}
            onClick={() => {
              setShowExternal && setShowExternal(false);
              handleSubmit();
            }}
          >
            Submit
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const BackModal = ({
  showModal,
  setShowExternal
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
}) => {
  const navigate = useNavigate();
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm leave page?</Modal.Header>
        <Modal.Body>You'll lose your current draft</Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => navigate('/cluster-tags')}
          >
            Leave
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const ClearModal = ({
  showModal,
  setShowExternal,
  handleClear
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleClear: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm clear clusterTags?</Modal.Header>
        <Modal.Body>
          You can reset this before submitting by clicking the RESET button
        </Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal(false);
              handleClear();
            }}
          >
            Clear
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const ResetModal = ({
  showModal,
  setShowExternal,
  handleReset
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleReset: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm reset clusterTags?</Modal.Header>
        <Modal.Body>You'll lose the current draft</Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.info}
            onClick={() => {
              setShowExternal(false);
              handleReset();
            }}
          >
            Reset
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const DeleteModal = ({
  showModal,
  setShowExternal,
  handleDelete,
  clusterTag,
  clusterTagIndex
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleDelete: Function;
  clusterTag: ClusterTag;
  clusterTagIndex: number;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        {clusterTag && (
          <Modal.Header>{`Confirm delete clusterTag(${clusterTag.clusterTagId})?`}</Modal.Header>
        )}
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal(false);
              handleDelete(clusterTagIndex);
            }}
          >
            Delete
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

export default UpdateClusterTags;
