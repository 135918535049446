import React, { Fragment } from 'react';
import _ from 'lodash';
import { render, unmountComponentAtNode } from 'react-dom';

import { ElementTextClassnames, ElementVariant } from '../interfaces';

import { StyledButton } from '.';

interface IButtonProps {
  label?: string;
  onClick?: Function;
}
interface IReactConfirmAlertProps {
  title: string;
  message: string | any;
  buttons: [IButtonProps?, IButtonProps?];
  afterClose?: Function;
}

const ReactConfirmAlert = (props: IReactConfirmAlertProps): JSX.Element => {
  const defaultProps: IReactConfirmAlertProps = {
    title: 'Confirm',
    message: '',
    buttons: [
      {
        label: 'Confirm',
        onClick: undefined
      },
      {
        label: 'Cancel',
        onClick: undefined
      }
    ],
    afterClose: () => null
  };

  const { title, message, buttons } = _.merge(defaultProps, props);

  const btnConfirmProps = buttons[0];
  const btnCancelProps = buttons[1];

  const close = () => {
    // const { afterClose } = this.props;
    removeBodyClass();
    removeElementReconfirm();
  };

  const handleClickButton = (onClickFunc?: Function) => (event: any) => {
    event.preventDefault();
    if (onClickFunc) onClickFunc();
    close();
  };

  return (
    <Fragment>
      <div
        id="modal"
        className="z-30 overflow-y-auto overflow-x-hidden block fixed inset-0"
      >
        <div
          id="modal-dialog"
          className="bg-background-alt relative z-40 border-0 rounded-lg shadow-lg w-1/3 outline-none focus:outline-none mt-20 mx-auto"
        >
          {/* Title */}
          <div className="bg-background-alt flex items-center justify-between py-3 px-5 rounded-t">
            <h1 className="text-white text-xl">{title}</h1>
          </div>

          {/* Body */}
          <div className="relative flex-auto px-5 mb-5">
            <div className={`${ElementTextClassnames.primary} text-lg`}>
              {message}
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-center py-3 px-5">
            <StyledButton
              variant={ElementVariant.success}
              className="mr-5"
              onClick={handleClickButton(btnConfirmProps?.onClick)}
            >
              {btnConfirmProps?.label}
            </StyledButton>

            <StyledButton
              variant={ElementVariant.primaryAltTwo}
              className="mr-5 text-white"
              onClick={handleClickButton(btnCancelProps?.onClick)}
            >
              {btnCancelProps?.label}
            </StyledButton>
          </div>
        </div>
      </div>
      <div className="z-20 opacity-80 fixed inset-0 bg-black" />
    </Fragment>
  );
};

const createElementReconfirm = (properties: any): void => {
  let divTarget = document.getElementById('react-confirm-alert');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<ReactConfirmAlert {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    divTarget = document.createElement('div');
    divTarget.id = 'react-confirm-alert';
    document.body.appendChild(divTarget);
    render(<ReactConfirmAlert {...properties} />, divTarget);
  }
};

const removeElementReconfirm = (): void => {
  const target = document.getElementById('react-confirm-alert');
  if (target) {
    unmountComponentAtNode(target);
    target?.parentNode?.removeChild(target);
  }
};

const addBodyClass = (): void => {
  document.body.classList.add('react-confirm-alert-body-element');
};

const removeBodyClass = (): void => {
  document.body.classList.remove('react-confirm-alert-body-element');
};

const confirmAlert = (properties: IReactConfirmAlertProps): void => {
  addBodyClass();
  createElementReconfirm(properties);
};

export default confirmAlert;
