interface ITooltipProps {
  className?: string;
  children?: React.ReactNode;
  topLeft?: boolean;
  icon?: string;
  tailwindColor?: string;
}

export interface ITooltipCardProps {
  children?: any;
  topLeft?: boolean;
}

export const TooltipCard = ({ children }: ITooltipCardProps) => {
  const card =
    'rounded max-w-sm text-xs text-black bg-tooltip-bg py-1 px-2 font-sans font-normal ml-4 shadow-md shadow-white text-left space-y-2 ';
  return <div className={card}>{children || null}</div>;
};

export const TooltipPopupContainer = ({ topLeft, children }: ITooltipCardProps) => {
  const baseClass = 'pointer-events-none absolute min-w-max z-50';
  const position = !topLeft
    ? 'left-full top-0 h-auto'
    : 'right-0 bottom-full h-auto mb-1 -mr-2';
  const animationClass =
    'transition ease-in-out duration-300 opacity-0 group-hover:opacity-100 transform -translate-y-10 group-hover:-translate-y-2';
  return (
    <div className={`${baseClass} ${position} ${animationClass}`}>
      {/* <Triangle /> */}
      <TooltipCard>{children}</TooltipCard>
    </div>
  );
};

const Tooltip = ({ topLeft, icon, children, tailwindColor }: ITooltipProps) => {
  const color = tailwindColor || 'text-gray-dark';
  const spanClass = `group ml-2 relative w-auto h-auto ${color} hover:text-gray-light`;
  return (
    <span className={spanClass}>
      <i className={icon || 'fas fa-question-circle'} />
      <TooltipPopupContainer topLeft={topLeft}>
        {children}
      </TooltipPopupContainer>
    </span>
  );
};

export default Tooltip;
