import React from 'react';

const Container = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <div className="container mx-auto py-5 w-full text-white">{children}</div>
);

const ContainerFull = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <div className="py-5 px-6 w-full text-text-primary">{children}</div>
);

export { Container, ContainerFull };
