import { memo, useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import apiAgent from '../../../../api/apiAgent';

import { StyledButton, WithLoadingDiv } from '../../../../components';

import classNames from 'classnames';
import { ElementTextClassnames } from '../../../../interfaces';

import {
  LayerGroup,
  LayersControl,
  MapContainer,
  Pane,
  TileLayer
} from 'react-leaflet';

import L, { LatLngBounds, LatLngExpression } from 'leaflet';
import Control from 'react-leaflet-custom-control';

import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';

import Input from '../../../../components/form/Input';
import { TSearchFormParams } from '../../../../interfaces/api';

import Checkbox from '../../../../components/form/Checkbox';
// import Select from 'react-select';
import { useAtom } from 'jotai';
import { mapIsLoadingAtom } from '../../../../atoms/dashboardStore';

import { Cluster, MapPoint } from '../../../../interfaces/mapChart';
import { toastHandler } from '../../../../utils';
import ClustersOverlay from './ClustersOverlay';
import CustomAreaDraw from './CustomAreaDraw';
import CustomMarkers from './CustomMarkers';
import DataGroupColorPicker from './DataGroupColorPicker';
import LocationsOverlay from './LocationsOverlay';
import { generateMapPointsBatch, generateRandomColorArray } from './MapUtils';

// interface IFA {
//   ifa: string;
//   // alias?: string;
//   latest_datetime: Date;
//   count: number;
//   // isDOI: boolean;
// }
// interface MapPoint {
//   id: string;
//   location: string;
//   position: number[];
//   IFAs: Map<string, IFA>;
// }

// interface Cluster {
//   id: string;
//   groupCount: number;
//   mapPoints: Map<string, MapPoint>;
// }

const MapChart = ({
  searchParams,
  locationsList = [],
  devicesList = [],
  totalEvents = 0,
  coordinatesSearchParams,
  setCoordinatesSearchParams,
  coordinatesFromEventRecords = [],
  setCoordinatesFromEventRecords,
  handleFilterClickFromMap
}: {
  searchParams?: TSearchFormParams;
  locationsList?: any[];
  devicesList?: any[];
  totalEvents?: number;
  coordinatesSearchParams?: any;
  setCoordinatesSearchParams: (latlngs: any) => void;
  coordinatesFromEventRecords: string[];
  setCoordinatesFromEventRecords: Function;
  handleFilterClickFromMap: Function;
}): JSX.Element => {
  // const [mapChartData, setMapChartData] = useState({
  //   // loi_data: [],
  //   cluster_data: [],
  //   totalMapPoints: 0
  //   // loi_list: []
  // });

  // const useFocus = (): [any, () => void] => {
  //   const htmlElRef: MutableRefObject<any> = useRef(null);
  //   const setFocus = (): void => {
  //     htmlElRef?.current?.focus?.();
  //   };

  //   return [htmlElRef, setFocus];
  // };
  const focusArea = useRef<HTMLInputElement>(null);

  const maxPoints_ref = useRef<any>(null);
  const [mapPointsLimit, setMapPointsLimit] = useState(1000);
  // const [pointCounter, setPointCounter] = useState(0);
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const map = useRef<L.Map>(null);

  const [visibleMapPoints, setVisibleMapPoints] = useState<LatLngBounds>(
    new L.LatLngBounds([
      [11.1353, 94.6268],
      [-11.3508, 134.911]
    ])
  );

  //State for resetting states checker
  const [reset, setReset] = useState<boolean>(false);
  //States for ColorPicker
  const [displayColorPickerPanel, setDisplayColorPickerPanel] =
    useState<boolean>(false);

  //State for CustomDrawn Rect
  const [currentRects, setCurrentRects] = useState<any>();

  //States for Go to Coordinates feature
  const [coordinates, setCoordinates] = useState<string>('');
  const [invalidCoordinatesError, setInvalidCoordinatesError] =
    useState<boolean>(false);

  //States for pin drops
  const [createPin, setCreatePin] = useState<boolean>(false);
  const [customMarkers, setCustomMarkers] = useState<[number, number][]>([]);

  //Tracking loading of map
  const [mapIsLoading, setMapIsLoading] = useState<boolean>(false);
  const [isFetchingMoreData, setIsFetchingMoreData] = useAtom(mapIsLoadingAtom);

  //Current fetched data
  const [mapChartData, setMapChartData] = useState<any>({});
  const [totalMapPoints, setTotalMapPoints] = useState<number>(0);

  //Tracking current fetched data
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [currentLoadedRecords, setCurrentLoadedRecords] = useState<number>(0);

  const [multiDateRangeArray, setMultiDateRangeArray] = useState<Date[][]>([]);
  const [currentMultiDateIndex, setCurrentMultiDateIndex] = useState<number>(0);

  const fetchMapArrayMultiDates = async (
    searchParams: any,
    firstFetch: boolean = false
  ): Promise<void> => {
    try {
      let page = currentPage;
      const limit = 50000;
      const PAGES_TO_QUERY = 5; //Auto loads up to 5 pages at a go
      let tempHasNextPage = hasNextPage;
      let tempTotalMapPoints = totalMapPoints;
      let loadedRecords = currentLoadedRecords;
      let dateRangeArray = [];

      let clusters_map: Map<string, any> = new Map(mapChartData.cluster_data); //Get the current displayed data if any for later accumulation with newly fetched data

      if (firstFetch) {
        // console.log('First fetch!');
        //Reset fetch values if its a new filter search.
        page = 1;
        tempHasNextPage = true;
        tempTotalMapPoints = 0;
        loadedRecords = 0;
        clusters_map = new Map();

        //If date range is given, check whether its more than N days. If so, break down in daily ranged queries.
        const { startDate, endDate } = searchParams;

        let startDateCheck = new Date(startDate);
        const endDateCheck = new Date(endDate);
        const startDatePlusOne = new Date(startDate);
        startDatePlusOne.setDate(startDatePlusOne.getDate() + 1);
        startDatePlusOne.setHours(23, 59, 59, 999);

        // console.log(startDateCheck.toLocaleDateString());
        // console.log(endDateCheck.toLocaleDateString());
        // console.log(startDatePlusOne.toLocaleDateString());

        //============= Create an array to house the date ranges ================

        while (startDatePlusOne <= endDateCheck) {
          dateRangeArray.push([
            new Date(startDateCheck),
            new Date(startDatePlusOne)
          ]);

          startDateCheck = new Date(startDatePlusOne);
          startDateCheck.setDate(startDateCheck.getDate() + 1);
          startDateCheck.setHours(0, 0, 0, 0);
          startDatePlusOne.setDate(startDatePlusOne.getDate() + 1);
        }
        dateRangeArray.push([startDateCheck, endDateCheck]); //Add the final date range until endDate
        dateRangeArray.reverse(); //Reverse the array so latest date ranges get retrieved first
        // console.log(JSON.stringify(dateRangeArray, null, 2));
        setMultiDateRangeArray(dateRangeArray);
      } else {
        //If this is not the first fetch, use the date range array from state
        dateRangeArray = multiDateRangeArray;
      }

      //Map through the date ranges and retrieve data in chunks of 50k rows
      //Consolidate the chunks and each time until no more data to fetch, while taking into consideration max return data

      let maxPage = page + PAGES_TO_QUERY;

      //============ Loop through micro date ranges ======================
      //currentMultiDateIndex tracks the current micro date range that has been retrieved thus far.
      //It will allow more data to load until final micro date range hits zero return.
      //Note that for() loop is used instead of .map because it shouldn't run all date ranges concurrently, will screw up the results.
      for (let i = currentMultiDateIndex; i < dateRangeArray.length; i++) {
        const range = dateRangeArray[i];

        tempHasNextPage = true; //Reset when start next date range query
        // setHasNextPage(true);

        // console.log(`loadedRecords in date loop is ${loadedRecords}`);
        // console.log(
        //   `currentLoadedRecords is date loop is ${currentLoadedRecords}`
        // );

        //Loops as long as there are more data to fetch and not yet hitting the max pages to fetch.
        while (
          tempHasNextPage &&
          page < maxPage &&
          loadedRecords < currentLoadedRecords + 250000
        ) {
          // console.log(`startDate check: ${range[0]}`);
          // console.log(`endDate check: ${range[1]}`);
          // console.log(`page check: ${page}`);

          //Grab the next batch of data via API call
          const { data } = await apiAgent.DeviceEvent.getEventListForMap({
            ...searchParams,
            page,
            limit,
            startDate: range[0],
            endDate: range[1]
          });

          const fetchedData = data.data;
          loadedRecords += fetchedData.length;
          setCurrentLoadedRecords(loadedRecords);

          // console.log(`${fetchedData.length} records retrieved this round.`);
          // console.log(`loadedRecords so far: ${loadedRecords}`);

          //if no more data to fetch in this micro date range
          if (fetchedData.length === 0) {
            //If is the last micro date range as well, set mapLoading to false to show map
            if (i === dateRangeArray.length - 1) {
              tempHasNextPage = false;
              setHasNextPage(false);
              setMapIsLoading(false);
            }
            page = 1; //Reset the page for next micro date range.
            break; //End of this micro date range
          }

          //Process the fetched data, display it once done by updating the data state
          else {
            const { cluster_data, uniqueMapPoints } =
              await generateMapPointsBatch(
                clusters_map,
                tempTotalMapPoints,
                fetchedData
              );

            clusters_map = new Map(cluster_data);

            tempTotalMapPoints = uniqueMapPoints;

            //Do the following only once, after the first round of processing, to let the map be visible first so user can see something quickly.
            if (page === currentPage || (firstFetch && page === 1)) {
              fitPointsToView();

              setMapChartData({
                cluster_data: clusters_map,
                tempTotalMapPoints
              }); //Set whatever data is processed so far to populate the map asap.
              setTotalMapPoints(tempTotalMapPoints); //Update the map point counts

              setVisibleMapPoints(
                mapChartData?.cluster_data?.length > 0
                  ? getBounds(mapChartData?.cluster_data)
                  : new L.LatLngBounds([
                      [11.1353, 94.6268],
                      [-11.3508, 134.911]
                    ])
              );

              setColorsArray(generateRandomColorArray(100));

              setMapIsLoading(false);
            }

            page++;
            // console.log(`totalMapPoints is ${tempTotalMapPoints}`);
            // console.log(`Fetched ${page} times so far`);
          }
        } //End of while more pages to load for this micro date range

        //If maxPage is hit for this round of loading, stop first
        if (page >= maxPage) break;
        //Else if not last page but exited the while loop, means this date range is done.
        //Track the current data retrieval for micro date range, to be used by "Load more" button.
        else setCurrentMultiDateIndex(i + 1);

        // console.log(
        //   `i is ${i} and dateRangeArray.length is ${dateRangeArray.length} and tempHasNextPage is ${tempHasNextPage}`
        // );
        if (i === dateRangeArray.length - 1 && !tempHasNextPage) {
          // console.log(`No more data to retrieve!`);
          setHasNextPage(false); //If this is the final loop and no more data
        }
      } //End of dateRangeArray for loop

      setMapChartData({
        cluster_data: clusters_map,
        tempTotalMapPoints
      }); //Set whatever data is processed so far to populate the map asap.
      setTotalMapPoints(tempTotalMapPoints); //Update the map point counts

      setVisibleMapPoints(
        mapChartData?.cluster_data?.length > 0
          ? getBounds(mapChartData?.cluster_data)
          : new L.LatLngBounds([
              [11.1353, 94.6268],
              [-11.3508, 134.911]
            ])
      );

      // setColorsArray(generateRandomColorArray(100));

      // console.log(`End of data fetching`);
      setCurrentPage(page);
      // console.log(`currentPage is ${currentPage}`);
      setCurrentLoadedRecords(loadedRecords);
      // console.log(`currentLoadedRecords is ${currentLoadedRecords}`);

      setMapIsLoading(false);
      setIsFetchingMoreData(false);

      return;
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    }
  };

  const fetchMapArray = async (
    searchParams: any,
    firstFetch: boolean = false
  ): Promise<void> => {
    try {
      // console.log(
      //   `entered fetchMapArray. currentPage is ${currentPage}. totalMapPoints is ${totalMapPoints}. currentLoadedRecords is ${currentLoadedRecords}`
      // );

      let page = currentPage;
      const limit = 50000;
      const PAGES_TO_QUERY = 5; //Auto loads up to 5 pages at a go
      let tempHasNextPage = hasNextPage;
      let tempTotalMapPoints = totalMapPoints;
      let loadedRecords = currentLoadedRecords;

      let clusters_map: Map<string, any> = new Map(mapChartData.cluster_data);

      if (firstFetch) {
        //Temp workaround. Reset fetch values if its a new filter search. set methods called before this function does not work immediately.
        setMapIsLoading(true);
        page = 1;
        tempHasNextPage = true;
        tempTotalMapPoints = 0;
        loadedRecords = 0;
        clusters_map = new Map();
      }

      let maxPage = page + PAGES_TO_QUERY;

      //Loops as long as there are more data to fetch and not yet hitting the max pages to fetch. Also limit to 10k points for now.
      while (tempHasNextPage && page < maxPage) {
        // && tempTotalMapPoints < 10000
        //Grab the next batch of data via API call
        const { data } = await apiAgent.DeviceEvent.getEventListForMap({
          ...searchParams,
          page,
          limit
        });

        const fetchedData = data.data;
        loadedRecords += fetchedData.length;
        setCurrentLoadedRecords(loadedRecords);

        //Ends the loop if no more data to fetch.
        if (fetchedData.length === 0) {
          tempHasNextPage = false;
          setHasNextPage(false);
          // console.log(`No more data to fetch!`);
          setMapIsLoading(false);
          break;
        }

        //Process the fetched data, display it once done by updating the data state
        else {
          const { cluster_data, uniqueMapPoints } =
            await generateMapPointsBatch(
              clusters_map,
              tempTotalMapPoints,
              fetchedData
            );

          clusters_map = new Map(cluster_data);

          tempTotalMapPoints = uniqueMapPoints;

          // console.log(`totalMapPoints is ${tempTotalMapPoints}`);
          // console.log(`Fetched ${page} times so far`);

          setMapChartData({ cluster_data: clusters_map, tempTotalMapPoints }); //Set whatever data is processed so far to populate the map asap.
          setTotalMapPoints(tempTotalMapPoints); //Update the map point counts

          setVisibleMapPoints(
            mapChartData?.cluster_data?.length > 0
              ? getBounds(mapChartData?.cluster_data)
              : new L.LatLngBounds([
                  [11.1353, 94.6268],
                  [-11.3508, 134.911]
                ])
          );

          //Do the following only once, after the first round of processing, to let the map be visible first so user can see something quickly.
          if (page === currentPage || (firstFetch && page === 1)) {
            fitPointsToView();
            setColorsArray(generateRandomColorArray(100));
            // console.log(`mapIsLoading is gonna set false liao`);
            setMapIsLoading(false);
          }

          page++;
        }
      }

      // console.log(`End of data fetching`);
      setCurrentPage(page);
      // console.log(`currentPage is ${currentPage}`);
      setCurrentLoadedRecords(loadedRecords);
      // console.log(`currentLoadedRecords is ${currentLoadedRecords}`);
      setIsFetchingMoreData(false);
      return;
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    }
  };

  const checkDateDiff = (
    startDate: string | number | Date,
    endDate: string | number | Date
  ): number => {
    const startDateCheck: Date = new Date(startDate);
    const endDateCheck: Date = new Date(endDate);

    const diffInMs = Math.abs(
      endDateCheck.getTime() - startDateCheck.getTime()
    );
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // To display the final no. of days (result)
    // console.log(
    //   'Total number of days between dates:\n' +
    //     startDate.toLocaleString() +
    //     '\n and \n' +
    //     endDate.toLocaleString() +
    //     ' is: \n' +
    //     diffInDays +
    //     ' days'
    // );

    return diffInDays;
  };

  const { refetch, isFetching } = useQuery(
    ['searchParams'],
    async () => {
      if (searchParams && searchParams.startDate && searchParams.endDate) {
        //if startDate and endDate has more than 2 days, fetchMapArrayMultiDates

        if (checkDateDiff(searchParams.startDate, searchParams.endDate) > 1) {
          // console.log(`calling fetchMapArrayMultiDates`);
          return await fetchMapArrayMultiDates(searchParams, true);
        } else {
          // console.log(`calling fetchMapArray`);
          return await fetchMapArray(searchParams, true);
        }
      } else return await fetchMapArray(searchParams, true);
    },
    {
      enabled: false,
      onSettled: () => {
        setIsFetchingMoreData(false);
      }
    }
  );

  const [colorsArray, setColorsArray] = useState(generateRandomColorArray(100));
  const [colorsMap, setColorsMap] = useState<Map<string, string>>(new Map());

  const loadMoreData = async () => {
    // console.log(`loadMoreData called`);
    try {
      setIsFetchingMoreData(true);
      if (searchParams && searchParams.startDate && searchParams.endDate)
        await fetchMapArrayMultiDates(searchParams);
      else return await fetchMapArray(searchParams, true);
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    } finally {
      setIsFetchingMoreData(false);
    }
  };

  useEffect(() => {
    // console.log(`colorsMap updated in MapChart index`);
  }, [colorsMap]);

  const resetStates = () => {
    setMapIsLoading(true);
    setCurrentPage(1);
    setCurrentLoadedRecords(0);
    setTotalMapPoints(0);
    setHasNextPage(true);
    setMapChartData({});
    setIsFetchingMoreData(true);
    setCurrentMultiDateIndex(0);
    setReset(!reset);
  };

  useEffect(() => {
    if (!isFetching) {
      // console.log(JSON.stringify(searchParams, null, 2));
      // console.log(`Refetching map data`);
      resetStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    //Having this to ensure that the variables are reset first when the searchParams are updated.
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    // console.log(`isFetchingMoreData is ${isFetchingMoreData}`);
  }, [isFetchingMoreData]);

  const goToCoordinates = (lat: number, lng: number): void => {
    const mapContainer = map?.current;

    const coords: LatLngExpression = [lat, lng];

    if (mapContainer) {
      mapContainer.flyTo(coords, 18);
      addCustomMarker(coords[0], coords[1]);
      focusArea.current?.scrollIntoView();
    }
  };

  useEffect(() => {
    // console.log(coordinatesFromEventRecords);

    if (coordinatesFromEventRecords.length > 1) {
      goToCoordinates(
        parseFloat(coordinatesFromEventRecords[0]),
        parseFloat(coordinatesFromEventRecords[1])
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinatesFromEventRecords]);

  const getBounds = (mapChartData: Cluster[]) => {
    // console.log(`in getBounds`);
    // console.log(data);
    const mapPointsArray: any = [];

    mapChartData.forEach((cluster: Cluster) => {
      cluster.mapPoints.forEach((entry: MapPoint) => {
        // console.log(entry.position);
        mapPointsArray.push(entry.position);
        return entry.position;
      });
    });

    // console.log(`mapPointsArray is ${mapPointsArray}`);
    // console.log(mapPointsArray);

    return new L.LatLngBounds(mapPointsArray);
  };

  const fitPointsToView = () => {
    const mapContainer = map?.current;

    const visibleLayerGroup = new L.FeatureGroup();

    mapContainer?.eachLayer((layer: any) => {
      if (layer instanceof L.Marker || layer instanceof L.CircleMarker) {
        visibleLayerGroup.addLayer(layer);
      }
    });

    const bounds = visibleLayerGroup.getBounds();
    mapContainer?.fitBounds(bounds?.pad(0.01));
  };

  const onlyNumbers = (array: any[]) => {
    return array.every((element) => {
      return !isNaN(element);
    });
  };

  const handleOnChangeCoordinates = (event: any) => {
    event.preventDefault();
    setCoordinates(event?.target?.value);
  };

  const handleOnToggleCreatePin = () => {
    setCreatePin(!createPin);
  };

  const addCustomMarker = (lat: number, lng: number) => {
    const markers = customMarkers.slice();
    markers.push([lat, lng]);

    setCustomMarkers(markers);
  };

  const removeCustomMarker = (event: any) => {
    const index = event.target.value;
    const markers = customMarkers.slice();

    markers.splice(index, 1).filter((marker, i) => {
      return marker !== undefined;
    });
    setCustomMarkers(markers);

    event.stopPropagation();
    event.preventDefault();
  };

  const handleFlyToClick = () => {
    const mapContainer = map?.current;

    const latlng = coordinates.split(',').map((value) => {
      return parseFloat(value);
    });

    if (onlyNumbers(latlng) && latlng.length === 2) {
      setInvalidCoordinatesError(false);

      const coords: LatLngExpression = [latlng[0], latlng[1]];

      if (mapContainer) mapContainer.flyTo(coords, 15);

      if (createPin) addCustomMarker(coords[0], coords[1]);
    } else {
      setInvalidCoordinatesError(true);
    }
  };

  const handleChangeMapPointsLimit = () => {
    if (maxPoints_ref.current.value !== null) {
      // console.log(maxPoints_ref.current.value);
      setMapPointsLimit(maxPoints_ref.current.value || 1000);
    }
  };

  const onKeyEnter = (event: any) => {
    if (event.key === 'Enter') handleChangeMapPointsLimit();
  };

  const toggleColorPickerPanel = () => {
    setDisplayColorPickerPanel(!displayColorPickerPanel);
  };

  return (
    <WithLoadingDiv isLoading={mapIsLoading}>
      <div className="grid grid-cols-2 gap-4" ref={focusArea}>
        <div
          style={{ zIndex: 1000 }}
          className="col-span-1 content-center flex justify-start items-center mb-5"
        >
          <label className="align-middle mr-2">Maximum points on map</label>
          <Input
            myRef={maxPoints_ref}
            className="w-40 mr-2"
            key="maxPoints_input"
            type="number"
            id="mapPointsLimit"
            name="mapPointsLimit"
            defaultValue={mapPointsLimit}
            onKeyDown={onKeyEnter}
          />

          <StyledButton onClick={handleChangeMapPointsLimit}>
            Apply
          </StyledButton>
          {mapPointsLimit >= 3000 && (
            <div
              className={classNames(
                ElementTextClassnames.warning,
                'text-center ml-2'
              )}
            >
              Warning: High map points will affect performance
            </div>
          )}
        </div>

        <div className="col-span-1 flex justify-end items-center mb-5">
          {invalidCoordinatesError && (
            <div
              className={classNames(
                ElementTextClassnames.danger,
                'text-center mr-2'
              )}
            >
              Invalid coordinates.
            </div>
          )}
          <div className="flex justify-end items-center mr-2">
            <label className="align-middle mr-2">Coordinates</label>
            <Input
              className="w-40 mr-2"
              key="coordinates_input"
              type="text"
              id="coordinates"
              name="coordinates"
              onChange={handleOnChangeCoordinates}
              value={coordinates.toString() || ''}
              placeholder="latitude, longitude"
            />
            <Checkbox
              label="Create Pin?"
              className="align-middle p-3 mr-2"
              onClick={handleOnToggleCreatePin}
            />
          </div>
          <StyledButton onClick={handleFlyToClick}>Go</StyledButton>
        </div>
      </div>
      {!mapIsLoading && mapChartData && (
        <MapContainer
          ref={map}
          style={{ minHeight: '700px', height: '90%', width: '100%' }}
          bounds={visibleMapPoints}
          // center={[-1.7795, 111.1772]}
          zoom={10}
          minZoom={4}
          maxZoom={18}
          scrollWheelZoom={true}
          doubleClickZoom={false}
        >
          <Control position="bottomleft">
            <StyledButton onClick={fitPointsToView}>
              Fit Points to View
            </StyledButton>
          </Control>

          {displayColorPickerPanel ? (
            <Control position="bottomleft">
              <DataGroupColorPicker
                data={mapChartData.cluster_data}
                colorsMap={colorsMap}
                colorsArray={colorsArray}
                setColorsMap={setColorsMap}
                toggleDisplayColorPicker={toggleColorPickerPanel}
                setMapIsLoading={setMapIsLoading}
              />
            </Control>
          ) : (
            <Control position="bottomleft">
              <StyledButton
                onClick={(e) => {
                  e.stopPropagation();
                  toggleColorPickerPanel();
                }}
              >
                Edit Clusters Color
              </StyledButton>
            </Control>
          )}

          <Control prepend position="bottomright">
            <p className={'text-black bg-white p-1'}>
              No. of clusters: {mapChartData?.cluster_data?.size}
              <br />
              Total No. of unique map points shown:{' '}
              {totalMapPoints >= mapPointsLimit
                ? mapPointsLimit
                : totalMapPoints}{' '}
              out of {totalMapPoints}
            </p>
          </Control>

          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Street">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Satellite">
              <TileLayer
                //   maxZoom={12}
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>

            <LayerGroup>
              <CustomAreaDraw
                currentRects={currentRects}
                setCurrentRects={setCurrentRects}
                coordinatesSearchParams={coordinatesSearchParams}
                setCoordinatesSearchParams={setCoordinatesSearchParams}
              />
            </LayerGroup>

            <LayerGroup>
              <LocationsOverlay
                searchParams={searchParams}
                locationsList={locationsList}
              />
            </LayerGroup>

            <Pane name="clusterOverlay" style={{ zIndex: 999 }}>
              <LayerGroup>
                <ClustersOverlay
                  data={mapChartData.cluster_data}
                  devices={devicesList}
                  mapPointsLimit={mapPointsLimit}
                  colorsMap={colorsMap}
                  // setColorsMap={setColorsMap}
                  colorsArray={colorsArray}
                  handleFilterClickFromMap={handleFilterClickFromMap}
                />
              </LayerGroup>
            </Pane>

            <LayerGroup>
              <CustomMarkers
                markers={customMarkers}
                addCustomMarker={addCustomMarker}
                removeCustomMarker={removeCustomMarker}
              />
            </LayerGroup>
          </LayersControl>
        </MapContainer>
      )}
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1 flex justify-start items-center my-5">
          {isFetchingMoreData ? (
            <div>
              <i className={`fas fa-circle-notch fa-spin text-white`} /> Loading
              more data... Currently loaded {currentLoadedRecords} records
            </div>
          ) : (
            <div>
              Plotted map points for {currentLoadedRecords} records.
              {hasNextPage && (
                <StyledButton onClick={loadMoreData}>Load more</StyledButton>
              )}
            </div>
          )}
        </div>
      </div>
    </WithLoadingDiv>
  );
};

export default memo(MapChart);
