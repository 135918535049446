import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import apiAgent from '../api/apiAgent';
import { LOADING_STATES } from '../cnstants';

const siteSettingLoadingStateAtom = atomWithReset(LOADING_STATES.LOADING);
interface IDefaultSiteSetting {
  siteName: string;
  siteDescription: string;
  loginMessage: string;
}

const siteSettingAtom = atom({
  siteName: '',
  siteDescription: '',
  loginMessage: ''
} as IDefaultSiteSetting);

const fetchSiteSettingAtom = atom(
  (get) => get(siteSettingAtom),
  async (_get, set) => {
    const result = await apiAgent.SiteSetting.getFirst();
    set(siteSettingAtom, result?.data?.data);
  }
);

export { siteSettingLoadingStateAtom, siteSettingAtom, fetchSiteSettingAtom };
