import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import millify from 'millify';

import apiAgent from '../../../api/apiAgent';
import {
  Badge,
  Pagination,
  ReactTable,
  WithLoadingDiv
} from '../../../components';
import { ElementTextClassnames, IPaginationState } from '../../../interfaces';
import { dateFormatter, toastHandler } from '../../../utils';
import GridChildTable from './GridChildTable';

const transformDataForTable = (list: any[]): any[] => {
  const data = list.map((obj: any) => {
    return {
      id: obj.id,
      ifa: obj.ifa,
      latestDatetime: dateFormatter({
        timestamp: obj.latest_datetime,
        withTime: true
      }),
      latitude: millify(obj.latitude, { precision: 4 }),
      longitude: millify(obj.longitude, { precision: 4 }),
      city: obj.city,
      state: obj.state,
      zipCode: parseInt(obj.zip_code) || 'Null',
      ip: obj.ip_address,
      campaignId: obj.campaign_id,
      count: millify(parseInt(obj.count)),
      locationId: obj.location_id,
      countryId: obj.country_id,
      asn: obj.asn,
      bundleId: obj.bundle_id,
      deviceManufacturer: obj.device_manufacturer,
      model: obj.model,
      deviceName: obj.devicename,
      platform: obj.platform,
      osVersion: obj.os_version,
      userAgent: obj.user_agent,
      carrier: obj.carrier,
      connectiontype: obj.connectiontype,
      clusterTags: obj.cluster_tags,
      startDatetime: dateFormatter({
        timestamp: obj.start_datetime,
        withTime: true
      })
    };
  });

  return data || [];
};

const DeviceEventsTable = ({
  searchText,
  searchClusterTags
}: {
  searchText?: string;
  searchClusterTags?: string[];
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [deviceEventsList, setDeviceEventsList] = useState<any[] | null>(null);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const fetchDeviceEventsList = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      const { data } = await apiAgent.Stats.getDeviceEventsList({
        searchText,
        searchClusterTags,
        page,
        limit
      });

      const pageInfo = data.meta;
      setDeviceEventsList(transformDataForTable(data.data.deviceEventsList));

      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastId: error?.code,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDeviceEventsList({
      page: 1,
      limit: paginationInfo.itemsPerPage
    });
  }, [
    paginationInfo.itemsPerPage,
    searchText,
    JSON.stringify(searchClusterTags)
  ]);

  const columns = useMemo(
    () => [
      {
        Header: 'Alias',
        accessor: 'clusterTags',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap',
        Cell: ({ row }: any) => {
          return <Badge>{row.values.clusterTags}</Badge>;
        }
      },
      {
        Header: 'ifa',
        accessor: 'ifa',
        thClassName: classNames(
          ElementTextClassnames.sysGenId,
          'whitespace-nowrap',
          'px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Datetime Start',
        accessor: 'startDatetime',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },

      {
        Header: 'Datetime End',
        accessor: 'latestDatetime', // accessor is the "key" in the data
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Occurrences',
        accessor: 'count',
        thClassName: 'px-2'
      },
      {
        Header: 'Location ID',
        accessor: 'locationId',
        thClassName: classNames(
          ElementTextClassnames.sysGenIdAlt,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Lat',
        accessor: 'latitude',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Lng',
        accessor: 'longitude',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'City',
        accessor: 'city',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'State',
        accessor: 'state',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Zip code',
        accessor: 'zipCode',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'IP',
        accessor: 'ip',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Country ID',
        accessor: 'countryId',
        thClassName: classNames(
          ElementTextClassnames.sysGenIdAlt,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      // {
      //   Header: 'Country Name',
      //   accessor: 'countryName',
      //   thClassName: classNames(
      //     ElementTextClassnames.sysGenIdAlt,
      //     'whitespace-nowrap px-2'
      //   ),
      //   tdClassName: 'whitespace-nowrap'
      // },
      {
        Header: 'Campaign ID',
        accessor: 'campaignId',
        thClassName: classNames(
          ElementTextClassnames.sysGenIdAlt,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Application',
        accessor: 'asn',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Bundle ID',
        accessor: 'bundleId',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Device Manufacturer',
        accessor: 'deviceManufacturer',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Model',
        accessor: 'model',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Device Name',
        accessor: 'deviceName',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Platform',
        accessor: 'platform',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'OS',
        accessor: 'osVersion',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'User Agent',
        accessor: 'userAgent',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Carrier',
        accessor: 'carrier',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Connection Type',
        accessor: 'connectiontype',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      }
    ],
    [JSON.stringify(deviceEventsList)]
  );

  return (
    <GridChildTable className="col-span-6" title="Device Events">
      {(searchText || '').length + (searchClusterTags || []).length > 0 ? (
        <Badge
          className={`mb-2 mx-auto table ${ElementTextClassnames.warning}`}
        >
          Searching IFAs based on
          {(searchText || '').length > 0 ? ` "${searchText}" ` : ''}
          {(searchText || '').length > 0 &&
            (searchClusterTags || []).length > 0 &&
            ' and '}
          {(searchClusterTags || []).length > 0
            ? ` ${(searchClusterTags || []).join(', ')}`
            : ''}
        </Badge>
      ) : (
        ''
      )}

      <WithLoadingDiv isLoading={isLoading}>
        <div className="-mx-5 mb-5 overflow-y-auto overflow-x-auto">
          <ReactTable
            columns={columns}
            data={deviceEventsList || []}
            className="w-full"
          />
        </div>
      </WithLoadingDiv>
      <div>
        {deviceEventsList && (
          <Pagination
            totalPages={paginationInfo.totalPages}
            totalItems={paginationInfo.totalItems}
            currentPageCount={paginationInfo.currentPageCount}
            itemsPerPage={paginationInfo.itemsPerPage}
            fetchData={fetchDeviceEventsList}
          />
        )}
      </div>
    </GridChildTable>
  );
};

export default DeviceEventsTable;
