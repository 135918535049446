import { useAtom } from 'jotai';
import React, { ReactNode, Dispatch, useState, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { isModalOpenAtom } from '../atoms';
import { ElementBgClassnames } from '../interfaces';
import { StyledButton } from './Button';

interface SlidingPanelProps {
  children: ReactNode;
  button?: ReactNode;
  width?: string;
  isPanelOpen: boolean;
  setIsPanelOpen: Dispatch<boolean>;
}

export const useDisableBodyScroll = (open: boolean) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);
};

const SlidingPanel = ({
  children,
  width = '750px',
  isPanelOpen,
  setIsPanelOpen
}: SlidingPanelProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useAtom(isModalOpenAtom);

  // useEffect(() => {
  //   setIsModalOpen(isPanelOpen);
  // }, [isPanelOpen]);

  const handleClose = () => {
    // setIsModalOpen(false);
    setIsPanelOpen(false);
  };

  return (
    <React.Fragment>
      <SlidingPane
        className={`${ElementBgClassnames.primary}`}
        overlayClassName="sliding-pane-overlay" //set z-index to high
        hideHeader
        width={width}
        isOpen={isPanelOpen}
        onRequestClose={handleClose}
        from="left"
      >
        {/* Header */}
        <div className="flex flex-row justify-end text-lg">
          <div className="justify-end">
            {/* Close Icon */}
            <i
              className={'fas fa-times text-3xl ml-3 text-white cursor-pointer'}
              onClick={handleClose}
            />
          </div>
        </div>

        {children}
      </SlidingPane>
    </React.Fragment>
  );
};

export default SlidingPanel;
