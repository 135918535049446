import React from 'react';
import { NavLink, LinkProps } from 'react-router-dom';

import { ElementVariant, ElementBgClassnames } from '../interfaces';
import { SmallLoader } from './Loaders';

const btnClassNames =
  'inline-block rounded py-2 px-4 focus:outline-none cursor-pointer';
const normalLinkClassNames = 'font-bold text-green-600 hover:underline';

interface ILinkProps {
  readonly variant?: ElementVariant;
  readonly showbtnshape?: boolean;
  readonly loading?: boolean;
}

const StyledRouterLink = (props: ILinkProps & LinkProps): JSX.Element => {
  const { loading, children, className, showbtnshape, ...rest } = props;
  return (
    <NavLink
      className={`${showbtnshape ? btnClassNames : normalLinkClassNames} ${
        className ?? ''
      }`}
      {...rest}
    >
      {loading ? <SmallLoader /> : children}
    </NavLink>
  );
};

const StyledBtnRouterLink = (props: ILinkProps & LinkProps): JSX.Element => {
  const { variant, loading, children, className, ...rest } = props;
  return (
    <NavLink
      className={`${btnClassNames}  ${
        ElementBgClassnames[variant || 'secondary']
      } ${className || ''}`}
      {...rest}
    >
      {children}
      {loading && <SmallLoader />}
    </NavLink>
  );
};

const StyledHrefLink = (
  props: ILinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
): JSX.Element => {
  const { loading, children, className, showbtnshape, href, onClick, ...rest } =
    props;

  let newClassName = className;
  if (showbtnshape && loading) {
    newClassName = `${className ?? ''} btn_disabled`;
  }

  const handleOnClick = (isLoading?: boolean) => (e: any) => {
    if (isLoading) {
      e.preventDefault();
    }
  };

  return (
    <a
      onClick={handleOnClick(loading)}
      href={href || ''}
      className={`${
        showbtnshape ? ` ${btnClassNames}` : normalLinkClassNames
      } ${newClassName}`}
      {...rest}
    >
      {loading && <SmallLoader />}
      {children}
    </a>
  );
};

export { StyledBtnRouterLink, StyledRouterLink, StyledHrefLink };
