import { useEffect, Suspense, Fragment } from 'react';
import { useAtom } from 'jotai';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { CubeLoader } from './components';
import {
  fetchSiteSettingAtom,
  fetchUserProfileAtom,
  siteSettingLoadingStateAtom,
  isModalOpenAtom
} from './atoms';
import { hasJwtCookie } from './auth';
import Navbar from './components/layout/Navbar';
import NavbarAdmin from './components/layout/NavbarAdmin';
import CustomSwitch from './routes';
import { LOADING_STATES } from './cnstants';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';

const CustomLoader = (): JSX.Element => {
  return (
    <div className="block text-center mt-28">
      <CubeLoader className="mx-auto" />
      <div className="mt-20 mb-10 text-xl text-white">
        Please wait for the application to load...
      </div>
    </div>
  );
};

const HideNavCheck = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element | null => {
  const location = useLocation();

  // Do not display navbar at login page
  // or do not have jwt or userProfileAtom
  if (
    location.pathname === '/' ||
    location.pathname === '/sys-login' ||
    location.pathname === '/sys-logout' ||
    !hasJwtCookie()
  ) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

// Create a client
const queryClient = new QueryClient();

const App = (): JSX.Element => {
  const [, fetchSiteSetting] = useAtom(fetchSiteSettingAtom);
  const [, setSiteSettingLoadingState] = useAtom(siteSettingLoadingStateAtom);
  const [, fetchUserProfile] = useAtom(fetchUserProfileAtom);
  const [isModalOpen] = useAtom(isModalOpenAtom);

  useEffect(() => {
    (async () => await fetchSiteSetting())();
    setSiteSettingLoadingState(LOADING_STATES.SUCCESS);
  }, []);

  useEffect(() => {
    if (hasJwtCookie()) {
      (async () => await fetchUserProfile())();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div
        className={classNames('min-h-screen  flex flex-col', {
          'overflow-hidden max-h-screen': isModalOpen
        })}
      >
        <Suspense fallback={<CustomLoader />}>
          <BrowserRouter>
            <HideNavCheck>
              <Navbar />
              <NavbarAdmin />
            </HideNavCheck>
            <CustomSwitch />
          </BrowserRouter>
          <ToastContainer limit={5} />
        </Suspense>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </div>
    </QueryClientProvider>
  );
};

export default App;
