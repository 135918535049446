import classNames from 'classnames';

const GridChild = ({
  children,
  className,
  title
}: {
  children: React.ReactNode;
  className?: string;
  title: string;
}): JSX.Element => (
  <div
    className={classNames(
      'rounded-md bg-background-alt py-4 px-5',
      className ?? ''
    )}
  >
    <div className="text-center text-lg mb-2">{title}</div>
    {children}
  </div>
);

export default GridChild;
