const rz_lois = [
  {
    Lat: -6.3006005,
    Long: 106.81076,
    Radius: 750,
    LOI_name: 'rz_eval_loi_01'
  },
  {
    Lat: -6.1017246,
    Long: 106.886734,
    Radius: 1300,
    LOI_name: 'rz_eval_loi_02'
  },
  {
    Lat: 0.93008184,
    Long: 104.4394,
    Radius: 180,
    LOI_name: 'rz_eval_loi_03'
  },
  {
    Lat: 1.0699898,
    Long: 104.229454,
    Radius: 100,
    LOI_name: 'rz_eval_loi_04'
  },
  {
    Lat: 0.9069371,
    Long: 104.43833,
    Radius: 440,
    LOI_name: 'rz_eval_loi_05'
  },
  {
    Lat: 1.1212775,
    Long: 104.118874,
    Radius: 2300,
    LOI_name: 'rz_eval_loi_06'
  },
  {
    Lat: -2.7487087,
    Long: 107.0711963,
    Radius: 35000,
    LOI_name: 'rz_eval_loi_07'
  },
  {
    Lat: 0.311551,
    Long: 104.9269311,
    Radius: 32000,
    LOI_name: 'rz_eval_loi_08'
  },
  {
    Lat: 0.904711944,
    Long: 104.406069,
    Radius: 3500,
    LOI_name: 'rz_eval_loi_09'
  },
  {
    Lat: 1.0612574,
    Long: 104.23894,
    Radius: 100,
    LOI_name: 'rz_eval_loi_10'
  },
  {
    Lat: 1.049428,
    Long: 104.25186,
    Radius: 100,
    LOI_name: 'rz_eval_loi_11'
  },
  {
    Lat: 0.91493714,
    Long: 104.47846,
    Radius: 100,
    LOI_name: 'rz_eval_loi_12'
  },
  {
    Lat: 1.0584636,
    Long: 104.476326,
    Radius: 100,
    LOI_name: 'rz_eval_loi_13'
  }
];

export default rz_lois;
