import React from 'react';

import { Marker, Pane, useMapEvents } from 'react-leaflet';
import { StyledPopup } from './MapUtils';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon } from 'leaflet';

import { StyledButton, PageSubTitle } from '../../../../components';
import GridChild from '../../../../components/GridChild';

import millify from 'millify';

const CustomMarkers = ({
  markers: customMarkers,
  addCustomMarker,
  removeCustomMarker
}: {
  markers: any[];
  addCustomMarker: (lat: number, lng: number) => void;
  removeCustomMarker: (event: any) => void;
}): JSX.Element => {
  // console.log(`CustomMarkers size is now ${customMarkers.length}`);

  useMapEvents({
    dblclick(event) {
      const { lat, lng } = event.latlng;
      addCustomMarker(lat, lng);
    }
  });

  if (customMarkers.length <= 0) return <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      {customMarkers.map((marker: any, index: number) => {
        // console.log(`index is ${index}`);
        return (
          <Marker
            key={index}
            position={marker}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 41]
              })
            }
          >
            <Pane
              name={`CustomMarker_Popup_${index}_${Math.random()}_Pane`}
              style={{ zIndex: 1000 }}
            >
              <StyledPopup key={index} maxWidth={600}>
                <GridChild
                  className="max-h-64 overflow-y-auto text-text-primary"
                  title="Dropped Pin"
                >
                  <PageSubTitle>
                    Coordinates: (
                    {`${millify(marker[0], { precision: 4 })}, ${millify(
                      marker[1],
                      { precision: 4 }
                    )}`}
                    ) <br />
                  </PageSubTitle>
                  <StyledButton value={index} onClick={removeCustomMarker}>
                    Remove
                  </StyledButton>
                </GridChild>
              </StyledPopup>
            </Pane>
          </Marker>
        );
      })}
    </React.Fragment>
  );
};

export default CustomMarkers;
