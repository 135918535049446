import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import apiAgent from '../../api/apiAgent';
import {
  ContainerFull,
  MainPanel,
  Modal,
  PageSubDescription,
  PageTitle,
  Pagination,
  ReactTable,
  SearchForm,
  StyledBtnRouterLink,
  StyledButton,
  WithLoadingDiv
} from '../../components';
import {
  ElementBgClassnames,
  ElementTextClassnames,
  ElementVariant,
  IPaginationState
} from '../../interfaces';
import { toastHandler } from '../../utils';

interface ClusterTag {
  clusterTagId: string;
  campaigns: string[];
  devices: string[];
  locations: string[];
  isEditing?: boolean;
}
type clusterTagKeys = keyof ClusterTag;

const ClusterTags = (): JSX.Element => {
  const [clusterTags, setClusterTags] = useState<ClusterTag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalClusterTagIndex, setModalClusterTagIndex] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');

  const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 1000,
    totalPages: 0
  });

  const formikSchema = {
    initialValues: {
      searchText: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setPaginationInfo({
        ...paginationInfo,
        currentPageCount: 1
      });
    },
    validateOnChange: true
  };

  const fetchClusterTags = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      setIsLoading(true);
      const result: any = await apiAgent.ClusterTags.getClusterTags({
        searchText: searchText !== '' ? searchText : undefined,
        page,
        limit
      });
      const fetchedClusterTags: any = result.data.data.clusterTags;
      let parsedClusterTags: ClusterTag[] = fetchedClusterTags.map(
        (clusterTag: any) => {
          return {
            clusterTagId: clusterTag.clusterTagId,
            // campaigns: clusterTag.campaigns.map(
            //   (campaign: any) => campaign.campaignId
            // ),
            devices: clusterTag.devices.map((device: any) => device.ifa)
            // locations: clusterTag.locations.map(
            //   (location: any) => location.locationId
            // )
          };
        }
      );
      const pageInfo = result.data.meta;
      setClusterTags(parsedClusterTags);
      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClusterTags({ page: 1, limit: 12 });
  }, [searchText]);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'clusterTagId', // accessor is the "key" in the data
        thClassName: ElementTextClassnames.sysGenId
      },
      // {
      //   Header: 'Campaign Count',
      //   accessor: 'campaignsLength' // accessor is the "key" in the data
      // },
      {
        Header: 'IFA Count',
        accessor: 'devicesLength' // accessor is the "key" in the data
      },
      // {
      //   Header: 'Location Count',
      //   accessor: 'locationsLength' // accessor is the "key" in the data
      // },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <div className="flex justify-center">
            <Fragment>
              <StyledButton
                onClick={() => {
                  setModalClusterTagIndex(row.id);
                  setShowModal(true);
                }}
              >
                Details
              </StyledButton>
            </Fragment>
          </div>
        )
      }
    ],
    [JSON.stringify(clusterTags)]
  );
  return (
    <ContainerFull>
      <MainPanel className="mb-6">
        <div className="flex justify-between mb-4">
          <div>
            <PageTitle>Cluster Tags</PageTitle>
            <PageSubDescription>
              Manage cluster tagging for IFAs
            </PageSubDescription>
          </div>
          <div className="flex ">
            <div className="flex justify-end items-start">
              <Formik {...formikSchema}>
                {(formikProps) => (
                  <SearchForm
                    {...formikProps}
                    placeholder="e.g. Cluster Tag ID, IFA"
                    currentSearch={searchText}
                  />
                )}
              </Formik>
            </div>
            <div className="text-right ml-2">
              <StyledBtnRouterLink
                to={'/cluster-tags/create'}
                variant={ElementVariant.info}
                className="mb-5"
              >
                Edit Cluster Tags
              </StyledBtnRouterLink>
            </div>
          </div>
        </div>
        <WithLoadingDiv isLoading={isLoading}>
          {clusterTags ? (
            <Fragment>
              <div className="-mx-5 mb-5 overflow-y-auto">
                <ReactTable
                  columns={columns}
                  data={[...clusterTags].map((clusterTag: ClusterTag) => {
                    return {
                      clusterTagId: clusterTag.clusterTagId,
                      // campaignsLength: clusterTag.campaigns.length,
                      devicesLength: clusterTag.devices.length
                      // locationsLength: clusterTag.locations.length
                    };
                  })}
                />
              </div>
            </Fragment>
          ) : (
            <div className="text-3xl text-center my-10">No results found</div>
          )}
        </WithLoadingDiv>
        <div>
          {clusterTags && (
            <React.Fragment>
              <Pagination
                totalPages={paginationInfo.totalPages}
                totalItems={paginationInfo.totalItems}
                currentPageCount={paginationInfo.currentPageCount}
                itemsPerPage={paginationInfo.itemsPerPage}
                fetchData={fetchClusterTags}
              />
            </React.Fragment>
          )}
        </div>
        <ClusterTagModal
          showModal={showModal}
          setShowExternal={setShowModal}
          clusterTag={clusterTags[modalClusterTagIndex]}
        />
      </MainPanel>
    </ContainerFull>
  );
};

const ClusterTagModal = ({
  showModal,
  setShowExternal,
  clusterTag
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
  clusterTag?: {
    clusterTagId: string;
    campaigns: string[];
    devices: string[];
    locations: string[];
  };
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      {clusterTag && (
        <Modal.Display>
          <Modal.Header>
            Cluster Tag ID: {clusterTag?.clusterTagId}
          </Modal.Header>
          <Modal.Body>
            {/* <div>Campaigns</div>
            <ul
              className={`rounded p-2 mb-3 overflow-y-scroll ${ElementBgClassnames.primaryAltTwo}`}
              style={{ height: '22vh' }}
            >
              {clusterTag.campaigns.map((campaignId: any, index: number) => (
                <li key={index}> {campaignId}</li>
              ))}
            </ul> */}
            <div>IFAs</div>
            <ul
              className={`rounded p-2 mb-3 overflow-y-scroll ${ElementBgClassnames.primaryAltTwo}`}
              style={{ height: '22vh' }}
            >
              {clusterTag.devices.map((ifa: any, index: number) => (
                <li key={index}>{ifa}</li>
              ))}
            </ul>
            {/* <div>Locations</div>
            <ul
              className={`rounded p-2 mb-3 overflow-y-scroll ${ElementBgClassnames.primaryAltTwo}`}
              style={{ height: '22vh' }}
            >
              {clusterTag.locations.map((locationId: any, index: number) => (
                <li key={index}>{locationId}</li>
              ))}
            </ul> */}
          </Modal.Body>
          <Modal.Toggle>
            <StyledButton>close</StyledButton>
          </Modal.Toggle>
        </Modal.Display>
      )}
    </Modal>
  );
};

export default ClusterTags;
