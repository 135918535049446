import { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import apiAgent from '../../api/apiAgent';
import { clearLoginCookie, jwtCookie } from '../..//auth';
import {
  ElementVariant,
  IFlashContainer,
  ElementBgClassnames
} from '../../interfaces';
import { toastHandler } from '../..//utils';
import { IOtpCodeParams } from '../..//interfaces/api';
import { Form, FlashMessage } from '../../components';
import {
  ContainerFull,
  MainPanel,
  StyledBtnRouterLink
} from '../../components';
import { FLASH_MESSAGES, HTTP_ERROR_CODES } from '../../cnstants';
import { fetchUserProfileAtom } from '../../atoms';
import { useAtom } from 'jotai';

const VerifyCodeForm: React.FC<FormikProps<any>> = (props): JSX.Element => {
  const {
    isSubmitting,
    // values,
    // touched,
    // errors,
    handleChange,
    handleBlur,
    handleSubmit
  } = props;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        submitting={isSubmitting}
        // errors={errorList}
        submitLabel="Verify"
        className="max-w-xs mx-auto"
      >
        <Form.Section>
          <Form.Element label="6-Digit Verification Code">
            <Form.Input
              type="text"
              placeholder="Enter your OTP code"
              name="otpCode"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              withFormikError={true}
            />
          </Form.Element>
        </Form.Section>
      </Form>
      <StyledBtnRouterLink
        to="/"
        variant={ElementVariant.primaryAltTwo}
        className="text-sm mt-6"
      >
        Back to Login Page
      </StyledBtnRouterLink>
    </>
  );
};

const VerifyOtp = (): JSX.Element => {
  const [, fetchUserProfile] = useAtom(fetchUserProfileAtom);

  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramUserId = query.get('uid');

  const redirectToDashboard = () => navigate('/dashboard');

  const verifyOtpCode = async (formData: IOtpCodeParams) => {
    try {
      const result: any = await apiAgent.Auth.verifyOtpCode(
        formData.otpCode,
        paramUserId || ''
      );

      if (result.data.status === 'success') {
        toastHandler({
          messages: result.data.message,
          toastOptions: { type: 'success' }
        });

        clearLoginCookie();
        jwtCookie.set(
          JSON.stringify({
            accessToken: result.data.data.accessToken,
            refreshToken: result.data.data.refreshToken
          })
        );

        await fetchUserProfile();

        redirectToDashboard();
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.UNPROCESSABLE_ENTITY) {
        setFlashContainer({
          messages: error?.response?.data?.message,
          variant: ElementVariant.danger
        });
      } else {
        toastHandler({
          messages: error.message,
          toastOptions: { type: 'error' }
        });
      }
    }
  };

  const formikSchema = {
    initialValues: {
      otpCode: ''
    },
    validationSchema: Yup.object({
      otpCode: Yup.number()
        .typeError('OTP code must be a number')
        .required('OTP code is required')
    }),
    onSubmit: async (values: any) => {
      await verifyOtpCode({
        otpCode: values.otpCode
      });
    },
    validateOnChange: true
  };

  return (
    <ContainerFull>
      <div className="flex justify-center mt-20">
        <MainPanel className="max-w-lg text-center" padding={false}>
          <h1 className="text-2xl block text-white m-5">Verify OTP</h1>
          <div className={`block w-full h-2 ${ElementBgClassnames.primary}`} />
          <div className="block text-gray-primary m-10">
            {(flashContainer.messages || []).length > 0 && (
              <FlashMessage
                messages={flashContainer?.messages || ''}
                variant={flashContainer.variant}
              />
            )}
            <Formik {...formikSchema}>
              {(formikProps) => <VerifyCodeForm {...formikProps} />}
            </Formik>
          </div>
          <div className={`block w-full h-2 ${ElementBgClassnames.primary}`} />
        </MainPanel>
      </div>
    </ContainerFull>
  );
};

export default VerifyOtp;
