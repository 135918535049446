import React, { useRef, useEffect } from 'react';
import _ from 'lodash';

import { ElementVariant, ElementBgClassnames } from '../interfaces';
import classNames from 'classnames/bind';

interface IFlashVariant {
  variant?: ElementVariant;
}

interface IFlashWrapper extends IFlashVariant {
  children: React.ReactNode;
}

// Relook again
const FlashWrapper = React.forwardRef<HTMLDivElement, IFlashWrapper>(
  ({ variant = ElementVariant.info, children }, ref) => (
    <div
      ref={ref}
      className={classNames(
        ElementBgClassnames[variant],
        'mt-3 px-3 py-2 rounded-md'
      )}
    >
      {children}
    </div>
  )
);

interface IFlashProps extends IFlashVariant {
  messages: string[] | string;
  focusOnDisplay?: boolean;
}

const FlashMessage = ({
  messages,
  variant = ElementVariant.info,
  focusOnDisplay
}: IFlashProps): JSX.Element => {
  const bannerRef = useRef<HTMLDivElement>(null);

  const scrollIntoView = () =>
    bannerRef.current?.scrollIntoView({ block: 'center' });

  useEffect(() => {
    focusOnDisplay && scrollIntoView();
  }, [JSON.stringify(messages)]);

  return (
    <FlashWrapper variant={variant} ref={bannerRef}>
      {messages.length > 0 && (
        <ul>
          {Array.isArray(messages)
            ? messages.map((msg) => (
                <li key={msg}>{_.upperFirst(_.toLower(_.startCase(msg)))}</li>
              ))
            : messages}
        </ul>
      )}
    </FlashWrapper>
  );
};

export default FlashMessage;
