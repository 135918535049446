import classNames from 'classnames';
import React from 'react';

import { FormInputType } from '../../interfaces';

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  isChecked?: boolean;
  onCheck?: (e: FormInputType) => void;
  name?: string;
}

const Checkbox = ({
  label,
  isChecked,
  onCheck,
  name,
  disabled,
  ...props
}: ICheckboxProps): JSX.Element => (
  <div className={classNames('text-text-primary block')}>
    <input
      type="checkbox"
      checked={isChecked}
      onChange={onCheck}
      name={name}
      disabled={disabled}
      className="mr-3"
      {...props}
    />
    {label}
  </div>
);

export default Checkbox;
