import { FormikProps } from 'formik';

import { Form, StyledButton } from '.';

interface ISearchFormProps extends FormikProps<any> {
  placeholder?: string;
  currentSearch: string;
}

const SearchForm = (props: ISearchFormProps): JSX.Element => {
  const {
    isSubmitting,
    placeholder,
    currentSearch,
    // values,
    // touched,
    // errors,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    resetForm
  } = props;

  const showClear = values.searchText.length > 0;
  const handleClear = () => {
    resetForm();
    if (currentSearch.length > 0) handleSubmit();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      submitting={isSubmitting}
      submitLabel="Login"
      noSubmitButton={true}
      className="w-96"
    >
      <div className="flex flex-nowrap w-full">
        <div className="flex-grow relative">
          <Form.Input
            type="text"
            placeholder={placeholder}
            name="searchText"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            withFormikError={true}
            className="rounded-r-none"
            value={values.searchText}
          />
          {showClear && (
            <div
              className="px-3 py-2 absolute right-0 bottom-0 hover:text-white cursor-pointer"
              onClick={() => handleClear()}
            >
              <i className="fa fa-times" />
            </div>
          )}
        </div>
        <StyledButton
          type="submit"
          className="rounded-l-none border-2 border-background-alt-2 items-center"
          loading={isSubmitting}
          hideChildren={true}
        >
          <i className="fa fa-search" />
        </StyledButton>
      </div>
    </Form>
  );
};

export default SearchForm;
