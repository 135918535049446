import React from 'react';
import { useAtom } from 'jotai';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

import { fetchUserProfileAtom } from '../..//atoms';

import { NavButtonText } from './Navbar';

interface NavButtonProps {
  children: string | JSX.Element | JSX.Element[];
  to: string;
  exact?: boolean;
  className?: string;
}

const NavButton = ({
  children,
  to,
  className
}: NavButtonProps): JSX.Element => (
  <NavLink
    className={({ isActive }) =>
      classNames(
        'text-base whitespace-nowrap hover:text-white px-2 lg:px-4',
        className,
        {
          'text-white': isActive
        }
      )
    }
    to={to}
  >
    {children}
  </NavLink>
);

const NavLi = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element => {
  return (
    <li
      className={`hidden md:block py-5 text-gray-400 w-full px-2 lg:px-4 order-3 md:py-0 md:w-min ${
        className ?? ''
      }`}
    >
      {children}
    </li>
  );
};

const NavBarAdminContent = (): JSX.Element => {
  return (
    <ul className="px-6 h-full flex flex-wrap md:px-5 md:h-12 md:flex-nowrap md:items-center">
      <NavLi>
        <NavButton to="/admin/site-settings">
          <NavButtonText iconClass="fa fa-cogs" label="Site Settings" />
        </NavButton>
      </NavLi>

      <NavLi>
        <NavButton to="/admin/users">
          <NavButtonText iconClass="fa fa-user-alt" label="Users" />
        </NavButton>
      </NavLi>
      <NavLi>
        <NavButton to="/admin/audit-trail">
          <NavButtonText iconClass="fa fa-user-alt" label="Audit Trail" />
        </NavButton>
      </NavLi>

      <NavLi>
        <NavButton to="/targeted-devices-dashboard">
          <NavButtonText
            iconClass="fa fa-chart-bar"
            label="Targeted Devices Dashboard (Beta)"
          />
        </NavButton>
      </NavLi>
    </ul>
  );
};

const NavbarAdmin = (): JSX.Element | null => {
  const [currentUser] = useAtom(fetchUserProfileAtom);

  let shouldShowMenu = false;

  if (currentUser.isAdmin) {
    shouldShowMenu = true;
  }

  if (
    currentUser.id &&
    currentUser.skipTwoFactor === false &&
    currentUser.otpMicrosoftFlag === false
  ) {
    shouldShowMenu = false;
  }

  if (!shouldShowMenu) {
    return null;
  }

  return (
    <header className="shadow-md bg-background-alt-2">
      <NavBarAdminContent />
    </header>
  );
};

export default NavbarAdmin;
