import { Editor, SyntheticEvent } from 'react-draft-wysiwyg';

import { IErrorInput } from '../../interfaces';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import { EditorState, RawDraftContentState } from 'draft-js';

const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'link',
    'remove',
    'colorPicker'
  ]
};

interface IRichTextEditor extends IErrorInput {
  editorState: EditorState;
  onEditorStateChange(editorState: EditorState): void;
  onContentStateChange?(contentState: RawDraftContentState): void;
  withFormikError?: boolean;
  placeholder?: string;
  onBlur?(event: SyntheticEvent): void;
}

const RichTextEditor = (props: IRichTextEditor): JSX.Element => {
  const {
    editorState,
    onEditorStateChange,
    onContentStateChange,
    placeholder,
    onBlur
  } = props;

  return (
    <Editor
      wrapperClassName="demo-wrapper"
      toolbarClassName="bg-background-editor"
      editorClassName="demo-editor bg-background-base px-2"
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      onContentStateChange={onContentStateChange}
      toolbar={toolbarOptions}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  );
};

export default RichTextEditor;
