const PageTitle = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element => (
  <h1 className={`${className} block text-white text-2xl mb-4`}>{children}</h1>
);

export default PageTitle;
