import React from 'react';
import { ElementTextClassnames } from '../../src/interfaces';

const PageSubDescription = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <h3 className={`block mb-4 ${ElementTextClassnames.primary}`}>{children}</h3>
);

export default PageSubDescription;
