import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { jwtCookie, clearJwtCookie, loginCookie } from '../../auth';
import { SmallLoader } from '../../components';
import apiAgent from '../../api/apiAgent';
import { FLASH_MESSAGES, HTTP_ERROR_CODES, APP_URL } from '../../cnstants';
import { toastHandler } from '../../utils';
import { fetchUserProfileAtom } from '../../atoms';

const CheckOtpSetup: React.FC = (): JSX.Element => {
  const [, fetchUserProfile] = useAtom(fetchUserProfileAtom);

  const [forceSignOut, setForceSignOut] = useState<boolean>(false);
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let _isMounted = true;
    const query = new URLSearchParams(search);
    const paramUserId = query.get('uid');
    const paramLoginToken = query.get('login-token');

    loginCookie.set(
      JSON.stringify({
        loginToken: paramLoginToken
      })
    );

    const verifyOtpSetup = async (): Promise<void> => {
      try {
        const result = await apiAgent.Auth.checkOtpSetup(paramUserId || '');

        // If user has not setup 2FA, allow the user to login,
        // And force redirect user to 2FA setup
        if (
          result.data.status === 'success' &&
          result.data.data.otpMicrosoftFlag !== true
        ) {
          jwtCookie.set(
            JSON.stringify({
              accessToken: result.data.data.accessToken,
              refreshToken: result.data.data.refreshToken
            })
          );

          await fetchUserProfile();

          navigate('/my/profile-dashboard');
        } else {
          // Redirect to 2 FA verification
          navigate(
            `/auth/verify-otp?uid=${encodeURIComponent(paramUserId || '')}`
          );
        }
      } catch (error: any) {
        clearJwtCookie();
        toastHandler({
          messages: FLASH_MESSAGES.MSG_SERVER_DOWN,
          toastOptions: {
            type: 'error',
            toastId: HTTP_ERROR_CODES.INTERNAL_SERVER_ERROR
          }
        });
        setForceSignOut(true);
      }
    };

    if (_isMounted && !forceSignOut) {
      verifyOtpSetup();
    }

    // React performs the cleanup when the component unmounts.
    return () => {
      _isMounted = false;
    };
  }, []);

  if (forceSignOut) {
    return <Navigate to={APP_URL.ADMIN_LOGIN_PAGE} />;
  }

  return (
    <div className="block text-center mt-20 text-white">
      <SmallLoader className="text-3xl" />

      <div className="mt-5 mb-10 text-xl">
        Please wait while we check your profile settings...
      </div>
    </div>
  );
};

export default CheckOtpSetup;
