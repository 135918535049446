import React from 'react';
import { TSearchFormParams } from '../../../../interfaces/api';
import { renderToStaticMarkup } from 'react-dom/server';

import { LatLngBoundsExpression, divIcon } from 'leaflet';

import {
  Marker,
  // Popup,
  LayersControl,
  FeatureGroup,
  Polygon,
  Pane
} from 'react-leaflet';
import { StyledPopup } from './MapUtils';

import { PageSubTitle } from '../../../../components';
import GridChild from '../../../../components/GridChild';

import millify from 'millify';

const LocationsOverlay = ({
  searchParams,
  locationsList = []
}: {
  searchParams?: TSearchFormParams;
  locationsList: any[];
}): JSX.Element => {
  // console.log(
  //   `locationsList at mapChart are ${JSON.stringify(locationsList, null, 2)}`
  // );
  // console.log(`Number of locationsList to overlay: ${locationsList.length}`);
  if (locationsList.length <= 0) return <React.Fragment></React.Fragment>;

  // console.log(`list of LOIs in searchParams is ${searchParams?.searchLOIs}`);
  const searchedLOIs = searchParams?.searchLOIs;

  const PI = Math.PI;

  const iconMarkup = renderToStaticMarkup(
    <i
      className="fa-solid fa-location-crosshairs fa-2xl"
      style={{ color: 'red' }}
    />
  );

  const showOverlay = searchedLOIs ? true : false;

  return (
    <LayersControl.Overlay checked={showOverlay} name={'LOIs'}>
      <FeatureGroup>
        {/* <Pane name={`LOI_Pane`} style={{ zIndex: 399 }}> */}
        {locationsList.map((location: any, index: number) => {
          const { locationId, latitude, longitude, radius } = location;

          if (latitude !== null && longitude !== null && radius !== null) {
            const polygonOptions = {
              color: 'green',
              weight: 0.5,
              fillOpacity: 0.05
            };
            const positions: LatLngBoundsExpression = [];
            const radiusInMm = radius * 0.000009;
            // console.log(`radius is ${radiusInMm}`);
            for (let i = 0; i < 2 * PI; i += PI / 100) {
              const x = parseFloat(latitude) + radiusInMm * Math.cos(i);
              const y = parseFloat(longitude) + radiusInMm * Math.sin(i);

              positions.push([x, y]);
            }
            // console.log(`positions are ${positions}`);

            if (searchedLOIs === undefined) {
              //Create markers for all existing locations if none are specified
              return (
                // <Polygon pathOptions={polygonOptions} positions={positions}>
                //   <Popup key={`Polygon_Popup_${index}`} maxWidth={600}>
                //     <GridChild
                //       className="max-h-64 overflow-y-auto text-text-primary"
                //       title={locationId}
                //     >
                //       <PageSubTitle>
                //         Coordinates: (
                //         {`${millify(latitude, { precision: 4 })}, ${millify(
                //           longitude,
                //           { precision: 4 }
                //         )}`}
                //         ) <br />
                //         Radius: {radius}
                //       </PageSubTitle>
                //     </GridChild>
                //   </Popup>
                // </Polygon>
                <Marker
                  key={`LOI_Marker_${index}`}
                  position={[latitude, longitude]}
                  icon={divIcon({
                    html: iconMarkup,
                    iconSize: [24, 24],
                    className: 'text-orange-500',
                    iconAnchor: [12, 10]
                  })}
                >
                  <StyledPopup key={`LOI_Marker_Popup_${index}`} maxWidth={600}>
                    <GridChild
                      className="max-h-64 overflow-y-auto text-text-primary"
                      title={locationId}
                    >
                      <PageSubTitle>
                        Coordinates: (
                        {`${millify(latitude, { precision: 4 })}, ${millify(
                          longitude,
                          { precision: 4 }
                        )}`}
                        ) <br />
                        Radius: {radius}m
                      </PageSubTitle>
                    </GridChild>
                  </StyledPopup>
                </Marker>
              );
            } else if (searchedLOIs?.includes(locationId)) {
              //Else only plot locations existing in search param
              return (
                <React.Fragment key={`LOI_Fragment_${index}`}>
                  <Polygon
                    key={`LOI_Polygon_${index}`}
                    pathOptions={polygonOptions}
                    positions={positions}
                  >
                    {/* <Popup maxWidth={600}>
                      <GridChild
                        className="max-h-64 overflow-y-auto text-text-primary"
                        title={locationId}
                      >
                        <PageSubTitle>
                          Coordinates: (
                          {`${millify(latitude, { precision: 4 })}, ${millify(
                            longitude,
                            { precision: 4 }
                          )}`}
                          ) <br />
                          Radius: {radius}
                        </PageSubTitle>
                      </GridChild>
                    </Popup> */}
                  </Polygon>
                  <Marker
                    key={`LOI_Marker_${index}`}
                    position={[latitude, longitude]}
                    icon={divIcon({
                      html: iconMarkup,
                      iconSize: [24, 24],
                      className: 'text-orange-500',
                      iconAnchor: [12, 10]
                    })}
                  >
                    <StyledPopup
                      key={`LOI_Marker_Popup_${index}`}
                      maxWidth={600}
                    >
                      <GridChild
                        className="max-h-64 overflow-y-auto text-text-primary"
                        title={locationId}
                      >
                        <PageSubTitle>
                          Coordinates: (
                          {`${millify(latitude, { precision: 4 })}, ${millify(
                            longitude,
                            { precision: 4 }
                          )}`}
                          ) <br />
                          Radius: {radius}m
                        </PageSubTitle>
                      </GridChild>
                    </StyledPopup>
                  </Marker>
                </React.Fragment>
              );
            }
          }
          return null;
        })}
        {/* </Pane> */}
      </FeatureGroup>
    </LayersControl.Overlay>
  );
};

export default LocationsOverlay;
