import React from 'react';

import { SmallLoader } from '../Loaders';
import { ElementTextClassnames } from '../../interfaces';

interface FormSectionProps {
  title?: string;
  description?: string;
  loading?: boolean;
  children: React.ReactNode;
}

const FormSection = ({
  title,
  description,
  children,
  loading
}: FormSectionProps): JSX.Element => (
  <section className="w-full pb-4 mb-4">
    <div className="block w-full mb-5">
      <span className="block text-lg font-semibold capitalize mb-2">
        {title}
      </span>
      <span className={ElementTextClassnames.primary}>{description || ''}</span>
    </div>
    {loading ? (
      <div className="m-auto">
        <SmallLoader />
      </div>
    ) : (
      <div className="block w-full">{children}</div>
    )}
  </section>
);

export default FormSection;
