import { useEffect, useState } from 'react';
import AlertTable from './components/AlertTable';
import * as Yup from 'yup';
import React from 'react';
import { Formik } from 'formik';
import { Form, StyledBtnRouterLink, StyledButton } from '../../../components';
import {
  ElementBgClassnamesWithHover,
  ElementTextClassnames,
  ElementVariant,
  IPaginationState
} from '../../../interfaces';
import classNames from 'classnames';
import apiAgent from '../../../api/apiAgent';
import { toastHandler } from '../../../utils';
import { useNavigate } from 'react-router-dom';

interface IAlerts {
  alertRuleName: string | null;
}

const Alerts = ({ alertRuleName }: IAlerts) => {
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState<any>(null);
  const [searchAcknowledged, setSearchAcknowledged] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState<any[] | null>(null);
  const [aliases, setAliases] = useState<any[] | null>(null);
  const [clusterTags, setClusterTags] = useState<any[] | null>(null);

  const [thisAlertRuleName, setThisAlertRuleName] = useState<string | null>(
    alertRuleName
  );

  const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const navigate = useNavigate();

  const fetchAlerts = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      let params: any = {};
      if (searchText) {
        params.searchText = searchText;
      }
      if (searchType) {
        params.alertType = searchType;
      }
      if (searchAcknowledged !== null) {
        params.acknowledged = searchAcknowledged === 'yes';
      }
      if (thisAlertRuleName) {
        params.alertRuleName = thisAlertRuleName;
      }
      const result = await apiAgent.Alerts.getAlerts({
        ...params,
        page,
        limit
      });
      const aliases = await apiAgent.Alias.getAllAliases();
      const clusterTags = await apiAgent.ClusterTags.getAllClusterTags();
      setAlerts(result.data.data.alerts);
      setAliases(aliases.data.data.aliases);
      setClusterTags(clusterTags.data.data.clusterTags);
      const pageInfo = result.data.meta;
      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAlerts({
      page: 1,
      limit: paginationInfo.itemsPerPage
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationInfo.itemsPerPage,
    searchText,
    searchAcknowledged,
    searchType,
    thisAlertRuleName
  ]);

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {
      searchText: '',
      searchType: null,
      searchAcknowledged: null
    },
    validationSchema: Yup.object({
      searchText: Yup.string().nullable(),
      searchType: Yup.string().nullable(),
      searchAcknowledged: Yup.string().nullable()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setSearchType(values.searchType);
      setSearchAcknowledged(values.searchAcknowledged);
    },
    validateOnChange: true
  };

  //update acknowledged field after acknowledged confirmed
  const updateAlertAcknowledged = (rowIndex: number) => {
    const dataCopy = [...alerts!];
    let updatedAlert = dataCopy[rowIndex];
    updatedAlert.acknowledged = true;
    dataCopy[rowIndex] = updatedAlert;
    setAlerts(dataCopy);
  };

  return (
    <React.Fragment>
      {thisAlertRuleName && (
        <div className="mb-2 text-text-primary flex items-center">
          <h1>Showing only alerts from Alert Rule: </h1>
          <h1
            className={`${ElementTextClassnames.warning} mx-1`}
          >{` ${alertRuleName}`}</h1>
          <StyledButton
            className="mx-2"
            onClick={() => {
              setThisAlertRuleName(null);
              navigate('/alerts');
            }}
          >
            Show All
          </StyledButton>
        </div>
      )}
      <Formik {...formikSchema}>
        {(formikProps) => (
          <Form
            onSubmit={formikProps.handleSubmit}
            submitting={formikProps.isSubmitting}
            submitLabel="Login"
            noSubmitButton={true}
            className="flex gap-x-4"
          >
            <div className="flex-grow">
              <Form.Input
                type="text"
                name="searchText"
                placeholder="e.g. Name, IFA, Location, etc"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                autoComplete="off"
                value={formikProps.values.searchText}
              />
            </div>
            <div className="flex items-center">
              <h1>Type</h1>
            </div>
            <div className="w-1/6">
              <Form.Select
                name="searchType"
                value={formikProps.values.searchType}
                options={[
                  { label: 'ENTER', value: 'ENTER' },
                  { label: 'EXIT', value: 'EXIT' }
                  // { label: 'count-loi', value: 'count-loi' }
                ]}
                onBlur={formikProps.setFieldTouched}
                onSelect={formikProps.setFieldValue}
                withFormikError={true}
                isClearable={true}
                placeholder={'Select...'}
              />
            </div>
            <div className="flex items-center">
              <h1>Acknowledged</h1>
            </div>
            <div className="w-1/6 text-white">
              <Form.Select
                name="searchAcknowledged"
                value={formikProps.values.searchAcknowledged}
                options={[
                  { label: 'yes', value: 'yes' },
                  { label: 'no', value: 'no' }
                ]}
                onBlur={formikProps.setFieldTouched}
                onSelect={formikProps.setFieldValue}
                withFormikError={true}
                isClearable={true}
                placeholder={'Select...'}
              />
            </div>
            <StyledButton
              type="submit"
              className={classNames(
                ElementBgClassnamesWithHover.success,
                'border-none'
              )}
              loading={formikProps.isSubmitting}
              hideChildren={true}
            >
              Search
            </StyledButton>
          </Form>
        )}
      </Formik>
      <AlertTable
        isLoading={isLoading}
        alerts={alerts}
        aliases={aliases}
        clusterTags={clusterTags}
        paginationInfo={paginationInfo}
        updateAlertAcknowledged={updateAlertAcknowledged}
        fetchAlerts={fetchAlerts}
      />
    </React.Fragment>
  );
};

// const Alerts = (): JSX.Element => {
//   const [searchText, setSearchText] = useState('');
//   const [searchType, setSearchType] = useState<any>(null);
//   const [searchAcknowledged, setSearchAcknowledged] = useState<any>(null);

// const formikSchema = {
//   enableReinitialize: true,
//   initialValues: {
//     searchText: '',
//     searchType: null,
//     searchAcknowledged: null
//   },
//   validationSchema: Yup.object({
//     searchText: Yup.string().nullable(),
//     searchType: Yup.string().nullable(),
//     searchAcknowledged: Yup.string().nullable()
//   }),
//   onSubmit: async (values: any) => {
//     setSearchText(values.searchText);
//     setSearchType(values.searchType);
//     setSearchAcknowledged(values.searchAcknowledged);
//   },
//   validateOnChange: true
// };

//   return (
//     <ContainerFull>
//       <PageTitle>Alert Dashboard</PageTitle>
// <PageSubDescription>
//   Main dashboard showing alerts generated and useful information.
// </PageSubDescription>
//       <div className="mb-6">
//         <div className="grid grid-cols-10 gap-4 mb-4">
//           <AlertStats />
//         </div>
//       </div>
//       <div className="rounded-md bg-background-alt py-4 px-5 mb-4">
//         <Formik {...formikSchema}>
//           {(formikProps) => (
//             <Form
//               onSubmit={formikProps.handleSubmit}
//               submitting={formikProps.isSubmitting}
//               submitLabel="Login"
//               noSubmitButton={true}
//               className="flex gap-x-4"
//             >
//               <div className="flex-grow">
//                 <Form.Input
//                   type="text"
//                   name="searchText"
//                   placeholder="e.g. Name, IFA, Location etc"
//                   onChange={formikProps.handleChange}
//                   onBlur={formikProps.handleBlur}
//                   autoComplete="off"
//                   value={formikProps.values.searchText}
//                 />
//               </div>
//               <div className="flex items-center">
//                 <h1>Type</h1>
//               </div>
//               <div className="w-1/6">
//                 <Form.Select
//                   name="searchType"
//                   value={formikProps.values.searchType}
//                   options={[
//                     { label: 'ENTER', value: 'ENTER' },
//                     { label: 'EXIT', value: 'EXIT' },
//                     // { label: 'count-loi', value: 'count-loi' }
//                   ]}
//                   onBlur={formikProps.setFieldTouched}
//                   onSelect={formikProps.setFieldValue}
//                   withFormikError={true}
//                   isClearable={true}
//                   placeholder={'Select...'}
//                 />
//               </div>
//               <div className="flex items-center">
//                 <h1>Acknowledged</h1>
//               </div>
//               <div className="w-1/6 text-white">
//                 <Form.Select
//                   name="searchAcknowledged"
//                   value={formikProps.values.searchAcknowledged}
//                   options={[
//                     { label: 'yes', value: 'yes' },
//                     { label: 'no', value: 'no' }
//                   ]}
//                   onBlur={formikProps.setFieldTouched}
//                   onSelect={formikProps.setFieldValue}
//                   withFormikError={true}
//                   isClearable={true}
//                   placeholder={'Select...'}
//                 />
//               </div>
//               <StyledButton
//                 type="submit"
//                 className={classNames(
//                   ElementBgClassnamesWithHover.success,
//                   'border-none'
//                 )}
//                 loading={formikProps.isSubmitting}
//                 hideChildren={true}
//               >
//                 Search
//               </StyledButton>
//             </Form>
//           )}
//         </Formik>
//       </div>
//       <div className="mb-4">
//         <AlertTable
//           searchText={searchText}
//           searchAcknowledged={searchAcknowledged}
//           searchType={searchType}
//         />
//       </div>
//     </ContainerFull>
//   );
// };

export default Alerts;
