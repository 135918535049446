import { useParams } from 'react-router-dom';
// import { IRouteParams } from "interfaces";

const useRouteId = (): [string] => {
  const params = useParams<{ id?: string }>();
  const id = params.id || '';

  return [id];
};

export default useRouteId;
