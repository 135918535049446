import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { dateFormatter, formValidation } from '../../../utils';
import apiAgent from '../../../api/apiAgent';
import { ElementVariant } from '../../../interfaces';
import { toastHandler } from '../../../utils';
import {
  Container,
  PageTitle,
  PageSubDescription,
  MainPanel,
  FlashMessage,
  SmallLoader
} from '../../../components';
import { IUserFormParams } from '../../../interfaces/api';
import { IFlashContainer } from '../../../interfaces';
import {
  HTTP_ERROR_CODES,
  FLASH_MESSAGES,
  AUTH_METHODS
} from '../../..//cnstants';
import { useRouteId } from '../../../hooks';
import UserForm from './components/UserForm';

const UserEdit = (): JSX.Element => {
  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });
  const [userId] = useRouteId();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>(null);

  const getUser = async (id: number) => {
    try {
      setIsLoading(true);
      const result: any = await apiAgent.User.getById(id);

      console.log(JSON.stringify(result.data.data.user));

      setUser(result.data.data.user);
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.UNPROCESSABLE_ENTITY) {
        setFlashContainer({
          messages: formValidation.getMsgArr(error?.response?.data?.data),
          variant: ElementVariant.danger
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUser(parseInt(userId));
  }, [userId]);

  const updateUser = async (formData: IUserFormParams): Promise<boolean> => {
    try {
      const result: any = await apiAgent.User.update(parseInt(userId), {
        ...formData,
        password: formData.password,
        confirmPassword: formData.confirmPassword
      });

      if (result.data.status === 'success') {
        toastHandler({
          messages: 'User updated successfully.',
          toastOptions: { type: 'success' }
        });
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }
      return true;
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.UNPROCESSABLE_ENTITY) {
        setFlashContainer({
          messages: formValidation.getMsgArr(error?.response?.data?.data),
          variant: ElementVariant.danger
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }
      return false;
    }
  };

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {
      email: user?.email,
      name: user?.name,
      authMethod: user?.authMethod,
      unlockUser: false,
      remove2FA: false,
      isAdmin: user?.isAdmin === 1 ? true : false,
      skipTwoFactor: user?.skipTwoFactor === 1 ? true : false,
      lockedAt: user?.lockedAt
    },
    validationSchema: Yup.object({
      email: Yup.string().email().min(5).required('Email is required'),
      name: Yup.string()
        .required('Full Name is a required field')
        .trim()
        .min(3, 'Full Name must be at least 3 characters'),
      password: Yup.string()
        .min(12)
        .matches(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])/,
          'At least 1 upper case character 1 lower case character, 1 digit and 1 special character'
        ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
      authMethod: Yup.string()
        .required('Authentication Method is required')
        .oneOf(
          [...AUTH_METHODS.values()],
          `Authentication Method must be ${[...AUTH_METHODS.keys()].join(
            ' or '
          )}`
        )
    }),
    onSubmit: async ({ name, ...values }: any) => {
      const flag = await updateUser({
        name: name.trim(),
        ...values
      });
      if (flag) {
        setFlashContainer({
          messages: '',
          variant: undefined
        });
      }
    },
    validateOnChange: true
  };

  return (
    <Container>
      <MainPanel className="mb-6">
        <PageTitle>Edit User</PageTitle>
        <PageSubDescription>
          Change and update this User's account details.
        </PageSubDescription>

        {(flashContainer.messages || []).length > 0 && (
          <FlashMessage
            messages={flashContainer?.messages || ''}
            variant={flashContainer.variant}
          />
        )}

        {isLoading && <SmallLoader className="block text-center my-10" />}

        {!isLoading && (
          <Formik {...formikSchema}>
            {(formikProps) => <UserForm {...formikProps} isEdit={true} />}
          </Formik>
        )}
      </MainPanel>
    </Container>
  );
};

export default UserEdit;
