import { toast } from 'react-toastify';

interface IToast {
  messages: string[] | string;
  toastId?: string;
  toastOptions: any;
}

const toastHandler = ({ messages, toastOptions, toastId }: IToast): void => {
  const options = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    closeButton: false,
    limit: 3,
    theme: 'colored',
    ...toastOptions
  };

  if (toastId) {
    options['toastId'] = toastId;
  }

  const msgArr = Array.isArray(messages) ? [...messages] : [messages];
  toast(
    <ul className="toast-list">
      {msgArr.map((obj, index) => (
        <li key={index}>{obj}</li>
      ))}
    </ul>,
    {
      ...options
    }
  );
};

export default toastHandler;
