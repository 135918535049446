import React, { Fragment, useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import {
  Container,
  PageTitle,
  PageSubTitle,
  MainPanel,
  Paragraph,
  StyledBtnRouterLink,
  FlashMessage
} from '../../../components';
import {
  ElementTextClassnames,
  ElementVariant,
  IFlashContainer
} from '../../../interfaces';
import OtpModal from '../otp-modal';
import { fetchUserProfileAtom } from '../../../atoms';
import { Formik } from 'formik';
import * as Yup from 'yup';
import UserPasswordForm from '../UserPasswordForm';
import { IUserPasswordFormParams } from '../../../interfaces/api';
import { formValidation } from '../../../utils';
import apiAgent from '../../../api/apiAgent';
import { FLASH_MESSAGES, HTTP_ERROR_CODES } from '../../../cnstants';
import { toastHandler } from '../../../utils';

const GridChild = ({
  labelText,
  content
}: {
  labelText: string;
  content: React.ReactNode;
}): JSX.Element => (
  <Fragment>
    <span className="text-gray-primary mr-2">{labelText}</span>
    <div className="col-span-4">{content}</div>
  </Fragment>
);

const ProfileDashboard = (): JSX.Element => {
  const [userProfile, fetchUserProfile] = useAtom(fetchUserProfileAtom);
  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });

  const updateUserPassword = async (
    formData: IUserPasswordFormParams
  ): Promise<boolean> => {
    try {
      const result: any = await apiAgent.User.updatePassword(
        parseInt(userProfile.id),
        {
          ...formData,
          currentPassword: formData.currentPassword,
          password: formData.password,
          confirmPassword: formData.confirmPassword
        }
      );

      if (result.data.status === 'success') {
        toastHandler({
          messages: 'Password updated successfully.',
          toastOptions: { type: 'success' }
        });
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }
      return true;
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.UNPROCESSABLE_ENTITY) {
        setFlashContainer({
          messages: formValidation.getMsgArr(error?.response?.data?.data),
          variant: ElementVariant.danger
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }
      return false;
    }
  };

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Please enter current password'),
      password: Yup.string()
        .min(12)
        .matches(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])/,
          'At least 1 upper case character 1 lower case character, 1 digit and 1 special character'
        )
        .required('New Password is a required field'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is a required field')
    }),
    onSubmit: async ({ ...values }: any) => {
      const flag = await updateUserPassword({
        ...values
      });
      if (flag) {
        setFlashContainer({
          messages: '',
          variant: undefined
        });
      }
    },
    validateOnChange: true
  };

  useEffect(() => {
    if (!userProfile.id) {
      (async () => await fetchUserProfile())();
    }
  }, [userProfile.id]);

  return (
    <Container>
      <PageTitle>Welcome back, {userProfile.name}</PageTitle>
      {/* <MainPanel className="mb-6">
        <PageSubTitle>Microsoft Authenticator Settings</PageSubTitle>
        {userProfile.otpMicrosoftFlag ? (
          <Paragraph className="mb-5">
            Your two-factor authentication has been setup and is working
            correctly.
          </Paragraph>
        ) : (
          <Paragraph className={`${ElementTextClassnames.danger} mb-5`}>
            Please set up your two-factor authentication (2FA) before using the
            Data Setting Portal.
          </Paragraph>
        )}
        <OtpModal />
      </MainPanel> */}
      <MainPanel className="mb-6">
        <PageSubTitle>User details</PageSubTitle>

        <div className="grid grid-cols-5 gap-y-4 items-center mb-10">
          <GridChild labelText="Email" content={userProfile.email} />
          <GridChild labelText="Full Name" content={userProfile.name} />
          {/* <GridChild labelText="Mobile Number" content="**** 8557" /> */}
          {/* {userProfile.otpMicrosoftFlag && (
            <GridChild
              labelText="Roles"
              // content="WholeGov-PMO, Minister-MOM, Agency-CPF, Group-CPF, Admin-Ensign"
              content={userProfile.roleNames}
            />
          )} */}
        </div>

        {false && userProfile.otpMicrosoftFlag && (
          <StyledBtnRouterLink to="test">
            Update Mobile Number
          </StyledBtnRouterLink>
        )}
      </MainPanel>
      <MainPanel>
        <PageSubTitle>Update Password</PageSubTitle>
        {(flashContainer.messages || []).length > 0 && (
          <FlashMessage
            messages={flashContainer?.messages || ''}
            variant={flashContainer.variant}
          />
        )}
        <Formik {...formikSchema}>
          {(formikProps) => <UserPasswordForm {...formikProps} />}
        </Formik>
      </MainPanel>
    </Container>
  );
};

export default ProfileDashboard;
