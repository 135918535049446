import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useResetAtom } from 'jotai/utils';

import { sleep, toastHandler } from '../../utils';
import { FLASH_MESSAGES, HTTP_ERROR_CODES, APP_URL } from '../../cnstants';
import { clearJwtCookie, clearLoginCookie } from '../../auth';
import { userProfileAtom } from '../../atoms';
import { CubeLoader } from '../../components';
import apiAgent from '../../api/apiAgent';

const SignOut = (): JSX.Element => {
  const resetUserProfileAtom = useResetAtom(userProfileAtom);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [logoutState, setLogoutState] = useState<boolean>(false);

  useEffect(() => {
    const logout = async (): Promise<void> => {
      await apiAgent.Auth.logout();

      // Clear session on client side
      clearJwtCookie();
      clearLoginCookie();
      resetUserProfileAtom();

      await sleep(2000);

      setLogoutState(true);
    };
    logout();
  }, []);

  if (query.get('session')) {
    toastHandler({
      messages: FLASH_MESSAGES.SESSION_EXPIRED,
      toastOptions: {
        type: 'error',
        toastId: HTTP_ERROR_CODES.UNAUTHORIZED
      }
    });
  } else {
    toastHandler({
      messages: FLASH_MESSAGES.SIGN_OUT,
      toastOptions: { type: 'info', toastId: 'sign_out' }
    });
  }

  if (logoutState) {
    return <Navigate to={`${APP_URL.ADMIN_LOGIN_PAGE}`} />;
  } else {
    return (
      <div className="block text-center mt-28">
        <CubeLoader className="mx-auto" />
        <div className="mt-20 mb-10 text-xl text-white">
          Please wait for the application to sign you out...
        </div>
      </div>
    );
  }
};

export default SignOut;
