import React from 'react';
import classNames from 'classnames/bind';

const FillAppHeightDiv = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
  padding?: boolean;
}): JSX.Element => (
  <div
    className={classNames(
      'flex flex-col flex-1 h-full w-full ',
      className ?? ''
    )}
  >
    {children}
  </div>
);

export default FillAppHeightDiv;
