import { Fragment } from 'react';
import classNames from 'classnames/bind';
import { ErrorMessage } from 'formik';
import _ from 'lodash';
import Select from 'react-select';
import { MultiValue, SingleValue } from 'react-select';

import { ElementTextClassnames } from '../../interfaces';

type OptionType = {
  label: string;
  value: string | number;
};

type SelectProps = {
  options: OptionType[];
  onBlur: (field: string, arg1: any) => void;
  onSelect: (field: string, value: any) => void;
  onChange?: (e: any) => void;
  // value?: ReactText;
  name: string;
  isMulti?: boolean;
  value?: [string | number] | string | number | null;
  // disabled?: boolean;
  // hasSelected?: boolean;
  // canCancel?: boolean;
  placeholder?: string;
  withFormikError?: boolean;
  className?: string;
  isClearable?: boolean;
};

const SelectSpecial = (props: SelectProps): JSX.Element => {
  const {
    options,
    onSelect,
    onChange,
    onBlur,
    name,
    isMulti = false,
    value,
    // value = "",
    // disabled = false,
    placeholder = 'Select an option',
    withFormikError = false,
    isClearable = false
  } = props;

  const handleChange = (
    values: MultiValue<OptionType> | SingleValue<OptionType>
    // actionMeta: ActionMeta<OptionType>
  ) => {
    let newValues = values as OptionType[];
    let singleValue: any = values;

    if (!isMulti) {
      onSelect(name, singleValue === null ? null : singleValue.value);
      return;
    }
    if (onChange) onChange(newValues);
    onSelect(
      name,
      newValues.map((obj: OptionType) => obj.value)
    );
  };

  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    onBlur(name, true);
  };

  const getSelectedValues = () =>
    (options || []).filter((obj) =>
      Array.isArray(value)
        ? (value || []).includes(obj.value)
        : value === obj.value
    );

  const customStyles = {
    input: (provided: any) => ({
      ...provided,
      color: '#FFFFFF'
    })
  };

  return (
    <Fragment>
      <Select
        value={getSelectedValues()}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        options={options}
        className={'select-special-container'}
        closeMenuOnSelect={!isMulti}
        classNamePrefix="tw-select"
        isMulti={isMulti}
        placeholder={placeholder}
        styles={customStyles}
        isClearable={isClearable}
      />
      {withFormikError && (
        <ErrorMessage
          name={name || ''}
          render={(msg) => (
            <div
              className={classNames(ElementTextClassnames.danger, 'block mt-1')}
            >
              {_.upperFirst(_.toLower(_.startCase(msg)))}
            </div>
          )}
        />
      )}
    </Fragment>
  );
};

export default SelectSpecial;
