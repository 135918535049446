const AUTH_METHODS = new Map([['NORMAL', 'normal']]);

enum CREATION_METHOD {
  MANUAL = 0
}

const CREATION_METHOD_LABEL = ['Manual'];
const CREATION_METHOD_FORM_LABEL = ['Manual'];

export {
  AUTH_METHODS,
  CREATION_METHOD,
  CREATION_METHOD_LABEL,
  CREATION_METHOD_FORM_LABEL
};
