import React, { Fragment } from 'react';

interface SmallLoaderProps {
  readonly secondary?: boolean;
  className?: string;
}

const SmallLoader = ({ className }: SmallLoaderProps) => (
  <i className={`fas fa-circle-notch fa-spin text-white ${className ?? ''}`} />
);

const CubeLoader = ({
  className
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div className={`cube ${className || ''}`}>
    <div className="sides">
      <div className="top"></div>
      <div className="right"></div>
      <div className="bottom"></div>
      <div className="left"></div>
      <div className="front"></div>
      <div className="back"></div>
    </div>
  </div>
);

interface WrapperLoader {
  isLoading?: boolean;
  children: React.ReactNode;
}

const FormLoader = ({ isLoading = false, children }: WrapperLoader) => {
  if (isLoading) {
    return (
      <div className="mt-14 mb-10 text-center">
        <SmallLoader />
      </div>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export { FormLoader, SmallLoader, CubeLoader };
