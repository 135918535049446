import React from 'react';
import classNames from 'classnames/bind';

import {
  ElementVariant,
  ElementBgClassnamesWithHover,
  ElementBgClassnames
} from '../interfaces';
import { SmallLoader } from './Loaders';
import { TooltipCard, ITooltipCardProps } from './Tooltip';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly variant?: ElementVariant;
  readonly className?: string;
}

const Button = ({
  className,
  children,
  ...restProps
}: IButtonProps): JSX.Element => (
  <button
    type="button"
    className={`inline-block rounded py-1.5 px-3 text-sm focus:outline-none ${
      className ?? ''
    }`}
    {...restProps}
  >
    {children}
  </button>
);

export interface IStyledButtonProps extends IButtonProps {
  loading?: boolean;
  hideChildren?: boolean;
}

export const StyledButton = (props: IStyledButtonProps): JSX.Element => {
  const {
    hideChildren = false,
    variant,
    loading,
    children,
    className,
    disabled,
    ...rest
  } = props;
  const elementClassNames = disabled
    ? ElementBgClassnames[variant || 'primaryAltTwo']
    : ElementBgClassnamesWithHover[variant || 'primaryAltTwo'];

  return (
    <Button
      className={classNames(
        elementClassNames,
        className,
        'disabled:opacity-50'
      )}
      {...rest}
      disabled={!!loading || disabled}
    >
      {loading ? (
        <div className="whitespace-nowrap">
          {!hideChildren && children}
          &nbsp;
          <SmallLoader />
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

const ButtonTooltip = ({
  children
}: {
  children: ITooltipCardProps;
}): JSX.Element => {
  const baseClass = 'pointer-events-none absolute min-w-max z-50 h-full';
  const position = 'right-full h-auto mr-2';
  const animationClass =
    'transition ease-in-out duration-300 opacity-0 group-hover:opacity-100 transform -translate-y-0.5';
  return (
    <div className={`${baseClass} ${position} ${animationClass}`}>
      <TooltipCard>{children}</TooltipCard>
    </div>
  );
};

interface IHintButtonProps extends IStyledButtonProps {
  itemLabel?: string;
}

export const ListDownloadButton = (props: IHintButtonProps): JSX.Element => {
  const { itemLabel, className, ...restProps } = props;
  return (
    <StyledButton
      variant={ElementVariant.success}
      className={`group relative ${className}`}
      {...restProps}
    >
      <ButtonTooltip>Download {itemLabel || 'Item'}</ButtonTooltip>
      <i className="fa fa-download" />
    </StyledButton>
  );
};

export const ListViewButton = (props: IHintButtonProps): JSX.Element => {
  const { itemLabel, className, ...restProps } = props;
  return (
    <StyledButton
      variant={ElementVariant.info}
      className={`group relative ${className}`}
      {...restProps}
    >
      <ButtonTooltip>View {itemLabel || ''} Details</ButtonTooltip>
      <i className="fa fa-eye" />
    </StyledButton>
  );
};

export const ListEditButton = (
  props: IHintButtonProps & {
    forMonthlyReport?: boolean;
  }
): JSX.Element => {
  const { itemLabel, forMonthlyReport, className, ...restProps } = props;
  return (
    <StyledButton
      variant={ElementVariant.info}
      className={`group relative rounded-r-none ${className}`}
      {...restProps}
    >
      <ButtonTooltip>
        {forMonthlyReport
          ? 'Add/Modify Comments for this Report Document'
          : `Edit ${itemLabel || 'Item'}`}
      </ButtonTooltip>
      {forMonthlyReport ? (
        <i className="fa fa-edit" />
      ) : (
        <i className="fa fa-pencil-alt" />
      )}
    </StyledButton>
  );
};

export const ListDeleteButton = (props: IHintButtonProps): JSX.Element => {
  const { itemLabel, className, ...restProps } = props;
  return (
    <StyledButton
      variant={ElementVariant.danger}
      className={`group relative rounded-l-none ${className}`}
      {...restProps}
    >
      <ButtonTooltip>Delete {itemLabel || 'Item'}</ButtonTooltip>
      <i className="fa fa-trash-alt"></i>
    </StyledButton>
  );
};

interface IToggleButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly isOn?: boolean;
}

export const ToggleButton = ({ isOn, ...restProps }: IToggleButton) => {
  if (isOn) {
    return (
      <button
        className={`flex rounded-xl w-8 h-4 ${ElementBgClassnames.success} justify-end items-center`}
        {...restProps}
      >
        <div
          className={`rounded-lg w-4 h-4 ${ElementBgClassnames.secondary}`}
        ></div>
      </button>
    );
  }
  return (
    <button
      className={`flex rounded-xl w-8 h-4 ${ElementBgClassnames.primaryAltTwo} justify-start items-center`}
      {...restProps}
    >
      <div
        className={`rounded-lg w-4 h-4 ${ElementBgClassnames.secondary}`}
      ></div>
    </button>
  );
};
