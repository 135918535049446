import moment from 'moment';

export interface Device {
  isExpanded?: boolean;
  ifa: string;
  alias?: string;
}
export type deviceKeys = keyof Device;

export interface Location {
  isExpanded?: boolean;
  locationId: string;
  longitude: string | number;
  latitude: string | number;
  radius: string | number;
}
export type locationKeys = keyof Location;

export interface Campaign {
  isExpanded?: boolean;
  campaignId: string;
  active: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  devices: Array<Device>;
  locations: Array<Location>;
}
export type campaignKeys = keyof Campaign;

//validates campaign and throws error
export const validateCampaigns = (testCampaigns: Array<Campaign>) => {
  const locationsMap: Map<string, Location> = new Map();
  const uniqueCampaigns: Map<string, boolean> = new Map();

  for (
    var campaignIndex = 0;
    campaignIndex < testCampaigns.length;
    campaignIndex++
  ) {
    const campaign = testCampaigns[campaignIndex];

    if (!uniqueCampaigns.has(campaign.campaignId)) {
      uniqueCampaigns.set(campaign.campaignId, true);
    } else {
      throw new Error(`Duplicate campaign id detected: ${campaign.campaignId}`);
    }

    //check for valid campaign id
    if (!/^[a-zA-Z0-9-_]+$/.test(campaign.campaignId)) {
      throw new Error(`invalid campaign id: ${campaign.campaignId}`);
    }

    //check dates

    if (
      campaign.active &&
      (!campaign.endDate ||
        !campaign.startDate ||
        campaign.endDate! < campaign.startDate!)
    ) {
      if (
        !moment(campaign.startDate).isValid() ||
        !moment(campaign.endDate).isValid()
      ) {
        throw new Error('Invalid date');
      }
      throw new Error('missing dates');
    }

    if (campaign.active && campaign.endDate! < campaign.startDate!) {
      throw new Error('start date is after end date');
    }
    //check for devices
    for (
      var deviceIndex = 0;
      deviceIndex < campaign.devices.length;
      deviceIndex++
    ) {
      const device = campaign.devices[deviceIndex];
      //validate device fields
      if (!/^[a-zA-Z0-9-_]+$/.test(device.ifa)) {
        throw new Error(`invalid ifa: ${device.ifa}`);
      }
    }
    //check for locations with same location id but different values
    for (
      var locationIndex = 0;
      locationIndex < campaign.locations.length;
      locationIndex++
    ) {
      const location = campaign.locations[locationIndex];
      // validate location fields
      if (!/^[a-zA-Z0-9-_]+$/.test(location.locationId)) {
        console.log(`'${location.locationId}'`);
        throw new Error(`invalid location id: ${location.locationId}`);
      }

      let latitude = Number(location.latitude);
      let longitude = Number(location.longitude);
      let radius = Number(location.longitude);

      if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        throw new Error(`invalid latitude: ${latitude}`);
      }

      if (isNaN(longitude) || longitude < -180 || longitude > 180) {
        throw new Error(`invalid longitude: ${longitude}`);
      }

      if (isNaN(radius)) {
        throw new Error(`invalid radius: ${radius}`);
      }

      //check for locations with same location id but different values
      if (!locationsMap.has(location.locationId)) {
        locationsMap.set(location.locationId, location);
      } else {
        const oldLocation = locationsMap.get(location.locationId);
        if (
          oldLocation!.latitude.toString() !== location.latitude.toString() ||
          oldLocation!.longitude.toString() !== location.longitude.toString() ||
          oldLocation!.radius.toString() !== location.radius.toString()
        ) {
          console.log(oldLocation);
          console.log(location);
          throw new Error(
            `Location (${location.locationId}) has multiple different values`
          );
        }
      }
    }
  }
};
