import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import apiAgent from '../../../api/apiAgent';

import {
  SearchForm,
  WithLoadingDiv,
  ReactTable,
  Pagination
} from '../../../components';
import { ElementTextClassnames, IPaginationState } from '../../../interfaces';
import { dateFormatter, toastHandler } from '../../../utils';
import GridChildTable from './GridChildTable';

const transformIFAsDataForTable = (
  ifaslist: any[]
): { id: number; ifa: string; createdAt: string }[] => {
  const data = ifaslist.map((obj: any) => {
    return {
      id: obj.id,
      ifa: obj.ifa,
      createdAt: dateFormatter({
        timestamp: obj.created_at,
        withTime: true
      })
    };
  });

  return data || [];
};

const InactiveTargetedIfasListTable = (): JSX.Element => {
  const [searchText, setSearchText] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [inactiveIFAsList, setInactiveIFAsList] = useState<any[] | null>(null);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const fetchInactiveIfasList = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      const { data } = await apiAgent.Stats.getInactiveTargetedIfasList({
        searchText,
        page,
        limit
      });

      const pageInfo = data.meta;
      setInactiveIFAsList(transformIFAsDataForTable(data.data.ifaslist));

      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastId: error?.code,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInactiveIfasList({
      page: 1,
      limit: paginationInfo.itemsPerPage
    });
  }, [paginationInfo.itemsPerPage, searchText]);

  const formikSchemaInactive = {
    initialValues: {
      searchText: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setPaginationInfo({
        ...paginationInfo,
        currentPageCount: 1
      });
    },
    validateOnChange: true
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ifa',
        accessor: 'ifa',
        thClassName: ElementTextClassnames.sysGenId
      }
      // {
      //   Header: 'Created At',
      //   accessor: 'createdAt',
      //   thClassName: ElementTextClassnames.sysGenDate
      // }
    ],
    []
  );

  return (
    <GridChildTable className="col-span-6" title="Inactive Targeted IFAs List">
      <div className="flex justify-end items-start mb-5">
        <Formik {...formikSchemaInactive}>
          {(formikProps) => (
            <SearchForm
              {...formikProps}
              placeholder="e.g. ifa"
              currentSearch={searchText}
            />
          )}
        </Formik>
      </div>
      <WithLoadingDiv isLoading={isLoading}>
        <div className="-mx-5 mb-5 overflow-y-auto">
          <ReactTable columns={columns} data={inactiveIFAsList || []} />
        </div>
      </WithLoadingDiv>
      <div>
        {inactiveIFAsList && (
          <Pagination
            totalPages={paginationInfo.totalPages}
            totalItems={paginationInfo.totalItems}
            currentPageCount={paginationInfo.currentPageCount}
            itemsPerPage={paginationInfo.itemsPerPage}
            fetchData={fetchInactiveIfasList}
          />
        )}
      </div>
    </GridChildTable>
  );
};

export default InactiveTargetedIfasListTable;
