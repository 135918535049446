import { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useAtom } from 'jotai';

import { formValidation } from '../../../utils';
import { siteSettingAtom, siteSettingLoadingStateAtom } from '../../../atoms';
import apiAgent from '../../../api/apiAgent';
import { ElementVariant } from '../../..//interfaces';
import { loadingStateHandler, toastHandler } from '../../../utils';
import {
  Container,
  PageTitle,
  PageSubDescription,
  MainPanel,
  FlashMessage,
  Form,
  SmallLoader
} from '../../../components';
import { ISiteSettingParams } from '../../../interfaces/api';
import { IFlashContainer } from '../../../interfaces';
import { HTTP_ERROR_CODES, FLASH_MESSAGES } from '../../../cnstants';

import { fetchUserProfileAtom } from '../../../atoms';
import Error403 from '../../../components/error-pages/403';

interface IFormProps extends FormikProps<any> {
  noSubmitButton?: boolean;
}

const SiteSettingForm = (props: IFormProps): JSX.Element => {
  const {
    isSubmitting,
    values,
    // touched,
    // errors,
    handleChange,
    handleBlur,
    handleSubmit,
    noSubmitButton = false
  } = props;

  return (
    <Form
      onSubmit={handleSubmit}
      submitting={isSubmitting}
      submitLabel="Submit"
      noSubmitButton={noSubmitButton}
    >
      <Form.Section>
        <Form.Element label="Site Name" required>
          <Form.Input
            type="text"
            placeholder="Site name"
            name="siteName"
            onChange={handleChange}
            onBlur={handleBlur}
            withFormikError={true}
            value={values.siteName}
          />
        </Form.Element>
        <Form.Element label="Login Message" required>
          <Form.Input
            type="text"
            placeholder="Login message"
            name="loginMessage"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            withFormikError={true}
            value={values.loginMessage}
          />
        </Form.Element>
      </Form.Section>
    </Form>
  );
};

const SiteSettings = (): JSX.Element => {

  const [currentUser] = useAtom(fetchUserProfileAtom);

  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });
  const [siteSettingLoadingState] = useAtom(siteSettingLoadingStateAtom);
  const loadingState = loadingStateHandler(siteSettingLoadingState);
  const [siteSettingState, setSiteSettingState] = useAtom(siteSettingAtom);

  const updateSiteSetting = async (formData: ISiteSettingParams) => {
    try {
      const result: any = await apiAgent.SiteSetting.update({
        ...formData
      });

      if (result.data.status === 'success') {
        setSiteSettingState(result.data.data);

        toastHandler({
          messages: 'Site setting updated successfully.',
          toastOptions: { type: 'success' }
        });
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.UNPROCESSABLE_ENTITY) {
        setFlashContainer({
          messages: formValidation.getMsgArr(error?.response?.data?.data),
          variant: ElementVariant.danger
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }
    }
  };

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {
      ...siteSettingState
    },
    validationSchema: Yup.object({
      siteName: Yup.string().required('Site name is a required field'),
      loginMessage: Yup.string().required('Login message is a required field')
    }),
    onSubmit: async (values: any) => {
      await updateSiteSetting(
        JSON.parse(JSON.stringify(values).replace(/"\s+|\s+"/g, '"')) //trim() for all values in object
      );
    },
    validateOnChange: true
  };

  if (!currentUser.isAdmin) {
    return <Error403 />;
  }

  return (
    <Container>
      <MainPanel className="mb-6">
        <PageTitle>Site Settings</PageTitle>
        <PageSubDescription>
          Modify the Site Name or Message on the login page.
        </PageSubDescription>

        {(flashContainer.messages || []).length > 0 && (
          <FlashMessage
            messages={flashContainer?.messages || ''}
            variant={flashContainer.variant}
          />
        )}

        {loadingState.isLoading && (
          <SmallLoader className="block text-center my-10" />
        )}

        {loadingState.isSuccess && (
          <Formik {...formikSchema}>
            {(formikProps) => <SiteSettingForm {...formikProps} />}
          </Formik>
        )}
      </MainPanel>
    </Container>
  );
};

export default SiteSettings;
