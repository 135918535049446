import {
  Fragment,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  PageSubDescription,
  MainPanel,
  ContainerFull,
  PageTitle,
  ReactTable,
  StyledBtnRouterLink,
  SearchForm,
  WithLoadingDiv,
  confirmAlert,
  BadgeBoolean,
  ListEditButton,
  ListDeleteButton,
  Badge
} from '../../../components';
import apiAgent from '../../../api/apiAgent';
import { dateFormatter, toastHandler } from '../../../utils';
import { ElementTextClassnames, ElementVariant } from '../../../interfaces';
import { Pagination } from '../../../components';
import { FLASH_MESSAGES } from '../../../cnstants';

import { useAtom } from 'jotai';
import { fetchUserProfileAtom } from '../../../atoms';
import Error403 from '../../../components/error-pages/403';

const DeleteButtonWithState = (props: {
  userId: number;
  afterFunc: Function;
}): JSX.Element => {
  const { userId, afterFunc } = props;
  const [isLoading, setIsLoading] = useState(false);

  const deleteUser =
    (id: number): Function =>
    async () => {
      try {
        setIsLoading(true);
        const result = await apiAgent.User.delete(id);

        if (result.data.status === 'success') {
          toastHandler({
            messages: `User ${result?.data?.data?.email} deleted successfully.`,
            toastOptions: { type: 'info' }
          });

          afterFunc();
        } else {
          throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
        }
      } catch (error: any) {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      } finally {
        setIsLoading(false);
      }
    };

  const handleOnDelete =
    (id: number): MouseEventHandler<HTMLButtonElement> =>
    (event: any) => {
      event.preventDefault();

      confirmAlert({
        title: 'Confirm remove user',
        message: confirmationMessage(id),
        buttons: [
          {
            onClick: deleteUser(id)
          }
        ]
      });
    };

  return (
    <ListDeleteButton
      itemLabel="User"
      loading={isLoading}
      onClick={handleOnDelete(userId)}
    />
  );
};

const confirmationMessage = (userId: number): JSX.Element => {
  return (
    <div className="mb-1" key={userId}>
      Are you sure you want to delete this User? This process cannot be undone.
    </div>
  );
};

const EditButton = (props: { userId: number }): JSX.Element => {
  const navigate = useNavigate();
  const { userId } = props;

  const handleOnEdit = (id: number) => (event: any) => {
    event.preventDefault();
    navigate(`/admin/users/${id}/edit`);
  };

  return <ListEditButton itemLabel="User" onClick={handleOnEdit(userId)} />;
};

const transformDataForTable = (users: any[]): any[] => {
  const data = users.map((obj: any) => {
    return {
      id: obj.id,
      name: obj.name,
      email: obj.email,
      authMethod: obj.authMethod,
      skipTwoFactor: obj.skipTwoFactor,
      isAdmin: obj.isAdmin,
      otpMicrosoftFlag: obj.otpMicrosoftFlag,
      lastSignInAt: dateFormatter({
        timestamp: obj.lastSignInAt,
        withTime: true
      }),
      createdAt: dateFormatter({
        timestamp: obj.createdAt,
        withTime: true
      }),
      updatedAt: dateFormatter({
        timestamp: obj.updatedAt,
        withTime: true
      }),
      roles: obj.roles,
      failedAttempts: obj.failedAttempts,
      lockedAt: dateFormatter({
        timestamp: obj.lockedAt,
        withTime: true
      })
    };
  });

  return data || [];
};

const UserIndex = (): JSX.Element => {
  const [currentUser] = useAtom(fetchUserProfileAtom);

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<any[] | null>(null);

  const [searchText, setSearchText] = useState('');

  const [paginationInfo, setPaginationInfo] = useState<any>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const { totalPages, totalItems, currentPageCount, itemsPerPage } =
    paginationInfo;

  const fetchUsers = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await apiAgent.User.getAll({
        searchText,
        page,
        limit
      });

      const pageInfo = result.data.meta;
      setUsers(transformDataForTable(result.data.data.users));

      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser.isAdmin) {
      fetchUsers({
        page: 1,
        limit: paginationInfo.itemsPerPage
      });
    }
  }, [paginationInfo.itemsPerPage, searchText]);

  const formikSchema = {
    initialValues: {
      searchText: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setPaginationInfo({
        ...paginationInfo,
        currentPageCount: 1
      });
    },
    validateOnChange: true
  };

  const deleteTableRow = (rowIndex: number) => () => {
    const dataCopy = [...users!];
    dataCopy.splice(rowIndex, 1);
    setUsers(dataCopy);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id', // accessor is the "key" in the data
        thClassName: ElementTextClassnames.sysGenId
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Auth Method',
        accessor: 'authMethod',
        tdClassName: `font-bold ${ElementTextClassnames.info} uppercase`
      },
      {
        Header: 'Admin?',
        accessor: 'isAdmin',
        Cell: ({ row }: any) => {
          return <BadgeBoolean value={row.values.isAdmin} />;
        }
      },
      {
        Header: 'Skip 2FA',
        accessor: 'skipTwoFactor',
        Cell: ({ row }: any) => {
          return <BadgeBoolean value={row.values.skipTwoFactor} />;
        }
      },
      {
        Header: '2FA Setup?',
        accessor: 'otpMicrosoftFlag',
        Cell: ({ row }: any) => {
          return <BadgeBoolean value={row.values.otpMicrosoftFlag} />;
        }
      },
      {
        Header: 'Last Login',
        accessor: 'lastSignInAt',
        thClassName: ElementTextClassnames.sysGenDate,
        Cell: ({ row }: any) => {
          return (
            <Fragment>
              <div>{row.values.lastSignInAt}</div>
              {row.original.lockedAt && (
                <Badge className="mt-3" variant={ElementVariant.danger}>
                  Account locked at
                  <br />
                  {row.original.lockedAt}
                </Badge>
              )}
            </Fragment>
          );
        }
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        thClassName: ElementTextClassnames.sysGenDate
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        thClassName: ElementTextClassnames.sysGenDate
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <div className="flex justify-center">
            <Fragment>
              <EditButton userId={row.values.id} />
              <DeleteButtonWithState
                userId={row.values.id}
                afterFunc={deleteTableRow(row.index)}
              />
            </Fragment>
          </div>
        )
      }
    ],
    [JSON.stringify(users)]
  );

  if (!currentUser.isAdmin) {
    return <Error403 />;
  }

  return (
    <ContainerFull>
      <MainPanel className="mb-6">
        <div className="flex justify-between mb-4">
          <div>
            <PageTitle>Users</PageTitle>
            <PageSubDescription>
              Manage user creation and module access rights.
            </PageSubDescription>
          </div>
          <div className="flex flex-col justify-end">
            <div className="text-right">
              <StyledBtnRouterLink
                to={'/admin/users/new'}
                variant={ElementVariant.primaryAltTwo}
                className="text-sm mb-5"
              >
                Add New User
              </StyledBtnRouterLink>
            </div>
            <div className="flex justify-end items-start">
              <Formik {...formikSchema}>
                {(formikProps) => (
                  <SearchForm
                    {...formikProps}
                    placeholder="e.g. name, email etc"
                    currentSearch={searchText}
                  />
                )}
              </Formik>
            </div>
          </div>
        </div>
        <WithLoadingDiv isLoading={isLoading}>
          {users ? (
            <Fragment>
              <div className="-mx-5 mb-5 overflow-y-auto">
                <ReactTable columns={columns} data={users} />
              </div>
            </Fragment>
          ) : (
            <div className="text-3xl text-center my-10">No results found</div>
          )}
        </WithLoadingDiv>
        {users && (
          <Pagination
            totalPages={totalPages}
            totalItems={totalItems}
            currentPageCount={currentPageCount}
            itemsPerPage={itemsPerPage}
            fetchData={fetchUsers}
          />
        )}
      </MainPanel>
    </ContainerFull>
  );
};

export default UserIndex;
