import { Fragment, useEffect, memo } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import apiAgent from '../../../api/apiAgent';
import {
  PageSubDescription,
  SmallLoader,
  StyledButton
} from '../../../components';
import { ElementTextClassnames } from '../../../interfaces';
import GridChild from '../../../components/GridChild';
import { TSearchFormParams } from '../../../interfaces/api';
import { useAtom } from 'jotai';
import { statButtonClickedAtom } from '../../../atoms/dashboardStore';
import { STAT_BUTTON } from '../../../cnstants';

const StatWithLoading = ({
  stat,
  isLoading,
  isError,
  statClassName
}: {
  stat?: string;
  isLoading: boolean;
  isError: boolean;
  statClassName?: string;
}): JSX.Element => {
  if (isError)
    return (
      <div className={classNames(ElementTextClassnames.danger, 'text-center')}>
        Error
      </div>
    );

  return isLoading ? (
    <SmallLoader className="text-sm mx-auto table" />
  ) : (
    <div className={statClassName}>{stat}</div>
  );
};

const Stats = ({
  searchParams
}: {
  searchParams?: TSearchFormParams;
}): JSX.Element => {
  const [statButtonClicked, setStatButtonClicked] = useAtom(
    statButtonClickedAtom
  );
  //use the default searchParams value
  searchParams = {};

  const useQueryOptions = {
    refetchOnWindowFocus: false,
    staleTime: 3 * 60 * 1000, //3 minutes
    cacheTime: 3 * 60 * 1000,
    retry: 2
  };

  //Active Unique IFAs detected today
  const {
    isLoading: isLoadingActiveIFAsCurrentDay,
    isError: isErrorActiveIFAsCurrentDay,
    data: activeIFAsCurrentDay
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    ['activeIFAsCurrentDay'],
    async () => {
      const { data } = await apiAgent.Stats.getActiveIFAsCurrentDayCount();
      return data.data;
    },
    useQueryOptions
  );

  //Total LOIs existing today
  const {
    isLoading: isLoadingActiveLOIsCurrentDay,
    isError: isErrorActiveLOIsCurrentDay,
    data: totalLOIsCurrentDay
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    ['totalLOIsCurrentDay'],
    async () => {
      const { data } = await apiAgent.Stats.getActiveLOIsCurrentDayCount();
      return data.data;
    },
    useQueryOptions
  );

  //Total DOIs detected today
  const {
    isLoading: isLoadingActiveDOIsCurrentDay,
    isError: isErrorActiveDOIsCurrentDay,
    data: totalDOIsCurrentDay
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    ['totalDOIsCurrentDay'],
    async () => {
      const { data } = await apiAgent.Stats.getActiveDOIsCurrentDayCount();
      // console.log(data);
      return data.data;
    },
    useQueryOptions
  );

  //ACTIVE IFAs PAST HOUR
  const {
    isLoading: isLoadingActiveIFAsPastHour,
    isError: isErrorActiveIFAsPastHour,
    data: activeIFAsPastHour
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    [
      'activeIFAsPastHour' //, searchParams
    ],
    async () => {
      const { data } = await apiAgent.Stats.getActiveIFAsPastHourCount(
        searchParams
      );
      return data.data;
    },
    useQueryOptions
  );

  //ACTIVE DOIs PAST HOUR
  const {
    isLoading: isLoadingActiveDOIsPastHour,
    isError: isErrorActiveDOIsPastHour,
    data: activeDOIsPastHour
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    [
      'activeDOIsPastHour' //, searchParams
    ],
    async () => {
      const { data } = await apiAgent.Stats.getActiveDOIsPastHourCount(
        searchParams
      );
      return data.data;
    },
    useQueryOptions
  );

  //ACTIVE LOIs PAST HOUR
  const {
    isLoading: isLoadingActiveLOIsPastHour,
    isError: isErrorActiveLOIsPastHour,
    data: activeLOIsPastHour
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    [
      'activeLOIsPastHour' //, searchParams
    ],
    async () => {
      const { data } = await apiAgent.Stats.getActiveLOIsPastHourCount(
        searchParams
      );
      // console.log(`activeLOIsPastHour is ${data.data}`);
      return data.data;
    },
    useQueryOptions
  );

  return (
    <Fragment>
      <GridChild className="col-span-2 text-center" title="Active IFAs (Day)">
        <PageSubDescription>
          {`(${moment().format('DD-MM-YY')} 0000H to ${moment().format('HHmm')}
          H)`}
        </PageSubDescription>
        <StatWithLoading
          stat={activeIFAsCurrentDay}
          isLoading={isLoadingActiveIFAsCurrentDay}
          isError={isErrorActiveIFAsCurrentDay}
          statClassName="text-center text-3xl"
        />
      </GridChild>
      <GridChild className="col-span-2 text-center" title="Active DOIs (Day)">
        <PageSubDescription>
          {`(${moment().format('DD-MM-YY')} 0000H to ${moment().format('HHmm')}
          H)`}
        </PageSubDescription>
        <StatWithLoading
          stat={totalDOIsCurrentDay}
          isLoading={isLoadingActiveDOIsCurrentDay}
          isError={isErrorActiveDOIsCurrentDay}
          statClassName="text-center text-3xl"
        />
      </GridChild>
      <GridChild className="col-span-2 text-center" title="Active LOIs (Day)">
        <PageSubDescription>
          {`(${moment().format('DD-MM-YY')} 0000H to ${moment().format('HHmm')}
          H)`}
        </PageSubDescription>
        <StatWithLoading
          stat={totalLOIsCurrentDay}
          isLoading={isLoadingActiveLOIsCurrentDay}
          isError={isErrorActiveLOIsCurrentDay}
          statClassName="text-center text-3xl"
        />
      </GridChild>

      <GridChild className="col-span-2 text-center" title="Active IFAs (Hour)">
        <PageSubDescription>
          {searchParams?.startDate && searchParams?.endDate
            ? `Filtered: (${moment(searchParams?.startDate).format(
                'DD-MM-YY HH:mm'
              )}H to ${moment(searchParams?.endDate).format(
                'DD-MM-YY HH:mm'
              )}H)`
            : `(${moment()
                .startOf('hour')
                .format('DD-MM-YY HH')}00H to ${moment().format('HHmm')}
          H)`}
        </PageSubDescription>
        <StatWithLoading
          stat={activeIFAsPastHour}
          isLoading={isLoadingActiveIFAsPastHour}
          isError={isErrorActiveIFAsPastHour}
          statClassName="text-center text-3xl"
        />
      </GridChild>
      <GridChild
        className="col-span-2 text-center relative"
        title="Active DOIs (Hour)"
      >
        <PageSubDescription>
          {searchParams?.startDate && searchParams?.endDate
            ? `Filtered: (${moment(searchParams?.startDate).format(
                'DD-MM-YY HH:mm'
              )}H to ${moment(searchParams?.endDate).format(
                'DD-MM-YY HH:mm'
              )}H)`
            : `(${moment()
                .startOf('hour')
                .format('DD-MM-YY HH')}00H to ${moment().format('HHmm')}
          H)`}
        </PageSubDescription>
        <StatWithLoading
          stat={activeDOIsPastHour}
          isLoading={isLoadingActiveDOIsPastHour}
          isError={isErrorActiveDOIsPastHour}
          statClassName="text-center text-3xl"
        />
        {/* <div className="absolute bottom-0 right-0 w-fit m-2">
          <StyledButton onClick={() => setStatButtonClicked(STAT_BUTTON.DOI)}>
            <i className="fa fa-angle-double-right" />
          </StyledButton>
        </div> */}
      </GridChild>
      <GridChild
        className="col-span-2 text-center relative"
        title="Active LOIs (Hour)"
      >
        <PageSubDescription>
          {searchParams?.startDate && searchParams?.endDate
            ? `Filtered: (${moment(searchParams?.startDate).format(
                'DD-MM-YY HH:mm'
              )}H to ${moment(searchParams?.endDate).format(
                'DD-MM-YY HH:mm'
              )}H)`
            : `(${moment()
                .startOf('hour')
                .format('DD-MM-YY HH')}00H to ${moment().format('HHmm')}
          H)`}
        </PageSubDescription>
        <StatWithLoading
          stat={activeLOIsPastHour}
          isLoading={isLoadingActiveLOIsPastHour}
          isError={isErrorActiveLOIsPastHour}
          statClassName="text-center text-3xl"
        />
        {/* <div className="absolute bottom-0 right-0 w-fit m-2">
          <StyledButton onClick={() => setStatButtonClicked(STAT_BUTTON.LOI)}>
            <i className="fa fa-angle-double-right" />
          </StyledButton>
        </div> */}
      </GridChild>
    </Fragment>
  );
};

export default memo(Stats);
