import classNames from 'classnames';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { Form, StyledButton } from '.';
import {
  ElementBgClassnamesWithHover,
  ElementTextClassnames
} from '../interfaces';
interface ISubmitFormProps extends FormikProps<any> {
  placeholder?: string;
  currentSearch: string;
}

const SubmitForm = (props: ISubmitFormProps): JSX.Element => {
  const {
    isSubmitting,
    placeholder,
    currentSearch,
    // values,
    // touched,
    // errors,
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    resetForm
  } = props;
  const [invalidCoordinatesError, setInvalidCoordinatesError] = useState(false);

  const showClear = values.searchText.length > 0;
  const handleClear = () => {
    resetForm();
    if (currentSearch.length > 0) handleSubmit();
  };

  const validateCoordinates = (value: string) => {
    const latlng = value.split(',').map((val) => parseFloat(val));
    if (latlng.length === 2 && latlng.every((num) => !isNaN(num))) {
      setInvalidCoordinatesError(false);
      return true;
    } else {
      setInvalidCoordinatesError(true);
      return false;
    }
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    if (validateCoordinates(values.searchText)) {
      handleSubmit(event);
    }
  };
  return (
    <Form
      onSubmit={handleFormSubmit}
      submitting={isSubmitting}
      submitLabel="Login"
      noSubmitButton={true}
      className="w-96"
    >
      <div className="flex flex-nowrap w-full">
        <div className="flex-grow relative">
          <Form.Input
            type="text"
            placeholder={placeholder}
            name="searchText"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            withFormikError={true}
            className="rounded-r-none"
            value={values.searchText}
          />
          {showClear && (
            <div
              className="px-3 py-2 absolute right-0 bottom-0 hover:text-white cursor-pointer"
              onClick={() => {
                handleClear();
                setInvalidCoordinatesError(false);
              }}
            >
              <i className="fa fa-times" />
            </div>
          )}
        </div>
        <StyledButton
          type="submit"
          className={`rounded-l-none w-24 items-center ${ElementBgClassnamesWithHover.success}`}
          loading={isSubmitting}
          hideChildren={true}
        >
          Add
        </StyledButton>
      </div>
      {invalidCoordinatesError && (
        <div
          className={classNames(
            ElementTextClassnames.danger,
            'text-center mr-2 pt-1'
          )}
        >
          Invalid coordinates.
        </div>
      )}
    </Form>
  );
};

export default SubmitForm;
