import React, { useEffect, useState } from 'react';
import {
  ContainerFull,
  Modal,
  PageSubDescription,
  PageTitle,
  StyledButton,
  Tooltip,
  WithLoadingDiv
} from '../../components';
import Input from '../../components/form/Input';
import FileUpload from '../../components/form/FileUpload';
import {
  ElementBgClassnames,
  ElementBgClassnamesWithHover,
  ElementTextClassnames,
  ElementVariant
} from '../../interfaces';
import apiAgent from '../../api/apiAgent';
import { useNavigate, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import {
  Campaign,
  Device,
  Location,
  campaignKeys,
  locationKeys,
  validateCampaigns
} from './filtersUtil';
import { ToggleButton } from '../../components/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UpArrowHeadIcon from '../../components/icons/UpArrowheadIcon';
import DownArrowHeadIcon from '../../components/icons/DownArrowheadIcon';
import { toastHandler } from '../../utils';
import TextArea from '../../components/form/TextArea';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import _ from 'lodash';

const LocationDiv = ({
  location,
  locationIndex,
  campaignIndex,
  handleLocationChange,
  handleDeleteLocation
}: {
  location: Location;
  locationIndex: number;
  campaignIndex: number;
  handleLocationChange: (
    campaignIndex: number,
    locationIndex: number,
    value: string,
    field: locationKeys
  ) => void;
  handleDeleteLocation: (campaignIndex: number, locationIndex: number) => void;
}) => {
  return (
    <div
      key={`${campaignIndex}-${locationIndex}`}
      className={`flex m-2 p-2  items-center mb-4 rounded ${ElementBgClassnames.primary}`}
    >
      <div className="whitespace-nowrap mr-2">Location ID</div>
      <Input
        className={`col-span-2 h-full mr-4 ${ElementBgClassnames.primaryAltTwo}`}
        type="text"
        value={location.locationId}
        onChange={(e: any) =>
          handleLocationChange(
            campaignIndex,
            locationIndex,
            e.target.value,
            'locationId'
          )
        }
      />
      <div className="mr-2">Latitude</div>
      <Input
        className={`col-span-2 h-full mr-4 ${ElementBgClassnames.primaryAltTwo}`}
        type="number"
        value={location.latitude}
        onChange={(e: any) =>
          handleLocationChange(
            campaignIndex,
            locationIndex,
            e.target.value,
            'latitude'
          )
        }
      />
      <div className="mr-2">Longitude</div>
      <Input
        className={`col-span-2 h-full mr-4 ${ElementBgClassnames.primaryAltTwo}`}
        type="number"
        value={location.longitude}
        onChange={(e: any) =>
          handleLocationChange(
            campaignIndex,
            locationIndex,
            e.target.value,
            'longitude'
          )
        }
      />

      <div className="mr-2">Radius</div>
      <Input
        className={`col-span-2 h-full mr-4 ${ElementBgClassnames.primaryAltTwo}`}
        type="number"
        value={location.radius}
        onChange={(e: any) =>
          handleLocationChange(
            campaignIndex,
            locationIndex,
            e.target.value,
            'radius'
          )
        }
      />
      <StyledButton
        className=""
        variant={ElementVariant.danger}
        onClick={(e: any) => handleDeleteLocation(campaignIndex, locationIndex)}
      >
        Del
      </StyledButton>
    </div>
  );
};

const CampaignDiv = ({
  campaign,
  campaignIndex,
  handleCampaignChange,
  handleDeleteCampaign,
  handleAddLocation,
  handleDeleteLocation,
  handleLocationChange,
  handleSetDevices
}: {
  campaign: Campaign;
  campaignIndex: number;
  handleCampaignChange: (
    campaignIndex: number,
    value: any,
    field: campaignKeys
  ) => void;
  handleDeleteCampaign: (index: number) => void;
  handleAddDevice: (campaignIndex: number, deviceIfa: string) => void;
  handleDeleteDevice: (campaignIndex: number, deviceIndex: number) => void;
  handleLocationChange: (
    campaignIndex: number,
    locationIndex: number,
    value: string,
    field: locationKeys
  ) => void;
  handleDeleteLocation: (campaignIndex: number, locationIndex: number) => void;
  handleAddLocation: (campaignIndex: number) => void;
  handleSetDevices: (devices: Device[], campaginIndex: number) => void;
}) => {
  return (
    <React.Fragment key={campaignIndex}>
      {campaign.isExpanded ? (
        <div
          className={`border-lg shadow-lg border-gray-800 rounded-md mb-12 w-10/12 ${ElementBgClassnames.primary}`}
        >
          <div className="py-4 px-2 w-full">
            <div className="grid grid-cols-12 gap-x-3 gap-y-6 items-center">
              <div className="col-span-2">Campaign ID</div>
              <Input
                className={`col-span-3 py-1.5 ${ElementBgClassnames.primaryAltTwo}`}
                value={campaign.campaignId}
                type="text"
                onChange={(e: any) =>
                  handleCampaignChange(
                    campaignIndex,
                    e.target.value,
                    'campaignId'
                  )
                }
              />
              <div className="flex col-start-11 h-full col-span-2 justify-end items-start">
                <StyledButton
                  className=""
                  variant={ElementVariant.primaryAltTwo}
                  onClick={() =>
                    handleCampaignChange(campaignIndex, false, 'isExpanded')
                  }
                >
                  <UpArrowHeadIcon />
                </StyledButton>
              </div>

              <div className="col-start-1 col-span-2">Active</div>
              <ToggleButton
                isOn={campaign.active}
                onClick={() => {
                  handleCampaignChange(
                    campaignIndex,
                    !campaign.active,
                    'active'
                  );
                }}
              />
              <div
                className={`col-start-1 
                col-span-2
                `}
              >
                Start Date
              </div>
              {!campaign.active ? (
                <div
                  className={`h-full w-full col-span-3 rounded  block p-2 text-gray-500 ${ElementBgClassnames.primaryAlt}`}
                >
                  Long Running
                </div>
              ) : (
                <div className="col-span-3">
                  <DatePicker
                    className={`rounded focus:text-white  border-0 w-full ${ElementBgClassnamesWithHover.primaryAltTwo}`}
                    showTimeSelect
                    selected={campaign.startDate}
                    dateFormat="dd/MM/yyy hh:mm aa"
                    onChange={(date) =>
                      handleCampaignChange(campaignIndex, date, 'startDate')
                    }
                  />
                </div>
              )}
              <div className={`col-start-1 col-span-2`}>End Date</div>
              {!campaign.active ? (
                <div
                  className={`h-full w-full col-span-3 rounded block p-2 text-gray-500 ${ElementBgClassnames.primaryAlt}`}
                >
                  Long Running
                </div>
              ) : (
                <div className="col-span-3">
                  <DatePicker
                    className={`rounded focus:text-white  border-0 w-full ${ElementBgClassnamesWithHover.primaryAltTwo}`}
                    showTimeSelect
                    selected={campaign.endDate}
                    onChange={(date) =>
                      handleCampaignChange(campaignIndex, date, 'endDate')
                    }
                    dateFormat="dd/MM/yyy hh:mm aa"
                  />
                </div>
              )}
              <div className="col-start-1">IFAs </div>
              <TextArea
                className={`col-start-3 col-span-10 block w-full rounded-md border border-transparent focus:border-gray-300 focus:ring-0 focus:text-white ${ElementBgClassnames.primaryAltTwo}`}
                value={campaign.devices
                  .map((device: Device) => device.ifa)
                  .join('\r\n')}
                onChange={(e) => {
                  let devices = e.target.value
                    .split('\n')
                    .map((ifa: string) => {
                      return { ifa };
                    });
                  if (devices.length === 1 && !devices[0].ifa) {
                    devices = [];
                  }
                  handleSetDevices(devices, campaignIndex);
                }}
              />
              <div className="col-start-1 col-span-2">Locations </div>
              <StyledButton
                className=""
                variant={ElementVariant.info}
                onClick={(e: any) => {
                  handleAddLocation(campaignIndex);

                  //updateRef(campaign.locations.length - 1);

                  //scrollSmoothHandler(campaign.locations.length -1);
                }}
              >
                Add
              </StyledButton>
              <div
                className={`rounded p-2 overflow-y-auto overscroll-none col-span-12 col-start-1 ${ElementBgClassnames.primaryAlt}`}
                style={{ maxHeight: '35vh', minHeight: '8vh' }}
                key={campaignIndex}
              >
                {campaign.locations?.map(
                  (location: Location, locationIndex: number) => (
                    <LocationDiv
                      location={location}
                      campaignIndex={campaignIndex}
                      locationIndex={locationIndex}
                      handleLocationChange={handleLocationChange}
                      handleDeleteLocation={handleDeleteLocation}
                      //ref={(el) => (scrollRefs.current[locationIndex] = el)}
                    />
                  )
                )}
              </div>
            </div>
            <div className="col-span-12 flex items-center w-full justify-center">
              <StyledButton
                className="m-2 "
                variant={ElementVariant.danger}
                onClick={() => handleDeleteCampaign(campaignIndex)}
              >
                Delete Campaign
              </StyledButton>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`grid grid-cols-12 items-center border-lg shadow-lg border-gray-800 rounded-md mb-4 w-10/12 py-4 px-2 gap-3 ${ElementBgClassnames.primary}`}
        >
          <div>Cam ID</div>
          <div
            className={`px-1 py-2 col-span-2 rounded  ${ElementBgClassnames.primaryAlt}`}
          >
            {campaign.campaignId}
          </div>
          <div
            className={`text-end ${
              campaign.active ? '' : ElementTextClassnames.primaryAlt
            }`}
          >
            Start Date{' '}
          </div>
          <div
            className={`px-1 py-2 col-span-2 rounded  ${
              campaign.active ? '' : 'text-gray-500'
            } ${ElementBgClassnames.primaryAlt}`}
          >
            {campaign.active
              ? moment(campaign.startDate).format('Do MMM YYYY hh:mm A')
              : 'Long Running'}
          </div>
          <div className="text-end">End Date </div>
          <div
            className={`px-1 py-2 col-span-2 rounded  ${
              campaign.active ? '' : 'text-gray-500'
            } ${ElementBgClassnames.primaryAlt} }`}
          >
            {campaign.active
              ? moment(campaign.endDate).format('Do MMM YYYY hh:mm A')
              : 'Long Running'}
          </div>

          <div className="flex col-start-11 col-span-2 justify-end">
            <StyledButton
              className="mr-8"
              variant={ElementVariant.danger}
              onClick={() => handleDeleteCampaign(campaignIndex)}
            >
              <i className="fa fa-trash-alt"></i>
            </StyledButton>
            <StyledButton
              className=""
              variant={ElementVariant.primaryAltTwo}
              onClick={() =>
                handleCampaignChange(campaignIndex, true, 'isExpanded')
              }
            >
              <DownArrowHeadIcon />
            </StyledButton>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const CreateFilters = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);
  const [showBackModal, setShowBackModal] = useState<boolean>(false);
  const [showClearModal, setShowClearModal] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteCampaignIndex, setDeleteCampaignIndex] = useState<number>();

  const navigate = useNavigate();
  const getLocationsQty = (): number => {
    let count = 0;
    campaigns.forEach((campaign: Campaign) => {
      count += campaign.locations.length;
    });
    return count;
  };
  const getDevicesQty = (): number => {
    let count = 0;
    campaigns.forEach((campaign: Campaign) => {
      count += campaign.devices.length;
    });
    return count;
  };

  const locationQty = getLocationsQty();
  const deviceQty = getDevicesQty();

  const { filterId } = useParams();

  const monDate = new Date();
  monDate.setDate(monDate.getDate() + ((((7 - monDate.getDay()) % 7) + 1) % 7));

  const maxDevicesQty = 5000;
  const maxLocationQty = 600;

  const parseCampaigns = (fetchedCampaigns: any) => {
    let campaignArr: any = [];
    fetchedCampaigns.forEach((campaign: any) => {
      let parsedCampaign: Campaign = {
        isExpanded: false,
        campaignId: campaign.campaignId,
        startDate: new Date(campaign.startDate),
        endDate: new Date(campaign.endDate),
        active: campaign.active === 1 ? true : false, //Explicitly check for 1 because Singlestore returns 1 for true
        devices: campaign.devices.map((device: Device) => {
          return {
            isExpanded: true,
            ifa: device.ifa
          };
        }),
        locations: campaign.locations.map((location: Location) => {
          return {
            isExpanded: true,
            locationId: location.locationId,
            longitude: location.longitude.toString(),
            latitude: location.latitude.toString(),
            radius: location.radius.toString()
          };
        })
      };
      campaignArr.push(parsedCampaign);
    });
    return campaignArr;
  };

  const fetchFiltersList = async () => {
    try {
      if (!filterId) {
        return;
      }
      setIsLoading(true);
      let result: any;
      if (filterId === 'current') {
        result = await apiAgent.Filters.getCurrentFilter();
      } else {
        result = await apiAgent.Filters.getSingleFilter(parseInt(filterId!));
      }

      const filter = result.data?.data?.filter;

      if (filter) {
        setCampaigns(parseCampaigns(filter.campaigns));
      }
    } catch (e: any) {
      console.log(e);
      toastHandler({
        messages: e.response.data.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFiltersList();
  }, []);

  const handleCampaignsFileUpload = async (uploadFiles: any) => {
    Papa.parse(uploadFiles[0], {
      header: true,
      skipEmptyLines: true,
      complete: (rows) => {
        try {
          const csvCampaigns: Array<any> = rows.data;
          if (csvCampaigns.length < 1) {
            throw Error('Empty file uploaded');
          }
          if (
            !csvCampaigns[0].campaignId ||
            (!csvCampaigns[0].startDate && csvCampaigns[0].startDate !== '') ||
            (!csvCampaigns[0].endDate && csvCampaigns[0].endDate !== '')
          ) {
            throw Error(
              'Campaign file should have campaignId, startDate, and endDate columns'
            );
          }

          let parsedCampaign: Campaign[] = [...csvCampaigns].map((row: any) => {
            let startDate = null;
            let endDate = null;
            let active = false;
            if (row.startDate !== '' && row.endDate !== '') {
              const startMoment = moment(row.startDate, 'DD/MM/YYYY hh:mm');
              const endMoment = moment(row.endDate, 'DD/MM/YYYY hh:mm');

              //check if dates are valid
              if (!startMoment.isValid() || !endMoment.isValid()) {
                throw new Error('Invalid date found in file');
              }
              startDate = startMoment
                .subtract(startMoment.minute() % 15, 'minutes')
                .toDate();
              endDate = endMoment
                .subtract(endMoment.minute() % 15, 'minutes')
                .toDate();

              active = true;
            }

            return {
              campaignId: row.campaignId,
              startDate,
              endDate,
              devices: [],
              locations: [],
              active
            };
          });
          parsedCampaign = campaigns.concat(parsedCampaign);

          validateCampaigns(parsedCampaign);
          setCampaigns(parsedCampaign);
          toastHandler({
            messages: `Campaign file: ${uploadFiles[0].name} upload success!`,
            toastOptions: { type: 'success' }
          });
        } catch (e: any) {
          console.log(e.message);
          toastHandler({
            messages: e.message,
            toastOptions: { type: 'error' }
          });
        }
      }
    });
  };
  const handleLocationsFileUpload = async (uploadFiles: any) => {
    Papa.parse(uploadFiles[0], {
      header: true,
      skipEmptyLines: true,
      complete: (rows) => {
        try {
          const csvLocations: Array<any> = rows.data;

          //check if missing any data
          if (
            !csvLocations[0].campaignId ||
            !csvLocations[0].locationId ||
            !csvLocations[0].longitude ||
            !csvLocations[0].latitude ||
            !csvLocations[0].radius
          ) {
            throw Error(
              'Location file should have campaignId, locationId, longitude, latitude, and radius columns'
            );
          }

          let parsedCampaigns = [...campaigns];

          //check if campaign exists
          csvLocations.forEach((row: any) => {
            const campaignIndex = parsedCampaigns.findIndex(
              (campaign) => campaign.campaignId === row.campaignId
            );
            if (campaignIndex < 0) {
              throw new Error(`Campaign ID ${row.campaignId} not found`);
            }
          });

          csvLocations.forEach((row: any) => {
            const location: Location = {
              locationId: row.locationId,
              latitude: Number(row.latitude),
              longitude: Number(row.longitude),
              radius: parseInt(row.radius)
            };

            const campaignIndex = parsedCampaigns.findIndex(
              (campaign) => campaign.campaignId === row.campaignId
            );

            parsedCampaigns[campaignIndex].locations.push(location);
          });
          validateCampaigns(parsedCampaigns);
          setCampaigns(parsedCampaigns);
          toastHandler({
            messages: `Location file: ${uploadFiles[0].name} upload success!`,
            toastOptions: { type: 'success' }
          });
        } catch (e: any) {
          console.log(e);
          toastHandler({
            messages: e.message,
            toastOptions: { type: 'error' }
          });
        }
      }
    });
  };
  const handleDevicesFileUpload = async (uploadFiles: any) => {
    Papa.parse(uploadFiles[0], {
      header: true,
      skipEmptyLines: true,
      complete: (rows) => {
        try {
          const csvLocations: Array<any> = rows.data;
          //check if missing any data
          if (!csvLocations[0].campaignId || !csvLocations[0].ID) {
            throw Error('IFA file should have campaignId and ID columns');
          }

          let parsedCampaigns = [...campaigns];

          csvLocations.forEach((row: any) => {
            const campaignIndex = parsedCampaigns.findIndex(
              (campaign) => campaign.campaignId === row.campaignId
            );

            if (campaignIndex < 0) {
              throw new Error(`Campaign ID ${row.campaignId} not found`);
            }
          });

          csvLocations.forEach((row: any) => {
            const device: Device = {
              ifa: row.ID
            };

            const campaignIndex = parsedCampaigns.findIndex(
              (campaign) => campaign.campaignId === row.campaignId
            );

            parsedCampaigns[campaignIndex].devices.push(device);
          });
          validateCampaigns(parsedCampaigns);
          setCampaigns(parsedCampaigns);
          toastHandler({
            messages: `IFA file: ${uploadFiles[0].name} upload success!`,
            toastOptions: { type: 'success' }
          });
        } catch (e: any) {
          console.log(e);
          toastHandler({
            messages: e.message,
            toastOptions: { type: 'error' }
          });
        }
      }
    });
  };

  const handleAddCampaign = () => {
    setCampaigns([
      ...campaigns.map((campaign: any) => {
        let tobeCampaign = campaign;
        tobeCampaign.isExpanded = false;
        return tobeCampaign;
      }),
      {
        isExpanded: true,
        campaignId: '',
        active: false,
        devices: [],
        locations: []
      }
    ]);
  };

  const handleClearCampaigns = () => {
    setIsLoading(true);
    setCampaigns([]);
    setIsLoading(false);
  };

  const handleDeleteCampaign = (index: number) => {
    setIsLoading(true);
    setCampaigns([
      ...campaigns.slice(0, index),
      ...campaigns.slice(index + 1, campaigns.length)
    ]);
    setIsLoading(false);
  };

  const handleCampaignChange = (
    campaignIndex: number,
    value: any,
    field: campaignKeys
  ) => {
    let newArr: any = [...campaigns];
    newArr[campaignIndex][field] = value;
    setCampaigns(newArr);
  };

  const handleAddDevice = (campaignIndex: number, deviceIfa: string) => {
    let newArr = [...campaigns];
    if (newArr.find((device: any) => device.ifa === deviceIfa)) {
      //create modal
      return;
    }
    if (!deviceIfa) {
      //create modal
      return;
    }
    newArr[campaignIndex].devices?.push({
      isExpanded: true,
      ifa: deviceIfa
    });
    setCampaigns(newArr);
  };

  const handleDeleteDevice = (campaignIndex: number, deviceIndex: number) => {
    let newCampaigns = [...campaigns];
    let newDevices = newCampaigns[campaignIndex].devices;
    newCampaigns[campaignIndex].devices = [
      ...newDevices!.slice(0, deviceIndex),
      ...newDevices!.slice(deviceIndex + 1, newDevices!.length)
    ];
    setCampaigns(newCampaigns);
  };

  const handleAddLocation = (campaignIndex: number) => {
    let newArr = [...campaigns];
    newArr[campaignIndex].locations?.push({
      isExpanded: true,
      locationId: '',
      longitude: '',
      latitude: '',
      radius: ''
    });
    setCampaigns(newArr);
  };

  const handleDeleteLocation = (
    campaignIndex: number,
    locationIndex: number
  ) => {
    let newCampaigns = [...campaigns];
    let newLocations = newCampaigns[campaignIndex].locations;
    newCampaigns[campaignIndex].locations = [
      ...newLocations!.slice(0, locationIndex),
      ...newLocations!.slice(locationIndex + 1, newLocations!.length)
    ];
    setCampaigns(newCampaigns);
  };

  const handleLocationChange = (
    campaignIndex: number,
    locationIndex: number,
    value: any,
    field: locationKeys
  ) => {
    let newCampaigns = [...campaigns];
    let newLocation: any = newCampaigns[campaignIndex].locations[locationIndex];
    newLocation[field] = value;
    newCampaigns[campaignIndex].locations[locationIndex] = newLocation;
    setCampaigns(newCampaigns);
  };

  const getQtyColorText = (num: number, maxNum: number): string => {
    return num < maxNum / 2
      ? ElementTextClassnames.success
      : num < maxNum
      ? ElementTextClassnames.warning
      : ElementTextClassnames.danger;
  };

  const handleDownloadCampaigns = () => {
    const blob = new Blob([JSON.stringify({ campaigns: campaigns })], {
      type: 'text.json'
    });
    const a = document.createElement('a');
    a.download = 'campaigns.json';
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleSubmit = async () => {
    //console.log(JSON.stringify(campaigns, null, 4));
    try {
      validateCampaigns(campaigns);
    } catch (err: any) {
      console.log(err);
      setError(err.message);
      return;
    }

    try {
      setIsSubmitting(true);
      const result = await apiAgent.Filters.createFilter(
        campaigns.map((campaign: Campaign) => {
          const parsedDevices = campaign.devices.map((device: Device) => {
            return {
              ifa: device.ifa
            };
          });
          const parsedLocations = campaign.locations.map(
            (location: Location) => {
              return {
                locationId: location.locationId,
                radius: location.radius,
                latitude: location.latitude,
                longitude: location.longitude
              };
            }
          );
          return {
            campaignId: campaign.campaignId,
            startDate: campaign.active ? campaign.startDate : null,
            endDate: campaign.active ? campaign.endDate : null,
            active: campaign.active,
            devices: parsedDevices,
            locations: parsedLocations
          };
        })
      );
      setIsSubmitting(false);
      setError('Filters updated!');
      toastHandler({
        messages: `Filters updated!`,
        toastOptions: { type: 'success' }
      });
      navigate(`/campaigns`);
    } catch (err: any) {
      console.log(err);
      setIsSubmitting(false);
      setError(err.response.data.message);
    }
  };

  const handleSetDevices = (devices: Device[], campaginIndex: number) => {
    let newCampaigns = _.cloneDeep(campaigns);
    let newCampaign = newCampaigns[campaginIndex];
    newCampaign.devices = devices;
    newCampaigns[campaginIndex] = newCampaign;
    setCampaigns(newCampaigns);
  };
  return (
    <ContainerFull>
      <div className="px-12 pt-8 ">
        <StyledButton className="mb-4" onClick={() => setShowBackModal(true)}>
          Back
        </StyledButton>
        <PageTitle>{`New Campaigns ${
          filterId
            ? filterId === 'current'
              ? 'from Current'
              : 'from Previous'
            : ''
        }`}</PageTitle>
        <PageSubDescription>
          {`Campaigns submitted will replace the existing set`}
        </PageSubDescription>
        <div className="flex">
          <PageTitle> From CSV Files</PageTitle>
          <Tooltip>
            <div>
              <div className="mb">
                Campaign file columns: campaignId, startDate, endDate.
              </div>
              <div className="mb-2">
                Date format: dd/mm/yyyy hh:mm , e.g 30/07/2022 12:00
              </div>
              <div className="mb-2">IFA file columns: campaignId, ID.</div>
              <div className="mb-2">
                Location file columns: campaignId, locationId, latitude,
                longitude, radius.
              </div>
              <div>
                Note: for locationId and campaignId, only alphanumeric, hyphen
                '-', and underscore '_' allowed. Radius is in meters.
              </div>
            </div>
          </Tooltip>
        </div>
        <PageSubDescription>
          Uploaded files add to current form
        </PageSubDescription>
        <div className="flex w-full">
          <FileUpload
            withFilename={false}
            handleSetFiles={handleCampaignsFileUpload}
          >
            Upload Campaigns File
          </FileUpload>

          <FileUpload
            withFilename={false}
            className="ml-3"
            handleSetFiles={handleDevicesFileUpload}
          >
            Upload IFA File
          </FileUpload>

          <FileUpload
            withFilename={false}
            className="ml-3"
            handleSetFiles={handleLocationsFileUpload}
          >
            Upload Locations File
          </FileUpload>
        </div>
        <div className="flex justify-between items-end">
          <div className="flex items-center">
            <PageTitle className="mr-2">Campaigns Qty</PageTitle>
            <div
              className={`text-2xl mb-4 ${getQtyColorText(
                campaigns.length,
                20
              )}`}
            >
              {campaigns.length}
            </div>
          </div>

          <div className="flex items-center">
            <PageTitle className="mr-2">Locations Qty</PageTitle>
            <div
              className={`text-2xl mb-4 ${getQtyColorText(
                locationQty,
                maxLocationQty
              )}`}
            >
              {locationQty}
            </div>
            <div className="text-2xl mb-4">/{maxLocationQty}</div>
          </div>

          <div className="flex justify-end items-center text-xl">
            <PageTitle className="mr-2">IFAs Qty</PageTitle>
            <div
              className={`text-2xl mb-4 ${getQtyColorText(
                deviceQty,
                maxDevicesQty
              )}`}
            >
              {deviceQty}
            </div>

            <div className="text-2xl mb-4">/{maxDevicesQty}</div>
          </div>
          <div className="flex items-center block text-xl mb-2">
            <StyledButton
              className=""
              variant={ElementVariant.primaryAltTwo}
              onClick={() => setShowResetModal(true)}
            >
              Reset
            </StyledButton>
            <StyledButton
              className="ml-2"
              variant={ElementVariant.danger}
              onClick={() => setShowClearModal(true)}
            >
              Clear
            </StyledButton>
          </div>
        </div>

        <div
          className={`w-full flex flex-col items-center py-8 rounded-lg ${ElementBgClassnames.primaryAlt}`}
        >
          <WithLoadingDiv isLoading={isLoading}>
            {campaigns.map((campaign, campaignIndex) => {
              return (
                <CampaignDiv
                  campaign={campaign}
                  campaignIndex={campaignIndex}
                  handleAddDevice={handleAddDevice}
                  handleAddLocation={handleAddLocation}
                  handleCampaignChange={handleCampaignChange}
                  handleDeleteCampaign={(index: number) => {
                    setDeleteCampaignIndex(index);
                    setShowDeleteModal(true);
                  }}
                  handleDeleteDevice={handleDeleteDevice}
                  handleDeleteLocation={handleDeleteLocation}
                  handleLocationChange={handleLocationChange}
                  handleSetDevices={handleSetDevices}
                />
              );
            })}

            <div className="w-full flex flex-col items-center mb-6">
              <StyledButton
                className=""
                onClick={handleAddCampaign}
                variant={ElementVariant.info}
              >
                Add Campaign
              </StyledButton>
            </div>
          </WithLoadingDiv>
        </div>
        <div
          className={`"w-full mt-4 text-center ${
            error === 'Filters updated!'
              ? ElementTextClassnames.success
              : ElementTextClassnames.danger
          }`}
        >
          {error}
        </div>
        <div className="w-full mt-4 mb-64 flex justify-center items-center">
          <WithLoadingDiv isLoading={isSubmitting}>
            <StyledButton
              className="my-2 mx-2"
              variant={ElementVariant.danger}
              onClick={() => {
                setShowBackModal(true);
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              className="my-2 mx-2"
              variant={ElementVariant.success}
              onClick={() => setShowSubmitModal(true)}
            >
              Submit
            </StyledButton>
          </WithLoadingDiv>
        </div>
      </div>
      <SubmitModal
        showModal={showSubmitModal}
        setShowExternal={setShowSubmitModal}
        handleSubmit={handleSubmit}
      />
      <BackModal showModal={showBackModal} setShowExternal={setShowBackModal} />
      <ClearModal
        showModal={showClearModal}
        setShowExternal={setShowClearModal}
        handleClear={handleClearCampaigns}
      />
      <ResetModal
        showModal={showResetModal}
        setShowExternal={setShowResetModal}
        handleReset={fetchFiltersList}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowExternal={setShowDeleteModal}
        handleDelete={handleDeleteCampaign}
        campaign={campaigns[deleteCampaignIndex!]}
        campaignIndex={deleteCampaignIndex!}
      />
    </ContainerFull>
  );
};

const SubmitModal = ({
  showModal,
  setShowExternal,
  handleSubmit
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
  handleSubmit: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm Submission?</Modal.Header>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal && setShowExternal(false);
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.success}
            onClick={() => {
              setShowExternal && setShowExternal(false);
              handleSubmit();
            }}
          >
            Submit
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const BackModal = ({
  showModal,
  setShowExternal
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
}) => {
  const navigate = useNavigate();
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm leave page?</Modal.Header>
        <Modal.Body>You'll lose your current draft</Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => navigate(-1)}
          >
            Leave
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const ClearModal = ({
  showModal,
  setShowExternal,
  handleClear
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleClear: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm clear campaigns?</Modal.Header>
        <Modal.Body>
          You can reset this before submitting by clicking the RESET button
        </Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal(false);
              handleClear();
            }}
          >
            Clear
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const ResetModal = ({
  showModal,
  setShowExternal,
  handleReset
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleReset: Function;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        <Modal.Header>Confirm reset campaigns?</Modal.Header>
        <Modal.Body>You'll lose the current draft</Modal.Body>
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.info}
            onClick={() => {
              setShowExternal(false);
              handleReset();
            }}
          >
            Reset
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

const DeleteModal = ({
  showModal,
  setShowExternal,
  handleDelete,
  campaign,
  campaignIndex
}: {
  showModal: boolean;
  setShowExternal: Function;
  handleDelete: Function;
  campaign: Campaign;
  campaignIndex: number;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      <Modal.Display>
        {campaign && (
          <Modal.Header>{`Confirm delete campaign(${campaign.campaignId})?`}</Modal.Header>
        )}
        <Modal.Footer>
          <StyledButton
            className="my-2 mx-2"
            variant={ElementVariant.danger}
            onClick={() => {
              setShowExternal(false);
              handleDelete(campaignIndex);
            }}
          >
            Delete
          </StyledButton>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

export default CreateFilters;
