import React, {
  Fragment,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  BadgeBoolean,
  confirmAlert,
  Pagination,
  ReactTable,
  StyledButton,
  WithLoadingDiv
} from '../../../../components';
import {
  ElementBgClassnames,
  ElementTextClassnames,
  IPaginationState
} from '../../../../interfaces';
import { toastHandler } from '../../../../utils';
import classNames from 'classnames';
import apiAgent from '../../../../api/apiAgent';
import moment from 'moment';
import { FLASH_MESSAGES } from '../../../../cnstants';

interface IThresholdAlertTable {
  isLoading: boolean;
  thresholdAlerts: any[] | null;
  paginationInfo: IPaginationState;
  updateAlertAcknowledged: (rowIndex: number) => void;
  fetchThresholdAlerts: ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }) => Promise<void>;
}

const AlertTable = ({
  isLoading,
  thresholdAlerts,
  paginationInfo,
  updateAlertAcknowledged,
  fetchThresholdAlerts
}: IThresholdAlertTable) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        )
      },

      {
        Header: 'Location',
        accessor: 'locationId',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        )
      },
      {
        Header: 'Baseline',
        accessor: 'baseline',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        )
      },
      {
        Header: 'Threshold (%)',
        accessor: 'varianceLimitPercentage',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        )
      },
      {
        Header: 'Increased/ Decreased',
        accessor: 'isIncreased',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        Cell: ({ row }: any) => {
          return (
            <div>{row.values.isIncreased ? 'INCREASED' : 'DECREASED'}</div>
          );
        }
      },
      {
        Header: 'Time',
        accessor: 'createdAt',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        Cell: ({ row }: any) => {
          const time = moment(row.values.createdAt);
          return <div>{time.format('DD/MM/YYYY hh:mm A')}</div>;
        }
      },
      {
        Header: 'Acknowledged?',
        accessor: 'acknowledged',
        Cell: ({ row }: any) => {
          return <BadgeBoolean value={row.values.acknowledged} />;
        }
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <div className="flex justify-center">
            <Fragment>
              {!row.values.acknowledged && (
                <AcknowledgeButtonWithState
                  alertId={row.original.id}
                  afterFunc={() => {
                    updateAlertAcknowledged(row.index);
                  }}
                />
              )}
            </Fragment>
          </div>
        )
      }
    ],
    [JSON.stringify(thresholdAlerts)]
  );

  return (
    <GridChildTable className="col-span-6" title="Alerts">
      <WithLoadingDiv isLoading={isLoading}>
        <div className="-mx-5 mb-5 overflow-y-auto overflow-x-auto">
          <ReactTable
            columns={columns}
            data={thresholdAlerts || []}
            className="w-full"
          />
        </div>
      </WithLoadingDiv>
      <div>
        {thresholdAlerts && (
          <Pagination
            totalPages={paginationInfo.totalPages}
            totalItems={paginationInfo.totalItems}
            currentPageCount={paginationInfo.currentPageCount}
            itemsPerPage={paginationInfo.itemsPerPage}
            fetchData={fetchThresholdAlerts}
          />
        )}
      </div>
    </GridChildTable>
  );
};

const GridChildTable = ({
  children,
  className,
  title
}: {
  children: React.ReactNode;
  className?: string;
  title: string;
}): JSX.Element => (
  <div
    className={classNames(
      'rounded-md bg-background-alt py-4 px-5',
      className ?? ''
    )}
  >
    {/* <div className="text-center text-lg mb-2">{title}</div> */}
    <div>{children}</div>
  </div>
);

const AcknowledgeButtonWithState = (props: {
  alertId: number;
  afterFunc: Function;
}): JSX.Element => {
  const { alertId, afterFunc } = props;
  const [isLoading, setIsLoading] = useState(false);

  const acknowledgeAlert =
    (id: number): Function =>
    async () => {
      try {
        setIsLoading(true);
        const result = await apiAgent.ThresholdAlerts.acknowledgeThresholdAlert(
          alertId
        );

        if (result.data.status === 'success') {
          toastHandler({
            messages: `Threshold Alert ${result?.data?.data?.name} acknowledged.`,
            toastOptions: { type: 'info' }
          });

          afterFunc();
        } else {
          throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
        }
      } catch (error: any) {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      } finally {
        setIsLoading(false);
      }
    };

  const handleOnClick =
    (id: number): MouseEventHandler<HTMLButtonElement> =>
    (event: any) => {
      event.preventDefault();

      confirmAlert({
        title: 'Confirm acknowledge alert',
        message: confirmationMessage(id),
        buttons: [
          {
            onClick: acknowledgeAlert(id)
          }
        ]
      });
    };

  return (
    <StyledButton loading={isLoading} onClick={handleOnClick(alertId)}>
      acknowledge
    </StyledButton>
  );
};

const confirmationMessage = (alertRuleId: number): JSX.Element => {
  return (
    <div className="mb-1" key={alertRuleId}>
      Are you sure you want to acknowledge this Alert? This process cannot be
      undone.
    </div>
  );
};

export default AlertTable;
