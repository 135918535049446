import React, { useEffect, memo } from 'react';
import moment from 'moment';

// import { divIcon } from 'leaflet';

import {
  CircleMarker,
  // Popup,
  LayersControl,
  FeatureGroup,
  Pane
} from 'react-leaflet';
import { StyledPopup } from './MapUtils';
import { renderToStaticMarkup } from 'react-dom/server';
import { PageSubDescription, StyledButton } from '../../../../components';
import GridChild from '../../../../components/GridChild';
import { IFA, MapPoint, Cluster } from '../../../../interfaces/mapChart';

const ClustersOverlay = ({
  data,
  devices = [],
  mapPointsLimit,
  colorsMap,
  // setColorsMap,
  colorsArray,
  handleFilterClickFromMap
}: {
  data: Map<string, Cluster>;
  devices: any[];
  mapPointsLimit: number;
  colorsMap: Map<string, string>;
  // setColorsMap: () => void;
  colorsArray: any[];
  handleFilterClickFromMap: Function;
}): JSX.Element => {
  // const [colorsMap, setColorsMap] = useState<Map<string, string>>(new Map());

  // useEffect(() => {}, [data]);

  useEffect(() => {
    // console.log(`colorsMap update triggered at ClustersOverlay`);
    // console.log([...colorsMap.entries()]);
  }, [colorsMap]);

  const limit = mapPointsLimit;
  let pointCounter = 0;

  //Get active Devices from list
  const active_doi_ref = new Map();
  devices.forEach((row: any) => {
    if (row.status === 1) active_doi_ref.set(row.ifa, row);
  });

  const generateIfasElement = (mapPoint: MapPoint): JSX.Element => {
    const ifaArray: any = [];

    mapPoint.IFAs.forEach((ifa: IFA, index: string) => {
      const doi = active_doi_ref.get(ifa.ifa);

      ifaArray.push(
        <GridChild
          className="mb-4 bg-background-alt-2 text-text-primary"
          title=""
          key={`CircleMarker_Popup_GridChild_${index}`}
        >
          <div className="">
            {doi && (
              <i className="fa-solid fa-circle fa-1x pr-1 text-green-300" />
            )}
            {`IFA: ${ifa.ifa}`}
            <br />
            {doi && (
              <React.Fragment>
                <div>
                  {`Alias: ${doi.alias}`}
                  <StyledButton className="pt-2">
                    <i
                      className="fa-solid fa-filter"
                      onClick={() => {
                        handleFilterClickFromMap(ifa.ifa, 'ALIAS');
                      }}
                    ></i>
                  </StyledButton>
                </div>
              </React.Fragment>
            )}
            {doi && <br />}
            {`Latest Datetime: ${moment(ifa.latest_datetime)}`}
            <br />
            {`Occurrences: ${ifa.count}`}
          </div>
        </GridChild>
      );
    });

    return ifaArray;
  };

  const generateMapPointsElement = (cluster: Cluster): JSX.Element => {
    const mapPointsArray: any = [];

    cluster?.mapPoints?.forEach((entry: MapPoint, index: string) => {
      //Limit number of unique coordinates on map if selected
      if (limit !== null && pointCounter >= limit) {
        mapPointsArray.push(
          <React.Fragment key={`Cluster_${index}`}></React.Fragment>
        );
      } else {
        pointCounter++;

        const num_of_ifas: number = entry.IFAs.size;
        let circle_marker_size = 5;

        //Scale size of signal the more IFA there is in the map point
        if (num_of_ifas > 1) {
          circle_marker_size += num_of_ifas / 10;
          if (circle_marker_size > 10) circle_marker_size = 10;
        }

        let num_of_occurrences = 0;

        // const ifaArray = []
        entry.IFAs.forEach((ifa) => {
          num_of_occurrences += ifa.count;
          // ifaArray.push(ifa);
        });

        let circle_opacity = 0.5; //Default opacity or less than 10 occurences
        if (num_of_occurrences >= 100) {
          circle_opacity = 1;
        } else if (num_of_occurrences >= 10) {
          circle_opacity = circle_opacity + (num_of_occurrences * 0.01) / 2; //10 to 99 -> 0.05 to 0.45 , so max will be 0.95
        }

        mapPointsArray.push(
          <CircleMarker
            key={`CircleMarker_${index}`}
            center={entry.position}
            radius={circle_marker_size}
            stroke={false}
            // weight={1}
            color={'#000'}
            fillColor={
              colorsMap.get(cluster.id)
                ? colorsMap.get(cluster.id)
                : colorsArray[cluster.groupCount]
            }
            fillOpacity={circle_opacity}
            // icon={customMarkerIcon}
            // icon={divIcon({
            //   html: iconMarkup,
            //   iconSize: [24, 24],
            //   iconAnchor: [12, 12]
            //   // className: 'text-orange-500'
            // })}
            eventHandlers={{
              mouseover: (event) => event.target.openPopup()
              // mouseout: (event) => event.target.closePopup()
            }}
          >
            <Pane
              name={`CircleMarker_Popup_${index}_${Math.random()}_Pane`}
              style={{ zIndex: 1000 }}
            >
              <StyledPopup key={`CircleMarker_Popup_${index}`} maxWidth={600}>
                <GridChild
                  className="max-h-64 overflow-y-auto text-text-primary p-0"
                  title="Details"
                >
                  <PageSubDescription>
                    Location ID: <b>{`${entry.location || 'N.A'}`}</b>{' '}
                    {entry.location !== '' && (
                      <StyledButton className="pt-2">
                        <i
                          className="fa-solid fa-filter"
                          onClick={() => {
                            // alert(JSON.stringify(row.values));
                            handleFilterClickFromMap(
                              entry.location,
                              'LOCATION'
                            );
                          }}
                        ></i>
                      </StyledButton>
                    )}
                    <br />
                    Coordinates: <b>({`${entry.position}`})</b> <br />
                    Cluster:{' '}
                    <b>
                      {`${cluster.id}`}{' '}
                      {cluster.id !== 'Unclustered' && (
                        <StyledButton className="pt-2">
                          <i
                            className="fa-solid fa-filter"
                            onClick={() => {
                              // alert(JSON.stringify(row.values));
                              handleFilterClickFromMap(cluster.id, 'CLUSTER');
                            }}
                          ></i>
                        </StyledButton>
                      )}
                    </b>
                    <br /> <br />{' '}
                    <span className="text-center">
                      No. of Devices ({entry.IFAs.size}) :
                    </span>
                  </PageSubDescription>
                  {generateIfasElement(entry)}
                </GridChild>
              </StyledPopup>
            </Pane>
          </CircleMarker>
        );
      }
    });

    return mapPointsArray;
  };

  const generateClustersElement = (
    data: Map<string, Cluster>,
    colorsMap: Map<string, string>
  ): JSX.Element => {
    const clustersArray: any = [];

    data.forEach((cluster: Cluster, index: string) => {
      // console.log(
      //   `There are ${cluster?.mapPoints?.size} mapPoints in cluster ${cluster?.id}`
      // );

      // console.log(
      //   `cluster.groupCount for ${cluster.id} is ${cluster.groupCount}`
      // );
      // console.log(
      //   `color for ${cluster.id} is ${colorsArray[cluster.groupCount]}`
      // );

      if (cluster.id !== 'Unclustered') {
        //Limit number of unique coordinates on map if selected
        if (limit !== null && limit <= pointCounter) {
          clustersArray.push(
            <React.Fragment key={`Clusters_${index}`}></React.Fragment>
          );
        } else {
          const iconMarkup = renderToStaticMarkup(
            <i
              className="fa-solid fa-circle fa-2xs"
              style={{
                color: colorsMap.get(cluster.id)
                  ? colorsMap.get(cluster.id)
                  : colorsArray[cluster.groupCount],
                verticalAlign: 'middle',
                alignSelf: 'center',
                paddingTop: 2
              }}
            />
          );

          // const iconMarkup = renderToStaticMarkup(<ClusterColorPicker />);

          clustersArray.push(
            <React.Fragment key={`Clusters_${index}_${cluster.id}`}>
              {/* <ClusterColorPicker
                cluster={cluster}
                colorsMap={colorsMap}
                setColorsMap={setColorsMap}
              /> */}
              <LayersControl.Overlay
                checked
                name={`${iconMarkup} ${cluster.id}`} // (${cluster.mapPoints.size})
              >
                <FeatureGroup key={`Cluster_${index}`}>
                  {generateMapPointsElement(cluster)}
                </FeatureGroup>
              </LayersControl.Overlay>
            </React.Fragment>
          );
        }
      }
    });

    //Add Unclustered last and only if yet to hit displayable map point limit
    const unclustered = data.get('Unclustered');
    if (limit !== null && limit > pointCounter && unclustered) {
      const iconMarkup = renderToStaticMarkup(
        <i
          className="fa-solid fa-circle fa-2xs"
          style={{
            color: colorsMap.get(unclustered.id)
              ? colorsMap.get(unclustered.id)
              : colorsArray[unclustered.groupCount],
            verticalAlign: 'middle',
            alignSelf: 'center',
            paddingTop: 2
          }}
        />
      );

      clustersArray.push(
        <React.Fragment key={`Clusters_unclustered`}>
          {/* <ClusterColorPicker
            cluster={unclustered}
            colorsMap={colorsMap}
            setColorsMap={setColorsMap}
          /> */}
          <LayersControl.Overlay
            checked
            name={`${iconMarkup} ${unclustered.id}`} // (${cluster.mapPoints.size})
          >
            <FeatureGroup key={`Cluster_unclustered`}>
              {generateMapPointsElement(unclustered)}
            </FeatureGroup>
          </LayersControl.Overlay>
        </React.Fragment>
      );
    }

    return <React.Fragment>{clustersArray}</React.Fragment>;
  };

  return (
    <React.Fragment>
      {data && data.size > 0 && generateClustersElement(data, colorsMap)}
    </React.Fragment>
  );
};

export default memo(ClustersOverlay);
