import { useEffect, useMemo, useState } from 'react';
import apiAgent from '../../../api/apiAgent';
import {
  Badge,
  Pagination,
  ReactTable,
  WithLoadingDiv
} from '../../../components';

import { ElementTextClassnames, IPaginationState } from '../../../interfaces';
import { dateFormatter, toastHandler } from '../../../utils';
import GridChildTable from './GridChildTable';

const transformIFAsDataForTable = (
  ifaslist: any[]
): {
  id: number;
  ifa: string;
  clusterTags: string;
  eventCount: number;
  createdAt: string;
}[] => {
  const data = ifaslist.map((obj: any) => {
    return {
      id: obj.id,
      ifa: obj.ifa,
      clusterTags: obj.cluster_tags,
      eventCount: parseInt(obj.event_count),
      createdAt: dateFormatter({
        timestamp: obj.created_at,
        withTime: true
      })
    };
  });

  return data || [];
};

const ActiveTargetedIFAsClusterTagsList = ({
  searchText,
  searchClusterTags
}: {
  searchText?: string;
  searchClusterTags?: string[];
}): JSX.Element => {
  const [isActiveIFAsListLoading, setIsActiveIFAsListLoading] = useState(true);
  const [activeIFAsList, setActiveIFAsList] = useState<any[] | null>(null);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const fetchActiveIfasList = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsActiveIFAsListLoading(true);
      const { data } = await apiAgent.Stats.getActiveTargetedIfasClusterTagsList({
        searchText,
        searchClusterTags,
        page,
        limit
      });

      const pageInfo = data.meta;
      setActiveIFAsList(transformIFAsDataForTable(data.data.activeIfasList));

      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastId: error?.code,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsActiveIFAsListLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveIfasList({
      page: 1,
      limit: paginationInfo.itemsPerPage
    });
  }, [
    paginationInfo.itemsPerPage,
    searchText,
    JSON.stringify(searchClusterTags)
  ]);

  const columns = useMemo(
    () => [
      {
        Header: 'ifa',
        accessor: 'ifa',
        thClassName: ElementTextClassnames.sysGenId
      },
      {
        Header: 'Cluster Tags',
        accessor: 'clusterTags',
        thClassName: ElementTextClassnames.sysGenDate,
        Cell: ({ row }: any) => {
          return <Badge>{row.values.clusterTags}</Badge>;
        }
      },
      // {
      //   Header: 'Created At',
      //   accessor: 'createdAt',
      //   thClassName: ElementTextClassnames.sysGenDate
      // },
      {
        Header: 'Total Events Count',
        accessor: 'eventCount'
      }
    ],
    []
  );

  return (
    <GridChildTable
      className="col-span-6 h-[32rem]"
      title="Active Targeted IFAs List (Cluster Tagged)"
    >
      {(searchText || '').length + (searchClusterTags || []).length > 0 ? (
        <Badge
          className={`mb-2 mx-auto table ${ElementTextClassnames.warning}`}
        >
          Searching IFAs based on
          {(searchText || '').length > 0 ? ` "${searchText}" ` : ''}
          {(searchText || '').length > 0 &&
            (searchClusterTags || []).length > 0 &&
            ' and '}
          {(searchClusterTags || []).length > 0
            ? ` ${(searchClusterTags || []).join(', ')}`
            : ''}
        </Badge>
      ) : (
        ''
      )}

      <WithLoadingDiv isLoading={isActiveIFAsListLoading}>
        <div className="-mx-5 mb-5 overflow-y-auto h-[22rem]">
          <ReactTable columns={columns} data={activeIFAsList || []} />
        </div>
      </WithLoadingDiv>
      <div>
        {activeIFAsList && (
          <Pagination
            totalPages={paginationInfo.totalPages}
            totalItems={paginationInfo.totalItems}
            currentPageCount={paginationInfo.currentPageCount}
            itemsPerPage={paginationInfo.itemsPerPage}
            fetchData={fetchActiveIfasList}
          />
        )}
      </div>
    </GridChildTable>
  );
};

export default ActiveTargetedIFAsClusterTagsList;
