import { ElementVariant } from '../interfaces';
import { StyledButton } from './Button';
import Modal from './Modal';
import Paragraph from './Paragraph';

const ChangelogModal = () => {
  return (
    <Modal>
      <Modal.Toggle>
        <i className="fas fa-2x fa-book cursor-pointer" />
      </Modal.Toggle>
      <Modal.Display>
        <Modal.Header showBorder>
          <Modal.Title>Change Logs</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className="pt-5 overflow-y-auto overflow-x-hidden h-96"
          padding={false}
        >
          {/** 
             * Daily Template:
             * 
             * 
              <u>
                <h3>11 October 2022</h3>
              </u>
              <h4>Fixed:</h4>
              <ul>
                <li>- Content goes here</li>
              </ul>
              <br/>
              <h4>Added:</h4>
              <ul>
                <li>- Change log</li>
              </ul>
              <h4>Removed:</h4>
              <ul>
                <li>- Content goes here</li>
              </ul>
              <br /> <hr /> <br />
             * 
             * 
            */}
          <div className="block w-full px-5 mb-5">
            <Paragraph>
              <u>
                <h3>26 April 2024</h3>
              </u>
              <h4>Changed:</h4>
              <ul>
                <li>
                  - Dashboard table bug fix of table not showing first page
                  after loading more data.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>11 April 2024</h3>
              </u>
              <h4>Changed:</h4>
              <ul>
                <li>
                  - Dashboard filtering now works with long date range with
                  minimal wait time for results.
                </li>
                <li>
                  - Campaigns Filter submission 'From Previous' page UI
                  refreshed.
                </li>
                <li>
                  - Audit trail details now show information for most activities
                  instead of only for filter searches.
                </li>
              </ul>
              <br />
              <h4>Added:</h4>
              <ul>
                <li>
                  - Dashboard now includes a new column 'Source Type' to
                  differentiate where the data originate from. Source Type is
                  also a new option on filter options.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>27 November 2023</h3>
              </u>
              <h4>Added:</h4>
              <ul>
                <li>
                  - Dashboard filter for coordinates minimum lat/long decimal
                  places for results. Defaults to at least 4 decimal places on
                  page load.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>04 September 2023</h3>
              </u>
              <h4>Added:</h4>
              <ul>
                <li>- "Uploaded At" column at Events Record table</li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>31 July 2023</h3>
              </u>
              <h4>Fixed:</h4>
              <ul>
                <li>
                  - Added IP Address field to custom filter parameter dropdown.
                </li>
              </ul>
              <br />
              <h4>Added:</h4>
              <ul>
                <li>
                  - Audit Trail now tracks filter search options submitted. Note
                  that default filter search or filter only involving date range
                  is not recorded.
                </li>
                <li>
                  - Added Password Update functionality on User Profile page.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>24 Jun 2023</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Migrate to Singlestore database for faster compute-heavy
                  queries.
                </li>
                <li>
                  - Free Search Functionality: able to search for all other
                  columns for matching values.
                </li>
                <li>- Color picker for clusters.</li>
                <li>
                  - Pagination and UI improvements for cluster tag and alias
                  modules.
                </li>
                <li>- UI improvements for listing previous campaign lists.</li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>09 May 2023</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li>- Increased map point size.</li>
                <li>
                  - Map point popup will open upon mouseover, rather than on
                  click.
                </li>
                <li>
                  - Map will not load upon page load. A new "Load Map" button is
                  added to trigger map load. Map will also load automatically
                  upon applying Filter Options
                </li>
                <li>
                  - Updated color palette for map points to be more contrasting
                  on map.
                </li>
                <li>
                  - Ordering of clusters on map checkboxes selection depriortise
                  Unclustered on map load.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>28 April 2023</h3>
              </u>
              <h4>Fixed:</h4>
              <ul>
                <li>
                  - Fixed map lag issue. It was showing all data map points
                  regardless of Maximum point limit input.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>09 March 2023</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Map now loads progressively as more data is retrieved. Map
                  points will update as data comes in.
                </li>
              </ul>
              <br />
              <h4>Added:</h4>
              <ul>
                <li>
                  - Dashboard map indicator of map points loading, how much
                  records are processed and plotted, as well as button to
                  trigger more map points to be retrieved and plotted.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>28 Feburary 2023</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li> - Alerts renamed to Detection Alerts</li>
                <li> - Detection Alerts can be tagged with a Cluster Tag</li>
              </ul>
              <br />
              <h4>Added:</h4>
              <ul>
                <li> - Threshold Alerts and Alert Rules</li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>18 November 2022</h3>
              </u>
              <h4>Added:</h4>
              <ul>
                <li>
                  - New feature to draw custom Rectangle on map, which will add
                  to the Filter Options as a coordinates range. Note that only 1
                  Rectangle can be draw at a time. To remove the coordinates
                  range, simply remove the Rectangle on the map via the
                  controls.
                </li>
              </ul>
              <br />
              <br />
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Map Clusters overlay filtering now shows color for each
                  Cluster.
                </li>
                <li>- Map LOIs overlay now hides by default when page load.</li>
                <li>
                  - Map LOIs overlay now shows radius range for LOIs that are
                  specified on Filter Options.
                </li>
                <li>
                  - Clusters colors palette is refreshed to use more distinct
                  colors for easier differentiation between clusters.
                </li>
                <li>
                  - Each node on map now varies in size and opacity depending on
                  data values.
                </li>
                <li>- Updated Useful Tips section with more info.</li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>03 Feburary 2023</h3>
              </u>
              <h4>Fixed:</h4>
              <ul>
                <li>
                  - Fixed a bug where if Filtering Option only chosen option is
                  toggled as Non-Intersection, will cause API call error.
                </li>
              </ul>
              <br />
              <br />
              <h4>Added:</h4>
              <ul>
                <li>
                  - Added dashboard feature that allows zooming to location when
                  clicking on each event record entry.
                </li>
              </ul>
              <br />
              <br />
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Revamped Filter Options to become a toggable panel using a
                  button located at the left side of screen.
                </li>
                <li>
                  - Replacing the Intersction/Union button, users can now choose
                  the column filters which they want intersecting. If the
                  intersect button is toggled off, then the column would no
                  longer intersect with the other columns and would then bahave
                  like a union query.
                </li>
                <li>
                  - Drawing custom Rectangle on map is now called Area of
                  Interest (AOI). Instead of a single AOI, users can now draw
                  multiple AOIs and have the option to toggle Intersection query
                  for the drawn AOIs.
                </li>
                <li>
                  - Overall Dashboard performance has been improved. Users
                  should notice lesser lag between clicks now.
                </li>
                <li>
                  - Updated Dashboard "Useful Tips" section to reflect the
                  latest changes made.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>18 November 2022</h3>
              </u>
              <h4>Added:</h4>
              <ul>
                <li>
                  - New feature to draw custom Rectangle on map, which will add
                  to the Filter Options as a coordinates range. Note that only 1
                  Rectangle can be draw at a time. To remove the coordinates
                  range, simply remove the Rectangle on the map via the
                  controls.
                </li>
              </ul>
              <br />
              <br />
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Map Clusters overlay filtering now shows color for each
                  Cluster.
                </li>
                <li>- Map LOIs overlay now hides by default when page load.</li>
                <li>
                  - Map LOIs overlay now shows radius range for LOIs that are
                  specified on Filter Options.
                </li>
                <li>
                  - Clusters colors palette is refreshed to use more distinct
                  colors for easier differentiation between clusters.
                </li>
                <li>
                  - Each node on map now varies in size and opacity depending on
                  data values.
                </li>
                <li>- Updated Useful Tips section with more info.</li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>09 November 2022</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li>- Dashboard metric values updated</li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>7 November 2022</h3>
              </u>
              <h4>Fixed:</h4>
              <ul>
                <li>
                  - Submission of campaigns with overlapping IFAs no longer
                  throws bug.
                </li>
                <li>
                  - Fix filters to allow a large amount of filter parameters
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>5 November 2022</h3>
              </u>
              <h4>Fixed:</h4>
              <ul>
                <li>
                  - Map now shows different colors across different clusters
                  accurately
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>28 October 2022</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Map Visualization's "Fit Points to View" button will now fit
                  currently checked clusters on map only.
                </li>
                <li>
                  - Events Record table to show 1000 rows on page load instead
                  of 50.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>26 October 2022</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Added option for 10000 rows at a time for Events Record
                  table.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>18 October 2022</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Increased timeout for API requests and updated the
                  notification when this timeout error occurs to be more
                  detailed. When timeout error occurs, the map's data will now
                  reset to prevent confusion.
                </li>
                <li>
                  - Dashboard stats were updated to be static and no longer
                  updates when filters change as queries over a long period of
                  datetime for these stats is using too much compute resource.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>16 October 2022</h3>
              </u>
              <h4>Modified:</h4>
              <ul>
                <li>
                  - Map Chart - "Maximum points on map" option changed from
                  dropdown list to text field.
                </li>
                <li>
                  - Events Record - "Entries Per Page" added new options: 500
                  and 1000 entries per page.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>11 October 2022</h3>
              </u>
              <h4>Fixed:</h4>
              <ul>
                <li>
                  - Improved error message presentation for data setting
                  campaign submission. Invalid location coordinates will be
                  flagged to user.
                </li>
              </ul>
              <br />
              <h4>Added:</h4>
              <ul>
                <li>
                  - Added change log pop up to notify and track changes made to
                  the application.
                </li>
              </ul>
              <br /> <hr /> <br />
              <u>
                <h3>10 October 2022</h3>
              </u>
              <h4>Fixed:</h4>
              <ul>
                <li>- Resolved locations not being able plotted on Map UI.</li>
              </ul>
              <br /> <hr /> <br />
            </Paragraph>
          </div>
        </Modal.Body>

        <Modal.Footer showBorder>
          <div className="block w-full text-center">
            <Modal.Toggle>
              <StyledButton
                variant={ElementVariant.primaryAltTwo}
                className="mr-5"
              >
                Close
              </StyledButton>
            </Modal.Toggle>
          </div>
        </Modal.Footer>
      </Modal.Display>
    </Modal>
  );
};

export default ChangelogModal;
