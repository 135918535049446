import { Formik } from 'formik';
import {
  BadgeBoolean,
  confirmAlert,
  ListDeleteButton,
  ListEditButton,
  PageSubDescription,
  Pagination,
  ReactTable,
  SearchForm,
  StyledBtnRouterLink,
  StyledButton,
  Tooltip,
  WithLoadingDiv
} from '../../../components';
import * as Yup from 'yup';
import {
  ElementBgClassnames,
  ElementTextClassnames,
  ElementVariant
} from '../../../interfaces';
import {
  Fragment,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react';
import { toastHandler } from '../../../utils';
import apiAgent from '../../../api/apiAgent';
import { string } from 'yup/lib/locale';
import { FLASH_MESSAGES } from '../../../cnstants';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const ThresholdAlertRules = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [thresholdAlertRules, setThresholdAlertRules] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const [paginationInfo, setPaginationInfo] = useState<any>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 12,
    totalPages: 0
  });

  const { totalPages, totalItems, currentPageCount, itemsPerPage } =
    paginationInfo;

  const fetchThresholdAlertRules = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setIsLoading(true);
      //fetch from backend
      const result = await apiAgent.ThresholdAlertRules.getThresholdAlertRules({
        searchText: searchText !== '' ? searchText : undefined,
        page,
        limit
      });
      const { thresholdAlertRules } = result.data.data;
      const pageInfo = result.data.meta;
      setPaginationInfo({
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        itemsPerPage: limit,
        totalPages: pageInfo.totalPages
      });
      setThresholdAlertRules(thresholdAlertRules);
    } catch (error: any) {
      console.log(error);
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchThresholdAlertRules({
      page: 1,
      limit: paginationInfo.itemsPerPage
    });
  }, [paginationInfo.itemsPerPage, searchText]);

  const formikSchema = {
    initialValues: {
      searchText: ''
    },
    validationSchema: Yup.object({
      searchText: Yup.string()
    }),
    onSubmit: async (values: any) => {
      setSearchText(values.searchText);
      setPaginationInfo({
        ...paginationInfo,
        currentPageCount: 1
      });
    },
    validateOnChange: true
  };

  const deleteTableRow = (rowIndex: number) => () => {
    const dataCopy = [...thresholdAlertRules!];
    dataCopy.splice(rowIndex, 1);
    setThresholdAlertRules(dataCopy);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
        thClassName: ElementTextClassnames.sysGenId
      },
      {
        Header: 'Location ID',
        accessor: 'locationId',
        thClassName: ElementTextClassnames.primary
      },
      {
        Header: 'Basline',
        accessor: 'baseline',
        thClassName: ElementTextClassnames.primary
      },
      {
        Header: 'Threshold (%)',
        accessor: 'varianceLimitPercentage',
        thClassName: ElementTextClassnames.primary
      },
      {
        Header: 'Active?',
        accessor: 'status',
        Cell: ({ row }: any) => {
          return <BadgeBoolean value={row.values.status} />;
        }
      },
      {
        Header: 'Latest Alert',
        Cell: ({ row }: any) => {
          const thresholdAlertRule = row.original;
          const thresholdAlert = row.original.thresholdAlert;
          if (!thresholdAlert) {
            return <div></div>;
          }
          const time = moment(thresholdAlert.createdAt);
          const timeStr = time.format('DD/MM/YYYY hh:mm A');
          const increaseStr = thresholdAlert.isIncreased
            ? 'Increase'
            : 'Decrease';
          return (
            <h1 className=" text-wrap">{`${timeStr} - ${increaseStr} of > ${thresholdAlert.varianceLimitPercentage}% of baseline (${thresholdAlert.baseline}) at ${thresholdAlert.locationId}`}</h1>
          );
        }
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <div className="flex justify-center">
            <Fragment>
              <StyledBtnRouterLink
                to={`/alerts?tab=threshold&alert-rule-name=${row.original.name}`}
                variant={ElementVariant.primaryAltTwo}
                className="mr-1"
              >
                View Alerts
              </StyledBtnRouterLink>
              <EditButton alertId={row.original.id} />
              <DeleteButtonWithState
                thresholdAlertRuleId={row.original.id}
                afterFunc={deleteTableRow(row.index)}
              />
            </Fragment>
          </div>
        )
      }
    ],
    [JSON.stringify(thresholdAlertRules)]
  );

  return (
    <Fragment>
      <div className="flex justify-between mb-4 items-center">
        <div className="">
          <PageSubDescription>
            Manage rules for generating Threshold Alerts
            <Tooltip>
              Threshold alerts are generated when the difference between
              actual number of unique IFAs detected and the baseline in the specified
              location exceeds the specified threshold difference.
            </Tooltip>
          </PageSubDescription>
        </div>
        <div className="flex">
          <div className="flex justify-end items-start">
            <Formik {...formikSchema}>
              {(formikProps) => (
                <SearchForm
                  {...formikProps}
                  placeholder="e.g. name"
                  currentSearch={searchText}
                />
              )}
            </Formik>
          </div>
          <div className="text-right ml-4">
            <StyledBtnRouterLink
              to={'/alert-rules/threshold/new'}
              variant={ElementVariant.primaryAltTwo}
              className="text-sm mb-5"
            >
              Add New Alert Rule
            </StyledBtnRouterLink>
          </div>
        </div>
      </div>
      <WithLoadingDiv isLoading={isLoading}>
        {thresholdAlertRules ? (
          <Fragment>
            <div className="-mx-5 mb-5 overflow-y-auto">
              <ReactTable columns={columns} data={thresholdAlertRules} />
            </div>
          </Fragment>
        ) : (
          <div className="text-3xl text-center my-10">No results found</div>
        )}
      </WithLoadingDiv>
      {thresholdAlertRules && (
        <Pagination
          totalPages={totalPages}
          totalItems={totalItems}
          currentPageCount={currentPageCount}
          itemsPerPage={itemsPerPage}
          fetchData={fetchThresholdAlertRules}
        />
      )}
    </Fragment>
  );
};

const DeleteButtonWithState = (props: {
  thresholdAlertRuleId: number;
  afterFunc: Function;
}): JSX.Element => {
  const { thresholdAlertRuleId, afterFunc } = props;
  const [isLoading, setIsLoading] = useState(false);

  const deleteThresholdAlertRule =
    (id: number): Function =>
    async () => {
      try {
        setIsLoading(true);
        const result =
          await apiAgent.ThresholdAlertRules.deleteThresholdAlertRule(
            thresholdAlertRuleId
          );
        if (result.data.status === 'success') {
          toastHandler({
            messages: `Threshold Alert Rule ${result?.data?.data?.thresholdAlertRule?.name} deleted successfully.`,
            toastOptions: { type: 'info' }
          });

          afterFunc();
        } else {
          throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
        }
      } catch (error: any) {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      } finally {
        setIsLoading(false);
      }
    };

  const handleOnDelete =
    (id: number): MouseEventHandler<HTMLButtonElement> =>
    (event: any) => {
      event.preventDefault();

      confirmAlert({
        title: 'Confirm remove threshold alert rule',
        message: confirmationMessage(id),
        buttons: [
          {
            onClick: deleteThresholdAlertRule(id)
          }
        ]
      });
    };

  return (
    <ListDeleteButton
      itemLabel="Alert Rule"
      loading={isLoading}
      onClick={handleOnDelete(thresholdAlertRuleId)}
    />
  );
};

const confirmationMessage = (alertRuleId: number): JSX.Element => {
  return (
    <div className="mb-1" key={alertRuleId}>
      Are you sure you want to delete this Threshold Alert Rule? This process
      cannot be undone.
    </div>
  );
};

const EditButton = (props: { alertId: number }): JSX.Element => {
  const navigate = useNavigate();
  const { alertId } = props;

  const handleOnEdit = (id: number) => (event: any) => {
    event.preventDefault();
    navigate(`/alert-rules/threshold/${id}/edit`);
  };

  return (
    <ListEditButton itemLabel="Alert Rule" onClick={handleOnEdit(alertId)} />
  );
};

export default ThresholdAlertRules;
