import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  Container,
  FlashMessage,
  MainPanel,
  PageSubDescription,
  PageTitle
} from '../../../components';
import {
  ElementBgClassnames,
  ElementTextClassnames,
  ElementVariant,
  IFlashContainer
} from '../../../interfaces';
import AlertRuleForm from './components/AlertRuleForm';
import apiAgent from '../../../api/apiAgent';
import { formValidation, toastHandler } from '../../../utils';
import { FLASH_MESSAGES, HTTP_ERROR_CODES } from '../../../cnstants';

const AlertRulesCreate = (): JSX.Element => {
  const navigate = useNavigate();
  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });
  const [error, setError] = useState<any>();

  const createAlertRule = async (params: {
    name: string;
    locations: string[];
    devices: string[];
    status: boolean;
  }): Promise<boolean> => {
    try {
      const result: any = await apiAgent.AlertRules.createAlertRule(params);

      if (result.data.status === 'success') {
        toastHandler({
          messages: 'Alert rule created successfully.',
          toastOptions: { type: 'success' }
        });
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }

      return true;
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.BAD_REQUEST) {
        let data = error?.response?.data?.data;
        let message: any = '';
        if (data.duplcateNames && data?.duplicateNames?.length > 0) {
          message = 'alert name in use';
          setError(data);
        } else {
          message = error?.response?.data?.message;
        }

        setFlashContainer({
          messages: message,
          variant: ElementVariant.danger
        });
        toastHandler({
          messages: message,
          toastOptions: { type: 'error' }
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }

      return false;
    }
  };

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {
      name: '',
      deviceValues: [],
      ifas: '',
      locationValues: [],
      locations: '',
      isActive: true,
      withClusterTag: true,
      clusterTag: '',
      withClusterTagDeviceValues: []
    },
    validationSchema: Yup.object().shape(
      {
        name: Yup.string()
          .matches(
            /^[a-zA-Z0-9-_]+$/,
            'Name can only use alphanumeric characters without spaces'
          )
          .required('Alert rule name required')
          .trim(),
        withClusterTag: Yup.boolean().required(),
        // clusterTag: Yup.string()
        //   .trim()
        //   .when('withClusterTag', {
        //     is: true,
        //     then: Yup.string().required('Select one cluster tag.'),
        //     otherwise: Yup.string().notRequired()
        //   }),
        // withClusterTagDeviceValues: Yup.array()
        //   .of(Yup.string())
        //   .when('withClusterTag', {
        //     is: false,
        //     then: Yup.array().of(Yup.string()).notRequired(),
        //     otherwise: Yup.array()
        //       .of(Yup.string())
        //       .min(1, 'Select at least one IFA.')
        //       .required('Select at least one IFA.')
        //   }),
        // deviceValues: Yup.array()
        //   .of(
        //     Yup.object({
        //       label: Yup.string().required(),
        //       value: Yup.object().shape({
        //         value: Yup.string(),
        //         groupLabel: Yup.string()
        //       })
        //     })
        //   )
        //   .when(['ifas', 'withClusterTag'], {
        //     //when withClusterTag = true OR ifas is not an empty string THEN this property is not required
        //     is: (ifas: string, withClusterTag: boolean) =>
        //       withClusterTag || (ifas && ifas !== ''),
        //     then: Yup.array()
        //       .of(
        //         Yup.object({
        //           label: Yup.string().required(),
        //           value: Yup.object()
        //             .shape({ value: Yup.string(), groupLabel: Yup.string() })
        //             .required()
        //         })
        //       )
        //       .notRequired(),
        //     otherwise: Yup.array()
        //       .of(
        //         Yup.object({
        //           label: Yup.string().required(),
        //           value: Yup.object()
        //             .shape({ value: Yup.string(), groupLabel: Yup.string() })
        //             .required()
        //         })
        //       )
        //       .required('At least one IFA required.')
        //   }),
        // ifas: Yup.string()
        //   .trim()
        //   .matches(
        //     /^[a-zA-Z0-9-_\n]+$/,
        //     'Device ifas should only contain alphanumeric or dashes or underscore characters without spaces'
        //   )
        //   .when(['deviceValues', 'withClusterTag'], {
        //     //when withClusterTag = true OR deviceValue is not empty
        //     is: (devicesValue: any, withClusterTag: boolean) =>
        //       withClusterTag || (devicesValue && devicesValue.length) > 0,
        //     then: Yup.string().notRequired(),
        //     otherwise: Yup.string().required('At least one IFA required.')
        //   }),
        clusterTag: Yup.string().trim(),
        withClusterTagDeviceValues: Yup.array().of(Yup.string()),

        deviceValues: Yup.array().of(
          Yup.object({
            label: Yup.string().required(),
            value: Yup.object().shape({
              value: Yup.string(),
              groupLabel: Yup.string()
            })
          })
        ),
        ifas: Yup.string()
          .trim()
          .matches(
            /^[a-zA-Z0-9-_\n]+$/,
            'Device ifas should only contain alphanumeric or dashes or underscore characters without spaces'
          ),
        locationValues: Yup.array()
          .of(
            Yup.object({
              label: Yup.string().required(),
              value: Yup.string().required()
            })
          )
          .when('locations', {
            is: (locations: string) => locations && locations.length > 0,
            then: Yup.array()
              .of(
                Yup.object({
                  label: Yup.string().required(),
                  value: Yup.string().required()
                })
              )
              .notRequired(),
            otherwise: Yup.array()
              .of(
                Yup.object({
                  label: Yup.string().required(),
                  value: Yup.string().required()
                })
              )
              .required('At least one location required.')
          })
          .required('At least one location required.')
          .min(0),
        locations: Yup.string()
          .trim()
          .matches(
            /^[a-zA-Z0-9-_\n]+$/,
            'Location ids should only contain alphanumeric or dashes or underscore characters without spaces'
          )
          .when('locationValues', {
            is: (locationValue: any) =>
              locationValue && locationValue.length > 0,
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('At least one location required.')
          }),
        isActive: Yup.boolean().required('Is active required')
        // trackNonDoi: Yup.boolean().required()
      },
      [
        ['deviceValues', 'ifas'],
        ['locationValues', 'locations']
      ]
    ),
    onSubmit: async ({ name, ...values }: any, { resetForm }: any) => {
      let params: any = {
        name: name,
        devices: values.withClusterTag
          ? // if withClusterTag === true, then only use withClusterTagDeviceValues
            values.withClusterTagDeviceValues
          : // else use the deviceValues together with the textarea values
            values.deviceValues
              .map((option: any) => option.value.value)
              .concat(
                values.ifas.split('\n').filter((str: string) => str !== '')
              ),
        locations: values.locationValues
          .map((option: any) => option.value)
          .concat(
            values.locations.split('\n').filter((str: string) => str !== '')
          ),
        status: values.isActive,
        clusterTag: values.withClusterTag ? values.clusterTag : null
      };

      //call backend
      let flag: any;
      flag = await createAlertRule(params);
      // console.log(params);
      if (flag) {
        resetForm();
        navigate('/alert-rules');

        setFlashContainer({
          messages: '',
          variant: undefined
        });
      }
    },
    validateOnChange: true
  };

  return (
    <Container>
      <MainPanel className="mb-6">
        <PageTitle>Add Alert Rule</PageTitle>
        <PageSubDescription>
          Alerts will be generated when the IFAs (or any IFA, when no IFAs are
          specified) enter/exit the locations.
        </PageSubDescription>
        {(flashContainer.messages || []).length > 0 && (
          <FlashMessage
            messages={flashContainer?.messages || ''}
            variant={flashContainer.variant}
          />
        )}
        {error && (
          <div className={`${ElementBgClassnames.danger} mt-2 rounded p-2`}>
            <div>
              {error.duplicateNames.length > 0 ? 'Alert name in use' : ''}
            </div>
            {error.conflictingValues.map((conflictingValue: any) => {
              return (
                <div>{`Conflicting with Alert Rule(${
                  conflictingValue.name
                }), IFAs: ${conflictingValue.devices.join(
                  ','
                )}, Locations: ${conflictingValue.locations.join(',')}`}</div>
              );
            })}
          </div>
        )}
        <Formik {...formikSchema}>
          {(formikProps) => (
            <div>
              <AlertRuleForm {...formikProps} />
              {/* <div>{JSON.stringify(formikProps.values)}</div> */}
            </div>
          )}
        </Formik>
      </MainPanel>
    </Container>
  );
};

export default AlertRulesCreate;
