import { Fragment, Ref } from 'react';
import classNames from 'classnames/bind';
import { ErrorMessage } from 'formik';
import _ from 'lodash';

import {
  IErrorInput,
  ElementTextClassnames,
  ElementBgClassnames
} from '../../interfaces';

interface IInput
  extends React.InputHTMLAttributes<HTMLInputElement>,
    IErrorInput {
  withFormikError?: boolean;
  myRef?: any;
}

const Input = ({
  myRef,
  withFormikError = false,
  error,
  className,
  ...restProps
}: IInput): JSX.Element => (
  <Fragment>
    <input
      ref={myRef}
      className={classNames(
        className,
        'leading-none w-full block rounded-md border border-transparent focus:border-gray-300 focus:ring-0 focus:text-white',
        ElementBgClassnames.primary,
        {
          'border-red-300 border-2': error,
          [ElementTextClassnames.danger]: error
        }
      )}
      autoComplete="off"
      {...restProps}
    />
    {withFormikError && (
      <ErrorMessage
        name={restProps.name || ''}
        render={(msg: string) => (
          <div
            className={classNames(ElementTextClassnames.danger, 'block mt-1')}
          >
            {_.upperFirst(_.toLower(_.startCase(msg)))}
          </div>
        )}
      />
    )}
  </Fragment>
);

export default Input;
