import { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import apiAgent from '../../api/apiAgent';
import { jwtCookie, clearJwtCookie, hasJwtCookie } from '../../auth';
import { ElementVariant, IFlashContainer } from '../../interfaces';
import { toastHandler } from '../../utils';
import { Form, MainPanel, FlashMessage, SmallLoader } from '../../components';
import { IUserLoginParams } from '../../interfaces/api';
import { HTTP_ERROR_CODES, FLASH_MESSAGES, APP_VERSION } from '../../cnstants';
import { fetchUserProfileAtom, siteSettingAtom } from '../../atoms';
import Cookies from 'js-cookie';
import Cookie from '../../auth/Cookie';

interface ILoginProps extends FormikProps<any> {}

const LoginForm = (props: ILoginProps): JSX.Element => {
  const {
    isSubmitting,
    // values,
    // touched,
    // errors,
    handleChange,
    handleBlur,
    handleSubmit
  } = props;

  // Showing an example of errorList
  // Use this if u want to show errors at the top
  // const errorList = formValidation.getErrors(errors);

  return (
    <Form
      onSubmit={handleSubmit}
      submitting={isSubmitting}
      // errors={errorList}
      submitLabel="Login"
      className="justify-center"
    >
      <Form.Section>
        <Form.Element>
          <div className="h-14">
            <Form.Input
              type="text"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              withFormikError={true}
              className="bg-button-base"
            />
          </div>
        </Form.Element>
        <Form.Element>
          <div className="h-14">
            <Form.Input
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              withFormikError={true}
              className="bg-button-base"
            />
          </div>
        </Form.Element>
      </Form.Section>
    </Form>
  );
};

const SysLoginPage = (): JSX.Element => {
  const [siteSetting] = useAtom(siteSettingAtom);
  const [, fetchUserProfile] = useAtom(fetchUserProfileAtom);

  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });
  const navigate = useNavigate();

  if (hasJwtCookie()) {
    return <Navigate to={{ pathname: '/home' }} />;
  }

  const loginWithEmailPassword = async (
    formData: IUserLoginParams
  ): Promise<void> => {
    try {
      const result: any = await apiAgent.Auth.login({
        ...formData,
        password: formData.password
      });

      if (result.data.status === 'success') {
        if (result.data.data.uid && result.data.data.loginToken) {
          navigate(
            `/auth/check-otp-setup?uid=${encodeURIComponent(
              result.data.data.uid
            )}&login-token=${result.data.data.loginToken}`
          );
        } else {
          toastHandler({
            messages: result.data.message,
            toastOptions: { type: 'success' }
          });
          
          jwtCookie.set(
            JSON.stringify({
              accessToken: result.data.data.accessToken,
              refreshToken: result.data.data.refreshToken
            })
          );

          await fetchUserProfile();

          navigate('/home');
        }
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }
    } catch (error: any) {
      clearJwtCookie();

      if (error?.response?.status === HTTP_ERROR_CODES.UNPROCESSABLE_ENTITY) {
        setFlashContainer({
          messages: error?.response?.data?.message,
          variant: ElementVariant.danger
        });
      } else {
        toastHandler({
          messages: error.message,
          toastOptions: { type: 'error' }
        });
      }
    }
  };

  const formikSchema = {
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('email is required'),
      password: Yup.string().required('password is required')
    }),
    onSubmit: async (values: any) => {
      await loginWithEmailPassword({
        email: values.email,
        password: values.password
      });
    },
    validateOnChange: true
  };

  return (
    <div className="w-full h-screen text-text-primary">
      <MainPanel
        className="flex relative justify-center content-center w-full h-full bg-opacity-0"
        padding={false}
      >
        <div className="text-center w-80 h-auto mt-16">
          <div className="mb-7">
            <div className="flex justify-center text-2xl mb-7">Sign in</div>
          </div>
          <div className="block text-gray-primary my-5">
            {siteSetting?.loginMessage ? (
              siteSetting.loginMessage
            ) : (
              <SmallLoader />
            )}
          </div>
          {(flashContainer.messages || []).length > 0 && (
            <FlashMessage
              messages={flashContainer?.messages || ''}
              variant={flashContainer.variant}
            />
          )}
          <Formik {...formikSchema}>
            {(formikProps) => <LoginForm {...formikProps} />}
          </Formik>
        </div>
        <div className="absolute right-0 bottom-0 p-2 text-xs">{`v${APP_VERSION}`}</div>
      </MainPanel>
    </div>
  );
};

export default SysLoginPage;
