import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import ReactEChart from 'echarts-for-react';

import apiAgent from '../../../api/apiAgent';
import { WithLoadingDiv } from '../../../components';
import GridChild from '../../../components/GridChild';
import { toastHandler } from '../../../utils';
import { ElementTextClassnames } from '../../../interfaces';
import classNames from 'classnames';

// const rawData = [
//   ['hour_of_day', 'count', 'country_id'],
//   [0, 4, 'ID'],
//   [0, 5, 'MY'],

//   [1, 3, 'ID'],
//   [1, 5, 'MY'],
//   [1, 1, 'SG'],

//   [2, 10, 'ID'],
//   [2, 8, 'MY'],
//   [2, 1, 'SG'],

//   [3, 9, 'ID'],
//   [3, 6, 'MY'],
//   [3, 1, 'SG'],

//   [4, 7, 'ID'],
//   [4, 15, 'MY'],
//   [4, 1, 'SG'],

//   [15, 20, 'MY'],
//   [15, 1, 'SG'],

//   [16, 25, 'ID'],
//   [16, 12, 'MY'],
//   [16, 5, 'SG'],

//   [17, 2, 'ID'],
//   [17, 23, 'MY'],

//   [18, 6, 'ID'],
//   [18, 16, 'MY'],
//   [18, 1, 'SG'],

//   [19, 4, 'ID'],
//   [19, 14, 'MY'],
//   [19, 1, 'SG'],

//   [20, 5, 'ID'],
//   [20, 13, 'MY'],
//   [20, 13, 'SG'],

//   [21, 3, 'ID'],
//   [21, 4, 'MY'],
//   [21, 13, 'SG'],

//   [22, 5, 'ID'],
//   [22, 7, 'MY'],
//   [22, 13, 'SG'],

//   [23, 8, 'ID'],
//   [23, 12, 'MY'],
//   [23, 13, 'SG']
// ];

const transformDataForChart = (data: any[]): any[][] => {
  return [
    ['hour_of_day', 'count', 'country_id'],
    ...data.map((val: any) => [val.hour_of_day, val.count, val.country_id])
  ];
};

const LineChart = ({
  searchText,
  searchClusterTags
}: {
  searchText?: string;
  searchClusterTags?: string[];
}): JSX.Element => {
  const {
    isSuccess,
    isLoading,
    isError,
    error,
    data: lineChartData,
    refetch
  }: {
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    error: any;
    data: any;
    refetch: VoidFunction;
  } = useQuery(
    ['activeTargetedIfasTimePeriodCount'],
    async () => {
      const { data } =
        await apiAgent.Stats.getActiveTargetedIfasTimePeriodCount({
          searchText,
          searchClusterTags
        });
      return transformDataForChart(data.data.countList || []);
    },
    { staleTime: 60 * 1000, cacheTime: 60 * 1000, retry: false }
  );

  if (isError) {
    toastHandler({
      messages: error?.message,
      toastId: error?.code,
      toastOptions: { type: 'error' }
    });
  }

  useEffect(() => {
    refetch();
  }, [searchText, JSON.stringify(searchClusterTags), refetch]);

  const eChartsOption = {
    dataset: [
      {
        id: 'dataset_raw',
        source: lineChartData || [['hour_of_day', 'count', 'country_id'], []]
      },
      {
        id: 'dataset_for_ID',
        fromDatasetId: 'dataset_raw',
        transform: {
          type: 'filter',
          config: {
            and: [{ dimension: 'country_id', '=': 'ID' }]
          }
        }
      },
      {
        id: 'dataset_for_MY',
        fromDatasetId: 'dataset_raw',
        transform: {
          type: 'filter',
          config: {
            and: [{ dimension: 'country_id', '=': 'MY' }]
          }
        }
      },
      {
        id: 'dataset_for_SG',
        fromDatasetId: 'dataset_raw',
        transform: {
          type: 'filter',
          config: {
            and: [{ dimension: 'country_id', '=': 'SG' }]
          }
        }
      }
    ],
    grid: {
      left: '5%',
      right: '5%',
      top: '7%',
      bottom: '20%',
      containLabel: false
    },
    xAxis: {
      // name of X Axis
      name: 'Hour of Day',
      nameTextStyle: {
        color: '#fff',
        padding: [20, 0, 0, 0]
      },
      type: 'category',
      // Contains the values for XAxis
      data: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24
      ],
      nameLocation: 'middle',
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 45,
        color: '#fff'
      }
    },
    yAxis: {
      // name of Y Axis
      name: 'Unique IFAs',
      nameTextStyle: {
        color: '#fff'
      },
      type: 'value',
      axisLabel: {
        show: true,
        interval: 0,
        color: '#fff'
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(226,232,240,0.1)'
        }
      }
    },
    //To enable tooltips
    tooltip: {
      trigger: 'axis'
    },
    series: [
      {
        type: 'line',
        datasetId: 'dataset_for_ID',
        // showSymbol: false,
        encode: {
          x: 'hour_of_day',
          y: 'count',
          itemName: 'ID',
          tooltip: ['count']
        },
        lineStyle: {
          normal: {
            color: 'rgb(234,179,8)'
          }
        },
        label: {
          show: true,
          position: 'top',
          textStyle: {
            color: 'rgb(234,179,8)',
            fontSize: 10
          }
        }
      },
      {
        type: 'line',
        datasetId: 'dataset_for_MY',
        // showSymbol: false,
        encode: {
          x: 'hour_of_day',
          y: 'count',
          itemName: 'MY',
          tooltip: ['count']
        },
        lineStyle: {
          normal: {
            color: 'rgb(96,165,250)'
          }
        },
        label: {
          show: true,
          position: 'top',
          textStyle: {
            color: 'rgb(96,165,250)',
            fontSize: 10
          }
        }
      },
      {
        type: 'line',
        datasetId: 'dataset_for_SG',
        // showSymbol: false,
        encode: {
          x: 'hour_of_day',
          y: 'count',
          itemName: 'SG',
          tooltip: ['count']
        },
        lineStyle: {
          normal: {
            color: 'rgb(192,132,252)'
          }
        },
        label: {
          show: true,
          position: 'top',
          textStyle: {
            color: 'rgb(192,132,252)',
            fontSize: 10
          }
        }
      }
    ]
  };

  return (
    <GridChild
      className="col-span-6 h-[32rem]"
      title="Active Targeted IFAs Timeline"
    >
      <WithLoadingDiv isLoading={isLoading}>
        {isSuccess && (
          <ReactEChart
            style={{
              width: '100%',
              height: '100%'
            }}
            option={eChartsOption}
          />
        )}
        {isError && (
          <div
            className={classNames(ElementTextClassnames.danger, 'text-center')}
          >
            Error
          </div>
        )}
      </WithLoadingDiv>
    </GridChild>
  );
};

export default LineChart;
