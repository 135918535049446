import React, { Fragment } from 'react';
import { SmallLoader } from './';

/*
 * Doesn't work well with children components that hold their own state
 * Doesn't work with ReachPaginate in it
 */
const WithLoadingDiv = ({
  isLoading,
  children
}: {
  isLoading: boolean;
  children: React.ReactNode | string;
}): JSX.Element => {
  return isLoading ? (
    <div className="block text-center my-10">
      <SmallLoader className="text-2xl" />
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export { WithLoadingDiv };
