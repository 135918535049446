import React from 'react';
import classNames from 'classnames/bind';

import Dropdown from './Dropdown';
import Checkbox from './Checkbox';
// import RadioGroup from "components/common/RadioGroup";
import {
  FormLoader,
  FlashMessage,
  StyledBtnRouterLink,
  StyledButton
} from '../';
import {
  ElementTextClassnames,
  ElementVariant,
  FormType
} from '../../interfaces';
import FormSection from './Section';
import { MultiWrapper, SectionElement, Hint } from './SectionElement';
import Input from './Input';
import TextArea from './TextArea';
import SelectSpecial from './SelectSpecial';
import RichTextEditor from './RichTextEditor';

interface ITextProps extends React.HTMLAttributes<HTMLSpanElement> {
  warning?: boolean;
  children: React.ReactNode;
}

const Text = ({ warning, children }: ITextProps): JSX.Element => (
  <span
    className={classNames('block', {
      'text-white': !warning,
      [ElementTextClassnames.danger]: warning
    })}
  >
    {children}
  </span>
);

type FormChildren = { Section?: typeof FormSection } & {
  Text?: typeof Text;
} & {
  Multi?: typeof MultiWrapper;
} & { Element?: typeof SectionElement } & { Dropdown?: typeof Dropdown } & {
  Checkbox?: typeof Checkbox;
} & {
  Input?: typeof Input;
} & {
  TextArea?: typeof TextArea;
} & {
  Select?: typeof SelectSpecial;
} & {
  RichTextEditor?: typeof RichTextEditor;
} & { Hint?: typeof Hint };

type FormProps = {
  id?: string;
  showCardBG?: boolean;
  title?: string;
  description?: React.ReactNode;
  backLink?: string;
  backLabel?: string;
  children: React.ReactNode;
  submitLabel?: string;
  onSubmit?: (e: FormType) => void;
  loading?: boolean;
  submitting?: boolean;
  dangerArea?: {
    description: string;
    buttonLabel: string;
    onDelete: () => void;
    deleting?: boolean;
    warningSection?: any;
  } | null;
  noSubmitButton?: boolean;
  submitDisabled?: boolean;
  errors?: string[];
  className?: string;
  encType?: string;
};

const Form = ({
  id,
  children,
  submitLabel = 'Save',
  loading = false,
  submitting = false,
  noSubmitButton = false,
  submitDisabled = false,
  className,
  onSubmit,
  backLink,
  backLabel,
  errors,
  encType
}: FormProps & FormChildren): JSX.Element => {
  const handleSubmit = (e: FormType) => {
    e.preventDefault();
    if (!submitting) {
      onSubmit && onSubmit(e);
    }
  };
  return (
    <form
      id={id}
      className={className}
      onSubmit={handleSubmit}
      encType={encType}
    >
      <FormLoader isLoading={loading}>
        {errors && errors.length > 0 && (
          <FlashMessage messages={errors} focusOnDisplay />
        )}
        {children}
        {!noSubmitButton && (
          <StyledButton
            type="submit"
            loading={submitting}
            disabled={submitDisabled}
            variant={ElementVariant.success}
            className="px-10"
          >
            {submitLabel}
          </StyledButton>
        )}
        {backLink && (
          <StyledBtnRouterLink
            to={backLink!}
            variant={ElementVariant.primaryAltTwo}
            className="ml-5 text-sm mb-5"
          >
            Back to {backLabel}
          </StyledBtnRouterLink>
        )}
      </FormLoader>
    </form>
  );
};

Form.Section = FormSection;
Form.Text = Text;
Form.Multi = MultiWrapper;
Form.Element = SectionElement;
Form.Hint = Hint;
Form.Dropdown = Dropdown;
Form.Select = SelectSpecial;
Form.Checkbox = Checkbox;
// Form.RadioGroup = RadioGroup;
// Form.InputWithUnits = InputWithUnits;
Form.Input = Input;
Form.TextArea = TextArea;
Form.RichTextEditor = RichTextEditor;
// Form.ImageUpload = ImageUpload;

export default Form;
