import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { string } from 'yup';
import apiAgent from '../../../../api/apiAgent';
import { Form, Tooltip } from '../../../../components';
import SelectMultiWithCheckBox from '../../../../components/form/SelectMultiWithCheckbox';
import { ElementTextClassnames } from '../../../../interfaces';
import { toastHandler } from '../../../../utils';

interface IFormProps extends FormikProps<any> {
  isEdit?: boolean;
}

const AlertRuleForm = (props: IFormProps): JSX.Element => {
  const {
    isSubmitting,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isEdit
  } = props;

  const [deviceOptions, setDeviceOptions] = useState<any[]>([]);
  const [locationOptions, setLocationOptions] = useState<any>([]);

  const [clusterTagOptions, setClusterTagOptions] = useState<any[]>([]);

  const fetchLists = async () => {
    try {
      //devices options
      const clusterTags = await apiAgent.ClusterTags.getAllClusterTags();
      let newClusterTagOptions: any[] = [];

      clusterTags.data.data.clusterTags.forEach((clusterTag: any) => {
        newClusterTagOptions.push({
          label: clusterTag.clusterTagId,
          value: clusterTag.clusterTagId
        });
      });

      setClusterTagOptions(newClusterTagOptions);
      let newDeviceOptions: any[] = [];

      //get devices from cluster tags
      const aliases = await (
        await apiAgent.Alias.getAllAliases()
      ).data.data.aliases;

      clusterTags.data.data.clusterTags.forEach((clusterTag: any) => {
        newDeviceOptions.push({
          label: clusterTag.clusterTagId,
          options: clusterTag.devices.map((device: any): any => {
            const alias = aliases.find((e: any) => {
              return e.ifa === device.ifa;
            });
            return {
              label: `${device.ifa} ${alias ? `(${alias.alias})` : ''}`,
              value: { value: device.ifa, groupLabel: clusterTag.clusterTagId }
            };
          })
        });
      });

      setDeviceOptions(newDeviceOptions);

      //location options
      let result = await apiAgent.DeviceEvent.getLocationsList();
      let newLocationOptions: any[] = [];
      result.data.data.locations.forEach((location: any) => {
        newLocationOptions.push({
          label: location.locationId,
          value: location.locationId
        });
      });
      setLocationOptions(newLocationOptions);
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' }
      });
    } finally {
    }
  };

  useEffect(() => {
    fetchLists();
  }, []);

  const handleOnClusterTagSelect = async (field: string, value: string) => {
    await setFieldTouched(field);
    await setFieldValue(field, value);
    //reset devices values
    await setFieldValue('withClusterTagDeviceValues', []);
  };

  const getClusterTagDeviceOptions = () => {
    let result: any[] = [];
    for (const index in deviceOptions) {
      if (deviceOptions[index].label === values.clusterTag) {
        result = deviceOptions[index].options.map(
          (option: {
            label: string;
            value: { value: string; groupOption: string };
          }) => {
            return { label: option.label, value: option.value.value };
          }
        );
        break;
      }
    }
    return result;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      submitting={isSubmitting}
      submitLabel="Submit"
      backLabel="Alert Rules"
      backLink="/alert-rules"
    >
      {/* <Form.Section>
        <Form.Element label="" hint={``}>
          <div className="flex">
            <div className="text-text-primary mb-2 inline-block">
              Alert Rule Type
            </div>
            <Tooltip>
              <div>
                entry-exit: Generates an alert when the IFAs enters/ exits the
                locations.
              </div>
              <div>
                count-loi: Generates an alert at 15min intervals with the count
                of IFAs detected in the locations
              </div>
            </Tooltip>
          </div>
          <Form.Select
            name="alertRuleType"
            options={[
              { label: 'entry-exit', value: 'entry-exit' },
              { label: 'count-loi', value: 'count-loi' }
            ]}
            onBlur={setFieldTouched}
            onSelect={setFieldValue}
            value={values.alertRuleType}
          />
        </Form.Element>
      </Form.Section> */}
      <Form.Section>
        <Form.Element
          label="Name"
          hint="Name of rule is required and must be unique."
        >
          <Form.Input
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            withFormikError={true}
            value={values.name}
          />
        </Form.Element>
      </Form.Section>
      <Form.Section>
        <Form.Element>
          <Form.Checkbox
            label="with Cluster Tag"
            isChecked={values.withClusterTag}
            onCheck={handleChange}
            name="withClusterTag"
          />
        </Form.Element>
      </Form.Section>

      {/* <Form.Section>
        <Form.Element label="IFAs" hint="">
          <Form.Checkbox
            label="Track unknown IFA?"
            isChecked={values.trackNonDoi}
            onCheck={handleChange}
            name="trackNonDoi"
          />
          {!values.trackNonDoi && (
            <Form.TextArea
              name="ifas"
              onChange={handleChange}
              onBlur={handleBlur}
              withFormikError={true}
              value={values.ifas}
              placeholder="IFAs separated by new line"
            />
          )}
        </Form.Element>
      </Form.Section> */}
      {values.withClusterTag ? (
        <Form.Section>
          <Form.Element label="Cluster Tag" hint="">
            <Form.Select
              name="clusterTag"
              options={clusterTagOptions}
              onBlur={handleBlur}
              onSelect={handleOnClusterTagSelect}
              placeholder="Select Cluster Tag"
              value={values.clusterTag}
              withFormikError={true}
              onChange={handleChange}
            />
          </Form.Element>
          <Form.Element
            label="IFAs"
            hint="Select a Cluster Tag to populate the list."
          >
            <SelectMultiWithCheckBox
              options={getClusterTagDeviceOptions()}
              onChange={(value) => {
                setFieldValue(
                  'withClusterTagDeviceValues',
                  parseOptionTypeValuesToString(value)
                );
              }}
              values={parseStringValuesToOptionType(
                values.withClusterTagDeviceValues,
                getClusterTagDeviceOptions()
              )}
              allowSelectAll={true}
              placeholder="Select IFAs"
            />
            {errors.withClusterTagDeviceValues &&
              touched.withClusterTagDeviceValues && (
                <div className={`${ElementTextClassnames.danger}`}>
                  {errors.withClusterTagDeviceValues.toString()}
                </div>
              )}
          </Form.Element>
        </Form.Section>
      ) : (
        <Form.Section>
          <Form.Element label="IFAs" hint="">
            <div className="mb-2">
              <SelectMultiWithCheckBox
                options={deviceOptions}
                onChange={(e) => {
                  setFieldTouched('deviceValues');
                  setFieldValue('deviceValues', e);
                }}
                values={values.deviceValues}
                allowSelectAll={true}
                placeholder="Select IFAs"
              />
              {errors.deviceValues && touched.deviceValues && (
                <div>{errors.deviceValues.toString()} </div>
              )}
            </div>
            <Form.TextArea
              name="ifas"
              onChange={handleChange}
              onBlur={handleBlur}
              withFormikError={true}
              value={values.ifas}
              placeholder="IFAs separated by new line"
            />
          </Form.Element>
        </Form.Section>
      )}

      <Form.Section>
        <Form.Element label="Location IDs" hint="">
          <div className="mb-2">
            <SelectMultiWithCheckBox
              options={locationOptions}
              onChange={(e) => {
                setFieldTouched('locationValues');
                setFieldValue('locationValues', e);
              }}
              values={values.locationValues}
              allowSelectAll={true}
              placeholder="Select Locations"
            />
          </div>
          <Form.TextArea
            name="locations"
            onChange={handleChange}
            onBlur={handleBlur}
            withFormikError={true}
            value={values.locations}
            placeholder="Location IDs separeted by new line"
          />
        </Form.Element>
      </Form.Section>
      <Form.Section>
        <Form.Element>
          <Form.Checkbox
            label="Is Active?"
            isChecked={values.isActive}
            onCheck={handleChange}
            name="isActive"
          />
        </Form.Element>
      </Form.Section>
    </Form>
  );
};

type OptionType = {
  label: string;
  value: string;
};

const parseStringValuesToOptionType = (
  stringValues: string[],
  options: OptionType[]
) => {
  return stringValues.map((stringValue: string) => {
    for (const option of options) {
      if (option.value === stringValue) {
        return option;
      }
    }
  });
};

const parseOptionTypeValuesToString = (options: OptionType[]) => {
  return options.map((option: any) => option.value);
};

export default AlertRuleForm;
