import { useAtom } from 'jotai';
import React, { Fragment, memo } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import {
  mapIsLoadingAtom,
  otherParamsAtom,
  tableIsLoadingAtom
} from '../../../atoms/dashboardStore';
import {
  ListDeleteButton,
  PageSubTitle,
  StyledButton,
  WithLoadingDiv
} from '../../../components';
import Input from '../../../components/form/Input';
import SelectMultiWithCheckBox from '../../../components/form/SelectMultiWithCheckbox';
import TextArea from '../../../components/form/TextArea';
import {
  ElementBgClassnames,
  ElementBgClassnamesWithHover,
  ElementVariant
} from '../../../interfaces';
import { CoordinatesSearchParams } from '../../../interfaces/filterOptions';

interface IDeviceEventsFilters {
  coordinatesSearchParams?: any;
  setCoordinatesSearchParams?: React.Dispatch<any>;
  aoiInt: boolean;
  setAoiInt: React.Dispatch<React.SetStateAction<boolean>>;
  handleOnCancel: () => void;
  applyFilters: (params: any) => void;
  //states
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;

  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;

  locationFilterType: string;
  setLocationFilterType: React.Dispatch<React.SetStateAction<string>>;
  locationValues: any;
  setLocationValues: React.Dispatch<any>;
  locationOptions: any;
  setLocationOptions: React.Dispatch<any>;
  locationInt: any;
  setLocationInt: React.Dispatch<React.SetStateAction<boolean>>;

  deviceValues: any;
  setDeviceValues: React.Dispatch<any>;
  deviceOptions: any;
  setDeviceOptions: React.Dispatch<any>;
  deviceInt: boolean;
  setDeviceInt: React.Dispatch<React.SetStateAction<boolean>>;

  campaignValues: any;
  setCampaignValues: React.Dispatch<any>;
  campaignOptions: any;
  setCampaignOptions: React.Dispatch<any>;
  campaignInt: boolean;
  setCampaignInt: React.Dispatch<React.SetStateAction<boolean>>;

  sourceTypeValues: any;
  setSourceTypeValues: React.Dispatch<any>;
  sourceTypeOptions: any;
  setSourceTypeOptions: React.Dispatch<any>;
  sourceTypeInt: boolean;
  setSourceTypeInt: React.Dispatch<React.SetStateAction<boolean>>;

  devicesText: string[];
  setDevicesTexts: React.Dispatch<React.SetStateAction<string[]>>;

  coordinatesDecimals: number;
  setCoordinatesDecimals: React.Dispatch<React.SetStateAction<number>>;

  filterChanged: boolean;
  setFilterChanged: React.Dispatch<React.SetStateAction<boolean>>;

  unclusteredOption: any;
}

/*
 'asn',
  'bundle_id',
  'cluster_tag',
  'carrier',
  'connection_type',
  'user_agent',
  'ip_address',
  'device_name',
  'device_manufacturer',
  'model',
  'platform',
  'os_version',
  'country_id',
  'state',
  'city',
  'zip_code'
*/

//column options for other search params
const columnOptions = [
  {
    label: 'ASN',
    value: 'asn'
  },
  {
    label: 'Bundle ID',
    value: 'bundle_id'
  },
  {
    label: 'Cluster Tag',
    value: 'cluster_tag'
  },
  {
    label: 'Carrier',
    value: 'carrier'
  },
  {
    label: 'Connection Type',
    value: 'connectiontype'
  },
  {
    label: 'User Agent',
    value: 'user_agent'
  },
  {
    label: 'Device Name',
    value: 'devicename'
  },
  {
    label: 'Device Manufacturer',
    value: 'device_manufacturer'
  },
  {
    label: 'Model',
    value: 'model'
  },
  {
    label: 'Platform',
    value: 'platform'
  },
  {
    label: 'IP Address',
    value: 'ip_address'
  },
  {
    label: 'OS Version',
    value: 'os_version'
  },
  {
    label: 'Country ID',
    value: 'country_id'
  },
  {
    label: 'State',
    value: 'state'
  },
  {
    label: 'City',
    value: 'city'
  },
  {
    label: 'Zip Code',
    value: 'zip_code'
  }
];

const numberOptions = [
  { label: '1 or more decimal places', value: 1 },
  { label: '2 or more decimal places', value: 2 },
  { label: '3 or more decimal places', value: 3 },
  { label: '4 or more decimal places', value: 4 }
];

const DeviceEventsFilter = ({
  coordinatesSearchParams,
  setCoordinatesSearchParams,
  aoiInt,
  setAoiInt,
  handleOnCancel,
  applyFilters,
  //states
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isLoading,
  setIsLoading,
  locationFilterType,
  setLocationFilterType,
  locationValues,
  setLocationValues,
  locationOptions,
  setLocationOptions,
  locationInt,
  setLocationInt,
  deviceValues,
  setDeviceValues,
  deviceOptions,
  setDeviceOptions,
  deviceInt,
  setDeviceInt,
  campaignValues,
  setCampaignValues,
  campaignOptions,
  setCampaignOptions,
  campaignInt,
  setCampaignInt,
  sourceTypeValues,
  setSourceTypeValues,
  sourceTypeOptions,
  setSourceTypeOptions,
  sourceTypeInt,
  setSourceTypeInt,
  devicesText,
  setDevicesTexts,
  coordinatesDecimals,
  setCoordinatesDecimals,
  filterChanged,
  setFilterChanged,
  unclusteredOption
}: IDeviceEventsFilters) => {
  //AOIS here

  const [mapIsLoading] = useAtom(mapIsLoadingAtom);
  const [tableIsLoading] = useAtom(tableIsLoadingAtom);
  const [otherParams, setOtherParams] = useAtom(otherParamsAtom);
  // const [statButtonClicked, setStatButtonClicked] = useAtom(
  //   statButtonClickedAtom
  // );

  //this function is not working right
  // const handleStatButtonClicked = async () => {
  //   switch (statButtonClicked) {
  //     case STAT_BUTTON.DOI:
  //       let devices = await (
  //         await apiAgent.DeviceEvent.getDevicesList()
  //       ).data.data.devices;
  //       devices = await devices.filter((device: any) => device.status);
  //       setDeviceValues([]);
  //       setLocationValues([]);
  //       setDevicesTexts(devices.map((device: any) => device.ifa));
  //       setCampaignValues([]);
  //       setLocationFilterType('or');
  //       await applyFilters({
  //         setIsLoading,
  //         startDate,
  //         endDate,
  //         locationFilterType: 'or',
  //         filterType: 'intersection',
  //         deviceValues: [],
  //         devicesText: devices.map((device: any) => device.ifa),
  //         locationValues: [],
  //         campaignValues: [],
  //         setFilterChanged,
  //         handleOnApply
  //       });
  //       break;
  //     case STAT_BUTTON.LOI:
  //       let locations = await (
  //         await apiAgent.DeviceEvent.getLocationsList()
  //       ).data.data.locations;
  //       locations = await locations.filter((location: any) => location.status);
  //       setDeviceValues([]);
  //       setLocationValues(
  //         locations.map((location: any) => {
  //           return {
  //             label: location.locationId,
  //             value: location.locationId
  //           };
  //         })
  //       );
  //       setDevicesTexts([]);
  //       setCampaignValues([]);
  //       setLocationFilterType('or');

  //       await applyFilters({
  //         setIsLoading,
  //         startDate,
  //         endDate,
  //         locationFilterType: 'or',
  //         filterType: 'intersection',
  //         deviceValues: [],
  //         devicesText: [],
  //         locationValues: locations.map((location: any) => {
  //           return {
  //             label: location.locationId,
  //             value: location.locationId
  //           };
  //         }),
  //         campaignValues: [],
  //         setFilterChanged,
  //         handleOnApply
  //       });
  //       break;
  //     default:
  //       return;
  //   }
  //   setStatButtonClicked(STAT_BUTTON.NONE);
  // };

  const addOtherSearchParam = () => {
    // setSearchOtherParams([
    //   ...searchOtherParams,
    //   { columnName: '', values: [], intersection: false }
    // ]);
    setOtherParams((prevState) => {
      return prevState.concat({
        columnName: '',
        values: [],
        intersection: false
      });
    });
  };

  const deleteOtherSearchParam = (index: number) => {
    setOtherParams((prevState) => {
      return [...prevState].filter(
        (_params, paramIndex) => paramIndex !== index
      );
    });
  };

  const deleteOtherAoi = (index: number) => {
    if (setCoordinatesSearchParams)
      setCoordinatesSearchParams((prevState: any) => {
        return [...prevState].filter(
          (_params, paramIndex) => paramIndex !== index
        );
      });
  };

  const handleColumnChange = (
    index: number,
    newValue: { label: string; value: string } | null
  ) => {
    if (newValue === null) {
      setOtherParams((prevState) => {
        let result = [...prevState];
        let temp = result[index];
        temp.columnName = '';
        result[index] = temp;
        return result;
      });
      return;
    }
    setOtherParams((prevState) => {
      let result = [...prevState];
      let temp = result[index];
      temp.columnName = newValue.value;
      result[index] = temp;
      return result;
    });
  };

  const addAOIparams = (
    coordinatesSearchParams: CoordinatesSearchParams[]
  ): JSX.Element => {
    return (
      <React.Fragment>
        <div className="flex justify-between">
          <h1 className="text-lg mt-5">Area of interest (AOIs)</h1>
          <StyledButton
            className="my-5"
            variant={
              aoiInt ? ElementVariant.success : ElementVariant.primaryAltTwo
            }
            onClick={(event) => {
              setAoiInt(!aoiInt);
              setFilterChanged(true);
            }}
          >
            Intersection
          </StyledButton>
        </div>
        {coordinatesSearchParams.map((coords, index) => {
          const { type, coordsEntry } = coords;
          return (
            <div
              className="flex justify-between items-center my-3"
              key={'other_param_' + index}
            >
              <div>
                <h1 className="text-lg mt-5">
                  AOI {index + 1} {type === 'rect' ? ': Rect' : ': Polygon'}
                </h1>
                {type === 'rect' ? (
                  <>
                    <div className="flex items-center mb-1">
                      <label className="p-2 mt-2 text-lg">Lat</label>
                      <Input
                        className={`rounded focus:text-white p-2 border-0 w-full mt-2 ${ElementBgClassnames.primary}`}
                        contentEditable={false}
                        disabled
                        value={coordsEntry?.latFrom}
                      />
                      <div className="mx-2">to</div>
                      <Input
                        className={`rounded focus:text-white p-2 border-0 w-full mt-2 ${ElementBgClassnames.primary}`}
                        contentEditable={false}
                        disabled
                        value={coordsEntry?.latTo}
                      />
                    </div>

                    <div className="flex items-center mb-2">
                      <label className="p-2 mt-5 text-lg">Long</label>
                      <Input
                        className={`rounded focus:text-white p-2 border-0 w-full mt-2 ${ElementBgClassnames.primary}`}
                        contentEditable={false}
                        disabled
                        value={coordsEntry?.lngFrom}
                      />
                      <div className="mx-2">to</div>
                      <Input
                        className={`rounded focus:text-white p-2 border-0 w-full mt-2 ${ElementBgClassnames.primary}`}
                        contentEditable={false}
                        disabled
                        value={coordsEntry?.lngTo}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className="rounded bg-background-base p-2 my-2"
                    style={{ height: '12vh', overflowY: 'auto' }}
                  >
                    {coordsEntry?.map((coord: any, index: any) => {
                      return index === coordsEntry.length - 1
                        ? `{${coord.lat}, ${coord.lng}}`
                        : `{${coord.lat}, ${coord.lng}} , `;
                    })}
                  </div>
                )}
              </div>{' '}
              <ListDeleteButton
                className="rounded-l ml-2"
                onClick={() => {
                  setFilterChanged(true);
                  deleteOtherAoi(index);
                }}
              />
            </div>
          );
        })}
        <hr className="my-2" />
      </React.Fragment>
    );
  };

  return (
    <Fragment>
      <div className="flex flex-col rounded-md bg-background-alt py-4 px-5 h-auto text-white">
        <WithLoadingDiv isLoading={isLoading}>
          <PageSubTitle>Filter Options</PageSubTitle>
          <div className="flex justify-between">
            <h1 className="text-lg">Date Range</h1>
          </div>
          <div className="flex items-center mb-5">
            <DatePicker
              className={`rounded focus:text-white  border-0 w-full mt-2 ${ElementBgClassnamesWithHover.primary}`}
              selected={startDate}
              timeInputLabel="Time:"
              dateFormat="dd-MM-yyyy HH:mm"
              showTimeInput
              onChange={(date: any) => {
                setStartDate(date);
                setFilterChanged(true);
              }}
              maxDate={endDate}
            />
            <div className="mx-2">to</div>
            <DatePicker
              className={`rounded focus:text-white  border-0 w-full mt-2 ${ElementBgClassnamesWithHover.primary}`}
              selected={endDate}
              timeInputLabel="Time:"
              dateFormat="dd-MM-yyyy HH:mm"
              showTimeInput
              onChange={(date: any) => {
                setEndDate(date);
                setFilterChanged(true);
              }}
              minDate={startDate}
            />
          </div>
          <hr />
          <div className="mt-5 mb-5">
            <div className="flex justify-between">
              <h1 className="text-lg">Locations of interest (LOIs)</h1>
              <StyledButton
                variant={
                  locationInt
                    ? ElementVariant.success
                    : ElementVariant.primaryAltTwo
                }
                onClick={(event) => {
                  setLocationInt(!locationInt);
                  setFilterChanged(true);
                }}
              >
                Intersection
              </StyledButton>
            </div>
            <div className="flex mb-2 ">
              {locationFilterType === 'or' ? (
                <div
                  className={`inline-block text-center rounded py-1.5 px-3 text-sm ${ElementBgClassnames.success}`}
                >
                  OR
                </div>
              ) : (
                <StyledButton
                  onClick={(event) => {
                    setFilterChanged(true);
                    setLocationFilterType('or');
                  }}
                >
                  OR
                </StyledButton>
              )}
              {locationFilterType === 'and' ? (
                <div
                  className={`inline-block text-center rounded py-1.5 px-3 text-sm ${ElementBgClassnames.success}`}
                >
                  AND
                </div>
              ) : (
                <StyledButton
                  onClick={(event) => {
                    setFilterChanged(true);
                    setLocationFilterType('and');
                  }}
                >
                  AND
                </StyledButton>
              )}
            </div>
            <SelectMultiWithCheckBox
              values={locationValues}
              options={locationOptions}
              onChange={(value) => {
                setFilterChanged(true);
                setLocationValues(value);
              }}
              placeholder={
                locationValues.length === 0 ||
                locationValues.some((location: any) => location.value === '*')
                  ? 'Show All Locations'
                  : locationValues
                      .map((location: any) => location.label)
                      .toString()
              }
              allowSelectAll={false}
              allowNull={false}
            />
          </div>
          <hr />
          <div className="mt-5 mb-5">
            <div className="flex justify-between mb-1">
              <h1 className="text-lg ">Devices of interest (DOIs)</h1>
              <StyledButton
                variant={
                  deviceInt
                    ? ElementVariant.success
                    : ElementVariant.primaryAltTwo
                }
                onClick={(event) => {
                  setDeviceInt(!deviceInt);
                  setFilterChanged(true);
                }}
              >
                Intersection
              </StyledButton>
            </div>
            <div className="mb-5">
              <SelectMultiWithCheckBox
                values={deviceValues}
                options={deviceOptions}
                onChange={(value) => {
                  setFilterChanged(true);
                  setDeviceValues(value);
                }}
                placeholder={
                  deviceValues.length === 0 ||
                  deviceValues.some((device: any) => device.value === '*')
                    ? 'Show All Devices'
                    : deviceValues.map((device: any) => device.label).toString()
                }
                allowSelectAll={true}
                allowNull={false}
              />
            </div>
            <h2>Other IFAs</h2>
            <TextArea
              placeholder="IFAs separated by new line"
              value={devicesText.join('\r\n')}
              onChange={(e) => {
                let devices = e.target.value.split('\n');
                if (devices.length === 1 && !devices[0]) {
                  devices = [];
                }
                setFilterChanged(true);
                setDevicesTexts(devices);
              }}
            />
          </div>
          <hr className="mb-5" />
          <div className="flex justify-between mb-1">
            <h1 className="text-lg ">Campaigns</h1>
            <StyledButton
              variant={
                campaignInt
                  ? ElementVariant.success
                  : ElementVariant.primaryAltTwo
              }
              onClick={(event) => {
                setCampaignInt(!campaignInt);
                setFilterChanged(true);
              }}
            >
              Intersection
            </StyledButton>
          </div>
          <SelectMultiWithCheckBox
            values={campaignValues}
            options={campaignOptions}
            onChange={(value) => {
              setFilterChanged(true);
              setCampaignValues(value);
            }}
            placeholder={
              campaignValues.length === 0 ||
              campaignValues.some((campaign: any) => campaign.value === '*')
                ? 'Show All Campaigns'
                : campaignValues
                    .map((campaign: any) => campaign.label)
                    .toString()
            }
            allowSelectAll={false}
          />
          <hr className="my-5" />
          <div className="flex justify-between mb-1">
            <h1 className="text-lg ">Source Types</h1>
            <StyledButton
              variant={
                sourceTypeInt
                  ? ElementVariant.success
                  : ElementVariant.primaryAltTwo
              }
              onClick={(event) => {
                setSourceTypeInt(!sourceTypeInt);
                setFilterChanged(true);
              }}
            >
              Intersection
            </StyledButton>
          </div>
          <SelectMultiWithCheckBox
            values={sourceTypeValues}
            options={sourceTypeOptions}
            onChange={(value) => {
              setFilterChanged(true);
              setSourceTypeValues(value);
            }}
            placeholder={
              sourceTypeValues.length === 0 ||
              sourceTypeValues.some(
                (sourceType: any) => sourceType.value === '*'
              )
                ? 'Show All Source Types'
                : sourceTypeValues
                    .map((sourceType: any) => sourceType.label)
                    .toString()
            }
            allowSelectAll={false}
          />
          <hr className="my-5" />
          <div>
            <h1 className="text-lg ">Coordinates Granularity</h1>
            <div className="flex justify-between items-center my-3">
              {/* <div className="w-1/2"> */}
              <Select
                isMulti={false}
                className={`select-special-container`}
                classNamePrefix="tw-select"
                placeholder="Coordinates decimal place"
                styles={{
                  valueContainer: (provided: any, state: any) => ({
                    ...provided,
                    maxHeight: '124px',
                    overflow: 'auto'
                  }),
                  input: (provided: any, state: any) => ({
                    ...provided,
                    minWidth: '20%'
                  })
                }}
                defaultValue={numberOptions.find(
                  (option) => option.value === coordinatesDecimals
                )}
                onChange={(newValue) => {
                  setFilterChanged(true);
                  setCoordinatesDecimals(newValue?.value || 1);
                }}
                options={numberOptions}
                isClearable={false}
              />
              {/* </div> */}
            </div>
          </div>
          <hr className="my-5" />
          <div>
            <h1 className="text-lg " onClick={() => console.log(otherParams)}>
              Others
            </h1>
            {otherParams &&
              otherParams.map((_param, index) => (
                <div
                  className="flex justify-between items-center my-3"
                  key={'other_param_' + index}
                >
                  <div className="w-1/3">
                    <Select
                      isOptionDisabled={(option) =>
                        typeof otherParams.find(
                          (param) => param.columnName === option.value
                        ) !== 'undefined'
                      }
                      isMulti={false}
                      className={`select-special-container ${index}`}
                      classNamePrefix="tw-select"
                      placeholder="Column name"
                      styles={{
                        valueContainer: (provided: any, state: any) => ({
                          ...provided,
                          maxHeight: '124px',
                          overflow: 'auto'
                        }),
                        input: (provided: any, state: any) => ({
                          ...provided,
                          minWidth: '20%'
                        })
                      }}
                      onChange={(newValue) => {
                        setFilterChanged(true);
                        handleColumnChange(index, newValue);
                      }}
                      options={columnOptions}
                      value={
                        typeof columnOptions.find(
                          (option) =>
                            option.value === otherParams[index].columnName
                        ) === 'undefined'
                          ? null
                          : columnOptions.find(
                              (option) =>
                                option.value === otherParams[index].columnName
                            )
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="flex-1 mx-2">
                    <TextArea
                      rows={1}
                      placeholder="Values separated by new line"
                      value={otherParams[index].values.join('\r\n')}
                      onChange={(e) => {
                        let paramValues = e.target.value.split('\n');
                        if (paramValues.length === 1 && !paramValues[0]) {
                          paramValues = [];
                        }
                        setFilterChanged(true);
                        setOtherParams((prevState) => {
                          let result = [...prevState];
                          let temp = result[index];
                          temp.values = paramValues;
                          result[index] = temp;
                          return result;
                        });
                      }}
                    />
                  </div>
                  <div>
                    <StyledButton
                      variant={
                        otherParams[index].intersection
                          ? ElementVariant.success
                          : ElementVariant.primaryAltTwo
                      }
                      onClick={() => {
                        setFilterChanged(true);
                        setOtherParams((prevState) => {
                          let result = [...prevState];
                          let temp = result[index];
                          temp.intersection = !temp.intersection;
                          result[index] = temp;
                          return result;
                        });
                      }}
                    >
                      Intersection
                    </StyledButton>
                    <ListDeleteButton
                      className="rounded-l ml-2"
                      onClick={() => {
                        setFilterChanged(true);
                        deleteOtherSearchParam(index);
                      }}
                    />
                  </div>
                </div>
              ))}
            <div className="w-full flex justify-center">
              <StyledButton
                onClick={() => {
                  setFilterChanged(true);
                  addOtherSearchParam();
                }}
              >
                <i className="fa-solid fa-plus" />
              </StyledButton>
            </div>
          </div>
          <hr className="my-5" />

          {coordinatesSearchParams?.length > 0 &&
            addAOIparams(coordinatesSearchParams)}
          <div className="flex  mt-12 ">
            {handleOnCancel ? (
              <StyledButton
                className="mr-2"
                onClick={handleOnCancel}
                variant={ElementVariant.danger}
              >
                Cancel
              </StyledButton>
            ) : null}
            <StyledButton
              onClick={() => {
                let tempDeviceValues = deviceValues;
                let unclusteredSelected = false;

                //remove unclustered option if it has been selected
                const filteredDeviceValues = tempDeviceValues.filter(
                  (option: any) =>
                    option.value !== unclusteredOption.value ||
                    option.label !== unclusteredOption.label
                );
                if (filteredDeviceValues.length !== tempDeviceValues.length) {
                  unclusteredSelected = true;
                }

                // const initialValue = 0;
                // let deviceOptionLength = deviceOptions
                //   .filter(
                //     (option: any) =>
                //       option.value !== unclusteredOption.value ||
                //       option.label !== unclusteredOption.label
                //   )
                //   .reduce(
                //     (accumulator, currentValue) =>
                //       accumulator + currentValue.options.length,
                //     initialValue
                //   );

                // const allDevicesSelected =
                //   filteredDeviceValues.length === deviceOptionLength;

                applyFilters({
                  setIsLoading,
                  startDate,
                  endDate,
                  locationFilterType,
                  deviceValues: filteredDeviceValues,
                  unclusteredSelected,
                  devicesText,
                  deviceInt,
                  locationValues,
                  locationInt,
                  campaignValues,
                  campaignInt,
                  sourceTypeValues,
                  sourceTypeInt,
                  coordinatesSearchParams,
                  aoiInt,
                  coordinatesDecimals,
                  setAoiInt,
                  setFilterChanged
                });
              }}
              loading={mapIsLoading || tableIsLoading}
              disabled={!filterChanged}
            >
              Apply
            </StyledButton>
          </div>
        </WithLoadingDiv>
      </div>
    </Fragment>
  );
};

export default memo(DeviceEventsFilter);
