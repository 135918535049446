import React, { Fragment, Children, cloneElement } from 'react';
import classNames from 'classnames/bind';

import { RequiredLabel } from '../../components';
import { IErrorInput, ElementTextClassnames } from '../../interfaces';

interface ILabelProps
  extends IErrorInput,
    React.LabelHTMLAttributes<HTMLLabelElement> {}

const Label = ({ error, ...otherProps }: ILabelProps): JSX.Element => (
  <label
    className={classNames('block mb-1', {
      [ElementTextClassnames.primary]: !error,
      [ElementTextClassnames.danger]: error
    })}
    {...otherProps}
  />
);

const ElementWrapper = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => <div className="mb-4 last:mb-0">{children}</div>;

const MultiElementWrapper: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ children }) => (
  <div className="grid grid-cols-12 mb-4 last:mb-0 gap-6">{children}</div>
);

interface IDescriptionProps {
  padded?: boolean;
  children: string | React.ReactNode;
}

const Hint = ({ padded, children }: IDescriptionProps): JSX.Element => (
  <div
    className={classNames(
      'block text-sm mt-2 font-medium',
      ElementTextClassnames.success,
      {
        'ml-7': padded
      }
    )}
  >
    {children}
  </div>
);

const MultiChildWrapper = ({
  children,
  className
}: {
  className: string;
  children: React.ReactNode;
}): JSX.Element => (
  <div className={classNames(className, 'last:pb-0 last:mb-0')}>{children}</div>
);

const WarningList = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => <ul className="mt-2">{children}</ul>;

const Warnings = ({ warnings }: { warnings: string[] }): JSX.Element => (
  <WarningList>
    {warnings.map((warn) => (
      <li className="mt-1 text-danger" key={warn}>
        {warn}
      </li>
    ))}
  </WarningList>
);

interface ISectionElementProps extends IErrorInput {
  children: React.ReactNode;
  label?: string;
  hint?: string;
  padded?: boolean;
  required?: boolean;
  warnings?: string[];
}

const SectionElement = ({
  label,
  hint,
  padded,
  required,
  error,
  children,
  warnings
}: ISectionElementProps): JSX.Element => (
  <ElementWrapper>
    {label && (
      <Label
        htmlFor="sectionElementLabel"
        error={error}
        className="text-text-primary mb-2 inline-block"
      >
        {required ? <RequiredLabel>{label}</RequiredLabel> : label}
      </Label>
    )}
    {Children.map(
      children,
      (child: React.ReactNode) =>
        child && <Fragment>{cloneElement(child as any, { error })}</Fragment>
    )}
    {hint && <Hint padded={padded}>{hint}</Hint>}
    {warnings && <Warnings warnings={warnings} />}
  </ElementWrapper>
);

type MultiWrapperProps = {
  children: React.ReactNode;
  size?: number | Array<number>;
};

const MultiWrapper = ({
  children,
  size = 6
}: MultiWrapperProps): JSX.Element => (
  <MultiElementWrapper>
    {Children.map(children, (child: React.ReactNode, index) => {
      const cn = 'col-span-' + String(Array.isArray(size) ? size[index] : size);
      return <MultiChildWrapper className={cn}>{child}</MultiChildWrapper>;
    })}
  </MultiElementWrapper>
);

export { MultiWrapper, SectionElement, Hint };
