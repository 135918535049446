import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiAgent from '../../api/apiAgent';
import {
  ContainerFull,
  MainPanel,
  Modal,
  StyledButton,
  WithLoadingDiv
} from '../../components';
import {
  ElementBgClassnames,
  ElementBgClassnamesWithHover,
  ElementVariant
} from '../../interfaces';
import { Campaign } from './filtersUtil';

const FiltersLanding = () => {
  const [currFilter, setCurrFilter] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalCampaignIndex, setModalCampaignIndex] = useState<number>();

  const fetchCurrFilter = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const result: any = await apiAgent.Filters.getCurrentFilter();
      const filter = result.data?.data?.filter;
      if (filter) {
        setCurrFilter(filter);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrFilter();
  }, []);

  const navigate = useNavigate();

  return (
    <ContainerFull>
      <MainPanel
        className=" relative  content-center w-full h-full bg-opacity-0"
        padding={true}
      >
        <div className="flex">
          <div
            className="h-full flex flex-col items-center"
            style={{ width: '50%' }}
          >
            <div className="text-4xl mb-12">Create Campaigns</div>
            <button
              className={`mb-12 w-1/2 inline-block rounded py-10 px-4 focus:outline-none text-wrap ${ElementBgClassnamesWithHover.success}`}
              onClick={() => navigate('/campaigns/create')}
            >
              <h1 className="text-3xl mb-4">New</h1>
              <h1 className="text-m ">
                Choose this to create campaigns from file or using form
              </h1>
            </button>
            <button
              className={`inline-block w-1/2 rounded py-10 px-4 focus:outline-none text-wrap ${ElementBgClassnamesWithHover.info}`}
              onClick={() => navigate('/campaigns/list')}
            >
              <h1 className="text-3xl mb-4">From Previous</h1>
              <h1 className="text-m ">
                Choose this to create campaigns from list of previous sets of
                campaigns
              </h1>
            </button>
          </div>
          <div
            className="h-full flex flex-col items-center"
            style={{ width: '50%' }}
          >
            <div className="flex justify-center ">
              <div className="text-4xl mb-12 mr-2">Current Campaigns</div>
              <div>
                <StyledButton
                  onClick={() => navigate(`/campaigns/create/current`)}
                >
                  <i className="fa fa-pencil-alt" />
                </StyledButton>
              </div>
            </div>
            <div
              className={`w-3/4 h-full ${ElementBgClassnames.primaryAlt} rounded p-2 items-center justify-center `}
              style={{ height: '68vh' }}
            >
              <WithLoadingDiv isLoading={isLoading}>
                {currFilter && (
                  <div>
                    <div className="grid grid-cols-3 gap-1 mb-2">
                      <div className="col-start-1">{`Set ID `}</div>
                      <div>{currFilter.id}</div>
                      <div className="flex justify-end items-center">
                        <div
                          className={`w-2 h-2 rounded-xl ${
                            ElementBgClassnames.success
                          } ${currFilter === 0 ? 'opacity-100' : 'opacity-0'}`}
                        ></div>
                      </div>
                      <div className="col-start-1">{`Created at`}</div>
                      <div className="col-span-2">
                        {moment(new Date(currFilter.createdAt)).format(
                          'DD/MM/YYYY hh:mm A'
                        )}
                      </div>
                      <div className="col-start-1">{`Vendor ver`}</div>
                      <div className="col-span-2">
                        {currFilter.vendorVersion}
                      </div>
                      <div className="col-start-1">Campaigns</div>
                    </div>
                    <div
                      className={`overflow-y-scroll overflow-x-hidden rounded p-2 ${ElementBgClassnames.primary}`}
                      style={{ height: '52vh' }}
                    >
                      {currFilter.campaigns?.map(
                        (campaign: any, campaignIndex: number) => {
                          return (
                            <div
                              className={`rounded-md px-1 py-2 mb-4 text-sm ${ElementBgClassnames.primaryAlt} `}
                              key={campaignIndex}
                            >
                              <div className="grid grid-cols-3">
                                <div>{`Cam ID `}</div>
                                <div className="col-span-2">
                                  {campaign.campaignId}
                                </div>
                                <div className="col-start-1">{`Active`}</div>
                                <div className="col-span-2">
                                  {campaign.active ? 'true' : 'false'}
                                </div>
                                <div className="col-start-1">{`Start date`}</div>
                                <div className="col-span-2">
                                  {!campaign.startDate
                                    ? 'Long running'
                                    : moment(campaign.startDate).format(
                                        'DD/MM/YYYY hh:mm A'
                                      )}
                                </div>
                                <div className="col-start-1">{`End date`}</div>
                                <div className="col-span-2">
                                  {!campaign.endDate
                                    ? 'Long running'
                                    : moment(campaign.endDate).format(
                                        'DD/MM/YYYY hh:mm A'
                                      )}
                                </div>
                                <div className="col-start-1">IFAs</div>
                                <div>{campaign.devices.length}</div>
                                <div className="col-start-1">Locations</div>
                                <div>{campaign.locations.length}</div>
                              </div>
                              <StyledButton
                                className="mt-2"
                                variant={ElementVariant.primaryAltTwo}
                                onClick={() => {
                                  setModalCampaignIndex(campaignIndex);
                                  setShowModal(true);
                                }}
                              >
                                Details
                              </StyledButton>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </WithLoadingDiv>
            </div>
          </div>
        </div>
      </MainPanel>
      <span className="text-white">
        <SimpleModal
          showModal={showModal}
          setShowExternal={setShowModal}
          campaign={
            typeof modalCampaignIndex !== 'undefined' &&
            currFilter.campaigns[modalCampaignIndex]
          }
        />
      </span>
    </ContainerFull>
  );
};

const SimpleModal = ({
  showModal,
  setShowExternal,
  campaign
}: {
  showModal?: boolean;
  setShowExternal?: Function | undefined;
  campaign?: Campaign;
}) => {
  return (
    <Modal showModal={showModal} setShowExternal={setShowExternal}>
      {campaign ? (
        <Modal.Display>
          <Modal.Header>
            <div>Cam ID: {campaign.campaignId}</div>
          </Modal.Header>
          <Modal.Body>
            <div>IFAs</div>
            <ul
              className={`overflow-y-scroll rounded ${ElementBgClassnames.primary}`}
              style={{ height: '35vh' }}
            >
              {campaign.devices.map((device: any) => (
                <li>{device.ifa}</li>
              ))}
            </ul>
            <div className="mt-4">Locations</div>
            <ul
              className={`overflow-y-scroll rounded ${ElementBgClassnames.primary}`}
              style={{ height: '35vh' }}
            >
              {campaign.locations.map((location: any) => (
                <li>{location.locationId}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <span></span>
          </Modal.Footer>
        </Modal.Display>
      ) : (
        <Modal.Display>
          <Modal.Header>No Data</Modal.Header>
        </Modal.Display>
      )}
    </Modal>
  );
};

export default FiltersLanding;
