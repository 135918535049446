import { StyledRouterLink } from '../../components';

const Error403 = (): JSX.Element => (
  <div className="container mx-auto pb-20">
    <section className="mt-14 text-center">
      <h1 className="text-white text-6xl mb-5 font-bold">403</h1>
    </section>

    <section className="text-center text-gray-primary text-s">
      <div>You do not have authorization for the page you requested. If you think this is a mistake, please contact your Administrator.</div>
      {/* <div>Please select one of the following options:</div> */}
    </section>

    <div className="mt-5">
      <ul className="text-center list-none">
        <StyledRouterLink className="text-white block mb-4" to="/">
          Go back to Main
        </StyledRouterLink>
        {/* 
        <StyledRouterLink className="text-white block" to="/dashboard">
          Go back to Dashboard
        </StyledRouterLink> */}
      </ul>
    </div>
  </div>
);

export default Error403;
