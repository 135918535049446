import React, { Fragment } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { hasJwtCookie } from '../auth';
import { toastHandler } from '../utils';
import { FLASH_MESSAGES } from '../cnstants';
import { clearJwtCookie } from '../auth';
import { useAtom } from 'jotai';
import { userProfileAtom } from '../atoms';
import { APP_URL } from '../cnstants';
import { CubeLoader } from '../components';

interface IPrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: IPrivateRouteProps): JSX.Element => {
  const location = useLocation();
  const [currentUser] = useAtom(userProfileAtom);

  if (!hasJwtCookie()) {
    clearJwtCookie(); // Clear to be safe
    window.location.replace(`${APP_URL.SIGN_OUT_PAGE}?session=expired`);

    return (
      <div className="block text-center mt-28">
        <CubeLoader className="mx-auto" />
        <div className="mt-20 mb-10 text-xl text-white">
          Please wait for the application to check your profile...
        </div>
      </div>
    );
  }

  // No need to check if user is in /my/profile-dashboard
  // Need to check whether OTP is setup
  if (location.pathname !== '/my/profile-dashboard') {
    if (
      currentUser.id &&
      !currentUser.skipTwoFactor &&
      !currentUser.otpMicrosoftFlag
    ) {
      toastHandler({
        messages: FLASH_MESSAGES.OTP_NOT_SETUP,
        toastOptions: { type: 'error', toastId: 'no_otp' }
      });

      return <Navigate to={{ pathname: '/my/profile-dashboard' }} />;
    }
  }

  return <Fragment>{children}</Fragment>;
};

export default PrivateRoute;
