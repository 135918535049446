import _ from 'lodash';
import React from 'react';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { StyledButton } from '../Button';

type OptionType = {
  label: string;
  value: string | number | { value: string | number; groupLabel: string };
};

type GroupOptionType = {
  label: string;
  options: OptionType[];
};

const isGroupOptionType = (object: any) => {
  return 'options' in object;
};

const ungroupOptions = (options: (GroupOptionType | OptionType)[]) => {
  let result: OptionType[] = [];
  options.forEach((option: any) => {
    if (isGroupOptionType(option)) {
      option.options.forEach((newOption: OptionType) => {
        result.push(newOption);
      });
    } else {
      result.push(option);
    }
  });
  return result;
};

const groupValueEquals = (
  value1: string | number | { value: string | number; groupLabel: string },
  value2: string | number | { value: string | number; groupLabel: string }
): boolean => {
  if (typeof value1 === 'object' && typeof value2 === 'object') {
    return (
      value1.value === value2.value && value1.groupLabel === value2.groupLabel
    );
  }
  return value1 === value2;
};

type SelectProps = {
  options: (OptionType | GroupOptionType)[];
  onChange: (e: any) => void;
  values: any;
  placeholder?: string;
  allowSelectAll?: boolean;
  allowNull?: boolean;
  hasGroupOptions?: boolean;
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="flex justify-between">
          <label>{props.label}</label>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => {}}
          />
        </div>
      </components.Option>
    </div>
  );
};

// const handleHeaderClick = (id: any) => {
//   const node = document!.querySelector(`#${id}`)!.parentElement!
//     .nextElementSibling;
//   const classes = node!.classList;
//   if (classes.contains('collapsed')) {
//     node!.classList.remove('collapsed');
//   } else {
//     node!.classList.add('collapsed');
//   }
// };

// Create custom GroupHeading component, which will wrap
// react-select GroupHeading component inside a div and
// register onClick event on that div
// const GroupHeading = (props: any) => {
//   return (
//     <div
//       className="group-heading-wrapper"
//       onClick={() => handleHeaderClick(props.id)}
//     >
//       <components.GroupHeading {...props} />
//     </div>
//   );
// };

let Group = (props: any) => {
  const { children, className, cx, getStyles, label } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <div>
      <GroupHeading
        originalProps={props}
        label={label}
        expanded={expanded}
        setExpanded={setExpanded}
      />

      <div style={{ display: expanded ? 'block' : 'none' }}>{children}</div>
    </div>
  );
};

const GroupHeading = (props: any) => {
  const { label, expanded, setExpanded, originalProps } = props;

  const checkIfHasAll = () => {
    let hasSome = false;
    let hasAll = true;

    for (const option of originalProps.data.options) {
      if (
        originalProps.selectProps.value &&
        !originalProps.selectProps.value.some((selectedOption: any) =>
          groupValueEquals(option, selectedOption)
        )
      ) {
        hasAll = false;
        break;
      }
      hasSome = true;
    }
    return hasAll;
  };

  const checked = checkIfHasAll();

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="font-bold ml-2">{label}</div>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => {
              // let selectedOptions = _.cloneDeep(
              //   originalProps.selectProps.value
              // );

              // selectedOptions.filter((option: any) => {
              //   if (typeof option === 'object') {
              //     return option.value.groupLabel !== label;
              //   }
              //   return true;
              // });

              // if (!checkIfHasAll()) {
              // selectedOptions = selectedOptions.concat(
              //   originalProps.data.options
              // );
              // }

              // originalProps.selectProps.onChange(selectedOptions);

              let selectedOptions = originalProps.selectProps.value
                .filter((option: any) => {
                  if (typeof option === 'object') {
                    return option.value.groupLabel !== label;
                  }
                  return true;
                })
                .concat(checkIfHasAll() ? [] : originalProps.data.options);
              originalProps.selectProps.onChange(selectedOptions);
            }}
          />
          <StyledButton
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <i
              className={`fa-sharp fa-solid ${
                expanded ? 'fa-caret-up' : 'fa-caret-down'
              }`}
            />
          </StyledButton>
        </div>
      </div>
    </div>
  );
};

const filterOption = ({ label, value, data }: any, string: any): boolean => {
  // default search

  if (label.toLocaleLowerCase().includes(string.toLocaleLowerCase())) {
    return true;
  }
  // // check if a group as the filter string as label
  // const groupOptions: any = options.filter((group) =>
  //   group.label.toLocaleLowerCase().includes(string.toLocaleLowerCase())
  // );

  // // groupOptions = ["ifa-1", "ifa-2", "ifa-3"]

  // if (groupOptions) {
  //   for (const groupOption of groupOptions) {
  //     // Check if current option is in group
  //     const option = groupOption.options?.find(
  //       (opt: any) => opt.value === value
  //     );
  //     if (option) {
  //       return true;
  //     }
  //   }
  // }
  if (
    data.value.groupLabel &&
    data.value.groupLabel
      .toLocaleLowerCase()
      .includes(string.toLocaleLowerCase())
  ) {
    return true;
  }
  return false;
};

const ValueContainer = ({ children, ...props }: any) => {
  if (children[0]?.length) {
    const newChilds = children[0].map((child: any) => {
      const data = child.props.data;
      return React.cloneElement(child, {
        key: `${data.label}-${JSON.stringify(data.value)}`
      });
    });
    newChilds.forEach((newChild: any, index: any) => {
      children[0][index] = newChild;
    });
    // children[0] = newChilds;  wont work. children[0] is readonly
  }
  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
};

const SelectMultiWithCheckBox = (props: SelectProps): JSX.Element => {
  const { options, onChange, values, placeholder, allowSelectAll } = props;

  const customStyles = useMemo(
    () => ({
      valueContainer: (provided: any, state: any) => ({
        ...provided,
        maxHeight: '124px',
        overflow: 'auto'
      }),
      input: (provided: any, state: any) => ({
        ...provided,
        minWidth: '20%'
      })
    }),
    []
  );

  // let newOptions = options;

  // // if (allowNull) {
  // //   newOptions = [{ label: 'null', value: '\x00' }, ...newOptions];
  // // }

  // if (allowSelectAll) {
  //   newOptions = [{ label: 'All', value: '*' }, ...newOptions];
  // }

  const valueRef = useRef(values);
  valueRef.current = values;

  const selectAllOption = {
    value: '*<SELECT_ALL>*',
    label: 'All'
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === ungroupOptions(options).length;

  const isOptionSelected = (option: any) =>
    valueRef.current.some(({ value }: any) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () =>
    allowSelectAll ? [selectAllOption, ...options] : options;

  const onChangeWithSelectAll = (newValue: any, actionMeta: any) => {
    if (!actionMeta) {
      onChange(newValue);
      return;
    }
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.value === selectAllOption.value) {
      onChange(ungroupOptions(options));
    } else if (
      (action === 'deselect-option' &&
        option.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value)
    ) {
      onChange([]);
    } else if (
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      onChange(
        ungroupOptions(options).filter(
          (currOption: any) => !groupValueEquals(currOption.value, option.value)
        )
      );
    } else {
      onChange(newValue || []);
    }
  };
  return (
    <Fragment>
      <Select
        options={getOptions()}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          Group,
          ValueContainer
        }}
        onChange={onChangeWithSelectAll}
        value={values}
        className={'select-special-container'}
        classNamePrefix="tw-select"
        styles={customStyles}
        placeholder={placeholder}
        filterOption={filterOption}
        isOptionSelected={isOptionSelected}
      />
    </Fragment>
  );
};

export default SelectMultiWithCheckBox;
