import L, { LatLngBoundsLiteral } from 'leaflet';
import millify from 'millify';
import { useEffect, useRef, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

const CustomAreaDraw = ({
  currentRects,
  setCurrentRects,
  coordinatesSearchParams,
  setCoordinatesSearchParams
}: {
  currentRects: any;
  setCurrentRects: (rects: L.FeatureGroup) => void;
  coordinatesSearchParams?: any[];
  setCoordinatesSearchParams: (latlngs: any[]) => void;
}): JSX.Element => {
  const [rectGroup, setRectGroup] = useState<L.FeatureGroup | null>(null);
  const featureGroupRef = useRef<L.FeatureGroup>(null);

  useEffect(() => {
    if (rectGroup) {
      setCurrentRects(rectGroup);
    }
  }, [rectGroup, setCurrentRects]);

  useEffect(() => {
    if (featureGroupRef.current && currentRects) {
      currentRects.eachLayer((layer: any) => {
        featureGroupRef.current?.addLayer(layer);
      });
    }
  }, [currentRects]);

  useEffect(() => {
    if (featureGroupRef.current && coordinatesSearchParams) {
      const fg = featureGroupRef.current;
      fg.clearLayers();
      if (!coordinatesSearchParams.length) return;
      coordinatesSearchParams.forEach((shape) => {
        if (shape.type === 'rect') {
          const { latFrom, latTo, lngFrom, lngTo } = shape.coordsEntry;
          const bounds: LatLngBoundsLiteral = [
            [latFrom, lngFrom],
            [latTo, lngTo]
          ];
          const rect = L.rectangle(bounds, {
            weight: 2,
            color: '#283F3B',
            fill: true,
            fillColor: '#99DDC8'
          });
          fg.addLayer(rect);
        } else if (shape.type === 'poly') {
          const latlngs = shape.coordsEntry.map(
            (coord: any) => [coord.lat, coord.lng] as [number, number]
          );
          const polygon = L.polygon(latlngs, {
            weight: 2,
            color: '#8B4000',
            fill: true,
            fillColor: '#FFA500'
          });
          fg.addLayer(polygon);
        }
      });
    }
  }, [coordinatesSearchParams]);

  const handleDrawShape = (event: any) => {
    saveCurrentRects();
  };

  const handleDeleteRect = (event: any) => {
    saveCurrentRects();
  };

  const isRectangle = (latlngs: any[]) => {
    if (latlngs.length !== 5) return false;
    const [bl, br, tr, tl, closing] = latlngs;
    if (bl[0] !== closing[0] || bl[1] !== closing[1]) return false;
    const dist = (p1: any, p2: any) =>
      Math.sqrt((p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2);
    return (
      dist(bl, br) === dist(tl, tr) &&
      dist(bl, tl) === dist(br, tr) &&
      dist(bl, br) > 0 &&
      dist(bl, tl) > 0
    );
  };

  const saveCurrentRects = () => {
    const rects = featureGroupRef.current;
    if (rects) setRectGroup(rects);

    const coordinatesArray: any[] = [];

    rects?.eachLayer((layer: any) => {
      const geoJson = layer.toGeoJSON();

      const latlngs = geoJson.geometry.coordinates[0];
      if (isRectangle(latlngs)) {
        const coordsEntry = {
          latFrom: millify(latlngs[0][1], { precision: 4 }),
          latTo: millify(latlngs[1][1], { precision: 4 }),
          lngFrom: millify(latlngs[0][0], { precision: 4 }),
          lngTo: millify(latlngs[2][0], { precision: 4 })
        };
        coordinatesArray.push({ type: 'rect', coordsEntry });
      } else {
        const latlngPairs = latlngs.map((latlng: any) => ({
          lat: millify(latlng[1], { precision: 4 }),
          lng: millify(latlng[0], { precision: 4 })
        }));
        coordinatesArray.push({ type: 'poly', coordsEntry: latlngPairs });
      }
    });
    setCoordinatesSearchParams(coordinatesArray);
  };

  return (
    <FeatureGroup ref={featureGroupRef}>
      <EditControl
        position="topleft"
        onCreated={handleDrawShape}
        onDeleted={handleDeleteRect}
        onMounted={() => setRectGroup(featureGroupRef.current)}
        edit={{
          edit: false,
          delete: false
        }}
        draw={{
          polygon: {
            allowIntersection: false,
            drawError: {
              color: '#e1e100',
              message: "<strong>Oh snap!<strong> you can't draw that!"
            },
            shapeOptions: {
              weight: 2,
              clickable: true,
              color: '#8B4000',
              fill: true,
              fillColor: '#FFA500'
            },
            metric: ['m', 'km']
          },
          circle: false,
          marker: false,
          polyline: false,
          circlemarker: false,
          rectangle: {
            shapeOptions: {
              weight: 2,
              clickable: true,
              color: '#283F3B',
              fill: true,
              fillColor: '#99DDC8'
            },
            metric: ['m', 'km']
          }
        }}
      />
    </FeatureGroup>
  );
};

export default CustomAreaDraw;
