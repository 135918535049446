import { Fragment } from 'react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';

import apiAgent from '../../../api/apiAgent';
import { SmallLoader } from '../../../components';
import { ElementTextClassnames } from '../../../interfaces';
import GridChild from '../../../components/GridChild';
import millify from 'millify';

const StatWithLoading = ({
  stat,
  isLoading,
  isError,
  statClassName
}: {
  stat?: string;
  isLoading: boolean;
  isError: boolean;
  statClassName?: string;
}): JSX.Element => {
  if (isError)
    return (
      <div className={classNames(ElementTextClassnames.danger, 'text-center')}>
        Error
      </div>
    );

  return isLoading ? (
    <SmallLoader className="text-sm mx-auto table" />
  ) : (
    <div className={statClassName}>{stat}</div>
  );
};

const Stats = (): JSX.Element => {
  const {
    isLoading: isLoadingTotalIfasSeen,
    isError: isErrorTotalIfasSeen,
    data: totalIfasSeen
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    ['totalIfasSeen'],
    async () => {
      const { data } = await apiAgent.Stats.getTotalIfasSeenCount();
      return millify(parseInt(data.data.count));
    },
    { staleTime: 60 * 1000, cacheTime: 60 * 1000, retry: false }
  );

  const {
    isLoading: isLoadingActiveIFAseen,
    isError: isErrorActiveIFAseen,
    data: activeIFAseen
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    ['activeIFAseen'],
    async () => {
      const { data } = await apiAgent.Stats.getActiveIFAsCount();
      return millify(parseInt(data.data.count));
    },
    { staleTime: 10 * 1000, cacheTime: 60 * 1000, retry: false }
  );

  const {
    isLoading: isLoadingActiveTargetedIFAseen,
    isError: isErrorActiveTargetedIFAseen,
    data: activeTargetedIFAseen
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    ['activeTargetedIFAseen'],
    async () => {
      const { data } = await apiAgent.Stats.getActiveTargetedIFAsCount();
      return millify(parseInt(data.data.count));
    },
    { staleTime: 10 * 1000, cacheTime: 60 * 1000, retry: false }
  );

  const {
    isLoading: isLoadingInactiveTargetedIFAseen,
    isError: isErrorInactiveTargetedIFAseen,
    data: inactiveTargetedIFAseen
  }: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  } = useQuery(
    ['inactiveTargetedIFAseen'],
    async () => {
      const { data } = await apiAgent.Stats.getInactiveTargetedIfasCount();
      return millify(parseInt(data.data.count));
    },
    { staleTime: 30 * 1000, cacheTime: 60 * 1000, retry: false }
  );

  return (
    <Fragment>
      <GridChild className="col-span-2" title="Total IFAs seen">
        <StatWithLoading
          stat={totalIfasSeen}
          isLoading={isLoadingTotalIfasSeen}
          isError={isErrorTotalIfasSeen}
          statClassName="text-center text-3xl"
        />
      </GridChild>

      <GridChild className="col-span-2" title="Active IFAs">
        <StatWithLoading
          stat={activeIFAseen}
          isLoading={isLoadingActiveIFAseen}
          isError={isErrorActiveIFAseen}
          statClassName="text-center text-3xl"
        />
      </GridChild>
      <GridChild className="col-span-2" title="Active Targeted IFAs">
        <StatWithLoading
          stat={activeTargetedIFAseen}
          isLoading={isLoadingActiveTargetedIFAseen}
          isError={isErrorActiveTargetedIFAseen}
          statClassName={classNames(
            ElementTextClassnames.danger,
            'text-center text-3xl'
          )}
        />
      </GridChild>

      <GridChild className="col-span-2" title="Inactive Targeted IFAs">
        <StatWithLoading
          stat={inactiveTargetedIFAseen}
          isLoading={isLoadingInactiveTargetedIFAseen}
          isError={isErrorInactiveTargetedIFAseen}
          statClassName={classNames(
            ElementTextClassnames.warning,
            'text-center text-3xl'
          )}
        />
      </GridChild>
    </Fragment>
  );
};

export default Stats;
