//To modify React-Leaflet's popup styling.
import { Popup } from 'react-leaflet';
import styled from 'styled-components';
const StyledPopup = styled(Popup)`
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background: rgb(36 46 59 / var(--tw-bg-opacity));
  }
`;

//Function to generate random array of colors. Fixed first 8 colors, any more will be generated
const generateRandomColorArray = (numOfColors: number): string[] => {
  // console.log(`Generating ${numOfColors} colors.`);
  const characters = '0123456789ABCDEF';
  // const colorsArray = [
  //   '#003f5c',
  //   '#2f4b7c',
  //   '#665191',
  //   '#a05195',
  //   '#d45087',
  //   '#f95d6a',
  //   '#ff7c43',
  //   '#ffa600'
  // ];
  const colorsArray = [
    '#03071E', //Unclustered always use this color
    '#370617',
    '#6A040F',
    '#9D0208',
    '#D00000',
    '#DC2F02',
    '#E85D04',
    '#F48C06',
    '#FAA307',
    '#FFBA08',
    '#00ffff',
    '#00fa9a',
    '#ba55d3',
    '#7cfc00',
    '#ff8c00',
    '#ff0000',
    '#000080',
    '#808000',
    '#7f0000'
  ];

  for (let i = 0; i < numOfColors - 8; i++) {
    let color = '#';
    for (let j = 0; j < 6; j++) {
      color += characters[Math.floor(Math.random() * 16)];
    }
    colorsArray.push(color);
  }

  return colorsArray;
};

interface IFA {
  ifa: string;
  // alias?: string;
  latest_datetime: Date;
  count: number;
  // isDOI: boolean;
}
interface MapPoint {
  id: string;
  location: string;
  position: number[];
  IFAs: Map<string, IFA>;
}

interface Cluster {
  id: string;
  groupCount: number;
  mapPoints: Map<string, MapPoint>;
}

//Function to generate map points by clusters in batches
const generateMapPointsBatch = (
  current_data: Map<string, any>,
  uniqueMapPoints: number,
  new_data: any[]
) => {
  try {
    // console.log(`Inside generateMapPointsBatch()`);
    const clusters_map: Map<string, Cluster> = new Map(current_data);
    let totalMapPoints: number = uniqueMapPoints;

    new_data.every((row: any) => {
      const coordinates_name = `${row.latitude}, ${row.longitude}`;

      //==========Cluster Centric==========
      const cluster_name =
        row.cluster_tag === '' || row.cluster_tag === null
          ? 'Unclustered'
          : row.cluster_tag;

      // const alreadyExistCluster = clusters_map.filter((entry: Cluster) => {
      //   return (
      //     entry.id ===
      //     (cluster_name === '' || cluster_name === null
      //       ? 'Unclustered'
      //       : cluster_name)
      //   );
      // });

      //Check if cluster entry already exist
      if (clusters_map.has(cluster_name)) {
        // console.log(`Existing entry...updating...`);
        const existing_cluster: Cluster | undefined =
          clusters_map.get(cluster_name);

        if (existing_cluster !== undefined) {
          // console.log(
          //   `existing_cluster is ${typeof existing_cluster.mapPoints}`
          // );

          //Check if coordinates already exist
          if (existing_cluster.mapPoints.has(coordinates_name)) {
            const existing_coordinate: MapPoint | undefined =
              existing_cluster.mapPoints.get(coordinates_name);

            //Add in IFA if not already there
            if (existing_coordinate !== undefined) {
              const current_ifas: Map<string, IFA> = existing_coordinate.IFAs;

              //If IFA already exist
              if (current_ifas.has(row.ifa)) {
                const existing_ifa = current_ifas.get(row.ifa);

                //Update latest timestamp occured here if applicable
                if (existing_ifa !== undefined) {
                  if (existing_ifa.latest_datetime < row.latest_datetime)
                    existing_ifa.latest_datetime = row.latest_datetime;

                  //add in count for occurrences
                  existing_ifa.count += row.count;

                  current_ifas.set(row.ifa, existing_ifa);
                }
              }
              //If not yet exist, add in the IFA and count
              else {
                existing_coordinate.IFAs.set(row.ifa, {
                  ifa: row.ifa,
                  // alias: doiRef.get(row.ifa) || 'None',
                  latest_datetime: row.latest_datetime,
                  count: row.count
                  // isDOI: doi_list.includes(row.ifa)
                });
              }
            }
          }

          //Coordinates doesn't exist in cluster yet, create it.
          else {
            // console.log(`Creating new coordinates ${coordinates_name}`);
            const entry: MapPoint = {
              id: coordinates_name,
              location: row.ext_location_id,
              position: [row.latitude, row.longitude],

              IFAs: new Map().set(row.ifa, {
                ifa: row.ifa,
                // alias: doiRef.get(row.ifa) || 'None',
                latest_datetime: row.latest_datetime,
                count: row.count
                // isDOI: doi_list.includes(row.ifa)
              })
            };

            existing_cluster.mapPoints.set(coordinates_name, entry);

            clusters_map.set(cluster_name, existing_cluster);
            totalMapPoints++;
          }
        }
      }

      //If cluster entry not yet exist, create it
      else {
        // console.log(`Adding new cluster entry... ${cluster_name}`);

        const entry: MapPoint = {
          id: coordinates_name,
          location: row.ext_location_id,
          position: [row.latitude, row.longitude],

          IFAs: new Map().set(row.ifa, {
            ifa: row.ifa,
            // alias: doiRef.get(row.ifa) || 'None',
            latest_datetime: row.latest_datetime,
            count: row.count
            // isDOI: doi_list.includes(row.ifa)
          })
        };

        const new_cluster: Cluster = {
          id: cluster_name,
          groupCount: clusters_map.size,
          mapPoints: new Map().set(coordinates_name, entry)
        };

        clusters_map.set(cluster_name, new_cluster);
        totalMapPoints++;
      }
      //==========End of Cluster Centric==========

      // if (totalMapPoints2 >= mapPointsLimit) return false; //Checker to prevent additional points to add after hitting 10k total map points

      return true; //If not it will continue to loop through all the data retrieved
    });

    // console.log(`clusters_map is`);
    // console.log(clusters_map);

    // console.log(`totalMapPoints is ${totalMapPoints}`);

    return {
      cluster_data: new Map(clusters_map),
      uniqueMapPoints: totalMapPoints
    };
  } catch (err: any) {
    console.log(`Error ${err}`);
    return err;
  }
};

export { generateMapPointsBatch, generateRandomColorArray, StyledPopup };
