import {
  ContainerFull,
  MainPanel,
  PageSubDescription,
  PageTitle
} from '../../components';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alerts from './detection/Alerts';
import ThresholdAlerts from './threshold/ThresholdAlerts';

enum TabType {
  error = -1,
  detection = 1,
  threshold = 2
}

const AlertsMain = (): JSX.Element => {
  const { search } = useLocation();
  const parameters = new URLSearchParams(search);

  const urlTab = parameters.get('tab');
  const alertRuleName = parameters.get('alert-rule-name');

  const getTab = (urlTab: string | null) => {
    switch (urlTab) {
      case 'detection':
        return TabType.detection;
      case 'threshold':
        return TabType.threshold;
      default:
        return TabType.detection;
    }
  };

  const [tab, setTab] = useState<number>(getTab(urlTab));

  const Content = () => {
    switch (tab) {
      case TabType.detection:
        return (
          <Alerts
            alertRuleName={
              getTab(urlTab) === TabType.detection ? alertRuleName : null
            }
          />
        );
      case TabType.threshold:
        return (
          <ThresholdAlerts
            alertRuleName={
              getTab(urlTab) === TabType.threshold ? alertRuleName : null
            }
          />
        );
    }
  };
  return (
    <ContainerFull>
      <MainPanel>
        <PageTitle>Alerts</PageTitle>
        <PageSubDescription>
          Main dashboard showing alerts generated and useful information.
        </PageSubDescription>
        <ul className="flex ">
          <TabButton
            label="Detection"
            isActive={tab === TabType.detection}
            handleClick={() => setTab(TabType.detection)}
          />
          <TabButton
            label="Threshold"
            isActive={tab === TabType.threshold}
            handleClick={() => setTab(TabType.threshold)}
          />
        </ul>
        <hr className={`mb-4 border-gray-400`} />
        {Content()}
      </MainPanel>
    </ContainerFull>
  );
};

const TabButton = ({
  label,
  isActive,
  handleClick
}: {
  label: string;
  isActive: boolean;
  handleClick: () => void;
}) => {
  return (
    <li
      className={`hidden md:block text-gray-400 w-min mr-5 py-2 ${
        isActive ? 'border-b-2 border-white' : ''
      }`}
    >
      <button
        className={`text-base w-fit hover:text-white ${
          isActive ? 'text-white' : ''
        }`}
        onClick={() => handleClick()}
      >
        {label}
      </button>
    </li>
  );
};

export default AlertsMain;
