import { ISortState } from '../interfaces';

export const SortableHeader = (
  header: string,
  accessor: string,
  sortState: ISortState,
  onClick?: (accessor: string) => void,
  thClassName?: string
) => {
  const notSelected = sortState.sortBy !== accessor;
  const baseClass =
    'min-w-max uppercase font-bold py-1 px-2 rounded hover:bg-background-alt-2';
  const className = notSelected
    ? baseClass + ` ${thClassName} text-gray-light`
    : baseClass + ` ${thClassName} text-white`;
  const caret = sortState.desc
    ? 'fa fa-caret-down ml-1'
    : 'fa fa-caret-up ml-1';
  const caretClass = notSelected ? 'fa fa-sort ml-1 opacity-30' : caret;
  return (
    <button
      onClick={onClick ? () => onClick(accessor) : () => {}}
      className={className}
    >
      {header}
      <i className={caretClass} />
    </button>
  );
};

export const addSortableHeadersToColumns = (
  priorityColumns: any,
  sortState: ISortState,
  sortFunction: (accessor: string) => void
) => {
  return priorityColumns.map((c: any) => {
    return !c.sortable
      ? c
      : {
          ...c,
          Header: SortableHeader(
            c.Header,
            c.accessor,
            sortState,
            sortFunction,
            c.thClassName
          )
        };
  });
};
