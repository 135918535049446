import { Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAtom } from 'jotai';

import { ReactComponent as Logo } from '../../assets/images/ensign-logo.svg';
import { Tooltip } from '../';
import { userProfileAtom } from '../../atoms';
import { ElementTextClassnames } from '../../interfaces';
import ChangelogModal from '../ChangelogModal';

const LogoHolder = (): JSX.Element => <Logo className="h-6 md:h-9 my-2" />;

interface NavButtonProps {
  children: React.ReactNode;
  to: string;
  className?: string;
}

const NavButton = ({
  children,
  to,
  className
}: NavButtonProps): JSX.Element => (
  <NavLink
    className={({ isActive }) =>
      classNames('text-base w-fit hover:text-white', className, {
        'text-white': isActive
      })
    }
    to={to}
  >
    {children}
  </NavLink>
);

const NavLi = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element => {
  return (
    <li
      className={`hidden md:block py-5 text-gray-400 w-full order-3 px-2 lg:px-4 md:py-0 md:w-min ${
        className ?? ''
      }`}
    >
      {children}
    </li>
  );
};

export const NavButtonText = ({
  iconClass,
  label
}: {
  iconClass: string;
  label: string;
}): JSX.Element => (
  <>
    <div className="lg:hidden text-inherit">
      <Tooltip icon={iconClass} tailwindColor="text-inherit opacity-inherit">
        {label}
      </Tooltip>
    </div>
    <span className="hidden lg:block">{label}</span>
  </>
);

const NavBarContent = (): JSX.Element => {
  const [userProfile] = useAtom(userProfileAtom);

  let shouldShowMenu = true;
  if (
    userProfile.skipTwoFactor === false &&
    userProfile.otpMicrosoftFlag === false
  ) {
    shouldShowMenu = false;
  }

  return (
    <Fragment>
      <ul className="px-6 h-full flex flex-wrap md:px-5 md:h-16 md:flex-nowrap md:items-center">
        {/* <li className={classNames('order-1', { 'flex-grow': !shouldShowMenu })}>
          <Link to="/home">
            <LogoHolder />
          </Link>
        </li> */}

        {shouldShowMenu && (
          <Fragment>
            <NavLi className="">
              <NavButton to="/home">
                <NavButtonText
                  iconClass="fa fa-map-location"
                  label="Dashboard"
                />
              </NavButton>
            </NavLi>
            <NavLi className="grow">
              <NavButton to="/alerts">
                <NavButtonText iconClass="fa fa-chart-bar" label="Alerts" />
              </NavButton>
            </NavLi>
            <NavLi>
              <NavButton to="/campaigns">
                <NavButtonText iconClass="fa fa-chart-bar" label="Campaigns" />
              </NavButton>
            </NavLi>
            <NavLi className="!w-32">
              <NavButton to="/cluster-tags">
                <NavButtonText
                  iconClass="fa fa-chart-bar"
                  label="Cluster Tags"
                />
              </NavButton>
            </NavLi>
            <NavLi className="">
              <NavButton to="/alias">
                <NavButtonText iconClass="fa fa-chart-bar" label="Aliases" />
              </NavButton>
            </NavLi>
            <NavLi className="!w-32">
              <NavButton to="/alert-rules">
                <NavButtonText
                  iconClass="fa fa-chart-bar"
                  label="Alert Rules"
                />
              </NavButton>
            </NavLi>
          </Fragment>
        )}

        {userProfile.id && (
          <Fragment>
            <NavLi>
              <NavButton
                to="/sys-logout"
                className={ElementTextClassnames.warning}
              >
                <NavButtonText
                  iconClass={'fas fa-sign-out-alt'}
                  label="Logout"
                />
              </NavButton>
            </NavLi>
            <NavLi>
              <div className="text-center">
                <div className="whitespace-nowrap text-sm">
                  Logged in as {userProfile.email}
                </div>
              </div>
            </NavLi>
            <NavLi>
              <Link to="/my/profile-dashboard">
                <i className="fas fa-2x fa-user-alt" />
              </Link>
            </NavLi>
            <NavLi>
              <ChangelogModal />
            </NavLi>
          </Fragment>
        )}
      </ul>
    </Fragment>
  );
};

const Navbar = (): JSX.Element => {
  return (
    <header className="shadow-md bg-background-alt">
      <NavBarContent />
    </header>
  );
};

export default Navbar;
