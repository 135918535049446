import React from 'react';
import classNames from 'classnames/bind';

const MainPanel = ({
  children,
  className,
  padding = true
}: {
  children: React.ReactNode;
  className?: string;
  padding?: boolean;
}): JSX.Element => (
  <div
    className={classNames(
      'w-full rounded-md bg-background-alt',
      { 'py-4 px-5': padding },
      className ?? ''
    )}
  >
    {children}
  </div>
);

export default MainPanel;
