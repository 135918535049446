import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  Container,
  FlashMessage,
  MainPanel,
  PageSubDescription,
  PageTitle
} from '../../../components';
import {
  ElementBgClassnames,
  ElementVariant,
  IFlashContainer
} from '../../../interfaces';
import apiAgent from '../../../api/apiAgent';
import { toastHandler } from '../../../utils';
import { FLASH_MESSAGES, HTTP_ERROR_CODES } from '../../../cnstants';
import ThresholdAlertRuleForm from './componenets/ThresholdAlertRuleForm';

const ThresholdAlertRulesCreate = (): JSX.Element => {
  const [flashContainer, setFlashContainer] = useState<IFlashContainer>({
    messages: '',
    variant: undefined
  });
  const [error, setError] = useState<any>();
  const navigate = useNavigate();

  const createThresholdAlertRule = async (params: {
    name: string;
    locationId: string;
    baseline: number;
    varianceLimitPercentage: number;
    status: boolean;
  }): Promise<boolean> => {
    try {
      const result: any =
        await apiAgent.ThresholdAlertRules.createThresholdAlertRule(params);

      if (result.data.status === 'success') {
        toastHandler({
          messages: 'Alert rule created successfully.',
          toastOptions: { type: 'success' }
        });
      } else {
        throw new Error(FLASH_MESSAGES.MSG_SERVER_DOWN);
      }

      return true;
    } catch (error: any) {
      if (error?.response?.status === HTTP_ERROR_CODES.BAD_REQUEST) {
        let data = error?.response?.data?.data;
        let message: any = '';
        if (data.duplcateNames && data?.duplicateNames?.length > 0) {
          message = 'alert name in use';
          setError(data);
        } else {
          message = error?.response?.data?.message;
        }

        setFlashContainer({
          messages: message,
          variant: ElementVariant.danger
        });
        toastHandler({
          messages: message,
          toastOptions: { type: 'error' }
        });
      } else {
        toastHandler({
          messages: error?.message,
          toastOptions: { type: 'error' }
        });
      }

      return false;
    }
  };

  const formikSchema = {
    enableReinitialize: true,
    initialValues: {
      name: '',
      location: '',
      baseline: 0,
      threshold: 1,
      isActive: true
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(
          /^[a-zA-Z0-9-_]+$/,
          'Name can only use alphanumeric characters without spaces'
        )
        .required('Alert rule name required')
        .trim(),

      location: Yup.string().required('At least one location required'),
      // .trim()
      baseline: Yup.number().min(0).integer(),
      // .nullable(false)
      threshold: Yup.number().min(0).max(100),
      // .nullable(false)
      isActive: Yup.boolean()
    }),
    onSubmit: async ({ ...values }: any, { resetForm }: any) => {
      const params = {
        name: values.name,
        locationId: values.location,
        baseline: values.baseline,
        varianceLimitPercentage: values.threshold,
        status: values.isActive
      };
      //call backend
      let flag: any;
      flag = await createThresholdAlertRule(params);

      if (flag) {
        resetForm();
        navigate('/alert-rules?tab=threshold');

        setFlashContainer({
          messages: '',
          variant: undefined
        });
      }
    },
    validateOnChange: false
  };

  return (
    <Container>
      <MainPanel className="mb-6">
        <PageTitle>Add Threshold Alert Rule</PageTitle>
        <PageSubDescription>
          Alerts will be generated when the difference between the actual number
          of unique IFAs detected and the baseline exceeds the threshold. This
          is based on a 1 hour window.
        </PageSubDescription>
        {(flashContainer.messages || []).length > 0 && (
          <FlashMessage
            messages={flashContainer?.messages || ''}
            variant={flashContainer.variant}
          />
        )}
        {error && (
          <div className={`${ElementBgClassnames.danger} mt-2 rounded p-2`}>
            <div>
              {error.duplicateNames.length > 0 ? 'Alert name in use' : ''}
            </div>
            {error.conflictingValues.map((conflictingValue: any) => {
              return (
                <div>{`Conflicting with Alert Rule(${
                  conflictingValue.name
                }), IFAs: ${conflictingValue.devices.join(
                  ','
                )}, Locations: ${conflictingValue.locations.join(',')}`}</div>
              );
            })}
          </div>
        )}
        <Formik {...formikSchema}>
          {(formikProps) => (
            <div>
              <ThresholdAlertRuleForm {...formikProps} />
              {/* <div>{JSON.stringify(formikProps.values)}</div> */}
            </div>
          )}
        </Formik>
      </MainPanel>
    </Container>
  );
};

export default ThresholdAlertRulesCreate;
