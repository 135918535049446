import { ElementVariant } from '../../interfaces';
import { Badge } from '..';

interface IBoolean {
  value: boolean;
}

const BadgeBoolean = ({ value }: IBoolean): JSX.Element => (
  <Badge
    className="mr-5"
    variant={value ? ElementVariant.success : ElementVariant.danger}
  >
    {value ? 'Yes' : 'No'}
  </Badge>
);

export default BadgeBoolean;
