const rz_dois = [
  {
    DOI: 'fa3ac41a-45bf-44c1-a520-96c680e58c8e'
  },
  {
    DOI: '9e2ed2f9-c2c4-4a47-994c-bf161f58beaf'
  },
  {
    DOI: '75e423ab-128e-48db-b317-d85847f720da'
  },
  {
    DOI: 'acf6d2ee-6c31-4b4a-afe0-09311987c3e9'
  },
  {
    DOI: '036b2a56-c2ce-4230-9462-9e97d0e6a255'
  },
  {
    DOI: 'c8b643b5-d24a-45d5-84c4-ab26cc74c2c5'
  },
  {
    DOI: 'fc9a2ca1-3115-440a-a23c-1765e1f3d0bf'
  },
  {
    DOI: '1e837730-1fa9-4aad-87b9-5a83639fb4e4'
  },
  {
    DOI: 'af0bfcdc-aa5e-4e81-95a8-dd999f548dac'
  },
  {
    DOI: 'fea1f045-30e5-48ab-b768-c15983773d60'
  },
  {
    DOI: 'fb811d7c-143a-4349-aa12-9d44592a38df'
  },
  {
    DOI: '807287c9-756d-4d17-abff-8b084c6dfaa7'
  },
  {
    DOI: '8777958e-2666-406d-ac63-a35427dfd271'
  },
  {
    DOI: '1609308e-c8b1-3db2-a01c-a0e609ed64fc'
  },
  {
    DOI: 'cc0e224e-17ec-46F1-asd0-ad10269ff2bd'
  },
  {
    DOI: '6d492f83-52c9-4492-9285-9e777810b5b4'
  },
  {
    DOI: 'cbcba285-935e-47c0-9b50-b72570af3205'
  },
  {
    DOI: 'c1ddea9f-b7eb-4704-90fc-da0e8f02f6b8'
  },
  {
    DOI: 'fce6d473-515d-408d-bf50-d43ed18bbd79'
  },
  {
    DOI: '4a979e72-1e2f-4276-8a39-cbf05cde9dde'
  },
  {
    DOI: '570754a9-5936-45a8-86a9-7f418661500a'
  },
  {
    DOI: '28824f50-c0fd-4ace-97a1-f21ed2e90702'
  },
  {
    DOI: '2b6ea554-a77f-4d02-9301-bc935d44daa8'
  },
  {
    DOI: '2162c464-944e-46e5-bae3-1223c39d0f99'
  },
  {
    DOI: '8262acb5-e1c7-4a22-87e1-5647591cc7b6'
  },
  {
    DOI: '0195ea7a-409e-40af-bb41-f522c76944ac'
  },
  {
    DOI: '7c26eaca-11be-42c9-8497-e5258c30ad21'
  },
  {
    DOI: 'd0402dd5-f6ed-4588-9aa7-299393956f9b'
  },
  {
    DOI: '6915c7a4-aef6-494b-8b5a-34c786f5f046'
  },
  {
    DOI: '6376f98e-275f-42de-aa0f-c91008795f58'
  },
  {
    DOI: 'ac08fa48-e4d9-43a0-ac71-a6c1a768e5e7'
  },
  {
    DOI: '8ee0b019-435d-42a3-a07c-ead4d8fc3b4e'
  },
  {
    DOI: '5aaa5508-cd51-40c0-835f-35247129e73f'
  },
  {
    DOI: 'f930f18b-e6fc-3cdd-ad13-000e8ed6d321'
  },
  {
    DOI: 'ad5edae9-e690-4482-af27-3296d1d61f23'
  },
  {
    DOI: '5dfcc5e7-0fb7-40c7-998d-f134c6038b8c'
  },
  {
    DOI: 'bb343a09-0927-47b8-873b-d3f9e6fe9eab'
  },
  {
    DOI: '202296b0-1c0f-3ae0-13f1-0a14142b8d06'
  },
  {
    DOI: 'be7e270f-0361-4a82-a1e0-4a425939064b'
  },
  {
    DOI: 'd99bb898-c91f-44e5-b77d-2efd170778bf'
  },
  {
    DOI: 'bf484043-23ee-44ad-adf7-1c9753040b54'
  },
  {
    DOI: '1d1316a5-a15b-4ec3-8d1a-326daf7187f0'
  },
  {
    DOI: '9bd53217-a3a4-4cc8-8816-3c93b1a8f682'
  },
  {
    DOI: 'c00a7ea2-72b7-45bc-a859-57738d0dae84'
  },
  {
    DOI: 'e9a65050-5db4-4845-8672-a4144ce4ee1c'
  },
  {
    DOI: 'c8541df0-1715-45b8-a767-60738e76234d'
  },
  {
    DOI: '40883de2-0a66-34c6-b106-af0d8b4049a0'
  },
  {
    DOI: 'ce38772e-12f5-4d6d-8d4d-904ae3ff11d2'
  },
  {
    DOI: 'ef6ed16f-8d00-3b00-ab34-3db49b0b61f8'
  },
  {
    DOI: 'de1d0ba0-d69d-3a38-2666-89ae9a629cc4'
  },
  {
    DOI: '83dbb515-d05c-425d-81ed-df887d393745'
  },
  {
    DOI: '225b2cf5-5157-4364-8797-ff772f6bddb1'
  },
  {
    DOI: 'c0066ed3-2bb8-3061-16ff-b14189ef6eb4'
  },
  {
    DOI: '83999349-9dee-3ead-b864-da4e6f6ad040'
  },
  {
    DOI: '3938f6eb-bbb8-4e40-9ae8-d57f9948f614'
  },
  {
    DOI: 'c688636a-7d58-4842-8af4-928943f565ce'
  },
  {
    DOI: 'f771a046-9b1e-4148-ad17-ad06191fe0f2'
  },
  {
    DOI: '7c975570-0d22-4249-8749-cc4d54d6889a'
  },
  {
    DOI: 'c13c881a-0208-30fa-b36d-2e1c816a41d6'
  },
  {
    DOI: '1af0d61f-a0ce-44fe-a671-946e762ceb68'
  },
  {
    DOI: '2ba34d1e-9dcf-482c-8322-720cdaae181d'
  },
  {
    DOI: 'dcde1406-88a5-4d99-ae31-e5d5efd80a01'
  },
  {
    DOI: 'a45ac5fd-a8f2-4359-b135-396f58e72ddc'
  },
  {
    DOI: '7dc57e65-9d8c-4828-a31d-b1dd71f2b18b'
  },
  {
    DOI: '97d0aa47-da5e-438f-bfc3-93435b54cae9'
  },
  {
    DOI: '8f295a79-8fbb-408b-ac15-21496ed6d4e5'
  },
  {
    DOI: 'cf1ab4da-a6d3-321c-2c0e-3491f10fb666'
  },
  {
    DOI: '9c876822-5c75-493d-9c5d-d20520d0c769'
  },
  {
    DOI: '1d64e93b-0d02-4b2e-91c4-9e46aeedfcc5'
  },
  {
    DOI: 'eeb04ce6-5adf-4d5e-a7f4-28217a946d0c'
  },
  {
    DOI: '00f08861-6d2c-3268-ade0-b0f4c0966a43'
  },
  {
    DOI: '08559f36-6c21-4231-b88f-b55238786b55'
  },
  {
    DOI: 'e138a46f-64b5-46ce-9c2c-70e1b2ffc2d7'
  },
  {
    DOI: '94608c52-2669-44d2-a58d-af16e2d91e47'
  },
  {
    DOI: '6916fdd7-6437-4f79-b616-8bbbefbe8030'
  },
  {
    DOI: 'cf121b13-5acb-4d70-8440-a5dac365b120'
  },
  {
    DOI: '893d63bf-1a80-33e1-ab64-13bbb0d90fae'
  },
  {
    DOI: 'bc466d6a-0916-3a6f-b039-9be6d3fe036f'
  },
  {
    DOI: 'e3ce259a-cc78-4525-a0da-07c48642920e'
  },
  {
    DOI: 'fb0f4d9e-936c-3818-266d-2d9df216b6e9'
  },
  {
    DOI: 'a4c9c3e3-348e-482b-afdb-5cf8f5531ef1'
  },
  {
    DOI: '109636ed-55d7-4087-b816-8e500ade5f12'
  },
  {
    DOI: '57f4fb7b-b4f2-45da-ae1a-e4df548b335e'
  },
  {
    DOI: '7a095f6e-4087-4e3b-9221-8e75b4c06fd0'
  },
  {
    DOI: '8353ff75-e73f-4f8f-9741-8f852267378f'
  },
  {
    DOI: '3a1d6d5c-2c1c-49bd-b16c-8498b350cfa7'
  },
  {
    DOI: 'c3dd94d2-d3a5-4f16-a998-f495c53221a4'
  },
  {
    DOI: '9408f161-495c-43e2-8ce2-1a41578d8cac'
  },
  {
    DOI: '5864ee43-1d3e-451a-afbe-bd58b456a7af'
  },
  {
    DOI: '8360d9ba-1eec-320a-16ae-6b90eda12e9d'
  },
  {
    DOI: '1a09f06a-7f02-4c56-81b4-ec7e16ce0409'
  },
  {
    DOI: '45060542-7420-4924-a291-f7a3c626832b'
  },
  {
    DOI: 'c4f1781f-7edd-44b8-a432-dbd20aff5670'
  },
  {
    DOI: 'eaec5730-d1a9-4507-ab59-515cce8293e0'
  },
  {
    DOI: '72c01754-04f4-4ddf-87e7-ce8116589a53'
  },
  {
    DOI: '5da7c3ac-1083-4441-8095-ce5ba07dc382'
  },
  {
    DOI: 'e3fea683-1a19-4364-92a1-5278a49bebcb'
  },
  {
    DOI: 'b899b48e-addf-40a1-9101-44f910f8ddc4'
  },
  {
    DOI: 'bd26b6f0-e9be-36ba-1e6b-4a0e26d8e19b'
  }
];

export default rz_dois;
