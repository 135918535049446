import authConstants from './constants';
import Cookie from './Cookie';

const { AUTH_TOKEN_KEY } = authConstants;

const jwtCookie = new Cookie(AUTH_TOKEN_KEY || '');
const loginCookie = new Cookie(`${AUTH_TOKEN_KEY}-login` || '');

const clearJwtCookie = (): void => {
  jwtCookie.delete();
};
const hasJwtCookie = (): boolean => !!jwtCookie.get();

const clearLoginCookie = (): void => {
  loginCookie.delete();
};

export {
  hasJwtCookie,
  clearJwtCookie,
  jwtCookie,
  loginCookie,
  clearLoginCookie
};
