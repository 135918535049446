export const convertToMilliseconds = (minutes: number): number =>
  minutes * 60 * 1000;

export const DateAfterXminutes = (minutes: number): Date =>
  new Date(new Date().getTime() + convertToMilliseconds(minutes));

export const sleep = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export { default as toastHandler } from './toastHandler';
export { default as formValidation } from './formValidation';
export { default as validationHandler } from './validationHandler';
export { default as loadingStateHandler } from './loadingStateHandler';

export {
  dateFormatter,
  strftime,
  formatDateForListings,
  formatDateForDetails,
  nl2br
} from './formatter';
