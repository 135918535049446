import { FormEvent, ChangeEvent } from 'react';

enum ElementVariant {
  primary = 'primary',
  primaryAlt = 'primaryAlt',
  primaryAltTwo = 'primaryAltTwo', // reserved as default for interactive buttons
  secondary = 'secondary',
  danger = 'danger',
  success = 'success',
  warning = 'warning',
  info = 'info'
}

enum ElementTextClassnames {
  primary = 'text-text-primary',
  primaryAlt = 'text-text-alt',
  primaryAltTwo = 'text-text-alt',
  secondary = 'text-text-alt',
  danger = 'text-red-400',
  success = 'text-green-500',
  warning = 'text-yellow-500',
  info = 'text-blue-400',
  sysGenId = 'text-green-300',
  sysGenIdAlt = 'text-purple-400',
  sysGenDate = 'text-yellow-500'
}

enum ElementBgClassnames {
  primary = 'bg-background-base',
  primaryAlt = 'bg-background-alt',
  primaryAltTwo = 'bg-background-alt-2',
  secondary = 'text-black bg-gray-200',
  danger = 'text-white bg-red-400',
  success = 'text-white bg-green-500',
  warning = 'text-white bg-yellow-500',
  info = 'text-white bg-blue-400'
}

enum ElementBgClassnamesWithHover {
  primary = 'bg-background-base hover:bg-gray-700',
  primaryAlt = 'bg-background-alt hover:bg-gray-700',
  primaryAltTwo = 'bg-background-alt-2 hover:bg-button-hover',
  secondary = 'text-black bg-gray-200 hover:bg-gray-300',
  danger = 'text-white bg-red-500 hover:bg-red-400',
  success = 'text-white bg-green-600 hover:bg-green-500',
  warning = 'text-white bg-yellow-500 hover:bg-yellow-400',
  info = 'text-white bg-blue-500 hover:bg-blue-400'
}

type FormButtonType = FormEvent<HTMLButtonElement>;
type FormInputType = ChangeEvent<HTMLInputElement>;
type FormSelectType = FormEvent<HTMLSelectElement>;
type FormType = FormEvent<HTMLFormElement>;

interface IErrorInput {
  error?: boolean;
}
interface IFlashContainer {
  messages: string | string[];
  variant?: ElementVariant;
}

interface ISortState {
  sortBy: string;
  desc: boolean;
}

interface IPaginationState {
  totalItems: number;
  currentPageCount: number;
  itemsPerPage: number;
  totalPages: number;
}


export type {
  FormButtonType,
  FormInputType,
  FormSelectType,
  FormType,
  IErrorInput,
  IFlashContainer,
  ISortState,
  IPaginationState
};

export {
  ElementVariant,
  ElementTextClassnames,
  ElementBgClassnames,
  ElementBgClassnamesWithHover
};
