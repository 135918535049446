import React, { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames/bind';
import { ErrorMessage } from 'formik';
import _ from 'lodash';

import { ElementBgClassnames, ElementTextClassnames } from '../../interfaces';

const Select = ({
  className,
  children,
  ...restProps
}: React.SelectHTMLAttributes<HTMLSelectElement>): JSX.Element => (
  <select
    className={classNames(
      className,
      'block w-full rounded-md border-transparent border focus:border-gray-300 focus:ring-0',
      ElementBgClassnames.primary,
      'disabled:opacity-60'
    )}
    {...restProps}
  >
    {children}
  </select>
);

type DropdownProps = {
  options: Map<string, string | number>;
  onSelect: (e: any) => void;
  onChange?: (e: any) => void;
  value?: string;
  name?: string;
  disabled?: boolean;
  hasSelected?: boolean;
  canCancel?: boolean;
  placeholder?: string;
  withFormikError?: boolean;
};

const Dropdown = (props: DropdownProps): JSX.Element => {
  const {
    options,
    onSelect,
    onChange,
    value = '',
    disabled = false,
    placeholder = 'Select an option',
    withFormikError = false,
    name,
    ...restProps
  } = props;
  const [selected, setSelected] = useState<string | number>('');

  useEffect(() => {
    setSelected(value || '-1');
  }, [value]);

  const handleChange = (e: any) => {
    if (onChange) onChange(e);
    e.preventDefault();
    onSelect(e);
    setSelected(e.target.value);
  };

  return (
    <Fragment>
      <Select
        onChange={handleChange}
        value={selected}
        disabled={disabled}
        name={name}
        {...restProps}
      >
        <option hidden={!!placeholder} value="-1">
          {placeholder}
        </option>
        {[...options.entries()].map((entry) => (
          <option key={entry[0]} value={entry[1]}>
            {entry[0]}
          </option>
        ))}
      </Select>
      {withFormikError && (
        <ErrorMessage
          name={name || ''}
          render={(msg) => (
            <div
              className={classNames(ElementTextClassnames.danger, 'block mt-1')}
            >
              {_.upperFirst(_.toLower(_.startCase(msg)))}
            </div>
          )}
        />
      )}
    </Fragment>
  );
};

export default Dropdown;
