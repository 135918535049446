import Cookies from 'js-cookie';

import { DateAfterXminutes } from '../utils';

class Cookie {
  protected _key: string;
  protected _ttl: number | Date;

  constructor(key: string) {
    this._key = key;
    this._ttl = DateAfterXminutes(
      parseInt(process.env.REACT_APP_COOKIE_ABSOLUTE_TIMEOUT!)
    );
  }

  set(value: any): boolean {
    if (!value) return false;

    Cookies.set(this._key, value, {
      expires: this._ttl,
      secure: true
    });
    return true;
  }

  get(): string | undefined {
    return Cookies.get(this._key);
  }

  delete() {
    Cookies.remove(this._key);
  }
}

export default Cookie;
